import { useApiQuery } from 'hooks/useApiQuery'

export const useGetRedrawnPatrons = ({
  sweepstakeDrawingId,
}: {
  sweepstakeDrawingId?: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/sweepstake-winners/sweepstake-drawing/{sweepstakeDrawingId}/ineligible',
    queryKey: [
      '/admin/sweepstake-winners/sweepstake-drawing/{sweepstakeDrawingId}/ineligible',
    ],
    queryArgs: { sweepstakeDrawingId },
  })
