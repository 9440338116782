import { TextField } from 'components/Shared/TextField'
import { Controller, useFormContext, useFormState } from 'react-hook-form'
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Stack,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { type InferType } from 'yup'
import { DateTimeField } from '@mui/x-date-pickers'
import { type CampaignSchema } from './CampaignSchema'
import { LocationSelect } from './LocationSelect'
import { TypeOfMessageSelect } from './TypeOfMessageSelect'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SweepstakeSelect } from './SweepstakeSelect'
import { ExcludedOptionsSelect } from './ExcludedOptionSelect'
import { SelectPatronTier } from 'components/SelectPatronTier'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

export const CampaignMessageForm = () => {
  const { register, control, watch } =
    useFormContext<InferType<typeof CampaignSchema>>()
  const { errors, isValid } = useFormState<InferType<typeof CampaignSchema>>()
  const typeOfMessage = watch('typeOfMessage')

  return (
    <>
      <TypeOfMessageSelect />
      {typeOfMessage === 'Location' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have checked in to this
          location at least once.
        </Alert>
      )}
      {typeOfMessage === 'Location' && <LocationSelect />}

      {typeOfMessage === 'Corporate' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who are tied to a corporate
          account through check-ins.
        </Alert>
      )}
      {typeOfMessage === 'Corporate' && (
        <SelectCorporateAccount
          required
          {...register('corporateAccountId', { required: true })}
          label="Corporate Accounts"
          fullWidth
          corporateAccountIdKey="corporateAccountId"
          placeholder="Select a corporate account"
        />
      )}
      {typeOfMessage === 'Sweepstakes' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have earned an entry
          into this sweepstake.
        </Alert>
      )}
      {typeOfMessage === 'Sweepstakes' && <SweepstakeSelect />}
      {typeOfMessage === 'AllPatrons' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons. Optionally, you can exclude
          patrons that have ONLY visited one Establishment or the Establishments
          associated with an Organization or Corporate Account.
        </Alert>
      )}
      {typeOfMessage === 'AllPatrons' && <ExcludedOptionsSelect />}

      {typeOfMessage === 'AllPatronsByTier' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have the selected tier.
        </Alert>
      )}
      {typeOfMessage === 'AllPatronsByTier' && (
        <SelectPatronTier {...register('tierId')} label="Tier" fullWidth />
      )}

      <TextField
        label={'Subject Line'}
        required
        placeholder="Enter Subject Line"
        {...register('subjectLine')}
        helperText="50 charcters max"
        bottomContent={
          errors.subjectLine?.message && (
            <FormFieldErrorMessage message={errors.subjectLine.message} />
          )
        }
      />

      <TextField
        label="Message Content"
        required
        rows={4}
        multiline
        placeholder="Enter description of Message"
        {...register('messageContent')}
        bottomContent={
          errors.messageContent?.message && (
            <FormFieldErrorMessage message={errors.messageContent.message} />
          )
        }
      />
      <Box>
        <FormControl>
          <InputLabel required htmlFor="scheduledDate">
            Scheduled Date
          </InputLabel>
          <Controller
            name="scheduledDate"
            control={control}
            render={({ field }) => (
              <DateTimeField id="scheduledDate" fullWidth {...field} />
            )}
          />
        </FormControl>
      </Box>

      <Stack direction="row" gap={2} data-testid="buttons">
        <Button
          variant="outlined"
          component={Link}
          to="/Communications"
          aria-label="Cancel"
        >
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          variant="contained"
          type="submit"
          aria-label="Schedule message"
        >
          Schedule Message
        </Button>
      </Stack>
    </>
  )
}
