import { Box, Grid, Typography, useTheme } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { type LicensedEstablishmentDetailsProps } from '../types'

export const LinksDetailsDesktop = ({
  licensedEstablishment,
  onClickEdit,
}: LicensedEstablishmentDetailsProps) => {
  const theme = useTheme()
  const website = licensedEstablishment.website
    ? licensedEstablishment.website
    : '-'
  const socialMedia = licensedEstablishment.socialMedia
    ? licensedEstablishment.socialMedia
    : '-'

  return (
    <Box>
      <SectionHeader title="Links" onClickButton={onClickEdit} />

      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={13}>
          <Grid item md={3}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Website
            </Typography>
            <Typography variant="body1" pb={2} sx={{ wordBreak: 'break-word' }}>
              {website || '-'}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Social Media
            </Typography>
            <Typography variant="body1" pb={2} sx={{ wordBreak: 'break-word' }}>
              {socialMedia || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
