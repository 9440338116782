import { number, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Grid, Stack } from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { useSnackbar } from 'stores/useSnackbar'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { type OrganizationRequest } from 'types/api'
import { type RequiredId } from 'utils/typeUtils'
import { usePutOrganization } from 'hooks/api/usePutOrganization'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

const OrganizationSchema = object().shape({
  Organization: object({
    corporateAccountId: number(),
  }),
})

export const EditAssociatedCorporateAccountModal = ({
  organization,
  isModalOpen,
  toggleModalIsOpen,
}: {
  organization: RequiredId<OrganizationRequest>
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const heightModal = '50%'
  const formMethods = useForm({
    resolver: yupResolver(OrganizationSchema),
  })
  const { register, handleSubmit } = formMethods

  const handleSubmitWrapper = handleSubmit((data) => {
    putOrganizationMutation.mutate({
      ...organization,
      corporateAccountId: Number(data.Organization.corporateAccountId),
    })
  })

  const putOrganizationMutation = usePutOrganization({
    onSuccess: () => {
      setMessage('Organization has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Organization', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: '40%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={`Assign ${organization.name} to a Corporate Account`}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container direction={'column'}>
              <Stack direction="column">
                <SelectCorporateAccount
                  {...register('Organization.corporateAccountId')}
                  label="Assign to Corporate Account"
                  required
                  fullWidth
                  corporateAccountIdKey="corporateAccountId"
                  placeholder="Select a corporate account"
                />
              </Stack>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={putOrganizationMutation.isPending}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
