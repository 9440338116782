import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type FullLicensedEstablishmentUserDTO } from 'types/api'

const path = '/admin/licensed-establishments/users/{id}'

export const useGetLicensedEstablishmentUserById = ({
  licensedEstablishmentUserId,
}: {
  licensedEstablishmentUserId: number
}): UseQueryResult<FullLicensedEstablishmentUserDTO> => {
  const get = useTypedApiClient({
    path,
    method: 'get',
  })
  return useQuery({
    queryKey: [path, licensedEstablishmentUserId],
    queryFn: async () => {
      const { data } = await get({ id: licensedEstablishmentUserId })
      return data
    },
  })
}
