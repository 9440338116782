import { Box, Typography } from '@mui/material'
import { type RewardsCatalogReward } from 'utils/rewardsCatalogRewards'
import { RedemptionValueInput } from './RedemptionValueInput'
import { RemoveRewardMenu } from './RemoveRewardMenu'

interface AssignRedemptionMobileRowProps {
  row: RewardsCatalogReward
  onChangeAmountField: (rewardId: number, value: string) => void
  onBlurAmountField: (rewardId: number, value: string) => Promise<void>
  removeRewardCatalogReward: () => void
}

export const AssignRedemptionMobileRow = ({
  row: { name, rewardId, amount, amountError },
  onChangeAmountField,
  onBlurAmountField,
  removeRewardCatalogReward,
}: AssignRedemptionMobileRowProps) => (
  <Box sx={{ flex: 1, p: '16px' }}>
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" fontWeight="bold">
        {name}
      </Typography>

      <RemoveRewardMenu
        removeRewardCatalogReward={removeRewardCatalogReward}
        rewardId={rewardId}
      />
    </Box>
    <Typography variant="body1" sx={{ mt: '8px' }}>
      Item ID: {rewardId}
    </Typography>
    <Box sx={{ flex: 1, mt: '8px' }}>
      <RedemptionValueInput
        value={String(amount)}
        error={amountError}
        onChange={(
          event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          onChangeAmountField(Number(rewardId), event.target.value)
        }}
        onBlur={async (
          event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
          await onBlurAmountField(Number(rewardId), event.target.value)
        }}
        testId={`textInput-${String(rewardId)}`}
      />
    </Box>
  </Box>
)
