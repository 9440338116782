import { useApiQuery } from './useApiQuery'

export const useGetPatronActivityLog = ({
  patronId,
  numOfDays = 30,
}: {
  patronId: number
  numOfDays: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/activity-logs/attendants/patrons/{patronId}',
    queryKey: ['/admin/activity-logs-patron', patronId, numOfDays],
    queryArgs: {
      patronId,
      transactionTypes: [
        'CheckInPoint',
        'CheckInPointMultiplier',
        'ManualPoint',
        'MilestoneBirthday',
        'MilestoneWelcome',
        'MilestoneNextTier',
        'MilestoneWelcomeBack',
        'HolidayBonus',
      ],
      numOfDays,
    },
  })
