import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { has, map, startsWith } from 'lodash'
import { useNavBar } from './useNavBar'
import { NAVIGATION_BAR_WIDTH, NavBarRoutes } from './NavigationBar'
import { type NavBarDefWithChildren, type NavBarRouteDef } from 'types/nav'
import ChevronDown from 'assets/chevron-down.svg'
import {
  type SnackbarState,
  useSideNavCollapseStore,
} from 'stores/useSideNavCollapseStore'
import { useLayout } from 'hooks/useLayout'

export const isChildrenRouteDef = (
  x: NavBarRouteDef
): x is NavBarDefWithChildren => {
  return has(x, 'childrenRoutes')
}

export const Content = () => {
  const { isMobile } = useLayout()
  const location = useLocation()
  const navigate = useNavigate()
  const close = useNavBar((state) => state.close)
  const openCollapse = useSideNavCollapseStore((state) => state)
  const setOpenCollapse = useSideNavCollapseStore((state) => state.setOpen)

  const checkLocation = (routes: string[]): boolean => {
    for (const route of routes) {
      if (startsWith(location.pathname, route)) {
        return true
      }
    }
    return false
  }

  return (
    <List
      sx={{
        ml: isMobile ? -0.8 : 0,
        backgroundColor: 'white',
        flex: 1,
        width: isMobile
          ? `${NAVIGATION_BAR_WIDTH * 1.35}px`
          : `${NAVIGATION_BAR_WIDTH}px`,
        borderRightWidth: 1,
      }}
    >
      {map(NavBarRoutes, (routeDef, key) => {
        if (isChildrenRouteDef(routeDef)) {
          return (
            <Box key={'children-routes-box' + key}>
              <ListItem
                sx={{ px: 1, py: 0.5 }}
                key={'children-routes-listitem' + key}
              >
                <ListItemButton
                  key={'children-routes-button' + key}
                  onClick={() => setOpenCollapse(key)}
                >
                  <ListItemIcon
                    key={'children-routes-icon' + key}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {routeDef.icon}
                  </ListItemIcon>
                  <ListItemText
                    key={'children-routes-text' + key}
                    primary={<Typography variant="body2">{key}</Typography>}
                  />
                  <img
                    src={ChevronDown}
                    className={
                      openCollapse[key as keyof SnackbarState]
                        ? 'rotate-180'
                        : '' + 'pl-2'
                    }
                    key={'children-routes-img' + key}
                  />
                </ListItemButton>
              </ListItem>
              <Collapse
                in={openCollapse[key as keyof SnackbarState] as boolean}
                timeout="auto"
                unmountOnExit
                key={'children-routes-collapse' + key}
              >
                <List
                  component="div"
                  disablePadding
                  key={'children-routes-collapse-list' + key}
                >
                  {map(routeDef.childrenRoutes, (childRoute) => {
                    return (
                      <ListItem
                        sx={{ px: 1, py: 0.5 }}
                        key={'children-routes-listitem2' + childRoute.path}
                      >
                        <ListItemButton
                          key={'children-routes-listbutton' + childRoute.path}
                          onClick={() => {
                            navigate(childRoute.path)
                            close()
                          }}
                          selected={
                            startsWith(location.pathname, childRoute.path) ||
                            checkLocation(childRoute.associatedPageUrls)
                          }
                        >
                          <ListItemIcon
                            key={'children-routes-listicon' + childRoute.path}
                            sx={{
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          ></ListItemIcon>
                          <ListItemText
                            sx={{ px: 0.25 }}
                            key={'children-routes-listtext' + childRoute.path}
                            primary={
                              <Typography variant="label-listSubItem">
                                {childRoute.title}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </Collapse>
            </Box>
          )
        } else {
          return (
            <ListItem
              sx={{ px: 1, py: 0.5 }}
              key={'listitem-standard-routes-listitem' + key}
            >
              <ListItemButton
                key={'listitem-standard-routes-button' + key}
                onClick={() => {
                  navigate(routeDef.path)
                  close()
                }}
                selected={startsWith(location.pathname, routeDef.path)}
              >
                <ListItemIcon
                  key={'listitem-standard-routes-icon' + key}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {routeDef.icon}
                </ListItemIcon>
                <ListItemText
                  key={'listitem-standard-routes-text' + key}
                  primary={
                    <Typography variant="label-listItem">{key}</Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        }
      })}
    </List>
  )
}
