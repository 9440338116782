import { Avatar, Box, MenuItem, Typography } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import ChevronDown from 'assets/chevron-down.svg'
import React from 'react'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { useNavigate } from 'react-router-dom'

export const ProfileContent = () => {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
      account,
    })
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl != null) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box
      onClick={toggleMenu}
      className="flex flex-row justify-center h-full gap-2"
      id="profile-top-bar-box"
    >
      <Avatar
        className="h-8 w-8"
        sx={{
          backgroundColor: '#F9D578',
          color: 'black',
        }}
      >
        <Typography variant="body-2">
          {account?.name
            ?.split(' ')
            .map((n) => n[0])
            .join('')}
        </Typography>
      </Avatar>
      <Typography
        variant="body-2"
        className="flex items-center"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'flex' },
          cursor: 'default',
        }}
      >
        {account?.name}
      </Typography>
      <Box className="flex items-center">
        <img
          alt="Chevron Down"
          src={ChevronDown}
          className={open ? 'w-6 h-6 p-[2px] rotate-180' : 'w-6 h-6 p-[2px]'}
        />
      </Box>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box className="px-4 py-2 cursor-default">
          <Typography variant="body-2" fontWeight={400}>
            {account?.name}
          </Typography>
          <Typography variant="body-2" color={'GrayText'} fontWeight={400}>
            {account?.username}
          </Typography>
        </Box>
        {import.meta.env.VITE_ENABLE_SUPPORT_TOOLS === 'true' && (
          <MenuItem
            disableRipple
            className="px-3"
            onClick={() => {
              navigate('/SupportTools')
            }}
          >
            <Box className="py-2">
              <Typography variant="body-2" fontWeight={400}>
                Support Tools
              </Typography>
            </Box>
          </MenuItem>
        )}
        <MenuItem className="px-3" disableRipple onClick={handleLogout}>
          <Typography variant="label-form" color={'#CC2027'} className="w-full">
            Sign Out
          </Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}
