import { isPresent } from '@jjvgaming/player-payback-library'
import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import * as _ from 'lodash'
import { type LicensedEstablishment } from 'src/types/api'

const path = '/admin/corporate-accounts/licensed-establishments/{id}'
const method = 'get'

const sortByEntityIdDesc = <T extends { id?: number }>(
  a: T | undefined,
  b: T | undefined
) => {
  const aId = a?.id
  const bId = b?.id

  if (aId === undefined || bId === undefined) {
    return 0
  }

  if (aId < bId) {
    return 1
  }

  if (aId === bId) {
    return 0
  }

  if (aId > bId) {
    return -1
  }

  return 0
}

export const useGetLicensedEstablishmentById = (
  licensedEstablishmentId: number | undefined
): UseQueryResult<LicensedEstablishment> => {
  const get = useTypedApiClient({ path, method })
  return useQuery({
    queryKey: [path, licensedEstablishmentId],
    queryFn: async () => {
      if (_.isNil(licensedEstablishmentId)) {
        throw new Error('licensedEstablishmentId is required')
      }
      const { data } = await get({ id: licensedEstablishmentId })

      data.contacts?.sort(sortByEntityIdDesc)
      return data
    },
    enabled: isPresent(licensedEstablishmentId),
  })
}
