import { useTypedApiClient } from './useTypedApiClient'
import { type paths } from 'api/playerPayback/api'
import type {
  ApiError,
  OpArgType,
  OpReturnType,
} from 'openapi-typescript-fetch'
import { type UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'

function isCompleteArg<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
>(
  arg: Partial<OpArgType<paths[PathT][MethodT]>>
): arg is OpArgType<paths[PathT][MethodT]> {
  for (const key of Object.keys(arg)) {
    if (arg[key as keyof OpArgType<paths[PathT][MethodT]>] === undefined) {
      return false
    }
  }

  return true
}

type QueryParamsType<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
> = OpArgType<paths[PathT][MethodT]>

type QueryDataType<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
> = OpReturnType<paths[PathT][MethodT]>

type UseApiQueryParams<
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
> = UseQueryOptions<
  QueryParamsType<PathT, MethodT>,
  ApiError,
  QueryDataType<PathT, MethodT>
> & {
  enabled?: boolean
  method: MethodT
  path: PathT
  queryArgs: Partial<QueryParamsType<PathT, MethodT>>
}

export const useApiQuery = <
  PathT extends keyof paths,
  MethodT extends keyof paths[PathT],
>({
  enabled = true,
  method,
  path,
  queryKey,
  queryArgs,
  staleTime,
}: UseApiQueryParams<PathT, MethodT>) => {
  const { accessToken } = useAccessTokenContext()
  const apiCall = useTypedApiClient({ path, method })

  return useQuery({
    enabled: enabled && isCompleteArg(queryArgs),
    queryKey,
    queryFn: async () => {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      }

      const results = await apiCall(
        // This `as` is ugly, but we can be assured all params are available because of `enabled`
        queryArgs as QueryParamsType<PathT, MethodT>,
        { headers }
      )

      return results.data
    },
    staleTime,
  })
}
