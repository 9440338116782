import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'
import { useLayout } from 'hooks/useLayout'

export const LicensedEstablishmentDetailsTabs = ({
  licensedEstablishmentId,
  currentTab,
}: {
  licensedEstablishmentId: number
  currentTab: string
}) => {
  const { isMobile } = useLayout()
  return (
    <Tabs value={currentTab} variant={isMobile ? 'fullWidth' : 'standard'}>
      <Tab
        label="Overview"
        value={`/LicensedEstablishments/${licensedEstablishmentId}`}
        to={`/LicensedEstablishments/${licensedEstablishmentId}`}
        component={Link}
      />
      <Tab
        label="Reward Programs"
        value={`/LicensedEstablishments/${licensedEstablishmentId}/RewardsProgram`}
        to={`/LicensedEstablishments/${licensedEstablishmentId}/RewardsProgram`}
        component={Link}
      />
    </Tabs>
  )
}
