import { useLayout } from 'hooks/useLayout'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { dataTableIdFormatter, formatLETypeName } from 'utils/util'
import { DataTable } from 'components/Shared/DataTable'
import { useNavigate } from 'react-router-dom'
import { useGetAllRewardPrograms } from 'hooks/api/useGetAllRewardPrograms'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { type GridColDef, type GridEventListener } from '@mui/x-data-grid'
import { SearchField } from 'components/SearchField'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

const getColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Program Name',
    flex: 2,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {params.formattedValue}
      </p>
    ),
  },
  {
    field: 'type',
    headerName: 'Program Type',
    flex: 1,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {formatLETypeName(params.value)}
      </p>
    ),
  },
  {
    field: 'corporateAccount',
    headerName: 'Corporate Account',
    flex: 1,
    renderCell: (params) => (
      <p className="overflow-hidden whitespace-nowrap text-ellipsis">
        {params.row.corporateAccount?.name}
      </p>
    ),
  },
  {
    field: 'id',
    headerName: 'Program ID',
    flex: 0.5,
    valueFormatter: dataTableIdFormatter(isMobile),
  },
]

export const RewardsProgramPage = () => {
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const { isPending: isPendingRewardsPrograms, data: rewardsPrograms } =
    useGetAllRewardPrograms({})
  const [searchString, setSearchString] = useState('')

  const selectRow: GridEventListener<'rowClick'> = (
    params // GridRowParams
  ) => {
    navigate(`/Rewards/RewardPrograms/${params.id as number}`)
  }

  const filteredRows = useMemo(() => {
    return rewardsPrograms
      ? rewardsPrograms
          .filter((rp) =>
            rp.name.toLowerCase().includes(searchString.toLowerCase())
          )
          .sort((a, b) => {
            if (a.name === b.name) return 0
            return a.name > b.name ? 1 : -1
          })
          .sort((a) => {
            if (a.type === 'Global') {
              return -1
            } else {
              return 0
            }
          })
      : []
  }, [rewardsPrograms, searchString])

  return (
    <Page header={<PageHeader title="Reward Programs" eyebrow="REWARDS" />}>
      {isPendingRewardsPrograms ? (
        <ActivityIndicator />
      ) : (
        <Box>
          <SearchField
            placeholder="Search Reward Programs"
            onChange={(e) => setSearchString(e.target.value)}
            fullWidth
            sx={{ my: '16px' }}
          />
          <DataTable
            columns={getColumns(isMobile)}
            rows={filteredRows}
            sortModel={[]}
            onRowClick={selectRow}
          />
        </Box>
      )}
    </Page>
  )
}
