import { Box } from '@mui/material'
import { CellLink } from 'components/CellLink/CellLink'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { SwitchRewardsProgramActive } from 'components/RewardsProgram/SwitchRewardsProgramActive'
import type { RewardsProgram } from 'types/api'

export const CorporateAccountRewardsProgramMobileRow = ({
  row,
}: {
  row: RewardsProgram
}) => (
  <Box sx={{ flex: 1 }}>
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <CellLink
        text={row.name}
        fontWeight="bold"
        to={`/Rewards/RewardPrograms/${String(row.id)}`}
      />
      <StatusChip active={row.active} />
      <SwitchRewardsProgramActive rewardsProgram={row} />
    </Box>
  </Box>
)
