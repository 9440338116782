import { number, object, string } from 'yup'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material'
import { type ContestLE } from 'types/api'
import { UseDeleteAdminLEContest } from 'hooks/api/useDeleteAdminLEContest'
import { useLayout } from 'hooks/useLayout'
import { formatAddress } from 'utils/util'
import { compact } from 'lodash'
import { type Address } from 'types/address'

const UnenrollLocationDetailsSchema = object({
  contestLE: object({
    licensedEstablishmentName: string(),
    licensedNumber: string(),
    address: string(),
    contestId: number(),
    licensedEstablishmentId: number(),
  }),
})

export const UnenrollLocationModal = ({
  contestLE,
  isModalOpen,
  toggleModalIsOpen,
}: {
  contestLE: ContestLE
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const setMessage = useSnackbar((x) => x.setMessage)
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '85%'
  const licensedEstablishmentName = String(
    contestLE.licensedEstablishment?.name
  )
  const licensedNumber = String(contestLE.licensedEstablishment?.licenseNumber)
  const addresses = compact(contestLE.licensedEstablishment?.addresses)
  const address = addresses ? formatAddress(addresses as Address[]) : ''
  const contestId = contestLE.contestId
  const licensedEstablishmentId = contestLE.licensedEstablishmentId

  const formMethods = useForm({
    resolver: yupResolver(UnenrollLocationDetailsSchema),
  })

  const { register, handleSubmit } = formMethods

  const deleteLocationMutation = UseDeleteAdminLEContest({
    onSuccess: () => {
      setMessage('Location has been unenrolled.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error unenrolling location.', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const handleSubmitWrapper = handleSubmit(() => {
    deleteLocationMutation.mutate({
      contestId: Number(contestId),
      licensedEstablishmentId: Number(licensedEstablishmentId),
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            title="Unenroll Location"
            subTitle={`${contestLE?.contest?.name}`}
            toggleIsOpen={toggleModalIsOpen}
          />

          <ModalFullBody heightModal={heightModal}>
            <Typography variant="body-1" className="text-secondary" pb={'16px'}>
              <b>
                Are you sure you want to unenroll this location from the
                sweepstakes?
              </b>
            </Typography>
            <Typography variant="body-1" className="text-secondary" pb={'16px'}>
              By unenrolling for this location, they will not be able to
              participate into the sweepstakes.
              <br />
              You can always re-enroll at a later time.
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="contestLE.licensedEstablishmentName"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Licensed Establishment Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled
                  {...register('contestLE.licensedEstablishmentName')}
                  defaultValue={licensedEstablishmentName}
                />
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="contestLE.licensedNumber"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  License Number
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled
                  {...register('contestLE.licensedNumber')}
                  defaultValue={licensedNumber}
                />
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="contestLE.address"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Address
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  disabled
                  {...register('contestLE.address')}
                  defaultValue={address}
                />
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={deleteLocationMutation.isPending}
              type="submit"
              variant="contained"
            >
              Unenroll Location
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
