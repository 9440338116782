import { Typography } from '@mui/material'

export const Sublabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      variant="body-3"
      sx={{ display: 'block', transform: 'translateY(-8px)' }}
    >
      {children}
    </Typography>
  )
}
