import createClient from 'openapi-fetch'
import { type paths } from 'api/playerPayback/api'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'

export const useApiClient = () => {
  const { accessToken } = useAccessTokenContext()

  return createClient<paths>({
    baseUrl: import.meta.env.VITE_ADMIN_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}
