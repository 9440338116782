import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  InputLabel,
  Typography,
} from '@mui/material'
import { type RewardsProgram, type FullCheckInPolicy } from 'types/api'
import { usePutCheckInPolicy } from 'hooks/api/usePutCheckInPolicy'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'stores/useSnackbar'
import { bool, number, object } from 'yup'
import { isNil, reduce } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { type PolicyType } from './RewardsProgramTool'
import React, { useState } from 'react'
import { ActivityButton } from 'components/ActivityButton'
import { isPresent } from '@jjvgaming/player-payback-library'

const RewardProgramToolsSchema = object({
  CheckInThresholdMin: number().nullable(),
  CheckInsDailyMax: number().nullable(),
  PatronBirthdayMilestoneBonus: number().nullable(),
  PatronCheckInPointBonusMin: number().nullable(),
  PatronCheckInPointBonusMax: number().nullable(),
  EnablePatronBirthdayMilestoneBonus: bool().nullable(),
})

export const RewardsProgramToolContent = ({
  rewardsProgram,
  checkInPolicies,
}: {
  rewardsProgram: RewardsProgram
  checkInPolicies: FullCheckInPolicy[]
}) => {
  const assignedPolicies = React.useMemo(() => {
    const res: PolicyType = {
      CheckInsDailyMax: null,
      CheckInThresholdMin: null,
      PatronBirthdayMilestoneBonus: null,
      PatronCheckInPointBonus: null,
    }

    return reduce(
      checkInPolicies,
      (acc, policy) => {
        switch (policy.type) {
          case 'CheckInThresholdMin':
            return { ...acc, CheckInThresholdMin: policy }
          case 'CheckInsDailyMax':
            return { ...acc, CheckInsDailyMax: policy }
          case 'PatronBirthdayMilestoneBonus':
            return { ...acc, PatronBirthdayMilestoneBonus: policy }
          case 'PatronCheckInPointBonus':
            return { ...acc, PatronCheckInPointBonus: policy }
        }
        return acc
      },
      res
    )
  }, [checkInPolicies])

  const isGlobalRewardProgram = rewardsProgram.type === 'Global'
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const qc = useQueryClient()

  const putCheckInPolicyMutation = usePutCheckInPolicy({
    onSuccess: async () => {
      setSnackbarMessage('Successfully updated Policy', 'success')
      await qc.invalidateQueries({ queryKey: ['/admin/rewards-programs'] })
      await qc.invalidateQueries({
        queryKey: ['/admin/policies/checkins/rewards-programs'],
      })
    },
    onError: (err) => {
      console.error(err.data.message)
      setSnackbarMessage('An error occured updating Policy', 'error')
    },
  })

  const handlePolicyPut = async (updatedPolicy: Partial<FullCheckInPolicy>) => {
    if (isNil(updatedPolicy.rewardsProgramId)) {
      throw new Error('Reward Program is undefined')
    }

    if (isNil(updatedPolicy.id)) {
      throw new Error('Policy ID is undefined')
    }

    if (isNil(updatedPolicy.type)) {
      throw new Error('Policy Type is undefined')
    }

    await putCheckInPolicyMutation.mutateAsync({
      ...updatedPolicy,
      // need to set these explicitly to satisfy typescript
      id: updatedPolicy.id,
      rewardsProgramId: updatedPolicy.rewardsProgramId,
      type: updatedPolicy.type,
    })
  }

  const formMethods = useForm({
    resolver: yupResolver(RewardProgramToolsSchema),
  })

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { defaultValues, dirtyFields, errors },
    reset,
  } = formMethods

  const [isSubmitting, setIsSubmitting] = useState(false)

  React.useEffect(() => {
    if (defaultValues?.CheckInThresholdMin !== undefined) {
      // once we've loaded a value, don't load again
      return
    }

    reset({
      CheckInsDailyMax: assignedPolicies.CheckInsDailyMax?.checkInsDailyMax,
      CheckInThresholdMin:
        assignedPolicies.CheckInThresholdMin?.checkInThresholdMin,
      PatronCheckInPointBonusMin:
        assignedPolicies.PatronCheckInPointBonus?.patronCheckInPointBonusMin,
      PatronCheckInPointBonusMax:
        assignedPolicies.PatronCheckInPointBonus?.patronCheckInPointBonusMax,
      PatronBirthdayMilestoneBonus:
        assignedPolicies.PatronBirthdayMilestoneBonus
          ?.patronBirthdayMilestoneBonus,
      EnablePatronBirthdayMilestoneBonus:
        assignedPolicies.PatronBirthdayMilestoneBonus
          ?.enablePatronBirthdayMilestoneBonus ?? false,
    })
  }, [assignedPolicies, defaultValues])

  const onChangeCheckBox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('EnablePatronBirthdayMilestoneBonus', e.target.checked)
    },
    [setValue]
  )

  const onSubmit = handleSubmit(async (checkInPolicyForm) => {
    const promises: Array<Promise<void>> = []

    if (dirtyFields.CheckInThresholdMin === true) {
      promises.push(
        handlePolicyPut({
          id: assignedPolicies.CheckInThresholdMin?.id,
          type: 'CheckInThresholdMin',
          rewardsProgramId:
            assignedPolicies.CheckInThresholdMin?.rewardsProgramId,
          checkInThresholdMin: checkInPolicyForm.CheckInThresholdMin,
        })
      )
    }

    if (dirtyFields.CheckInsDailyMax === true) {
      promises.push(
        handlePolicyPut({
          id: assignedPolicies.CheckInsDailyMax?.id,
          type: 'CheckInsDailyMax',
          rewardsProgramId: assignedPolicies.CheckInsDailyMax?.rewardsProgramId,
          checkInsDailyMax: checkInPolicyForm.CheckInsDailyMax,
        })
      )
    }

    if (
      dirtyFields.PatronCheckInPointBonusMin === true ||
      dirtyFields.PatronCheckInPointBonusMax === true
    ) {
      promises.push(
        handlePolicyPut({
          id: assignedPolicies.PatronCheckInPointBonus?.id,
          type: 'PatronCheckInPointBonus',
          rewardsProgramId:
            assignedPolicies.PatronCheckInPointBonus?.rewardsProgramId,
          patronCheckInPointBonusMin:
            checkInPolicyForm.PatronCheckInPointBonusMin ??
            assignedPolicies.PatronCheckInPointBonus
              ?.patronCheckInPointBonusMin,
          patronCheckInPointBonusMax:
            checkInPolicyForm.PatronCheckInPointBonusMax ??
            assignedPolicies.PatronCheckInPointBonus
              ?.patronCheckInPointBonusMax,
        })
      )
    }

    if (dirtyFields.EnablePatronBirthdayMilestoneBonus === true) {
      promises.push(
        handlePolicyPut({
          id: assignedPolicies.PatronBirthdayMilestoneBonus?.id,
          type: 'PatronBirthdayMilestoneBonus',
          rewardsProgramId:
            assignedPolicies.PatronBirthdayMilestoneBonus?.rewardsProgramId,
          enablePatronBirthdayMilestoneBonus:
            checkInPolicyForm.EnablePatronBirthdayMilestoneBonus,
          patronBirthdayMilestoneBonus:
            checkInPolicyForm.PatronBirthdayMilestoneBonus ??
            assignedPolicies.PatronBirthdayMilestoneBonus
              ?.patronBirthdayMilestoneBonus,
        })
      )
    }

    if (dirtyFields.PatronBirthdayMilestoneBonus === true) {
      promises.push(
        handlePolicyPut({
          id: assignedPolicies.PatronBirthdayMilestoneBonus?.id,
          type: 'PatronBirthdayMilestoneBonus',
          rewardsProgramId:
            assignedPolicies.PatronBirthdayMilestoneBonus?.rewardsProgramId,
          enablePatronBirthdayMilestoneBonus: true,
          patronBirthdayMilestoneBonus:
            checkInPolicyForm.PatronBirthdayMilestoneBonus,
        })
      )
    }

    setIsSubmitting(true)
    try {
      await Promise.all(promises)
    } finally {
      setIsSubmitting(false)
    }
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <Stack
          spacing={2}
          className="mt-4 border-2 p-4 rounded-md border-lightGray"
        >
          <Typography variant="h3">Check In Policies</Typography>
          {assignedPolicies.CheckInThresholdMin && (
            <Box>
              <InputLabel htmlFor="CheckInThresholdMin">
                Check In Threshold Min
              </InputLabel>
              <TextField
                id="CheckInThresholdMin"
                {...register('CheckInThresholdMin')}
                type="number"
                error={isPresent(errors.CheckInThresholdMin)}
                helperText={errors.CheckInThresholdMin?.message}
              />
            </Box>
          )}
          {assignedPolicies.CheckInsDailyMax && (
            <Box>
              <InputLabel htmlFor="CheckInsDailyMax">
                Check In Daily Max
              </InputLabel>
              <TextField
                id="CheckInsDailyMax"
                {...register('CheckInsDailyMax')}
                type="number"
                error={isPresent(errors.CheckInsDailyMax)}
                helperText={errors.CheckInsDailyMax?.message}
              />
            </Box>
          )}
          {assignedPolicies.PatronCheckInPointBonus && (
            <>
              <Box>
                <InputLabel htmlFor="PatronCheckInPointBonusMin">
                  Patron Check In Bonus Min
                </InputLabel>
                <TextField
                  {...register('PatronCheckInPointBonusMin')}
                  id="PatronCheckInPointBonusMin"
                  type="number"
                  error={isPresent(errors.PatronCheckInPointBonusMin)}
                  helperText={errors.PatronCheckInPointBonusMin?.message}
                />
              </Box>
              <Box>
                <InputLabel htmlFor="PatronCheckInPointBonusMax">
                  Patron Check In Bonus Max
                </InputLabel>
                <TextField
                  {...register('PatronCheckInPointBonusMax')}
                  id="PatronCheckInPointBonusMax"
                  type="number"
                  error={isPresent(errors.PatronCheckInPointBonusMax)}
                  helperText={errors.PatronCheckInPointBonusMax?.message}
                />
              </Box>
            </>
          )}
          {assignedPolicies.PatronBirthdayMilestoneBonus && (
            <FormControlLabel
              control={
                <Checkbox
                  {...register('EnablePatronBirthdayMilestoneBonus', {
                    onChange: onChangeCheckBox,
                  })}
                  checked={watch('EnablePatronBirthdayMilestoneBonus') ?? false}
                />
              }
              label="Enable Patron Birthday Milestone Bonus"
            />
          )}

          {isGlobalRewardProgram &&
            assignedPolicies.PatronBirthdayMilestoneBonus
              ?.patronBirthdayMilestoneBonus && (
              <Box className="">
                <Box>
                  <InputLabel htmlFor="PatronBirthdayMilestoneBonus">
                    Patron Birthday Milestone Bonus
                  </InputLabel>
                  <TextField
                    {...register('PatronBirthdayMilestoneBonus')}
                    id="PatronBirthdayMilestoneBonus"
                    type="number"
                    helperText={
                      errors.PatronCheckInPointBonusMax?.message ??
                      'Global Reward Programs Only'
                    }
                    error={isPresent(errors.PatronCheckInPointBonusMax)}
                  />
                </Box>
              </Box>
            )}
          <Box>
            <ActivityButton
              type="submit"
              variant="contained"
              onClick={onSubmit}
              active={isSubmitting}
            >
              Save Changes
            </ActivityButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}
