import { Box, Typography } from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { isNil } from 'lodash'
import { formatDateTime } from 'utils/util'

interface BusinessDetailsSectionProps {
  corporateAccount: components['schemas']['FullCorporateAccountDTO']
}

export const BusinessDetailsSection = ({
  corporateAccount,
}: BusinessDetailsSectionProps) => {
  return (
    <Box>
      <Box className="mb-2">
        <Typography variant="h2" color="text.primary">
          Business Details
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '16px',
          borderWidth: { xs: 0, sm: 1 },
          borderColor: 'grey.300',
          borderRadius: 2,
          p: { sm: '16px', md: '20px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            columnGap: { xs: '8px', sm: '220px' },
            rowGap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
            }}
          >
            <Box>
              <Typography variant="body-2" color="text.secondary">
                Account Name
              </Typography>
              <Typography variant="body-1" color="text.primary">
                {corporateAccount.name}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
            }}
          >
            <Box>
              <Typography variant="body-2" color="text.secondary">
                ID Number
              </Typography>
              {!isNil(corporateAccount.id) && (
                <Typography variant="body-1" color="text.primary">
                  #{corporateAccount.id}
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
            }}
          >
            <Box>
              <Typography variant="body-2" color="text.secondary">
                Account Created
              </Typography>

              <Typography variant="body-1" color="text.primary">
                {formatDateTime(corporateAccount.createdOn, "P 'at' p")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
