import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

const path = '/admin/corporate-accounts/licensed-establishments/{id}'

export const useGetLicensedEstablishmentByIds = ({
  ids,
  enabled = true,
}: {
  ids: number[] | undefined
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path,
    method: 'get',
  })
  return useQueries({
    queries: (ids ?? []).map((licensedEstablishmentId) => ({
      queryKey: [path, licensedEstablishmentId],
      queryFn: async () => {
        const queryResult = await get({
          id: licensedEstablishmentId,
        })
        return queryResult.data
      },
      enabled,
    })),
  })
}
