import { object, string, boolean } from 'yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  applyPhoneMask,
  getPhoneMaskRegex,
  isPresent,
  removePhoneMask,
} from '@jjvgaming/player-payback-library'
import { type LEType } from 'types/api'
import { ActivityButton } from 'components/ActivityButton'
import { type EditLicensedEstablishmentDetailsProps } from '../types'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import React from 'react'
import { useLayout } from 'hooks/useLayout'

const LicensedEstablishmentBusinessDetailsSchema = object({
  LicensedEstablishment: object({
    legalCompanyName: string().required(
      'Legal Company Name is a required field'
    ),
    isDBACheckBoxChecked: boolean(),
    standardName: string().when('isDBACheckBoxChecked', {
      is: (val: boolean) => !val,
      then: (schema) => schema.required('DBA Name is a required field'),
    }),
    shortName: string(),
    type: string<LEType>().required(
      'Establishment Trade Type is a required field'
    ),
    licenseNumber: string().required('License Number is a required field'),
    phoneNumber: string()
      .matches(getPhoneMaskRegex, {
        message: 'Please enter a valid phone number',
      })
      .required('Phone number is a required field'),
  }),
})

export const EditLicensedEstablishmentBusinessDetails = ({
  licensedEstablishment,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: EditLicensedEstablishmentDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'

  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentBusinessDetailsSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = formMethods
  const legalCompanyName = watch('LicensedEstablishment.legalCompanyName')
  const isDBACheckBoxChecked = watch(
    'LicensedEstablishment.isDBACheckBoxChecked'
  )

  const handleSubmitWrapper = handleSubmit((data) => {
    if (
      data.LicensedEstablishment.isDBACheckBoxChecked &&
      !data.LicensedEstablishment.standardName
    ) {
      data.LicensedEstablishment.standardName =
        data.LicensedEstablishment.legalCompanyName
    }

    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      licenseNumber: data.LicensedEstablishment.licenseNumber,
      name: data.LicensedEstablishment.legalCompanyName,
      standardName: data.LicensedEstablishment.standardName,
      shortName: data.LicensedEstablishment.shortName,
      type: data.LicensedEstablishment.type,
      phoneNumber: String(
        removePhoneMask(data.LicensedEstablishment.phoneNumber)
      ),
    })
  })

  const onChangeDbaBox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('LicensedEstablishment.isDBACheckBoxChecked', e.target.checked)
      if (e.target.checked) {
        setValue('LicensedEstablishment.standardName', '')
      }
    },
    [setValue, legalCompanyName, licensedEstablishment]
  )

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={`Business Details`}
            subTitle={`Licensed Establishment "${licensedEstablishment.standardName}"`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.LicensedEstablishment && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6}>
                <InputLabel
                  required
                  htmlFor="LicensedEstablishment.legalCompanyName"
                  sx={{ fontWeight: 'normal' }}
                >
                  Legal Company Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.legalCompanyName', {
                    required: true,
                  })}
                  defaultValue={licensedEstablishment.name}
                  error={isPresent(
                    errors.LicensedEstablishment?.legalCompanyName
                  )}
                />
                {errors.LicensedEstablishment?.legalCompanyName?.message && (
                  <FormFieldErrorMessage
                    message={
                      errors.LicensedEstablishment.legalCompanyName.message
                    }
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={2}>
                <InputLabel
                  required
                  htmlFor="LicensedEstablishment.standardName"
                  sx={{ fontWeight: 'normal' }}
                >
                  DBA Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.standardName', {
                    required: true,
                  })}
                  defaultValue={licensedEstablishment.standardName}
                  error={isPresent(errors.LicensedEstablishment?.standardName)}
                  disabled={isDBACheckBoxChecked}
                />
                <Box className="flex flex-row" mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register(
                          'LicensedEstablishment.isDBACheckBoxChecked',
                          {
                            onChange: onChangeDbaBox,
                          }
                        )}
                        checked={isDBACheckBoxChecked}
                      />
                    }
                    label="Same as company name"
                  />
                </Box>

                {errors.LicensedEstablishment?.standardName?.message && (
                  <FormFieldErrorMessage
                    message={errors.LicensedEstablishment.standardName.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="LicensedEstablishment.shortName"
                  sx={{ fontWeight: 'normal' }}
                >
                  Short Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.shortName', {
                    required: true,
                  })}
                  defaultValue={licensedEstablishment.shortName}
                  error={isPresent(errors.LicensedEstablishment?.shortName)}
                  placeholder="Enter short name"
                />
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="LicensedEstablishment.type"
                  required
                  sx={{ fontWeight: 'normal' }}
                >
                  Establishment Trade Type
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register('LicensedEstablishment.type', {
                    required: true,
                  })}
                  error={isPresent(errors.LicensedEstablishment?.type)}
                  inputProps={{ 'data-testid': 'le-type' }}
                  defaultValue={licensedEstablishment?.type}
                  required
                  fullWidth
                  select
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="Bar">Bar</MenuItem>
                  <MenuItem value="BowlingAlley">Bowling Alley</MenuItem>
                  <MenuItem value="Fraternal">Fraternal</MenuItem>
                  <MenuItem value="GolfCourse">Golf Course</MenuItem>
                  <MenuItem value="Restaurant">Restaurant</MenuItem>
                  <MenuItem value="Parlor">Parlor</MenuItem>
                  <MenuItem value="ConvenienceStore">
                    Convenience Store
                  </MenuItem>
                  <MenuItem value="TruckStop">Truck Stop</MenuItem>
                  <MenuItem value="LargeTruckStop">Large Truck Stop</MenuItem>
                  <MenuItem value="LiquorStore">Liquor Store</MenuItem>
                  <MenuItem value="Veterans">Veterans</MenuItem>
                  <MenuItem value="Winery">Winery</MenuItem>
                  <MenuItem value="GasStation">Gas Station</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="LicensedEstablishment.licenseNumber"
                  sx={{ fontWeight: 'normal' }}
                >
                  License Number
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.licenseNumber', {
                    required: true,
                  })}
                  defaultValue={licensedEstablishment.licenseNumber}
                  error={isPresent(errors.LicensedEstablishment?.licenseNumber)}
                />
              </Grid>
            </Grid>
            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="LicensedEstablishment.phoneNumber"
                  sx={{ fontWeight: 'normal' }}
                >
                  Phone Number
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="LicensedEstablishment.phoneNumber"
                  control={control}
                  defaultValue={
                    licensedEstablishment.contacts?.[0]
                      ? applyPhoneMask(
                          String(licensedEstablishment.contacts[0].phoneNumber)
                        )
                      : ''
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      {...register('LicensedEstablishment.phoneNumber', {
                        required: true,
                      })}
                      error={isPresent(
                        errors.LicensedEstablishment?.phoneNumber
                      )}
                      onChange={(phoneNumber) => {
                        onChange(
                          applyPhoneMask(String(phoneNumber.target.value))
                        )
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />

                {errors.LicensedEstablishment?.phoneNumber?.message && (
                  <FormFieldErrorMessage
                    message={errors.LicensedEstablishment?.phoneNumber.message}
                  />
                )}
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
