import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import type { Patron } from 'types/api'
import { type ApiError } from 'openapi-typescript-fetch'

interface InputType {
  id: number
  tierId: number
}

type UsePutPatronProps = UseMutationOptions<Patron, ApiError, InputType>

export const usePutPatronTier = ({ onSuccess, onError }: UsePutPatronProps) => {
  const putPatron = useTypedApiClient({
    path: '/admin/utilities/patron/{id}/current-tier/{tierId}',
    method: 'put',
  })

  return useMutation<Patron, ApiError, InputType>({
    mutationFn: async (data) => {
      const { data: putPatronResult } = await putPatron({
        id: data.id,
        tierId: data.tierId,
      })
      return putPatronResult
    },
    onSuccess: async (data, vars, context) => {
      await onSuccess?.(data, vars, context)
    },
    onError,
  })
}
