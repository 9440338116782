import { Typography, Box, useTheme } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

interface FormFieldErrorMessageProps {
  message: string
  variant?: string
  showIcon?: boolean
}

export const FormFieldErrorMessage = ({
  message,
  variant,
  showIcon = true,
}: FormFieldErrorMessageProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        mt: '6px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        alignItems: 'center',
        columnGap: '6px',
        color: '#CC2027',
        backgroundColor:
          variant === 'contained'
            ? ` ${theme.palette.error.main} !important`
            : '',
        borderRadius: '4px',
        height: variant === 'contained' ? '52px' : 'auto',
      }}
    >
      <Box sx={{ paddingLeft: variant === 'contained' ? '2%' : '' }}>
        {showIcon && <ErrorOutlineIcon />}
      </Box>

      <Typography
        variant="body-2"
        sx={{
          pt: '2.5px',
          color: variant === 'contained' ? 'rgba(95, 33, 32, 1)' : '',
        }}
      >
        {message}
      </Typography>
    </Box>
  )
}
