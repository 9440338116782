import { PatronOverviewDesktop } from 'pages/Patrons/PatronAccountDetails/Overview/PatronOverviewDesktop'
import { PatronOverviewMobile } from 'pages/Patrons/PatronAccountDetails/Overview/PatronOverviewMobile'
import { useLayout } from 'hooks/useLayout'

export const PatronOverviewPage = () => {
  const { isMobile } = useLayout()

  return (
    <>
      {!isMobile && <PatronOverviewDesktop />}
      {isMobile && <PatronOverviewMobile />}
    </>
  )
}
