import { ScheduleRow } from './ScheduleRow'
import { daysOfWeek } from 'utils/util'
import { InputLabel, Stack } from '@mui/material'
import { object, string } from 'yup'

const timeRegex = /^([01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/

export const scheduleSchema = object({
  dayName: string().required(),
  opening: string().matches(timeRegex, 'opening should have HH:mm:ss format'),
  closing: string().matches(timeRegex, 'opening should have HH:mm:ss format'),
})

export const HoursOfOperation = () => {
  return (
    <Stack
      gap={2}
      width={{
        xs: 'auto',
        sm: '800px',
      }}
    >
      <InputLabel>Hours of Operation</InputLabel>
      {daysOfWeek.map((day, index) => {
        return <ScheduleRow key={day} day={day} index={index} />
      })}
    </Stack>
  )
}
