import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const RewardProgramDetailTabs = ({
  rewardProgramId,
  currentTab,
}: {
  rewardProgramId: number
  currentTab: string
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Overview"
        value={`/Rewards/RewardPrograms/${rewardProgramId}`}
        to={`/Rewards/RewardPrograms/${rewardProgramId}`}
        component={Link}
      />
      <Tab
        label="Transaction History"
        value={`/Rewards/RewardPrograms/${rewardProgramId}/TransactionHistory`}
        to={`/Rewards/RewardPrograms/${rewardProgramId}/TransactionHistory`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Tab
        label="Redemption"
        value={`/Rewards/RewardPrograms/${rewardProgramId}/Redemption`}
        to={`/Rewards/RewardPrograms/${rewardProgramId}/Redemption`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Tab
        label="Send Reward"
        value={`/Rewards/RewardPrograms/${rewardProgramId}/SendReward`}
        to={`/Rewards/RewardPrograms/${rewardProgramId}/SendReward`}
        component={Link}
        sx={{
          whiteSpace: 'nowrap',
        }}
      />
      <Tab
        label="Settings"
        value={`/Rewards/RewardPrograms/${rewardProgramId}/Settings`}
        to={`/Rewards/RewardPrograms/${rewardProgramId}/Settings`}
        component={Link}
      />
    </Tabs>
  )
}
