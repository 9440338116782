import { object, string } from 'yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Alert, Button, Grid, InputLabel, TextField } from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  isPresent,
  applyPhoneMask,
  getPhoneMaskRegex,
  removePhoneMask,
} from '@jjvgaming/player-payback-library'
import { isNil } from 'lodash'
import { ActivityButton } from 'components/ActivityButton'
import { type EditLicensedEstablishmentDetailsProps } from '../types'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useLayout } from 'hooks/useLayout'

const LicensedEstablishmentContactDetailsSchema = object({
  Contact: object({
    primaryContact: string()
      .test(
        'two-words',
        'Primary contact must be First Name and Last Name',
        (value) => {
          if (!isNil(value)) {
            return /^\w+\s+\w+$/.test(value)
          }
          return false
        }
      )
      .required('Primary contact is a required field'),
    email: string().email().required('Email is a required field'),
    phoneNumber: string()
      .matches(getPhoneMaskRegex, {
        message: 'Please enter a valid phone number',
      })
      .required('Phone number is a required field'),
  }),
})

export const EditLicensedEstablishmentContactDetails = ({
  licensedEstablishment,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: EditLicensedEstablishmentDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'

  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentContactDetailsSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((formData) => {
    const existingPrimaryContactId = licensedEstablishment.contacts?.[0]?.id
    const contactData = formData.Contact
    if (isNil(existingPrimaryContactId)) {
      throw new Error('Id is null')
    }

    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      contacts: [
        {
          id: existingPrimaryContactId,
          firstName: contactData.primaryContact.split(' ')[0],
          lastName: contactData.primaryContact.split(' ')[1],
          email: contactData.email,
          phoneNumber: removePhoneMask(contactData.phoneNumber),
        },
      ],
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title="Contact"
            subTitle={`Licensed Establishment "${licensedEstablishment.standardName}"`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.Contact && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="primaryContact"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Primary Contact
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Contact.primaryContact', {
                    required: true,
                  })}
                  defaultValue={
                    licensedEstablishment.contacts?.[0]
                      ? `${licensedEstablishment.contacts[0].firstName} ${licensedEstablishment.contacts[0].lastName}`
                      : ''
                  }
                  error={isPresent(errors.Contact?.primaryContact)}
                />
                {errors.Contact?.primaryContact?.message && (
                  <FormFieldErrorMessage
                    message={errors.Contact?.primaryContact.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="email"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Email
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Contact.email', {
                    required: true,
                  })}
                  defaultValue={
                    licensedEstablishment.contacts?.[0]
                      ? licensedEstablishment.contacts[0].email
                      : ''
                  }
                  error={isPresent(errors.Contact?.email)}
                />

                {errors.Contact?.email?.message && (
                  <FormFieldErrorMessage
                    message={errors.Contact?.email.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="Contact.phoneNumber"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Phone Number
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="Contact.phoneNumber"
                  control={control}
                  defaultValue={
                    licensedEstablishment.contacts?.[0]
                      ? applyPhoneMask(
                          String(licensedEstablishment.contacts[0].phoneNumber)
                        )
                      : ''
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      fullWidth
                      error={isPresent(errors.Contact?.phoneNumber)}
                      onChange={(phoneNumber) => {
                        onChange(
                          applyPhoneMask(String(phoneNumber.target.value))
                        )
                      }}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />

                {errors.Contact?.phoneNumber?.message && (
                  <FormFieldErrorMessage
                    message={errors.Contact?.phoneNumber.message}
                  />
                )}
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              disabled={!!errors.Contact}
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
