import { Box, InputLabel, MenuItem, Select } from '@mui/material'
import { SystemBannerPlatformTypes } from '../types'
import { useFormContext } from 'react-hook-form'

export const PlatformSelect = () => {
  const { register } = useFormContext()

  return (
    <Box>
      <InputLabel htmlFor={'platform'} required>
        Select Platform
      </InputLabel>

      <Select
        {...register('platformType')}
        labelId={'platform'}
        variant="outlined"
        defaultValue=""
        fullWidth
        inputProps={{ id: 'platform' }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {SystemBannerPlatformTypes.map((type) => (
          <MenuItem value={type} key={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
