import { Box, Grid, Typography, useTheme } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { type OrganizationDetailsProps } from '../types'

export const OrganizationDetailsSection = ({
  organization,
  onClickEdit,
}: OrganizationDetailsProps) => {
  const theme = useTheme()

  return (
    <Box>
      <SectionHeader title="Organization Details" onClickButton={onClickEdit} />

      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container alignItems="flex-start">
          <Grid item md={2} style={{ minWidth: 420 }}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Organization Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {organization?.name ?? ''}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Organization ID
            </Typography>
            <Typography variant="body1" pb={2}>
              {organization?.id}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
