import { Box, Typography, useTheme } from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { getStatusStyleColors } from '../SweepstakesWinnersPage/LocationWinnersColumns'
import { toUpper } from 'lodash'
import { colorPrimitives } from 'components/Theme'
import { statusTextMap } from 'components/List/FilterStatusList'
import { format } from 'date-fns'
import { useMemo } from 'react'

export const AffidavitSection = ({
  winner,
}: {
  winner: components['schemas']['FullSweepstakeWinnerDTO']
}) => {
  const theme = useTheme()
  const formattedDate = useMemo(() => {
    const date = winner.affidavitCompletedAt
      ? new Date(winner.affidavitCompletedAt)
      : null
    return date && !isNaN(date.getTime())
      ? format(date, 'MM-dd-yyyy hh:mm aa')
      : '-'
  }, [winner.affidavitCompletedAt])

  const showAffidavitSection =
    winner.status === 'AffidavitReceived' ||
    winner.status === 'Awarded' ||
    winner.status === 'PrizeClaim'

  return (
    <>
      <Typography variant="h2" pt={8} pb={2}>
        Affidavit
      </Typography>

      {showAffidavitSection && (
        <Box
          border={{ md: `1px solid ${theme.palette.grey[200]}` }}
          borderRadius={'5px'}
          p={{ xs: 0, sm: 3 }}
        >
          <Box
            sx={{
              ...getStatusStyleColors('AffidavitReceived'),
              width: '177px',
            }}
          >
            {toUpper(statusTextMap.AffidavitReceived)}
          </Box>
          <Typography className="pt-4 pb-4">
            {winner.patron?.firstName} {winner.patron?.lastName} read and agreed
            that all the information in the affidavit is true.
          </Typography>
          {formattedDate !== '-' && (
            <Typography variant="body-1" color={colorPrimitives.gray70}>
              Submitted: {formattedDate}
            </Typography>
          )}
        </Box>
      )}
      {!showAffidavitSection && (
        <>
          <Box
            border={{ md: `1px solid ${theme.palette.grey[200]}` }}
            borderRadius="5px"
            style={{ height: '200px', backgroundColor: '#2C2C270F' }}
            p={{ xs: 0, sm: 3 }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body-1" color={colorPrimitives.gray70}>
              No affidavit has been received yet
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}
