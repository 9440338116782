import { Card as MUICard, useTheme } from '@mui/material'
import { CardSelectedIcon } from './CardSelectedIcon'

export const cardWidth = { xs: '342px', sm: '290px' }

interface CardProps {
  children: React.ReactNode
  height?: string
  isSelected?: boolean
  hideBoxShadow?: boolean
}

export const Card = ({
  children,
  height = 'auto',
  isSelected = false,
  hideBoxShadow,
}: CardProps) => {
  const theme = useTheme()
  return (
    <MUICard
      sx={{
        boxShadow: hideBoxShadow ? 'none' : '1px',
        width: cardWidth,
        height,
        position: 'relative',
        borderWidth: isSelected ? 2 : 0,
        borderColor: theme.palette.primary.main,
      }}
    >
      {isSelected && <CardSelectedIcon />}
      {children}
    </MUICard>
  )
}
