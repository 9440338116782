import { Box, Typography } from '@mui/material'
import type { components } from 'api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import { calculatePromotionStatus, getLastUpdated } from 'utils/util'
import { Colors } from 'components/Theme'
import { colorPrimitives } from '../../../components/Theme'

export const PromotionsDetailsHeader = ({
  promotion,
}: {
  promotion: components['schemas']['FullPromotionDTO']
}) => {
  if (!promotion.id) {
    return <Typography>Promotion Id must not be null.</Typography>
  }

  const startDate = promotion.startDate ? new Date(promotion.startDate) : null
  const endDate = promotion.endDate ? new Date(promotion.endDate) : null
  const status = calculatePromotionStatus(startDate, endDate).toUpperCase()

  const promoBackgroundColor: Record<string, string> = {
    ACTIVE: Colors.promotionsStatus.background.Active,
    EXPIRED: Colors.promotionsStatus.background.Expired,
    SCHEDULED: Colors.promotionsStatus.background.Scheduled,
  }

  return (
    <Box>
      <PageHeader
        title={isNil(promotion.title) ? 'Unknown' : promotion.title}
        subtitle={getLastUpdated(promotion.modifiedOn)}
        backText="Specials & Events"
        backPath="/Promotions"
        isSecondary={true}
        status={
          status && (
            <Box
              className="flex p-2 justify-center items-center rounded-sm"
              bgcolor={promoBackgroundColor[status]}
              color={colorPrimitives.black}
            >
              <Typography variant="body-1" fontWeight={'bold'}>
                {status}
              </Typography>
            </Box>
          )
        }
      />
    </Box>
  )
}
