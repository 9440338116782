import { Box, Grid, Link, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { SectionHeader } from 'components/SectionHeader'
import { colorPrimitives } from 'components/Theme'
import { Link as RouterLink } from 'react-router-dom'

export const RelatedAccountsDesktop = ({
  corporateAccountName,
  organizationName,
  leId,
}: {
  corporateAccountName: string
  organizationName?: string
  leId: number
}) => {
  return (
    <Box>
      <SectionHeader title="Related Accounts" showButton={false} />
      <Grid
        border={{ md: `1px solid ${grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
        container
      >
        <Grid item md={4}>
          <Typography variant="body2" pb={1} color="text.secondary">
            Corporate Account
          </Typography>
          <Typography variant="body1" pb={2}>
            {corporateAccountName || '-'}
          </Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" pb={1} color="text.secondary">
            Organization
          </Typography>
          {organizationName ? (
            <Typography variant="body1" pb={2}>
              {organizationName}
            </Typography>
          ) : (
            <Link
              to={`/LicensedEstablishments/${leId}/AssignOrganization`}
              underline="none"
              component={RouterLink}
              color={colorPrimitives.redGaming}
            >
              <Typography>Assign Organization</Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
