import { TextField } from 'components/Shared/TextField'
import { BannerTypeRadioGroup } from './BannerTypeRadioGroup'
import { useFormContext, useFormState } from 'react-hook-form'
import { DismissableRadioGroup } from './DismissableRadioGroup'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { SchedulingOptionsRadioGroup } from './SchedulingOptionsRadioGroup'
import { TimeFields } from './TimeFields'
import { Sublabel } from 'components/Sublabel'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { type SystemBannerSchema } from './SystemBannerSchema'
import { type InferType } from 'yup'

export const SystemBannerForm = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<InferType<typeof SystemBannerSchema>>()
  const { isValid } = useFormState()
  const showTimeOptions =
    watch('systemBanner.schedulingOption') === 'Schedule a Time'

  return (
    <>
      <BannerTypeRadioGroup />
      <TextField
        label={'Banner Title'}
        sublabel={<Sublabel>This title will not be visible to users.</Sublabel>}
        required
        placeholder="Enter banner title"
        {...register('systemBanner.title')}
        helperText="50 charcters max"
        bottomContent={
          errors.systemBanner?.title?.message && (
            <FormFieldErrorMessage
              message={errors.systemBanner.title.message}
            />
          )
        }
      />
      <TextField
        label="Banner Description"
        required
        rows={4}
        multiline
        placeholder="Enter description of banner"
        {...register('systemBanner.content')}
        helperText="150 characters max"
        bottomContent={
          errors.systemBanner?.content?.message && (
            <FormFieldErrorMessage
              message={errors.systemBanner.content.message}
            />
          )
        }
      />
      <DismissableRadioGroup />
      <SchedulingOptionsRadioGroup />
      {showTimeOptions && <TimeFields />}
      <Stack direction="row" gap={2} data-testid="buttons">
        <Button
          disabled={!isValid}
          variant="contained"
          type="submit"
          aria-label="Create banner"
        >
          Create Banner
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to="/Communications"
          aria-label="Cancel"
        >
          Cancel
        </Button>
      </Stack>
    </>
  )
}
