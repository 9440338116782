import { type Sweepstake } from 'types/api'
import {
  MDXEditor,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  listsPlugin,
  UndoRedo,
  ListsToggle,
  type MDXEditorMethods,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

export const MdxEditorWrapper = ({
  sweepstake,
  setGuidelines,
  mdxEditorRef,
}: {
  sweepstake: Sweepstake
  setGuidelines: React.Dispatch<React.SetStateAction<string | null | undefined>>
  mdxEditorRef: React.RefObject<MDXEditorMethods>
}) => {
  return (
    <MDXEditor
      placeholder="Enter Participation Guidelines"
      markdown={
        sweepstake.sweepstakeData?.participationGuidelines?.replace(
          /\n\n/gi,
          '&nbsp;\n\n'
        ) ?? ''
      }
      onChange={setGuidelines}
      autoFocus
      ref={mdxEditorRef}
      plugins={[
        listsPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
            </>
          ),
        }),
      ]}
    />
  )
}
