import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'

import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import { Navigate } from 'react-router-dom'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { InteractionStatus } from '@azure/msal-browser'

export const LoginPage = () => {
  const { loginRequired, isPending } = useAccessTokenContext()
  const { instance, inProgress } = useMsal()

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest)
  }

  if (!isPending && !loginRequired) {
    return <Navigate to="/Home" replace />
  }

  if (inProgress !== InteractionStatus.None || isPending) {
    return (
      <ActivityIndicator
        size="fullPage"
        data-testid="LoginPageActivityIndicator"
      />
    )
  }

  return (
    <Box
      maxWidth="lg"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      margin="auto"
    >
      <Stack spacing={2}>
        <Button
          variant="contained"
          onClick={async () => {
            await handleLogin()
          }}
        >
          Sign in to J&J Control Center
        </Button>
      </Stack>
    </Box>
  )
}
