import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { format, parseISO } from 'date-fns'
import { useGetNumberWinnerEntries } from 'hooks/api/Sweepstake/EntriesCount/useGetNumberWinnerEntries'
import { isNil } from 'lodash'
import { formatPhoneNumber } from 'utils/phoneUtils'

export const WinnerDetailsSection = ({
  winner,
}: {
  winner: components['schemas']['FullSweepstakeWinnerDTO']
}) => {
  const theme = useTheme()
  const dob = winner.patron?.dob ? parseISO(winner.patron.dob) : null
  const winnerEntries = useGetNumberWinnerEntries({
    patronId: winner.patronId,
    contestId: winner.contestEntry?.contestId,
    licensedEstablishmentId:
      winner.sweepstakeDrawing?.type === 'Location'
        ? winner.contestEntry?.patronCheckIn?.licensedEstablishmentId
        : undefined,
  })

  if (winnerEntries.isPending) {
    return (
      <Box>
        <CircularProgress />
        <Typography> Loading winnerEntries query...</Typography>
      </Box>
    )
  }

  return (
    <>
      <Typography variant="h2" pt={8} pb={2}>
        Winner Details
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Drawing Number
            </Typography>
            <Typography variant="body1" pb={2}>
              {(winner.drawingSequence ?? 0) + 1}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Name
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.firstName} {winner.patron?.lastName}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Birthdate
            </Typography>
            {!isNil(dob) && (
              <Typography variant="body1" pb={2}>
                {`${format(dob, 'P')}`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Gender
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.gender ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Tier Level
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.currentTier?.tier?.type ??
                winner.patronTier?.tier?.type ??
                'Member'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Total Entries by Winner
            </Typography>
            <Typography variant="body1" pb={2}>
              {winnerEntries.data ?? 'Could not retrieve'}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Email
            </Typography>
            <Typography variant="body1" pb={2}>
              {winner.patron?.email}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Phone Number
            </Typography>
            {winner.patron?.phoneNumber !== undefined && (
              <Typography variant="body1" pb={2}>
                {formatPhoneNumber(winner.patron.phoneNumber)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
