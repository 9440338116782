import { ComponentCatalogPage } from 'pages/ComponentCatalog/ComponentCatalogPage'
import { DeveloperRouted } from 'components/Auth/DeveloperRouted'
import { Route } from 'react-router-dom'
import { isDeveloperMode } from 'utils/isDeveloperMode'
import { Insights } from 'pages/Development/Insights'

export const getDeveloperRoutes = () => {
  if (isDeveloperMode()) {
    return [
      <Route
        path="/ComponentCatalog"
        key="/ComponentCatalog"
        element={
          <DeveloperRouted>
            <ComponentCatalogPage />
          </DeveloperRouted>
        }
      />,
      <Route
        path="/Insights"
        key="/Insights"
        element={
          <DeveloperRouted>
            <Insights />
          </DeveloperRouted>
        }
      />,
    ]
  }

  return []
}
