import { Typography, Link, Stack } from '@mui/material'
import { TreeDataGrid } from 'components/TreeDataGrid/TreeDataGrid'
import {
  type TreeDataGridRow,
  prepareTreeDataGridRows,
  buildTreeDataGridRows,
} from 'components/TreeDataGrid/utils'
import { type components } from 'api/playerPayback/api'
import { dataTableIdFormatter } from 'utils/util'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  type GridColDef,
  type DataGridProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid'
import { Link as RouterLink } from 'react-router-dom'
import { isNil } from 'lodash'
import { useLayout } from 'hooks/useLayout'
import { IndentedCell } from 'components/TreeDataGrid/IndentedCell'
import { useMemo } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'

enum AccountTypes {
  CorporateAccount,
  Organization,
  LicensedEstablishment,
}

const AccountTypesLabels = new Map([
  [AccountTypes.CorporateAccount, 'Corporate Account'],
  [AccountTypes.Organization, 'Organization'],
  [AccountTypes.LicensedEstablishment, 'Licensed Establishment'],
])

const AccountTypesLinks = new Map([
  [
    AccountTypes.CorporateAccount,
    '/CorporateAccounts/id/CorporateAccountOverview',
  ],
  [AccountTypes.Organization, '/Organization/id'],
  [AccountTypes.LicensedEstablishment, '/LicensedEstablishments/id'],
])

interface Item extends TreeDataGridRow {
  type: AccountTypes
  id: number
  licenseNumber?: string
  name: string
  initialExpanded?: boolean
}

export const MobileColumns: Array<GridColDef<Item>> = [
  {
    field: 'name',
    headerName: 'Account Name',
    flex: 2,
    sortable: false,
    renderCell: (params) => (
      <IndentedCell depth={params.row.parentIndexPath.length}>
        <Stack>
          <Link
            to={String(AccountTypesLinks.get(params.row.type)).replace(
              'id',
              String(params.row.id)
            )}
            component={RouterLink}
            underline="hover"
            color="text.primary"
            sx={{
              '&:hover': {
                color: '#1A4F64',
              },
            }}
          >
            <Typography
              fontWeight={
                params.row.parentIndexPath?.length < 2 ? 'bold' : 'regular'
              }
            >
              {params.row.type === 2
                ? params.row.standardName
                : params.row.name}
            </Typography>
          </Link>
          <Typography>
            Type: {AccountTypesLabels.get(params.row.type)}
          </Typography>
          <Typography>
            {dataTableIdFormatter(true)({ value: params.row.id.toString() })}
          </Typography>
          {isPresent(params.row.licenseNumber) && (
            <Typography>License Number: #{params.row.licenseNumber}</Typography>
          )}
        </Stack>
      </IndentedCell>
    ),
  },
]

export const DesktopColumns: Array<GridColDef<Item>> = [
  {
    field: 'name',
    headerName: 'Account Name',
    minWidth: 150,
    flex: 2,
    renderCell: (params) => (
      <Link
        to={String(AccountTypesLinks.get(params.row.type)).replace(
          'id',
          String(params.row.id)
        )}
        component={RouterLink}
        underline="hover"
        color="text.primary"
        sx={{
          '&:hover': {
            color: '#1A4F64',
          },
        }}
      >
        <Typography fontWeight={'regular'}>
          {params.row.type === 2 ? params.row.standardName : params.row.name}
        </Typography>
      </Link>
    ),
  },
  {
    field: 'type',
    headerName: 'Account Type',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params: { value: AccountTypes }) =>
      AccountTypesLabels.get(params.value),
  },
  {
    field: 'id',
    headerName: 'Account ID',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTableIdFormatter(false),
  },
  {
    field: 'licenseNumber',
    headerName: 'License Number',
    minWidth: 100,
    flex: 1,
    valueFormatter: (params: { value: string }) => {
      if (isNil(params.value)) {
        return '-'
      } else {
        return params.value
      }
    },
  },
]

const generateCorporateAccountTreeData = (
  corporateAccounts: Array<components['schemas']['FullCorporateAccountDTO']>,
  organizations: Array<components['schemas']['FullOrganizationDTO']>,
  licensedEstablishments: Array<
    components['schemas']['FullLicensedEstablishmentDTO']
  >,
  isMobile: boolean,
  initialExpanded?: boolean
): TreeDataGridRow[] => {
  const corporateAccountsPrepared = prepareTreeDataGridRows({
    records: corporateAccounts,
    rowIdPrefix: 'corporateAccount',
    newFields: {
      type: AccountTypes.CorporateAccount,
      expanded: isMobile || initialExpanded,
    },
  })

  const organizationsPrepared = prepareTreeDataGridRows({
    records: organizations,
    rowIdPrefix: 'organization',
    parentAggregationMaps: [
      {
        parentFieldName: 'corporateAccountId',
        parentRowIdPrefix: 'corporateAccount',
      },
      {
        parentFieldName: 'parentOrganizationId',
        parentRowIdPrefix: 'organization',
      },
    ],
    newFields: {
      type: AccountTypes.Organization,
      expanded: isMobile || initialExpanded,
    },
  })

  const licensedEstablishmentPrepared = prepareTreeDataGridRows({
    records: licensedEstablishments,
    rowIdPrefix: 'licensedEstablishment',
    parentAggregationMaps: [
      {
        parentFieldName: 'corporateAccountId',
        parentRowIdPrefix: 'corporateAccount',
      },
      {
        parentFieldName: 'organizationId',
        parentRowIdPrefix: 'organization',
      },
    ],
    newFields: {
      type: AccountTypes.LicensedEstablishment,
    },
  })

  return buildTreeDataGridRows([
    ...corporateAccountsPrepared,
    ...licensedEstablishmentPrepared,
    ...organizationsPrepared,
  ])
}

const sortComparatorByName = (
  a: { name?: string | null },
  b: { name?: string | null }
) => {
  const nameA = a.name ?? ''
  const nameB = b.name ?? ''
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

interface CorporateAccountsTreeDataGridProps
  extends Omit<DataGridProps, 'rows' | 'columns'> {
  columns?: Array<GridColDef<Item>>
  mobileColumns?: Array<GridColDef<Item>>
  corporateAccounts: Array<components['schemas']['FullCorporateAccountDTO']>
  organizations: Array<components['schemas']['FullOrganizationDTO']>
  licensedEstablishments: Array<
    components['schemas']['FullLicensedEstablishmentDTO']
  >
  initialExpanded?: boolean
  editing?: boolean
  allowedLocations?: string[]
  /* 
    some TreeDataGrids do not need to recalculate rows (i.e. CA - Associated Accounts),
    and others will need to dynamically recalculate rows (i.e. Sweepstakes - Enroll Locations)
  */
  recalculateRows?: boolean
}
export const CorporateAccountsTreeDataGrid = ({
  corporateAccounts,
  organizations,
  licensedEstablishments,
  columns,
  mobileColumns,
  initialExpanded,
  editing,
  allowedLocations,
  recalculateRows,
  ...dataGridProps
}: CorporateAccountsTreeDataGridProps) => {
  const { isMobile } = useLayout()

  // TS doesn't like us assigning Item to TreeDataGridRow :thinking:
  const castedDesktopColumns = (columns ?? DesktopColumns) as Array<
    GridColDef<TreeDataGridRow>
  >
  const castedMobileColumns = (mobileColumns ??
    columns ??
    MobileColumns) as Array<GridColDef<TreeDataGridRow>>

  const extraColumn =
    // ... (other extra columns, if any)
    editing === true
      ? {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          headerName: 'Participating LEs',
          flex: 0.4,
        }
      : editing === false
      ? {
          field: 'access',
          headerName: 'Access',
          flex: 0.4,
          renderCell: (params: { row: TreeDataGridRow }) => {
            const accountId: number = params.row.id // Explicitly specify the type
            const accountType = params.row.type

            let isAccessAllowed = false

            if (allowedLocations) {
              switch (accountType) {
                case AccountTypes.CorporateAccount:
                  isAccessAllowed = allowedLocations.includes(
                    `corporateAccount-${accountId}`
                  )
                  break
                case AccountTypes.Organization:
                  isAccessAllowed = allowedLocations.includes(
                    `organization-${accountId}`
                  )
                  break
                case AccountTypes.LicensedEstablishment:
                  isAccessAllowed = allowedLocations.includes(
                    `licensedEstablishment-${accountId}`
                  )
                  break
                default:
                  break
              }
            }

            return (
              <div>
                {isAccessAllowed ? (
                  <CheckCircleIcon style={{ color: '#40cc6a' }} />
                ) : (
                  <CloseIcon style={{ color: '#CC2027' }} />
                )}
              </div>
            )
          },
        }
      : undefined

  const finalDesktopColumns = extraColumn
    ? [...castedDesktopColumns, extraColumn]
    : castedDesktopColumns

  const finalMobileColumns = extraColumn
    ? [...castedMobileColumns, extraColumn]
    : castedMobileColumns

  const rows = useMemo(() => {
    corporateAccounts.sort(sortComparatorByName)
    organizations.sort(sortComparatorByName)
    licensedEstablishments.sort(sortComparatorByName)

    return generateCorporateAccountTreeData(
      corporateAccounts,
      organizations,
      licensedEstablishments,
      isMobile,
      initialExpanded
    )
  }, [
    corporateAccounts,
    organizations,
    licensedEstablishments,
    isMobile,
    initialExpanded,
  ])

  return (
    <TreeDataGrid
      columns={finalDesktopColumns}
      mobileColumns={finalMobileColumns}
      rows={rows}
      columnHeaderHeight={isMobile ? 0 : 56}
      recalculateRows={recalculateRows}
      {...dataGridProps}
    />
  )
}
