import { useState } from 'react'
import Box from '@mui/material/Box'
import { formatDateTime } from 'utils/util'
import { useSnackbar } from 'stores/useSnackbar'
import { useQueryClient } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { EditPromotionDetails } from './EditPromotionDetails'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { usePutPromotion } from 'hooks/api/Promotion/usePutPromotion'

type promotionType = components['schemas']['FullPromotionDTO']
interface PromotionDetailsProps {
  promotion: promotionType
}

export const PromotionDetailsMobile = ({
  promotion,
}: PromotionDetailsProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)

  const theme = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)

  const queryClient = useQueryClient()

  const promotionType = () => {
    if (promotion.type === 'Global') {
      return `J&J Promo`
    } else if (promotion.licensedEstablishment) {
      return 'Location Specific'
    } else return '-'
  }

  const putPromotionMutation = usePutPromotion({
    promotionId: promotion.id,
    onSuccess: () => {
      setMessage('Promotion has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      void queryClient.invalidateQueries({
        queryKey: ['/promotions', promotion.id],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Promotion', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  return (
    <Box className={'pt-4'}>
      <Box>
        <Grid
          container
          gap={2}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Grid item className="pt-3" xs={8}>
            <Typography variant="h3" pb={2}>
              Special & Event Details
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
            <Button
              data-testid="edit-le-business-details-button"
              variant={'text'}
              sx={{ justifyContent: 'flex-end' }}
              onClick={toggleModalIsOpen}
            >
              Edit
            </Button>
            <EditPromotionDetails
              promotion={promotion}
              updateIsActive={putPromotionMutation.isPending}
              isModalOpen={isModalOpen}
              toggleModalIsOpen={toggleModalIsOpen}
              onSubmitPromotion={(promotion) => {
                putPromotionMutation.mutate(promotion)
              }}
            />
          </Grid>
        </Grid>

        <Box
          border={{ md: `1px solid ${theme.palette.grey[200]}` }}
          borderRadius={'5px'}
          p={{ xs: 0, sm: 3 }}
        >
          <Grid container className="flex flex-row flex-wrap" columnGap={24}>
            <Grid item sm={8}>
              <Typography variant="body2" pb={1} color="text.secondary">
                Special & Event Name
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.title}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                Start Date
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.startDate
                  ? formatDateTime(promotion.startDate, 'P p')
                  : 'None'}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                End Date
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.endDate
                  ? formatDateTime(promotion.endDate, 'P p')
                  : 'None'}
              </Typography>
              <Typography variant="body2" pb={1} color="text.secondary">
                Special & Event Type
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotionType()}
              </Typography>
              {promotion.type === 'Location' && (
                <Box>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    LE Name
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.licensedEstablishment?.name}
                  </Typography>
                </Box>
              )}
              {promotion.type === 'Location' && (
                <Box>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    License Number
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    #{promotion.licensedEstablishment?.licenseNumber}
                  </Typography>
                </Box>
              )}
              <Typography variant="body2" pb={1} color="text.secondary">
                Description
              </Typography>
              <Typography variant="body1" pb={2}>
                {promotion.description ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
