import { Button, Typography } from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { colors } from 'components/ThemeProvider'
import { usePutRewardsProgram } from 'hooks/api/usePutRewardsProgram'
import { useLayout } from 'hooks/useLayout'
import { type RewardsProgram, type LicensedEstablishment } from 'types/api'

export const EditLicensedEstablishmentActiveStatus = ({
  licensedEstablishment,
  locationRewardsPrograms,
  isModalOpen,
  updateIsActive,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: {
  licensedEstablishment: LicensedEstablishment
  locationRewardsPrograms: RewardsProgram[]
  isModalOpen: boolean
  updateIsActive: boolean
  toggleModalIsOpen: () => void
  onSubmitLicensedEstablishment: (
    licensedEstablishment: LicensedEstablishment
  ) => void
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : 'auto'
  const putRewardsProgramMutation = usePutRewardsProgram({})

  const onChangeStatus = () => {
    // Re/Deactivate LE
    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      active: !licensedEstablishment.active,
    })

    // Re/Deactivate associated RPs
    locationRewardsPrograms.forEach((rewardsProgram: RewardsProgram) => {
      putRewardsProgramMutation.mutate({
        id: rewardsProgram.id,
        rewardsProgramData: {
          name: String(rewardsProgram.name),
          description: String(rewardsProgram.description),
          active: !licensedEstablishment.active,
          type: rewardsProgram.type,
          corporateAccountId: rewardsProgram.corporateAccountId,
          licensedEstablishmentId: rewardsProgram.licensedEstablishmentId,
        },
      })
    })
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: 'auto',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleModalIsOpen}
        title={`${
          licensedEstablishment.active ? 'Deactivate' : 'Reactivate'
        } Licensed Establishment`}
        subTitle={`Name: ${licensedEstablishment.standardName}`}
        subTitle2={`ID: ${licensedEstablishment.id}`}
      />
      <ModalFullBody heightModal={heightModal}>
        <div className="flex flex-col text-center py-8">
          <Typography variant="h4">
            {`Are you sure you want to ${
              licensedEstablishment.active ? 'deactivate' : 'reactivate'
            } the Licensed Establishment for ${
              licensedEstablishment.standardName
            }?`}
          </Typography>
          <Typography
            variant="body-1"
            color={colors.text.secondary}
            marginTop={2}
          >
            {`Doing this will ${
              licensedEstablishment.active ? 'hide' : 'display'
            } the Licensed Establishment and any points,
            rewards, and specials & events associated with it from patrons in
            the app.`}
          </Typography>
        </div>
      </ModalFullBody>
      <ModalFullFooter>
        <Button onClick={toggleModalIsOpen}>Cancel</Button>
        <ActivityButton
          active={updateIsActive}
          type="submit"
          variant="contained"
          onClick={onChangeStatus}
        >
          {licensedEstablishment.active
            ? 'Deactivate Licensed Establishment'
            : 'Reactivate Licensed Establishment'}
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}
