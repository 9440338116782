import { useEffect, useState } from 'react'
import { array, number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { sortBy } from 'lodash'
import { Alert, Button, Grid, Typography } from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { useSnackbar } from 'stores/useSnackbar'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { CheckBoxList } from 'components/List/CheckBoxList'
import { useGetLicensedEstablishmentsByCorporateAccountId } from 'hooks/api/useGetLicensedEstablishment'
import { useGetLicensedEstablishmentsByOrganizationId } from 'hooks/api/useGetLicensedEstablishmentsByOrganizationId'
import { usePatchLicensedEstablishmentsToOrganization } from 'hooks/api/usePatchLicensedEstablishmentsToOrganization'
import { type Organization } from 'types/api'
import { useQueryClient } from '@tanstack/react-query'

const OrganizationDetailsSchema = object({
  organization: object({
    name: string(),
    corporateAccountId: number(),
    parentOrganizationId: number(),
    licensedEstablishmentIds: array(),
    id: number(),
  }),
})

export const EditAssociatedLicensedEstablishmentsModal = ({
  organization,
  isModalOpen,
  toggleModalIsOpen,
}: {
  organization: Organization
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const queryClient = useQueryClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const heightModal = '90%'

  // Get all LEs assigned to the current Corporate Account
  const { data: allLicensedEstablishmentsByCorpId } =
    useGetLicensedEstablishmentsByCorporateAccountId({
      corporateAccountId: Number(organization.corporateAccountId),
    })

  // Filter out the LEs that belong to the current Corporate Account or that don't have an Organization ID
  // then sort by the LEs that have an Org ID first
  const licensedEstablishmentsByCorpIdWithoutOrgId = sortBy(
    allLicensedEstablishmentsByCorpId?.filter(
      (le) => le.organizationId === organization.id || !le.organizationId
    ),
    ['organizationId']
  )

  // Get all LEs assigned to the current Organization
  const { data: licensedEstablishmentsByOrgId } =
    useGetLicensedEstablishmentsByOrganizationId({
      organizationId: Number(organization.id),
    })

  const selectedLicensedEstablishmentIds = licensedEstablishmentsByOrgId?.map(
    (le) => {
      return Number(le.id)
    }
  )

  const [checkedLicensedEstablishments, setCheckedLicensedEstablishments] =
    useState<number[]>(selectedLicensedEstablishmentIds ?? [])

  useEffect(() => {
    setCheckedLicensedEstablishments(selectedLicensedEstablishmentIds ?? [])
  }, [licensedEstablishmentsByOrgId])

  const checkPrevCheckedEstablishments = (
    prevCheckedLicensedEstablishments: number[],
    licensedEstablishmentId: number
  ) => {
    if (prevCheckedLicensedEstablishments.includes(licensedEstablishmentId)) {
      return prevCheckedLicensedEstablishments.filter(
        (id: number) => id !== licensedEstablishmentId
      )
    } else {
      return [...prevCheckedLicensedEstablishments, licensedEstablishmentId]
    }
  }

  const handleToggle = (licensedEstablishmentId: number) => () => {
    const prevCheckedLicensedEstablishments = (
      prevCheckedLicensedEstablishments: number[]
    ) => {
      return checkPrevCheckedEstablishments(
        prevCheckedLicensedEstablishments,
        licensedEstablishmentId
      )
    }
    setCheckedLicensedEstablishments(prevCheckedLicensedEstablishments)
  }

  const formMethods = useForm({
    resolver: yupResolver(OrganizationDetailsSchema),
  })

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit(() => {
    assignLicensedEstablishmentsToOrganizationMutation.mutate({
      organizationId: organization.id,
      leIds: checkedLicensedEstablishments,
    })
  })

  const assignLicensedEstablishmentsToOrganizationMutation =
    usePatchLicensedEstablishmentsToOrganization({
      onSuccess: async () => {
        setMessage('Organization has been updated.', 'success', {
          vertical: 'top',
          horizontal: 'right',
        })
        toggleModalIsOpen()
        await queryClient.invalidateQueries({
          queryKey: [
            'licensed-establishments-by-organization-id',
            organization.id,
          ],
        })
      },
      onError: async () => {
        setMessage('Error updating Organization', 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      },
    })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: 'auto',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={`Add or Remove LEs from ${organization.name}`}
            subTitle={`You're viewing LEs that are associated with this organization's parent corporate account`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.organization && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container direction={'column'}>
              <Grid item xs={12} p="0">
                <Typography className="!font-bold text-base !mb-3">
                  Parent Corporate Account
                </Typography>
              </Grid>
              <Grid item className="rounded bg-[#F7F7F7] p-3 !mb-8">
                <Typography>{organization.corporateAccount?.name}</Typography>
                <Typography>{organization.corporateAccountId}</Typography>
              </Grid>
              <Grid item>
                <CheckBoxList<'FullLicensedEstablishmentDTO'>
                  rowDivider
                  items={licensedEstablishmentsByCorpIdWithoutOrgId ?? []}
                  propertyShow={'standardName'}
                  extendedPropertyShow={'id'}
                  secondary={true}
                  secondaryPropertyShow={'addresses[0].address1'}
                  identifier={'id'}
                  handleToggle={handleToggle}
                  checked={checkedLicensedEstablishments}
                  titleForSelected="LEs selected"
                />
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={
                assignLicensedEstablishmentsToOrganizationMutation.isPending
              }
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
