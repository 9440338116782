import { Typography, Box, useTheme, Divider, Stack } from '@mui/material'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { type FullCheckInPolicy } from 'types/api'
import { PointsMultiplierSection } from './PointsMultiplierSection'

interface GrantCheckInsDetailsProps {
  checkInGrantPointsPolicy: FullCheckInPolicy
  checkInMaxPer24HoursPolicy: FullCheckInPolicy
}

export const GrantCheckInsDetails = ({
  checkInGrantPointsPolicy,
  checkInMaxPer24HoursPolicy,
}: GrantCheckInsDetailsProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.grey[200],
        mt: '16px',
        gap: '16px',
        p: '24px',
      }}
    >
      {checkInGrantPointsPolicy?.enablePatronCheckInPointBonus ? (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: { xs: 'column' },
            gap: '16px',
          }}
        >
          <Stack spacing={3}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography variant="h4" marginRight={2}>
                Grant Points on Check-In
              </Typography>
              <StatusChip
                active={
                  !!checkInGrantPointsPolicy?.enablePatronCheckInPointBonus
                }
                sx={{ maxWidth: '100px' }}
              />
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Daily Max per Check-In
                </Typography>
                <Typography variant="body1">
                  {checkInMaxPer24HoursPolicy?.checkInsDailyMax}
                </Typography>
              </Box>
            </Box>
            <Divider />
            {checkInGrantPointsPolicy?.checkInPointBonusSchedules ? (
              <PointsMultiplierSection
                checkInGrantPointsPolicy={checkInGrantPointsPolicy}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      ) : (
        <Box sx={{ flex: 1 }}>
          <Typography variant="body1" pb={1}>
            Edit to make active
          </Typography>
        </Box>
      )}
    </Box>
  )
}
