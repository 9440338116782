import { LicensedEstablishmentDetailsDesktop } from 'components/LicensedEstablishmentDetails/LicensedEstablishmentDetailsDesktop'
import { CircularProgress } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useGetLicensedEstablishmentById } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentById'
import { LicensedEstablishmentDetailsHeader } from './LicensedEstablishmentDetailsHeader'
import { isIdPresent } from 'utils/typeUtils'

export const LicensedEstablishmentDetailsPage = () => {
  const licensedEstablishmentId = Number(useParams().id)

  const getLicensedEstablishmentQuery = useGetLicensedEstablishmentById(
    licensedEstablishmentId
  )

  if (getLicensedEstablishmentQuery.isPending) {
    return <CircularProgress />
  }

  if (getLicensedEstablishmentQuery.isError) {
    return <p>An error occured.</p>
  }

  const licensedEstablishment = getLicensedEstablishmentQuery.data

  if (isNil(licensedEstablishment) || !isIdPresent(licensedEstablishment)) {
    return <p>An error occured.</p>
  }

  return (
    <Page
      header={
        <LicensedEstablishmentDetailsHeader
          licensedEstablishment={licensedEstablishment}
          currentTab={`/LicensedEstablishments/${licensedEstablishment.id}`}
        />
      }
    >
      <LicensedEstablishmentDetailsDesktop
        licensedEstablishment={licensedEstablishment}
      />
    </Page>
  )
}
