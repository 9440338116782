import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetLicensedEstablishmentsByOrganizationId = ({
  organizationId,
}: {
  organizationId: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/corporate-accounts/licensed-establishments/organizations/{id}/licensed-establishments',
    method: 'get',
  })
  return useQuery({
    queryKey: ['licensed-establishments-by-organization-id', organizationId],
    queryFn: async () => {
      const { data } = await get({ id: organizationId })
      return data
    },
  })
}
