import { object, string } from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Button, Grid, InputLabel, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ActivityButton } from 'components/ActivityButton'
import { type EditLicensedEstablishmentDetailsProps } from '../types'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useLayout } from 'hooks/useLayout'

const LicensedEstablishmentLinksDetailsSchema = object({
  LicensedEstablishment: object({
    website: string(),
    socialMedia: string(),
  }),
})

export const EditLicensedEstablishmentLinksDetails = ({
  licensedEstablishment,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: EditLicensedEstablishmentDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'

  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentLinksDetailsSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((formData) => {
    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      website: formData.LicensedEstablishment.website,
      socialMedia: formData.LicensedEstablishment.socialMedia,
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title="Links"
            subTitle={`Licensed Establishment "${licensedEstablishment.standardName}"`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.LicensedEstablishment && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="website"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Website
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.website')}
                  defaultValue={
                    licensedEstablishment.website
                      ? licensedEstablishment.website
                      : ''
                  }
                  placeholder="Enter URL"
                />
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="socialMedia"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Social Media
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('LicensedEstablishment.socialMedia')}
                  defaultValue={
                    licensedEstablishment.socialMedia
                      ? licensedEstablishment.socialMedia
                      : ''
                  }
                  placeholder="Enter URL"
                />
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              disabled={!!errors.LicensedEstablishment}
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
