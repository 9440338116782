import { create } from 'zustand'

interface NumberOfCheckInsState {
  checkIns: number
  setCheckIns: (checkIns: number) => void
}

export const useNumberOfCheckIns = create<NumberOfCheckInsState>((set) => ({
  checkIns: 0,
  setCheckIns: (checkIns: any) => {
    set({ checkIns })
  },
}))
