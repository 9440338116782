import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { ActivityButton } from 'components/ActivityButton'
import { useDeleteCampaignById } from 'hooks/api/useDeleteCampaignById'
import { useSnackbar } from 'stores/useSnackbar'
import { type Campaign } from 'types/api'
import { formatDateTime } from 'utils/util'

interface DeleteMessageModalProps {
  isModalOpen: boolean
  data?: Campaign | null
  toggleIsOpen: () => void
}

export const DeleteMessageModal = ({
  isModalOpen,
  toggleIsOpen,
  data,
}: DeleteMessageModalProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const useDeleteCampaignMutation = useDeleteCampaignById({
    onSuccess: () => {
      setSnackbarMessage(`Message successfully deleted`, 'success')
      toggleIsOpen()
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while deleting the message`,
        'error'
      )
      toggleIsOpen()
    },
  })

  const handleDelete = (id?: number | null) => {
    useDeleteCampaignMutation.mutate({ id })
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Delete Scheduled Message
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {data?.type}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Box paddingBottom={2} paddingTop={3}>
          <Typography variant="h4" fontSize={18} paddingBottom={2}>
            Are you sure you want to delete this scheduled message? Deleting
            this message will cancel it from being sent at the scheduled time.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Scheduled Date & Time
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={formatDateTime(data?.deliveryDate)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Subject Line
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={data?.subject}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Message Contents
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              key={data?.id}
              fullWidth
              aria-label="TextareaAutosize"
              defaultValue={' '}
              margin="normal"
              value={data?.body}
              aria-readonly
              multiline={true}
              minRows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="text" onClick={toggleIsOpen}>
          Cancel
        </Button>
        <ActivityButton
          variant="contained"
          onClick={() => handleDelete(data?.id)}
          active={useDeleteCampaignMutation.isPending}
        >
          Delete Message
        </ActivityButton>
      </DialogActions>
    </Dialog>
  )
}
