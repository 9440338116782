import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from './Shared/ActivityIndicator'
import {
  Box,
  InputLabel,
  MenuItem,
  Typography,
  FormControl,
  createFilterOptions,
  Popper,
  Autocomplete,
  TextField,
  type AutocompleteProps,
} from '@mui/material'
import { useState } from 'react'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
// import { ContestShowcaseCard } from 'components/ContestShowcaseCard'
import { type FieldValues, type Path, Controller } from 'react-hook-form'
import { type Contest } from 'types/api'
import { omit } from 'lodash'
import { formatDateTime } from 'utils/util'

const MAX_LOCATIONS = 200

interface ExtraProps<SchemaT extends FieldValues> {
  hideCard?: boolean
  required?: boolean
  error?: boolean
  label?: string
  contestIdKey: Path<SchemaT>
  name?: Path<SchemaT>
}

export const SelectSweepstake = <SchemaT extends FieldValues>({
  label,
  required,
  contestIdKey,
  name = contestIdKey,
  error,
  ...otherProps
}: Omit<
  AutocompleteProps<Contest, false, false, false>,
  'options' | 'renderInput'
> &
  ExtraProps<SchemaT>) => {
  const get = useTypedApiClient({
    path: '/admin/contests/type/{contestType}',
    method: 'get',
  })
  const {
    isPending,
    isError,
    data: contests,
  } = useQuery({
    queryKey: ['sweepstakes'],
    queryFn: async () => {
      const { data } = await get({ contestType: 'Sweepstake' })

      return data
    },
    select: (data) =>
      data
        .filter((x) => x.sweepstakeData?.state !== 'Draft')
        .sort((a, b) => a.name.localeCompare(b.name)),
  })

  const [selectOpen, setSelectOpen] = useState(false)

  if (isPending) {
    return <ActivityIndicator />
  }

  if (isError) {
    return <Typography>Error</Typography>
  }

  return (
    <Box>
      <FormControl>
        <InputLabel id={`${name}-label`} htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Autocomplete<Contest>
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                limit: MAX_LOCATIONS,
              })}
              renderInput={(params) => <TextField error={error} {...params} />}
              id={name}
              disablePortal
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
              options={contests}
              getOptionLabel={(option) => option.name}
              ListboxProps={{ sx: { maxHeight: '250px' } }}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  popperOptions={{
                    modifiers: [
                      { name: 'flip', options: { fallbackPlacements: [] } },
                    ],
                  }}
                  placement="bottom"
                />
              )}
              renderOption={(props, contest) => (
                <MenuItem
                  key={contest.id}
                  value={contest.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  {...omit(props, 'className', 'key')}
                >
                  {contest.name}
                  {selectOpen && contest.startDate && contest.endDate && (
                    <Typography variant="body-3">
                      {`${formatDateTime(
                        contest.startDate,
                        'MMM d, yyyy'
                      )}-${String(
                        formatDateTime(contest.endDate, 'MMM d, yyyy')
                      )}`}
                    </Typography>
                  )}
                </MenuItem>
              )}
              {...otherProps}
              onChange={(_ev, value) => onChange(value?.id)}
            />
          )}
        />
      </FormControl>
    </Box>
  )
}
