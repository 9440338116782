import { type AnyObject, type StringSchema } from 'yup'

export const applyEmailSchema = (
  schema: StringSchema<string | undefined, AnyObject, undefined, ''>
) =>
  schema.required('Must enter a valid email.').test({
    name: 'ValidEmail',
    exclusive: false,
    message: 'Must enter a valid email.',
    test: (value) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
  })
