import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { type LicensedEstablishmentUser } from 'types/api'

interface LEConnectUserListProps {
  users: LicensedEstablishmentUser[]
}

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'firstName',
      minWidth: 300,
      headerName: 'First Name',
      flex: 1,
      valueGetter: (params) => {
        return isMobile
          ? `${String(params.row.firstName)} ${String(params.row.lastName)}`
          : params.row.firstName
      },
    },
    {
      field: 'lastName',
      minWidth: 50,
      headerName: 'Last Name',
      flex: 1,
      valueGetter: (params) => {
        return isMobile ? '' : params.row.lastName
      },
    },
    {
      field: 'email',
      minWidth: 50,
      headerName: 'Email',
      flex: 1,
    },
  ]
}

export const LEConnectUserList = ({ users }: LEConnectUserListProps) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()

  return (
    <Box paddingTop={{ xs: '20px', sm: '32px' }}>
      <DataTable
        columns={defaultColumns(isMobile)}
        rows={users}
        noDataMessage="No J&J Connect Users Found"
        sortFieldName="firstName"
        onRowClick={(row) => {
          navigate(`/ClientManagement/LEConnectUsers/${row.id}`)
        }}
        getRowClassName={() => 'text-[#2c2c27]'}
      />
    </Box>
  )
}
