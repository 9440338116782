import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

interface RedrawWinnerData {
  sweepstakeDrawingId: number
  redrawReason: string
  sweepstakeWinnerId?: number
}

interface UseRedrawWinnerProps {
  onSuccess?: (data: undefined, variables: RedrawWinnerData) => void
  onError?: (error: Error, variables: RedrawWinnerData) => void
}

export const useRedrawWinner = ({
  onSuccess,
  onError,
}: UseRedrawWinnerProps): UseMutationResult<
  undefined,
  Error,
  RedrawWinnerData
> => {
  const redrawWinner = useTypedApiClient({
    path: '/admin/sweepstake-winners/nextWinner/{sweepstakeDrawingId}',
    method: 'put',
  })

  const queryClient = useQueryClient()

  return useMutation<undefined, Error, RedrawWinnerData>({
    mutationFn: async ({
      sweepstakeDrawingId,
      redrawReason,
      sweepstakeWinnerId,
    }: RedrawWinnerData) => {
      await redrawWinner({
        redrawReason,
        sweepstakeDrawingId,
        sweepstakeWinnerId,
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
