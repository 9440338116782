import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetOrganizationById = ({
  organizationId,
}: {
  organizationId: number
  enabled?: boolean
}): UseQueryResult<components['schemas']['FullOrganizationDTO']> => {
  const get = useTypedApiClient({
    path: '/admin/organizations/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/organizations', organizationId],
    queryFn: async () => {
      const { data } = await get({ id: organizationId })
      return data
    },
  })
}
