import { type components } from 'api/playerPayback/api'
import { date, number, object, string } from 'yup'

export type TypeOfMessage = components['schemas']['CampaignType']
export type ExcludeType = 'LE' | 'Org' | 'CA' | 'None'

export const CampaignSchema = object({
  typeOfMessage: string<TypeOfMessage>().required(),
  subjectLine: string()
    .required('Subject is required')
    // eslint-disable-next-line no-template-curly-in-string
    .max(50, 'Subject can be at most ${max} characters'),
  messageContent: string().required('Message is required'),
  scheduledDate: date().required('Scheduled date is required'),
  licensedEstablishmentId: number().when('typeOfMessage', {
    is: 'Location',
    then: (schema) => schema.required('Licensed Establishment is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  contestId: number().when('typeOfMessage', {
    is: 'Sweepstakes',
    then: (schema) => schema.required('Sweepstake is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  excludeType: string<ExcludeType>().default('None'),
  excludedId: number().when('excludeType', {
    is: 'None',
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required('Select an item to exclude'),
  }),
  corporateAccountId: number().when('typeOfMessage', {
    is: 'Corporate',
    then: (schema) => schema.required('Corporate Account Id is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  tierId: number().when('typeOfMessage', {
    is: 'AllPatronsByTier',
    then: (schema) => schema.required('Tier is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
})
