import { useApiQuery } from 'hooks/useApiQuery'
import { minimumSearchLength } from 'utils/util'

export const useGetFilteredLicensedEstablishmentUsers = (query: string) => {
  const path =
    query.length >= minimumSearchLength
      ? '/admin/licensed-establishments/users/search'
      : '/admin/licensed-establishments/users'
  return useApiQuery({
    method: 'get',
    path,
    queryKey: ['users', query],
    queryArgs: {
      searchKey: query,
    },
  })
}
