import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'
export const useGetPointsLedgersByRewardProgramId = ({
  rewardProgramId,
  enabled = true,
  types = [
    'CheckInPoint',
    'CheckInPointMultiplier',
    'ManualPoint',
    'MilestoneBirthday',
    'MilestoneWelcome',
    'MilestoneNextTier',
  ],
}: {
  rewardProgramId: number
  enabled?: boolean
  types?: Array<components['schemas']['TransactionType']>
}): UseQueryResult<
  Array<components['schemas']['FullPointsLedgerEntryDTO']>
> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-programs/{id}/points-ledgers',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/rewards-programs/{id}/points-ledgers', rewardProgramId],
    queryFn: async () => {
      const { data } = await get({
        id: rewardProgramId,
        types,
      })
      return data
    },
    enabled,
  })
}
