import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { minimumSearchLength } from 'utils/util'

const path = '/admin/corporate-accounts/licensed-establishments/search'

interface UseGetLicensedEstablishmentSearchParams {
  searchKey?: string
  limit?: number
}

export const useGetLicensedEstablishmentSearch = ({
  searchKey,
  limit,
}: UseGetLicensedEstablishmentSearchParams) => {
  const get = useTypedApiClient({ path, method: 'get' })
  return useQuery({
    enabled: (searchKey?.length ?? 0) >= minimumSearchLength,
    queryKey: [path, searchKey, limit],
    queryFn: async () => {
      const { data } = await get({ searchKey, limit })
      return data
    },
  })
}
