import { Box, InputLabel } from '@mui/material'
import { DateTimeField } from '@mui/x-date-pickers'
import { Controller, useFormContext } from 'react-hook-form'
import { type InferType } from 'yup'
import { type SystemBannerSchema } from './SystemBannerSchema'

export const TimeFields = () => {
  const { control } = useFormContext<InferType<typeof SystemBannerSchema>>()

  return (
    <>
      <Box>
        <InputLabel required htmlFor="startDate">
          Start Date
        </InputLabel>
        <Controller
          name="systemBanner.startDate"
          control={control}
          render={({ field }) => (
            <DateTimeField id="startDate" fullWidth {...field} />
          )}
        />
      </Box>
      <Box>
        <InputLabel required htmlFor="endDate">
          End Date
        </InputLabel>
        <Controller
          name="systemBanner.endDate"
          control={control}
          render={({ field }) => (
            <DateTimeField id="endDate" fullWidth {...field} />
          )}
        />
      </Box>
    </>
  )
}
