import { BusinessDetailsSection } from './BusinessDetailsSection'
import { RewardProgramSection } from './RewardProgramSection'
import type { components } from 'api/playerPayback/api'

export const Overview = ({
  corporateAccount,
}: {
  corporateAccount: components['schemas']['FullCorporateAccountDTO']
}) => {
  return (
    <>
      <BusinessDetailsSection corporateAccount={corporateAccount} />
      <RewardProgramSection corporateAccount={corporateAccount} />
    </>
  )
}
