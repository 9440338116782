import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from '../useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'

export const usePostPatronNote = ({
  onError,
  onSuccess,
}: {
  patronId: number
  onSuccess?: () => Promise<void>
  onError?: (error: ApiError) => void
}) => {
  const post = useTypedApiClient({
    path: '/admin/patrons/notes',
    method: 'post',
  })

  return useMutation({
    mutationFn: async ({
      patronId,
      note,
    }: {
      patronId: number
      note: string
    }) => {
      const { data } = await post({
        patronId,
        note,
      })
      return data
    },
    onSuccess,
    onError,
  })
}
