import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useAccessTokenContext } from 'contexts/AccessTokenContext'
import { useOutlet, Navigate } from 'react-router-dom'

export const ProtectedLayout = () => {
  const { isPending, loginRequired } = useAccessTokenContext()
  const outlet = useOutlet()

  if (loginRequired) {
    return <Navigate to="/" />
  }

  return isPending ? <ActivityIndicator size="fullPage" /> : outlet
}
