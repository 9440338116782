import { Box, Typography, TextField } from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage/FormFieldErrorMessage'

interface RedemptionValueInputProps {
  value: string
  error?: string
  onChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onBlur?: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  testId?: string
}

export const RedemptionValueInput = ({
  value,
  error,
  onChange,
  onBlur,
  testId,
}: RedemptionValueInputProps) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '8px',
      }}
    >
      <TextField
        error={!!error}
        value={value}
        sx={{ width: '80px' }}
        inputProps={{
          'data-testid': testId,
          style: {
            padding: '6px 8px',
          },
        }}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Typography variant="body1">Points</Typography>
    </Box>
    {error && <FormFieldErrorMessage message={error} showIcon={false} />}
  </Box>
)
