import { Typography, Box, Grid, Stack } from '@mui/material'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { type FullCheckInPolicy } from 'types/api'
import { convertScheduleHourTo12HourFormat } from 'utils/util'
import { colors } from 'components/ThemeProvider'
import { addMissingDays } from '../RewardsProgramDetailsUtils'

interface PointsMultiplierSectionProps {
  checkInGrantPointsPolicy: FullCheckInPolicy
}

export const PointsMultiplierSection = ({
  checkInGrantPointsPolicy,
}: PointsMultiplierSectionProps) => {
  const daysToDisplay = addMissingDays(
    checkInGrantPointsPolicy?.checkInPointBonusSchedules ?? []
  )

  const pointMultiplerEnabled =
    checkInGrantPointsPolicy?.checkInPointBonusSchedules?.some(
      (policy) => policy.patronCheckInPointBonusMultiplier !== undefined
    )

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          pr: { xs: '0px', sm: '16px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            alignItems: 'center',
            mb: '24px',
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Points Multiplier
          </Typography>
          <StatusChip
            active={pointMultiplerEnabled}
            sx={{ maxWidth: '100px' }}
          />
        </Box>
      </Stack>
      <Box>
        {pointMultiplerEnabled ? (
          <Grid container spacing={{ xs: 2, md: 8 }}>
            <Grid item>
              {daysToDisplay?.map((schedule) => {
                return (
                  <Typography
                    key={`${schedule.id}-${schedule.dayName}`}
                    variant="body-1"
                    pb={1}
                  >
                    {`${schedule.dayName}:`}
                  </Typography>
                )
              })}
            </Grid>
            <Grid item>
              {daysToDisplay?.map((schedule) => {
                return (
                  <Typography
                    key={`${schedule.id}-${schedule.dayName}`}
                    variant="body-1"
                    color={
                      (schedule.opening || schedule.closing) &&
                      schedule.patronCheckInPointBonusMultiplier === 2
                        ? colors.text.primary
                        : colors.text.disabled
                    }
                    pb={1}
                  >
                    {schedule.opening &&
                    schedule.patronCheckInPointBonusMultiplier === 2
                      ? `${convertScheduleHourTo12HourFormat(schedule.opening)}`
                      : 'Disabled'}
                    {schedule.closing &&
                    schedule.patronCheckInPointBonusMultiplier === 2
                      ? ` - ${convertScheduleHourTo12HourFormat(
                          schedule.closing
                        )}`
                      : ''}
                  </Typography>
                )
              })}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" color={colors.text.secondary}>
            Enable check ins to edit the points multiplier section
          </Typography>
        )}
      </Box>
    </Box>
  )
}
