import { Box, Typography, useTheme, Switch } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'

export const GrantPointsOnCheckInSwitch = () => {
  const theme = useTheme()
  const { control } = useFormContext()

  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.grey[300],
        m: '16px',
        gap: '16px',
        p: '16px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Grant Points on Check-In
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Allow patrons to receive points upon check-in for this rewards
          program.
        </Typography>
      </Box>
      <Controller
        name="enablePatronCheckInPointBonus"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Switch checked={value} onChange={onChange} />
        )}
      />
    </Box>
  )
}
