import { Box, Button, MenuItem, Typography } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { type SweepstakeWinnerStatusType } from '../../../../types/api'
import ListBullet from 'assets/format_list_bulleted.svg'
import { useState } from 'react'
import './styles.css'
import { usePutSweepstakeWinner } from 'hooks/api/Sweepstake/usePutSweepstakeWinner'
import { SweepstakesValidateWinnerModal } from './SweepstakesValidateWinnerModal'
import { useQueryClient } from '@tanstack/react-query'
import { RedrawWinnerMenuItem } from './LocationPrizeMenu'

export const GrandPrizeMenu = ({
  params,
  setIsIndividualRedrawModalOpen,
}: {
  params: GridRenderCellParams
  setIsIndividualRedrawModalOpen: () => void
}) => {
  const qc = useQueryClient()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedSweepstakeWinnerId, setSelectedSweepstakeWinnerId] = useState<
    number | undefined
  >()
  const [
    isSweepstakesValidateWinnerModalOpen,
    setIsSweepstakesValidateWinnerModalOpen,
  ] = useState(false)
  const toggleSweepstakesValidateWinnerModalOpen = () =>
    setIsSweepstakesValidateWinnerModalOpen(
      !isSweepstakesValidateWinnerModalOpen
    )

  const { mutateAsync } = usePutSweepstakeWinner({
    onError: () => {},
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/sweepstakes'] })
      setAnchorEl(null)
    },
  })

  const toggleMenu = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation()
    if (anchorEl != null) {
      setAnchorEl(null)
      setSelectedSweepstakeWinnerId(undefined)
    } else {
      setSelectedSweepstakeWinnerId(id)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = anchorEl !== null

  const status: SweepstakeWinnerStatusType = params.row.winnerStatus

  if (status === 'NoWinner') {
    return null
  }

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          toggleMenu(e, params.row.id)
        }}
        style={{
          minWidth: '50px',
        }}
      >
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </Button>
      <StyledMenu
        sx={{ mt: 0 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {status === 'GrandPrizeValidation' && (
          <MenuItem
            onClick={() => setIsSweepstakesValidateWinnerModalOpen(true)}
          >
            <Box className="py-2">
              <Typography variant="body-2">Validate Winner</Typography>
            </Box>
          </MenuItem>
        )}
        {status === 'WinnerValidation' && (
          <>
            <MenuItem
              onClick={() =>
                alert(
                  'Functionality of this button to be added in a future PBI'
                )
              }
            >
              <Box className="py-2">
                <Typography variant="body-2">Notified Winner</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={async () =>
                await mutateAsync({
                  req: {
                    id: selectedSweepstakeWinnerId,
                    status: 'AffidavitReceived',
                    sweepstakeDrawingId: params.row.sweepstakeDrawingId,
                    contestEntryId: params.row.contestEntryId,
                    patronId: params.row.patronId,
                  },
                })
              }
            >
              <Box className="py-2">
                <Typography variant="body-2">Affidavit Received</Typography>
              </Box>
            </MenuItem>
          </>
        )}
        {status === 'WinnerNotified' && (
          <MenuItem
            onClick={() =>
              alert('Functionality of this button to be added in a future PBI')
            }
          >
            <Box className="py-2">
              <Typography variant="body-2">Affidavit Received</Typography>
            </Box>
          </MenuItem>
        )}

        {status === 'AffidavitReceived' && (
          <MenuItem
            onClick={async () =>
              await mutateAsync({
                req: {
                  id: selectedSweepstakeWinnerId,
                  status: 'Awarded',
                  sweepstakeDrawingId: params.row.sweepstakeDrawingId,
                  contestEntryId: params.row.contestEntryId,
                  patronId: params.row.patronId,
                },
              })
            }
          >
            <Box className="py-2">
              <Typography variant="body-2">
                Sent Prize Confirmation Details
              </Typography>
            </Box>
          </MenuItem>
        )}
        {status === 'Awarded' && (
          <MenuItem
            onClick={() =>
              alert('Functionality of this button to be added in a future PBI')
            }
          >
            <Box className="py-2">
              <Typography variant="body-2">Winner Claimed Prize</Typography>
            </Box>
          </MenuItem>
        )}
        {status === 'PrizeClaim' && (
          <MenuItem
            onClick={() =>
              alert('Functionality of this button to be added in a future PBI')
            }
          >
            <Box className="py-2">
              <Typography variant="body-2">
                Sent Prize Confirmation Details
              </Typography>
            </Box>
          </MenuItem>
        )}
        {status !== 'PrizeClaim' && (
          <RedrawWinnerMenuItem
            key="redrawWinner"
            handleClose={handleClose}
            setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
          />
        )}
      </StyledMenu>
      <SweepstakesValidateWinnerModal
        winnerName={params.row.winnerName}
        tierName={params.row.tierName}
        isModalOpen={isSweepstakesValidateWinnerModalOpen}
        toggleIsOpen={toggleSweepstakesValidateWinnerModalOpen}
        onConfirm={async () => {
          toggleSweepstakesValidateWinnerModalOpen()
          await mutateAsync({
            req: {
              id: selectedSweepstakeWinnerId,
              status: 'WinnerValidation',
              sweepstakeDrawingId: params.row.sweepstakeDrawingId,
              contestEntryId: params.row.contestEntryId,
              patronId: params.row.patronId,
            },
          })
          handleClose()
        }}
        onCancel={() => {
          toggleSweepstakesValidateWinnerModalOpen()
          handleClose()
        }}
      />
    </>
  )
}
