import {
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from '@mui/material'
import { green, grey } from '@mui/material/colors'

const colors = {
  active: green[900],
  inactive: grey[700],
}

const backgroundColors = {
  active: green[100],
  inactive: grey[300],
}

export const SectionHeader = ({
  buttonText,
  chipInactive,
  chipText,
  onClickButton,
  showButton = true,
  sx,
  title,
}: {
  title: string
  chipText?: string
  chipInactive?: boolean
  onClickButton?: () => void
  buttonText?: string
  showButton?: boolean
  sx?: SxProps<Theme>
}) => {
  const status = chipInactive ? 'inactive' : 'active'
  const finalSx = {
    borderRadius: '100px',
    color: colors[status],
    backgroundColor: backgroundColors[status],
    ...(sx ?? {}),
  }

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      alignItems="center"
      mb={2}
      sx={sx}
    >
      <Grid display="flex" alignContent="center" alignItems="center" gap={2}>
        <Typography variant="h3">{title}</Typography>
        {chipInactive ? (
          <Chip
            className="!rounded-sm h-24"
            label={
              <Typography fontSize={'14px'} fontWeight="bold">
                {chipText?.toUpperCase()}
              </Typography>
            }
            sx={finalSx}
          />
        ) : null}
      </Grid>
      {showButton && (
        <Grid item xs={5} display={'flex'} justifyContent={'flex-end'}>
          <Button
            data-testid="section-header-button"
            variant="text"
            onClick={onClickButton}
            sx={{ height: '100%', justifyContent: 'flex-end', minWidth: 10 }}
          >
            {buttonText ?? 'Edit'}
          </Button>
        </Grid>
      )}
    </Stack>
  )
}
