import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from './Shared/ActivityIndicator'
import {
  Box,
  InputLabel,
  MenuItem,
  Typography,
  FormControl,
  createFilterOptions,
  Popper,
  Autocomplete,
  TextField,
  type AutocompleteProps,
} from '@mui/material'
import { useState, useMemo } from 'react'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { LicensedEstablishmentShowcaseCard } from 'components/LicensedEstablishmentShowcaseCard'
import {
  type FieldValues,
  type Path,
  useFormContext,
  Controller,
} from 'react-hook-form'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useGetLicensedEstablishmentById } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentById'
import { type LicensedEstablishment } from 'types/api'
import { omit } from 'lodash'

const MAX_LOCATIONS = 200

interface ExtraProps<SchemaT extends FieldValues> {
  hideCard?: boolean
  required?: boolean
  error?: boolean
  label?: string
  licensedEstablishmentIdKey: Path<SchemaT>
  name?: Path<SchemaT>
}

export const SelectLicensedEstablishment = <SchemaT extends FieldValues>({
  label,
  required,
  hideCard,
  licensedEstablishmentIdKey,
  name = licensedEstablishmentIdKey,
  error,
  ...otherProps
}: Omit<
  AutocompleteProps<LicensedEstablishment, false, false, false>,
  'options' | 'renderInput'
> &
  ExtraProps<SchemaT>) => {
  const { watch } = useFormContext<SchemaT>()
  const get = useTypedApiClient({
    path: '/admin/corporate-accounts/licensed-establishments',
    method: 'get',
  })
  const {
    isPending,
    isError,
    data: licensedEstablishments,
  } = useQuery({
    queryKey: ['licensedEstablishments'],
    queryFn: async () => {
      const { data } = await get({})

      return data
    },
    select: (data) => data.sort((a, b) => a.name.localeCompare(b.name)),
  })

  const watchedLicensedEstablishmentId = watch(licensedEstablishmentIdKey)
  const selectedLicensedEstablishmentId = useMemo(() => {
    if (hideCard) {
      return undefined
    }

    if (isNaN(watchedLicensedEstablishmentId)) {
      return undefined
    }

    return Number(watchedLicensedEstablishmentId)
  }, [hideCard, watchedLicensedEstablishmentId])

  const { data: licensedEstablishment } = useGetLicensedEstablishmentById(
    selectedLicensedEstablishmentId
  )
  const [selectOpen, setSelectOpen] = useState(false)

  if (isPending) {
    return <ActivityIndicator />
  }

  if (isError) {
    return <Typography>Error</Typography>
  }

  return (
    <Box>
      <FormControl>
        <InputLabel id={`${name}-label`} htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Autocomplete<LicensedEstablishment>
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                limit: MAX_LOCATIONS,
              })}
              renderInput={(params) => <TextField error={error} {...params} />}
              id={name}
              disablePortal
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
              options={licensedEstablishments}
              getOptionLabel={(option) =>
                option.standardName ? option.standardName : option.name
              }
              ListboxProps={{ sx: { maxHeight: '250px' } }}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  popperOptions={{
                    modifiers: [
                      { name: 'flip', options: { fallbackPlacements: [] } },
                    ],
                  }}
                  placement="bottom"
                />
              )}
              renderOption={(props, le) => (
                <MenuItem
                  key={le.id}
                  value={le.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  {...omit(props, 'className', 'key')}
                >
                  {le.standardName ? le.standardName : le.name}
                  {selectOpen && le.addresses[0] && (
                    <Typography variant="body-3">
                      {le.addresses[0].address1}
                      {le.addresses[0].address1 &&
                        le.addresses[0].city &&
                        `, ${String(le.addresses[0].city)}`}
                      {!le.addresses[0].address1 &&
                        le.addresses[0].city &&
                        `${String(le.addresses[0].city)}`}
                    </Typography>
                  )}
                </MenuItem>
              )}
              {...otherProps}
              onChange={(_ev, value) => onChange(value?.id)}
            />
          )}
        />
        {isPresent(licensedEstablishment) && (
          <LicensedEstablishmentShowcaseCard
            licensedEstablishment={licensedEstablishment}
          />
        )}
      </FormControl>
    </Box>
  )
}
