import { Typography, Box } from '@mui/material'
import { type GridRenderCellParams, type GridColDef } from '@mui/x-data-grid'
import { colors } from 'components/ThemeProvider'
import { type SweepstakeWinnerStatusType } from 'types/api'
import { getStatusStyleColors } from './LocationWinnersColumns'
import { toUpper } from 'lodash'
import { statusTextMap } from 'components/List/FilterStatusList'
import { GrandPrizeMenu } from './GrandPrizeMenu'
import { formatFullDate } from 'utils/util'
import { type RequestRedrawModalDataType } from 'stores/useRequestRedrawModal'

export const getGrandPrizeWinnersColumns = (
  setIsIndividualRedrawModalOpen: (
    data: RequestRedrawModalDataType | undefined
  ) => void
): GridColDef[] => [
  {
    field: '',
    minWidth: 50,
    headerName: '',
    renderCell: (params) => {
      return (
        <GrandPrizeMenu
          params={params}
          setIsIndividualRedrawModalOpen={() => {
            setIsIndividualRedrawModalOpen({
              winnerName: params.row.winnerName,
              sweepstakeDrawingId: params.row.sweepstakeDrawingId,
              tierName: params.row.tierName,
              sweepstakeWinnerId: params.row.id,
            })
          }}
        />
      )
    },
  },
  {
    field: 'tierName',
    minWidth: 200,
    headerName: 'Tier Level',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Typography color={colors.text.primary}>{params.value}</Typography>
    ),
  },
  {
    field: 'winnerName',
    minWidth: 200,
    headerName: 'Winner Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Typography color={colors.text.primary}>{params.value}</Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Winner Status',
    minWidth: 150,
    flex: 1,
    filterable: false,
    valueGetter: (params) => {
      return params.row.winnerStatus
    },
    renderCell: (params: GridRenderCellParams) => {
      const statusText = params.value as SweepstakeWinnerStatusType
      const style = getStatusStyleColors(
        params.value as SweepstakeWinnerStatusType
      )

      return <Box sx={{ ...style }}>{toUpper(statusTextMap[statusText])}</Box>
    },
  },
  {
    field: 'drawnDate',
    minWidth: 200,
    headerName: 'Date Drawn',
    flex: 1,
    renderCell: (params) => (
      <Typography color={colors.text.primary}>
        {params.value === '-' ? params.value : formatFullDate(params.value)}
      </Typography>
    ),
  },
]
