import { useRef } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Button, type SxProps, type Theme, Typography } from '@mui/material'

const commonSxTextButton: SxProps<Theme> = {
  marginLeft: '-4px',
  marginRight: '8px',
  paddingTop: '1%',
}

interface UploadButtonProps {
  addFiles: (files: File[]) => void
  textToShow?: string
  informationFile?: string
  fileFormatsAllowed?: string
  multiple?: boolean
}

export const UploadButton = ({
  addFiles,
  textToShow,
  informationFile,
  fileFormatsAllowed,
  multiple = false,
}: UploadButtonProps) => {
  const uploadInputRef = useRef<HTMLInputElement | null>(null)

  const onChangeFilesUploaded = () => {
    if (uploadInputRef.current?.files != null) {
      addFiles(Array.from(uploadInputRef.current?.files))
    }
  }

  return (
    <>
      <input
        ref={uploadInputRef}
        type="file"
        id="contained-button-file"
        accept={fileFormatsAllowed ?? ''}
        style={{ display: 'none' }}
        data-testid="upload-button"
        onChange={onChangeFilesUploaded}
        multiple={multiple}
      />
      <label htmlFor="contained-button-file">
        <Button
          startIcon={<FileUploadIcon sx={{ fontSize: '40px !important' }} />}
          onClick={() => uploadInputRef.current?.click()}
          className="w-full"
          sx={{
            height: '120px',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography sx={commonSxTextButton}>
            {textToShow ?? 'Upload'}
          </Typography>

          <Typography variant="body-3" sx={commonSxTextButton}>
            {informationFile}
          </Typography>
        </Button>
      </label>
    </>
  )
}
