import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const commonSx = {
  display: 'flex',
  alignItems: 'center',
  color: 'text.secondary',
  p: '16px',
  justifyContent: 'center',
  backgroundColor: grey[100],
  height: '110px',
}

export const NoRowsOverlay = () => {
  return (
    <Box sx={commonSx}>
      <Typography variant="body1">No Location reward programs</Typography>
    </Box>
  )
}
