import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import { Box, Typography } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import {
  type GridEventListener,
  type GridRowParams,
  type GridColDef,
} from '@mui/x-data-grid'
import { formatDateTime } from 'utils/util'
import { colorPrimitives } from 'components/Theme'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { useGetPatronNotesById } from 'hooks/api/useGetPatronNotesById'
import { type PatronNote } from 'types/api'
import { useState } from 'react'
import { PatronNoteModal } from './PatronNoteModal'
import { SectionHeader } from 'components/SectionHeader'
import { AddPatronNoteModal } from './AddPatronNoteModal'

export const PatronNotesList = () => {
  const [selectedNote, setSelectedNote] = useState<PatronNote>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false)
  const { id: patronAccountId } = useParams()
  const patronQuery = useGetPatronById({ id: Number(patronAccountId) })

  const {
    data: patronNotes,
    isPending,
    isError,
  } = useGetPatronNotesById({
    id: Number(patronAccountId),
  })

  const selectRow: GridEventListener<'rowClick'> = (
    params: GridRowParams<PatronNote>
  ) => {
    setSelectedNote(params.row)
    toggleModalIsOpen()
  }

  const toggleModalIsOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const toggleAddNoteModalIsOpen = () => {
    setIsAddNoteModalOpen(!isAddNoteModalOpen)
  }

  if (patronQuery.isPending || isPending) {
    return <ActivityIndicator />
  }

  if (
    patronQuery.isError ||
    isNil(patronQuery.data) ||
    isError ||
    isNil(patronNotes)
  ) {
    return <ErrorIndicator message="An Error Occurred" />
  }

  const NotesColumns: Array<GridColDef<PatronNote>> = [
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 200,
      valueFormatter: (params: { value: string }) => {
        return formatDateTime(params.value, 'MM-dd-yyyy p')
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      minWidth: 300,
    },
    {
      field: 'note',
      headerName: 'Note',
      minWidth: 400,
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {params.row.note}
        </Typography>
      ),
    },
  ]

  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/Notes`}
    >
      <Box paddingBottom={3}>
        <SectionHeader
          title="Notes"
          buttonText="Add Note"
          onClickButton={() => toggleAddNoteModalIsOpen()}
        />

        <DataTable
          onRowClick={selectRow}
          rowHeight={80}
          sortFieldName="createdOn"
          sort="desc"
          columns={NotesColumns}
          rows={patronNotes}
          getRowId={(row) => row.id ?? 0}
          sx={{
            '& .MuiDataGrid-cell': {
              color: colorPrimitives.black,
            },
          }}
        />
      </Box>

      <AddPatronNoteModal
        isModalOpen={isAddNoteModalOpen}
        toggleModalIsOpen={toggleAddNoteModalIsOpen}
        patronId={Number(patronAccountId)}
      />

      <PatronNoteModal
        isModalOpen={isModalOpen}
        row={selectedNote}
        toggleModalIsOpen={toggleModalIsOpen}
      />
    </PatronDetailsHeader>
  )
}
