import { Stack, Typography } from '@mui/material'

export function NoResultsOverlayCustom() {
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Typography variant="body-3" fontSize={'16px'}>
        No Results Found
      </Typography>
    </Stack>
  )
}
