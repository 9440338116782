import { Typography } from '@mui/material'
import type { components } from 'api/playerPayback/api'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import { getLastUpdated } from 'utils/util'
import { SweepstakeDetailsTabs } from './SweepstakeDetailsTabs'
import { SweepstakesStatusLabel } from 'components/Sweepstakes/SweepstakesStatusLabel'

export const SweepstakesDetailsHeader = ({
  sweepstake,
  currentTab,
  actionButton,
  children,
}: {
  sweepstake: components['schemas']['FullContestDTO']
  currentTab: string
  actionButton?: React.ReactNode
  children?: React.ReactNode
}) => {
  if (!sweepstake.id) {
    return <Typography>Sweepstake Id must not be null.</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title={isNil(sweepstake.name) ? 'Unknown' : sweepstake.name}
          subtitle={getLastUpdated(sweepstake.modifiedOn)}
          backText="Sweepstakes"
          backPath="/Contests/Sweepstakes"
          isSecondary={true}
          status={
            <SweepstakesStatusLabel
              status={sweepstake.sweepstakeData?.state ?? 'Draft'}
            />
          }
          actionButton={actionButton}
          tabs={
            <SweepstakeDetailsTabs
              currentTab={currentTab}
              sweepstakeId={sweepstake.id}
            />
          }
        />
      }
    >
      {children}
    </Page>
  )
}
