import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { usePutContestLe } from 'hooks/api/usePutContestLe'
import { useLayout } from 'hooks/useLayout'
import { type Control, Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import { type ContestLE } from 'types/api'
import { bool, object } from 'yup'

const SignageFormController = ({
  onChange,
  control,
  title,
  name,
}: {
  title: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  control: Control<
    {
      signageOne: boolean
      signageTwo: boolean
    },
    any
  >
  name: 'signageOne' | 'signageTwo'
}) => {
  return (
    <Box>
      <Typography variant="body-1" className="text-secondary">
        {title}
      </Typography>

      <Controller
        render={({ field }) => (
          <RadioGroup aria-label="type" {...field} onChange={onChange}>
            {[true, false].map((type) => (
              <FormControlLabel
                key={type ? 'Yes' : 'No'}
                value={type}
                control={<Radio />}
                label={type ? 'Yes' : 'No'}
              />
            ))}
          </RadioGroup>
        )}
        name={name}
        control={control}
      />
    </Box>
  )
}

const SweepstakeSignageSchema = object({
  signageOne: bool().required(),
  signageTwo: bool().required(),
})
export const EditSignageModal = ({
  isModalOpen,
  toggleModalIsOpen,
  contestLE,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void

  contestLE: ContestLE
}) => {
  const qc = useQueryClient()
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : 'auto'
  const setMessage = useSnackbar((x) => x.setMessage)
  const signageOne = Boolean(contestLE.signage1)
  const signageTwo = Boolean(contestLE.signage2)

  const formMethods = useForm({
    resolver: yupResolver(SweepstakeSignageSchema),
    defaultValues: { signageOne, signageTwo },
  })

  const { handleSubmit, setValue, control, reset } = formMethods

  const { mutateAsync: putContestLe, isPending } = usePutContestLe({
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: [
          '/admin/contests/{contestId}/licensed-establishments/{licensedEstablishmentId}',
        ],
      })
      reset()
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Contest LE')
      reset()
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {
        reset()
        toggleModalIsOpen()
      }}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: 'auto',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={() => {
          reset()
          toggleModalIsOpen()
        }}
        title={`Sweepstake Signage`}
      />
      <ModalFullBody heightModal={heightModal}>
        <Stack spacing={'30%'} direction={'row'}>
          <SignageFormController
            control={control}
            name="signageOne"
            onChange={(e) => {
              setValue('signageOne', e.target.value === 'true')
            }}
            title="Decal"
          />
          <SignageFormController
            control={control}
            name="signageTwo"
            onChange={(e) => {
              setValue('signageTwo', e.target.value === 'true')
            }}
            title="Banner"
          />
        </Stack>
      </ModalFullBody>
      <ModalFullFooter>
        <Button
          onClick={() => {
            reset()
            toggleModalIsOpen()
          }}
        >
          Cancel
        </Button>
        <ActivityButton
          active={isPending}
          type="submit"
          variant="contained"
          onClick={handleSubmit(async (data) => {
            const { signageOne, signageTwo } = data
            await putContestLe({
              req: {
                ...contestLE,
                signage1: signageOne,
                signage2: signageTwo,
              },
            })
          })}
        >
          Save
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}
