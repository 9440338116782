import { Box, Typography, useTheme } from '@mui/material'
import { type RedrawnPatrons } from './RedrawnPatronsColumns'
import { type GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { isNil } from 'lodash'
import { colorPrimitives } from 'components/Theme'

export const RedrawnPatronsSection = ({
  rows,
  columns,
}: {
  rows: RedrawnPatrons[]
  columns: GridColDef[]
}) => {
  const theme = useTheme()

  return (
    <>
      <Typography variant="h2" pt={8} pb={2}>
        Redrawn Patrons
      </Typography>
      {(isNil(rows) || rows.length === 0) && (
        <Box
          border={{ md: `1px solid ${theme.palette.grey[200]}` }}
          borderRadius="5px"
          style={{ height: '200px', backgroundColor: '#2C2C270F' }}
          p={{ xs: 0, sm: 3 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body-1" color={colorPrimitives.gray70}>
            Winners have not been redrawn for this prize
          </Typography>
        </Box>
      )}
      {!isNil(rows) && rows.length > 0 && (
        <DataTable
          sortFieldName="drawingNumber"
          rows={rows}
          columns={columns}
        />
      )}
    </>
  )
}
