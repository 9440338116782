import { Page } from 'components/Shared/Page'
import { Link as RouterLink } from 'react-router-dom'
import { Link, Stack } from '@mui/material'
import { isDeveloperMode } from 'utils/isDeveloperMode'

export const DashboardPage = () => {
  return (
    <Page>
      <Stack spacing={2} my={2}>
        {isDeveloperMode() && (
          <Link to="/ComponentCatalog" component={RouterLink}>
            Component Catalog
          </Link>
        )}
        <Link to="/PatronAccounts" component={RouterLink}>
          Patrons
        </Link>
      </Stack>
    </Page>
  )
}
