import { Box, type Theme, type SxProps } from '@mui/material'

const commonSxModalProps: SxProps<Theme> = {
  width: '100%',
  borderTop: '1px solid #e5e7eb',
}

export const ModalFullFooter = ({
  children,
  leftFooter,
}: {
  children: React.ReactNode
  leftFooter?: React.ReactNode
}) => {
  return (
    <Box sx={commonSxModalProps}>
      <Box
        className="flex flex-row px-8 py-4 gap-x-2"
        sx={{ justifyContent: leftFooter ? 'space-between' : 'end' }}
      >
        {leftFooter}
        <Box>{children}</Box>
      </Box>
    </Box>
  )
}
