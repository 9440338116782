import { useTypedApiClient } from 'hooks/useTypedApiClient'
import {
  type RewardsProgramCatalogDTO,
  type RewardsProgramCatalog,
} from 'types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type ApiError } from 'openapi-typescript-fetch'

interface usePutRewardProgramCatalogsProps {
  onSuccess?: (data: RewardsProgramCatalog) => Promise<void>
  onError?: () => void
}

export const usePutRewardProgramCatalogs = ({
  onSuccess,
  onError,
}: usePutRewardProgramCatalogsProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards-program-catalogs/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<RewardsProgramCatalog, ApiError, RewardsProgramCatalog>({
    mutationFn: async (inputData: RewardsProgramCatalogDTO) => {
      const { data } = await put({
        ...inputData,
        id: Number(inputData.id),
      })
      return data
    },
    onSuccess: async (data: RewardsProgramCatalog) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-program-catalogs/{id}', data.id],
      })
      await onSuccess?.(data)
    },
    onError,
  })
}
