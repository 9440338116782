import { useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ContestLeRequest } from 'types/api'

export const usePutContestLe = ({
  onError,
  onSuccess,
}: {
  onError: () => void | Promise<void>
  onSuccess: () => void | Promise<void>
}) => {
  const putClient = useTypedApiClient({
    path: '/admin/contests/licensed-establishments/{id}',
    method: 'put',
  })
  return useMutation({
    mutationFn: async ({ req }: { req: ContestLeRequest }) => {
      const { data } = await putClient({ ...req, id: Number(req.id) })
      return data
    },
    onSuccess,
    onError,
  })
}
