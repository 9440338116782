import { Page } from 'components/Shared/Page'
import { Button, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { useSnackbar } from 'stores/useSnackbar'
import { FormProvider, useForm } from 'react-hook-form'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { array, number, object, string } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityButton } from 'components/ActivityButton'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

interface OrganizationForm {
  organization: {
    corporateAccountId: number
    name: string
    licensedEstablishmentIds?: number[]
  }
}

const OrganizationSchema = object().shape({
  organization: object().shape({
    name: string().required('Must enter a name'),
    corporateAccountId: number()
      .required('Must select a corporate account')
      .transform((val) => (isNaN(val) ? undefined : val)),
    licensedEstablishmentIds: array(),
  }),
})

export const OrganizationsNewPage = () => {
  const client = useApiClient()
  const navigate = useNavigate()
  const formMethods = useForm({
    resolver: yupResolver(OrganizationSchema),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const createOrganization = useMutation<unknown, Error, OrganizationForm>({
    mutationFn: async ({ organization }) => {
      const result = await client.post('/admin/organizations', {
        params: { header: undefined },
        body: {
          corporateAccountId: organization.corporateAccountId,
          name: organization.name,
          licensedEstablishmentIds: [],
        },
      })
      if (!result.response.ok) {
        // TODO: capture validation error message
        throw new Error()
      }
    },
    onSuccess: () => {
      setSnackbarMessage('Organization successfully created')
      navigate('/ClientManagement/Organizations')
    },
    onError: () => {
      setSnackbarMessage('An error occurred', 'error')
    },
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    createOrganization.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Organization"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backPath="/ClientManagement/Organizations"
          backText="Organizations"
          isSecondary={true}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <Stack direction="column" gap={6}>
            <Stack direction="column">
              <TextField
                label="Organization Name"
                placeholder="Enter organization name"
                {...register('organization.name')}
                error={isPresent(errors.organization?.name)}
                fullWidth
                required
              />
              {errors.organization?.name?.message && (
                <FormFieldErrorMessage
                  message={errors.organization.name.message}
                  data-testid="new-organization-name-error"
                />
              )}
            </Stack>
            <Stack maxWidth="520px" direction="column">
              <SelectCorporateAccount
                {...register('organization.corporateAccountId')}
                error={isPresent(errors.organization?.corporateAccountId)}
                label="Assign to Corporate Account"
                required
                fullWidth
                corporateAccountIdKey="corporateAccountId"
                placeholder="Select a corporate account"
              />
              {errors.organization?.corporateAccountId?.message && (
                <FormFieldErrorMessage
                  message={errors.organization?.corporateAccountId.message}
                  data-testid="new-organization-corporate-account-error"
                />
              )}
            </Stack>
            <Stack spacing={2} direction="row">
              <Link to="/ClientManagement/Organizations">
                <Button variant="outlined">Cancel</Button>
              </Link>
              <ActivityButton
                type="submit"
                active={createOrganization.isPending}
                onClick={handleSubmitWrapper}
                variant="contained"
              >
                Submit
              </ActivityButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
