import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { usePutContestLe } from 'hooks/api/usePutContestLe'
import { useLayout } from 'hooks/useLayout'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import { type ContestLE } from 'types/api'
import { applyEmailSchema } from 'utils/schemas/nestedEmailSchema'
import { bool, object, string } from 'yup'

const SweepstakeDetailsSchema = object({
  notes: string(),
  usePrimaryContact: bool(),
  email: string().when('usePrimaryContact', {
    is: (usePrimaryContact: boolean) => !usePrimaryContact,
    then: (schema) => applyEmailSchema(schema),
    otherwise: (schema) => schema.notRequired(),
  }),
})

export const EditEmailAndNotesModal = ({
  isModalOpen,
  toggleModalIsOpen,
  locationEmail,
  contestLE,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  locationEmail: string
  contestLE: ContestLE
}) => {
  const qc = useQueryClient()
  const setMessage = useSnackbar((x) => x.setMessage)
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : 'auto'

  const email = String(contestLE.emailAddress)
  const notes = contestLE.notes ?? ''

  const formMethods = useForm({
    resolver: yupResolver(SweepstakeDetailsSchema),
    defaultValues: {
      email,
      notes,
      usePrimaryContact: false,
    },
  })

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = formMethods

  const { mutateAsync: putContestLe, isPending } = usePutContestLe({
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: [
          '/admin/contests/{contestId}/licensed-establishments/{licensedEstablishmentId}',
          contestLE.contestId,
          contestLE.licensedEstablishmentId,
        ],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      reset()
      setMessage('Error updating Contest LE', 'error')
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {
        reset()
        toggleModalIsOpen()
      }}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: 'auto',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={() => {
          reset()
          toggleModalIsOpen()
        }}
        title={`Edit Sweepstake Details`}
      />
      <ModalFullBody heightModal={heightModal} sx={{ minWidth: '100%' }}>
        <Box width={'100%'}>
          <InputLabel
            required
            htmlFor="email"
            sx={{ fontSize: '16px', fontWeight: '100' }}
          >
            Email Address
          </InputLabel>
          <Typography variant="body-1" className="text-secondary" pb={'16px'}>
            Winner notification will be sent to the email address provided
          </Typography>
          <TextField
            fullWidth
            {...register('email', { required: true })}
            error={isPresent(errors.email)}
          />
          <FormControlLabel
            className="pt-2"
            control={
              <Checkbox
                {...register('usePrimaryContact', {})}
                checked={watch('usePrimaryContact') ?? false}
                onChange={(event) => {
                  setValue('usePrimaryContact', event.target.checked)
                  if (event.target.checked) {
                    setValue('email', '')
                  }
                }}
              />
            }
            label="Use primary contact associated with LE"
          />
        </Box>
        <Box py={'32px'}>
          <InputLabel
            htmlFor="notes"
            sx={{ fontSize: '16px', fontWeight: '100' }}
          >
            Notes
          </InputLabel>
          <TextField
            fullWidth
            multiline
            minRows={4}
            {...register('notes')}
            error={isPresent(errors.notes)}
          />
        </Box>
      </ModalFullBody>
      <ModalFullFooter>
        <Button
          onClick={() => {
            reset()
            toggleModalIsOpen()
          }}
        >
          Cancel
        </Button>
        <ActivityButton
          active={isPending}
          type="submit"
          variant="contained"
          onClick={handleSubmit(async (data) => {
            const { email, notes, usePrimaryContact } = data
            await putContestLe({
              req: {
                ...contestLE,
                emailAddress: usePrimaryContact ? locationEmail : email,
                notes,
              },
            })
          })}
        >
          Save
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}
