/* eslint-disable @typescript-eslint/no-misused-promises */
// There's a slight mismatch between the callbacks RHF provides
// and the ones MUI expects, so we need to wrap the callback
import { Box, InputLabel } from '@mui/material'
import { TimeField, type TimeFieldProps } from '@mui/x-date-pickers'
import { isNil } from 'lodash'
import { type ForwardedRef, forwardRef } from 'react'
import { type ChangeHandler } from 'react-hook-form'

export const HookableTimeField = forwardRef(
  <TDate extends string>(
    {
      onChange,
      required,
      name,
      label,
      ...otherProps
    }: Omit<TimeFieldProps<TDate>, 'onChange'> & { onChange: ChangeHandler },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const onChangeWrapper: TimeFieldProps<TDate>['onChange'] = async (
      value
    ) => {
      const valueAsString = isNil(value) ? '' : value.toString()
      await onChange({ target: { value: valueAsString } })
    }

    return (
      <Box>
        <InputLabel required={required} htmlFor={name}>
          {label}
        </InputLabel>
        <TimeField
          fullWidth
          onChange={onChangeWrapper}
          id={name}
          name={name}
          {...otherProps}
          ref={ref}
        />
      </Box>
    )
  }
)

HookableTimeField.displayName = 'HookableTimeField'
