import { type CheckInPointBonusSchedule } from 'types/api'
import { daysOfWeek } from 'utils/util'

export const addMissingDays = (schedules: CheckInPointBonusSchedule[]) => {
  const allDays: CheckInPointBonusSchedule[] = [
    {
      id: 0,
      dayName: 'Monday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 1,
      dayName: 'Tuesday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 2,
      dayName: 'Wednesday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 3,
      dayName: 'Thursday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 4,
      dayName: 'Friday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 5,
      dayName: 'Saturday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
    {
      id: 6,
      dayName: 'Sunday',
      opening: '',
      closing: '',
      patronCheckInPointBonusMultiplier: 3,
    },
  ]
  schedules.forEach((day) => {
    const indexToReplace = daysOfWeek.indexOf(day.dayName ?? '')
    allDays[indexToReplace] = day
  })
  return allDays
}
