import { Page } from 'components/Shared/Page'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetCheckInPolicyByRewardsProgramsId } from 'hooks/api/RewardProgram/useGetCheckInPolicyByRewardsProgramsId'
import { GrantCheckInsSection } from './GrantCheckInsSection'
import { type FullCheckInPolicy } from 'types/api'
import { RewardProgramSettingsSection } from './RewardProgramSettingsSection'
import { Stack } from '@mui/material'

export const RewardProgramSettingsPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId: Number(rewardProgramId),
  })

  const policiesQuery = useGetCheckInPolicyByRewardsProgramsId({
    rewardProgramId: Number(rewardProgramId),
  })

  if (rewardProgramQuery.isPending || policiesQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || policiesQuery.isError) {
    return <p>An error occurred.</p>
  }

  let checkInGrantPointsOnCheckInPolicy: FullCheckInPolicy
  let maxNumberOfCheckInsPer24HoursPolicy: FullCheckInPolicy

  // each policy is one item in the array as we need to display them we need to figure out which one it is and assign to unique name to then display
  const assignPoliciesFromQuery = (queryArray: FullCheckInPolicy[]) => {
    let foundGrantPointsPolicy = false
    let foundMaxCheckInsPolicy = false

    for (const policy of queryArray) {
      if (
        !foundGrantPointsPolicy &&
        policy.type === 'PatronCheckInPointBonus'
      ) {
        checkInGrantPointsOnCheckInPolicy = policy
        foundGrantPointsPolicy = true
      }

      if (!foundMaxCheckInsPolicy && policy.type === 'CheckInsDailyMax') {
        maxNumberOfCheckInsPer24HoursPolicy = policy
        foundMaxCheckInsPolicy = true
      }

      if (foundGrantPointsPolicy && foundMaxCheckInsPolicy) {
        break // Stop looping once both policies are found
      }
    }

    return {
      checkInGrantPointsOnCheckInPolicy,
      maxNumberOfCheckInsPer24HoursPolicy,
    }
  }

  const policies = assignPoliciesFromQuery(policiesQuery.data ?? [])

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${rewardProgramId}/Settings`}
        />
      }
    >
      <Stack gap={2} width={'100%'}>
        <Stack>
          <GrantCheckInsSection
            rewardsProgram={rewardProgramQuery.data}
            checkInGrantPointsPolicy={
              policies.checkInGrantPointsOnCheckInPolicy
            }
            checkInMaxPer24HoursPolicy={
              policies.maxNumberOfCheckInsPer24HoursPolicy
            }
          />
        </Stack>
        <Stack>
          <RewardProgramSettingsSection
            rewardsProgram={rewardProgramQuery.data}
          />
        </Stack>
      </Stack>
    </Page>
  )
}
