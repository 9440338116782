import { useState, useCallback } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useLayout } from 'hooks/useLayout'
import { NoRowsOverlay } from './NoRowsOverlay'
import {
  DataGrid,
  type GridRenderCellParams,
  type GridColDef,
} from '@mui/x-data-grid'
import { type components } from 'src/api/playerPayback/api'
import { grey } from '@mui/material/colors'
import { CellLink } from 'components/CellLink/CellLink'
import { LocationRewardsProgramMobileRow } from './LocationRewardsProgramMobileRow'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { useSnackbar } from 'stores/useSnackbar'
import { isEmpty } from 'lodash'
import { usePostRewardsProgram } from 'hooks/api/usePostRewardsProgram'
import { SwitchRewardsProgramActive } from 'components/RewardsProgram/SwitchRewardsProgramActive'
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal'

const desktopSxDataGrid = {
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'white',
  },
}

const mobileSxDataGrid = {
  border: 0,
  backgroundColor: grey[50],
  '& .MuiDataGrid-row:hover': {
    backgroundColor: grey[50],
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 0,
  },
  '& .MuiDataGrid-cell': {
    border: 0,
  },
}

const getDesktopColumns = (): Array<
  GridColDef<components['schemas']['FullRewardsProgramDTO']>
> => [
  {
    field: 'name',
    headerName: 'Program Name',
    flex: 1.5,
    renderCell: (
      params: GridRenderCellParams<
        components['schemas']['FullRewardsProgramDTO']
      >
    ) => (
      <CellLink
        text={params.row.name}
        to={`/Rewards/RewardPrograms/${String(params.row.id)}`}
      />
    ),
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 1,
    align: 'left',
    renderCell: (
      params: GridRenderCellParams<
        components['schemas']['FullRewardsProgramDTO']
      >
    ) => <StatusChip sx={{ borderRadius: 0.5 }} active={params.row.active} />,
  },
  {
    field: '__switch__',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: (
      params: GridRenderCellParams<
        components['schemas']['FullRewardsProgramDTO']
      >
    ) => <SwitchRewardsProgramActive rewardsProgram={params.row} />,
  },
]

const getMobileColumns = (): Array<
  GridColDef<components['schemas']['FullRewardsProgramDTO']>
> => [
  {
    field: '__mobile__',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: (
      params: GridRenderCellParams<
        components['schemas']['FullRewardsProgramDTO']
      >
    ) => <LocationRewardsProgramMobileRow row={params.row} />,
  },
]

interface LocationRewardsProgramsSectionProps {
  locationRewardsPrograms: Array<components['schemas']['FullRewardsProgramDTO']>
  licensedEstablishment: components['schemas']['FullLicensedEstablishmentDTO']
}

export const LocationRewardsProgramsSection = ({
  locationRewardsPrograms,
  licensedEstablishment,
}: LocationRewardsProgramsSectionProps) => {
  const { isMobile } = useLayout()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const toggleModalIsOpen = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen, setIsModalOpen])

  const { mutate: postRewardsProgramMutation } = usePostRewardsProgram({
    onSuccess: (_, variables: components['schemas']['RewardsProgramDTO']) => {
      toggleModalIsOpen()
      setSnackbarMessage(`${variables.name} created successfully.`)
    },
    onError: (_, variables: components['schemas']['RewardsProgramDTO']) => {
      setSnackbarMessage(
        `Error creating reward program ${variables.name}.`,
        'error'
      )
    },
  })

  const licensedEstablishmentRewardName = isEmpty(
    licensedEstablishment.standardName
  )
    ? licensedEstablishment.name
    : licensedEstablishment.standardName

  const createRewardsProgram = useCallback(() => {
    postRewardsProgramMutation({
      name: `${licensedEstablishmentRewardName}`,
      active: true,
      type: 'LicensedEstablishment',
      licensedEstablishmentId: licensedEstablishment.id,
    })
  }, [licensedEstablishment, postRewardsProgramMutation])

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            rowGap: '16px',
          }}
        >
          <Typography variant="h2">Location Reward Programs</Typography>
          {isEmpty(locationRewardsPrograms) && (
            <Button
              variant="contained"
              onClick={toggleModalIsOpen}
              sx={{
                maxWidth: { xs: '233px', sm: 'auto' },
              }}
            >
              Create Rewards Program
            </Button>
          )}
        </Box>
        <DataGrid
          autoHeight
          columns={isMobile ? getMobileColumns() : getDesktopColumns()}
          rows={locationRewardsPrograms}
          getRowHeight={() => (isMobile ? 125 : 'auto')}
          columnHeaderHeight={
            isMobile || isEmpty(locationRewardsPrograms) ? 0 : 56
          }
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          hideFooter
          sx={
            isMobile || isEmpty(locationRewardsPrograms)
              ? mobileSxDataGrid
              : desktopSxDataGrid
          }
        />
      </Box>
      <ConfirmModal
        confirmMessage={`Please confirm you want to create the following rewards program for ${licensedEstablishmentRewardName}`}
        secondaryMessage={`${licensedEstablishmentRewardName}`}
        isModalOpen={isModalOpen}
        toggleIsOpen={toggleModalIsOpen}
        onConfirm={createRewardsProgram}
        onCancel={toggleModalIsOpen}
      />
    </Box>
  )
}
