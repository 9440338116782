import { Box } from '@mui/material'
import { AssociatedLicensedEstablishmentsTable } from 'pages/ClientManagement/Organization/OrganizationDetailsPage/OrganizationAssociatedLicensedEstablishment/AssociatedLicensedEstablishmentsTable'
import { SectionHeader } from 'components/SectionHeader'
import { useGetLicensedEstablishmentsByOrganizationId } from 'hooks/api/useGetLicensedEstablishmentsByOrganizationId'

interface AssociatedLicensedEstablishmentsTableContainerProps {
  organizationId: number
  onClickEdit?: () => void
}

export const AssociatedLicensedEstablishmentsTableContainer = ({
  organizationId,
  onClickEdit,
}: AssociatedLicensedEstablishmentsTableContainerProps) => {
  const { data: licensedEstablishmentsByOrgId } =
    useGetLicensedEstablishmentsByOrganizationId({
      organizationId: Number(organizationId),
    })

  return (
    <Box>
      <SectionHeader
        title="Associated Licensed Establishments"
        onClickButton={onClickEdit}
        buttonText="Add Associated LE"
      />

      <AssociatedLicensedEstablishmentsTable
        organizationId={organizationId}
        licensedEstablishments={licensedEstablishmentsByOrgId}
      />
    </Box>
  )
}
