import { isPresent } from '@jjvgaming/player-payback-library'
import { Box, Typography } from '@mui/material'
import { PageHeader } from 'components/Shared/PageHeader'
import { getStatusStyleColors } from '../SweepstakesWinnersPage/LocationWinnersColumns'
import { statusTextMap } from 'components/List/FilterStatusList'
import { isNil, toUpper } from 'lodash'
import { type Sweepstake, type SweepstakeWinner } from 'types/api'
import { SweepstakesStatusLabel } from 'components/Sweepstakes/SweepstakesStatusLabel'

export const WinnerDetailsHeader = ({
  winner,
  sweepstakes,
}: {
  winner: SweepstakeWinner
  sweepstakes: Sweepstake
}) => {
  const sweepstakeName = winner.sweepstakeDrawing?.contest?.name
  const sweepstakeId = winner.sweepstakeDrawing?.contestId

  if (isNil(winner) || !isPresent(winner)) {
    return <p>An error occurred.</p>
  }

  const status = sweepstakes.sweepstakeData?.state ?? 'Draft'

  return (
    <Box>
      <PageHeader
        title={
          winner.sweepstakeDrawing?.type === 'Location'
            ? `${winner.sweepstakeDrawing?.licensedEstablishment?.standardName} Winner`
            : `Grand Prize Winner (${
                winner.patron?.currentTier?.tier?.type ??
                winner.patronTier?.tier?.type ??
                'Member'
              })`
        }
        backText={sweepstakeName}
        backPath={`/Contests/Sweepstakes/${sweepstakeId}/Winners`}
        isSecondary={true}
        status={
          <>
            <Box
              sx={{
                ...getStatusStyleColors(winner.status ?? 'LocationValidation'),
              }}
            >
              {toUpper(statusTextMap[winner.status ?? 'LocationValidation'])}
            </Box>
            <Typography color={'text.secondary'}>|</Typography>
            <SweepstakesStatusLabel status={status} />
          </>
        }
      />
    </Box>
  )
}
