import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import { formatAddress, formatDateTime, formatFullDateTime } from 'utils/util'
import { compact, isEmpty, isNil, last } from 'lodash'
import { useGetContestLeByContestAndLe } from 'hooks/api/useGetContestLeByContestAndLe'
import { useState } from 'react'
import { EditEmailAndNotesModal } from './Modals/EditEmailAndNotesModal'
import { EditSignageModal } from './Modals/EditSignageModal'
import { type Address } from 'types/address'
import { UnenrollLocationModal } from './Modals/UnenrollLocationModal'

export const SweepstakeLocationDetailPage = () => {
  const {
    contestId: contestIdString,
    licensedEstablishmentId: licensedEstablishmentIdString,
  } = useParams()

  const [isLocationEnrollmentModalOpen, setLocationEnrollmentModalOpen] =
    useState(false)
  const [isSweepstakeSignageModalOpen, setSweepstakeSignageModalOpen] =
    useState(false)
  const [isUnenrollLocationModalOpen, setUnenrollLocationModalOpen] =
    useState(false)

  if (isNil(contestIdString) || isNil(licensedEstablishmentIdString)) {
    throw Error('Contest or LE Id is not present')
  }

  const contestId = Number(contestIdString)
  const licensedEstablishmentId = Number(licensedEstablishmentIdString)
  const theme = useTheme()

  const { data: contestLicensedEstablishment, isLoading: isContestLeLoading } =
    useGetContestLeByContestAndLe({
      contestId,
      licensedEstablishmentId,
    })

  if (isContestLeLoading) {
    return <ActivityIndicator />
  }

  const sweepstake = contestLicensedEstablishment?.contest
  const sweepstakeGuideline =
    sweepstake?.sweepstakeData?.participationGuidelines

  const licensedEstablishment =
    contestLicensedEstablishment?.licensedEstablishment

  if (
    isNil(sweepstake) ||
    isNil(contestLicensedEstablishment) ||
    isNil(licensedEstablishment)
  ) {
    throw Error('Sweepstake or Contest LE or LE is null')
  }

  const addresses = compact(licensedEstablishment?.addresses)

  return (
    <Page
      header={
        <>
          <PageHeader
            title={`${String(
              licensedEstablishment.standardName
            )} Enrollment Form`}
            isSecondary={true}
            backText={`Participating Locations`}
            backPath={`/Contests/Sweepstakes/${contestId}/ParticipatingLocations`}
          >
            <Typography
              variant="body-1"
              color="rgba(41, 43, 38, 0.60)"
              paddingTop="12px"
              paddingBottom="8px"
            >
              Enrolled:{' '}
              {formatFullDateTime(contestLicensedEstablishment.createdOn)} By{' '}
              {contestLicensedEstablishment.createdBy}
            </Typography>
          </PageHeader>
        </>
      }
    >
      <EditEmailAndNotesModal
        isModalOpen={isLocationEnrollmentModalOpen}
        toggleModalIsOpen={() => {
          setLocationEnrollmentModalOpen(!isLocationEnrollmentModalOpen)
        }}
        locationEmail={String(last(licensedEstablishment.contacts)?.email)}
        contestLE={contestLicensedEstablishment}
      />
      <EditSignageModal
        isModalOpen={isSweepstakeSignageModalOpen}
        toggleModalIsOpen={() => {
          setSweepstakeSignageModalOpen(!isSweepstakeSignageModalOpen)
        }}
        contestLE={contestLicensedEstablishment}
      />
      <UnenrollLocationModal
        isModalOpen={isUnenrollLocationModalOpen}
        toggleModalIsOpen={() => {
          setUnenrollLocationModalOpen(!isUnenrollLocationModalOpen)
        }}
        contestLE={contestLicensedEstablishment}
      />
      <Box>
        <Typography variant="h3" pb={2}>
          Location Details
        </Typography>
        <Box
          border={{ md: `1px solid ${theme.palette.grey[200]}` }}
          borderRadius={'5px'}
          p={{ xs: 0, sm: 3 }}
        >
          <Grid container>
            <Grid item md={4}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                LE Name
              </Typography>
              <Typography variant="body1" pb={2}>
                {licensedEstablishment?.standardName}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                License Number
              </Typography>
              <Typography variant="body1" pb={2}>
                #{licensedEstablishment?.licenseNumber}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                LE Address
              </Typography>
              <Typography variant="body1" pb={2}>
                {addresses ? formatAddress(addresses as Address[]) : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                Corporate Account
              </Typography>
              <Typography variant="body1" pb={2}>
                {licensedEstablishment?.corporateAccount?.name ?? '-'}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body2" pb={0.5} color="text.secondary">
                Organization
              </Typography>
              <Typography variant="body1" pb={2}>
                {licensedEstablishment?.organization?.name ?? '-'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h3" pb={2}>
            Location Enrollment
          </Typography>
          <Box>
            <Button
              sx={{ justifyContent: 'flex-end' }}
              onClick={() => {
                setLocationEnrollmentModalOpen(!isLocationEnrollmentModalOpen)
              }}
            >
              Edit
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Typography variant="body-1" color="text.secondary">
            Winner Notification Email Address{' '}
          </Typography>
          <Typography variant="body-1">
            {isEmpty(contestLicensedEstablishment.emailAddress)
              ? '-'
              : contestLicensedEstablishment.emailAddress}
          </Typography>

          <Box sx={{ paddingTop: '40px' }}>
            <Typography variant="body-1" color="text.secondary">
              Notes
            </Typography>
            <Typography variant="body-1">
              {isEmpty(contestLicensedEstablishment.notes)
                ? '-'
                : contestLicensedEstablishment.notes}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ paddingTop: '40px' }}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h3" pb={2}>
            Sweepstakes Signage
          </Typography>
          <Button
            sx={{ justifyContent: 'flex-end' }}
            onClick={() => {
              setSweepstakeSignageModalOpen(!isSweepstakeSignageModalOpen)
            }}
          >
            Edit
          </Button>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Grid container>
            <Grid item md={4}>
              <Typography variant="body-1" color="text.secondary">
                Decal
              </Typography>
              <Typography variant="body-1">
                {contestLicensedEstablishment.signage1 ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body-1" color="text.secondary">
                Banner
              </Typography>

              <Typography variant="body-1">
                {contestLicensedEstablishment.signage2 ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" pb={2}>
          Sweepstakes Overview
        </Typography>
        <Stack
          direction={'column'}
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Grid container>
            <Grid item md={4}>
              <Typography variant="body-1" color="text.secondary">
                Sweepstakes Name
              </Typography>
              <Typography variant="body-1">{sweepstake?.name}</Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body-1" color="text.secondary">
                Start Date
              </Typography>
              <Typography variant="body-1">
                {formatDateTime(sweepstake?.startDate, 'P p')}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant="body-1" color="text.secondary">
                End Date
              </Typography>
              <Typography variant="body-1">
                {formatDateTime(sweepstake?.endDate, 'P p')}
              </Typography>
            </Grid>
          </Grid>
          <Box py={'24px'}>
            <Typography variant="body-1" color="text.secondary">
              URL
            </Typography>
            <Typography variant="body-1">
              {sweepstake?.url ? sweepstake?.url : '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body-1" color="text.secondary">
              Sweepstakes Description
            </Typography>
            <Typography variant="body-1">{sweepstake?.description}</Typography>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" paddingBottom={1}>
          Details
        </Typography>

        <Box
          sx={{
            p: '16px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Typography variant="body-1" color="text.secondary">
            Description for Locations to Enroll
          </Typography>
          <Typography variant="body-1" color="text.primary">
            {sweepstakeGuideline ? (
              <Markdown rehypePlugins={[rehypeRaw]}>
                {sweepstakeGuideline.replace(/\n\n/gi, '&nbsp;\n\n')}
              </Markdown>
            ) : (
              '-'
            )}
          </Typography>
        </Box>
      </Box>
      <Button
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        onClick={() => {
          setUnenrollLocationModalOpen(!isUnenrollLocationModalOpen)
        }}
      >
        Unenroll Location
      </Button>
    </Page>
  )
}
