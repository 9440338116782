import { Box } from '@mui/material'
import { cardWidth } from './Card'
import NoImage from 'assets/noImage.png'

interface CardImageProps {
  containerBackgroundColor?: string
  imageHeight?: string
  imageWidth?: string
  imageSource?: string
}

export const CardImage = ({
  containerBackgroundColor = '#ffffff',
  imageHeight = '100%',
  imageWidth = '100%',
  imageSource,
}: CardImageProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        width: cardWidth,
        backgroundColor: imageSource ? containerBackgroundColor : '#white',
      }}
    >
      <Box
        sx={{
          height: imageSource ? imageHeight : '64px',
          width: imageSource ? imageWidth : '64px',
        }}
      >
        {imageSource && (
          <img
            src={imageSource ?? NoImage}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        )}
      </Box>
    </Box>
  )
}
