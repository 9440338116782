import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetRewardsCatalogById = ({
  rewardsCatalogId,
}: {
  rewardsCatalogId: number
}): UseQueryResult<components['schemas']['FullRewardsCatalogDTO']> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-catalogs/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/rewards-catalogs', rewardsCatalogId],
    queryFn: async () => {
      const { data } = await get({ id: rewardsCatalogId })
      return data
    },
  })
}
