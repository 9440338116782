import { useState } from 'react'
import type { Promotion } from 'types/api'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { type StateFileUpload } from 'types/fileUpload'
import { Box, Button, Typography } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { UploaderDisplayer } from 'components/FileUploader/UploaderDisplayer'
import { usePutImagePromotion } from 'hooks/api/userPutImagePromotion'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton'

export const UploadImagePromotion = ({
  isModalOpen,
  toggleModalIsOpen,
  promotion,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  promotion: Promotion
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [files, setFiles] = useState<File[]>([])
  const [showImages, setShowImages] = useState(false)
  const [stateFilesUpload, setStateFilesUpload] = useState<StateFileUpload>(0)
  const [messageError, setMessageError] = useState('')
  const { isMobile } = useLayout()
  const qc = useQueryClient()
  const { handleSubmit } = useForm()

  const toggleShowImages = () => setShowImages(!showImages)
  const toggleStateFilesUpload = (value: StateFileUpload) => {
    setMessageError('')
    if (stateFilesUpload === 0) {
      setStateFilesUpload(value)
    }
    if (value === 2) {
      setMessageError('Please correct errors to continue')
    }
  }

  const removeFile = (file: File) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((fileMatch) => {
        return fileMatch.name !== file.name
      })
    })
    setMessageError('')
    setStateFilesUpload(0)
    toggleShowImages()
  }

  const addFiles = (files: File[]) => {
    setFiles(files)
    toggleShowImages()
  }

  const resetAll = () => {
    toggleModalIsOpen()
    setShowImages(false)
    setFiles([])
    setMessageError('')
  }

  const { mutate: putImagePromotionMutation, isPending: isUploadActive } =
    usePutImagePromotion({
      id: Number(promotion.id),
      onSuccess: async () => {
        resetAll()
        setSnackbarMessage(`${promotion.title} has been updated`)
        await qc.invalidateQueries({
          queryKey: ['/promotions'],
        })
      },
      onError: (error, _) => {
        console.log(error)
        setMessageError(error.message)
      },
    })

  const updatePromotionWrapper = handleSubmit(async () => {
    if (stateFilesUpload !== 1) {
      setMessageError('Please correct errors to continue')
      return
    }

    putImagePromotionMutation({
      ImageFileName: files[0].name,
      ImageFile: files[0],
      ImageFileType: 'Image',
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: isMobile ? '100%' : '75%',
      }}
    >
      <Box>
        <form onSubmit={updatePromotionWrapper}>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={promotion.title}
          />
          <ModalFullBody heightModal={isMobile ? '100%' : '75%'}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <Typography variant="subtitle-2" className="w-full">
              Upload Thumbnail Image
            </Typography>
            <UploaderDisplayer
              files={files}
              addFiles={addFiles}
              removeFile={removeFile}
              toggleStateFilesUpload={toggleStateFilesUpload}
              showImages={showImages}
              textToShowButton="Click to upload"
              informationFileUpload="JPEG or PNG (max. 512kb)"
              maxSizeAllowedUpload={500}
              fileFormatsAllowedUpload="image/jpeg, image/jpg, image/png"
            />
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={isUploadActive}
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
