import { InputAdornment, TextField, type TextFieldProps } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

type SearchFieldProps = TextFieldProps

export const SearchField = ({ ...props }: SearchFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}
