import { useNavigate, useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { SweepstakesDetailsHeader } from './SweepstakeDetailsHeader'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { isNil, uniqBy } from 'lodash'
import { Box, Button, Stack, Typography } from '@mui/material'
import { SearchField } from 'components/SearchField'
import { useMemo, useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { useGetContestLicensedEstablishmentSearch } from 'hooks/api/LicensedEstablishment/useGetContestLicensedEstablishmentSearch'
import { minimumSearchLength, formatDateTime } from 'utils/util'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { ParticipatingLocationList } from '../SweepstakesEnrollLocations/ParticipatingLocationList'
import { useGetParticipatingLocations } from 'hooks/api/Sweepstake/useGetParticipatingLocations'
import { isPresent } from '@jjvgaming/player-payback-library'
import { type LicensedEstablishment } from 'types/api'

export const SweepstakeParticipatingLocationsPage = () => {
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })
  const [searchKey, setSearchKey] = useState('')
  const debouncedSearchKey = useDebounce(searchKey, 500)
  const navigate = useNavigate()

  const { setParticipatingLocations, setEnrollmentDetails } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()

  const licensedEstablishmentSearchQuery =
    useGetContestLicensedEstablishmentSearch({
      contestId: sweepstakeId,
      searchKey: debouncedSearchKey,
    })

  const participatingLocationsQuery = useGetParticipatingLocations({
    contestId: sweepstakeId,
  })

  const preferredQuery = useMemo(() => {
    if (debouncedSearchKey.length >= minimumSearchLength) {
      return licensedEstablishmentSearchQuery
    }
    return participatingLocationsQuery
  }, [
    debouncedSearchKey,
    licensedEstablishmentSearchQuery,
    participatingLocationsQuery,
  ])

  interface ParticipatingLocationsCsv {
    IGBNumber: string
    LicensedEstablishment: string
    OptInDate: string | null | undefined
    OptInUser: string | null | undefined
    Email: string | null | undefined
    Signage1: boolean | undefined
    Signage2: boolean | undefined
    Notes: string | null | undefined
  }

  // Get all related organizations to show in the table
  const allOrganizations = useMemo(() => {
    const allOrganizations = sweepstakeQuery.data?.licensedEstablishments?.map(
      (le) => le.organization
    )
    return uniqBy(allOrganizations, 'id').filter(isPresent)
  }, [sweepstakeQuery.data])

  // Get all related corporate account to show in the table
  const allCorporateAccounts = useMemo(() => {
    const allCorporateAccounts =
      sweepstakeQuery.data?.licensedEstablishments?.map(
        (le) => le.corporateAccount
      )
    return uniqBy(allCorporateAccounts, 'id').filter(isPresent)
  }, [sweepstakeQuery.data])

  const locations: LicensedEstablishment[] = useMemo(() => {
    return (preferredQuery.data ?? [])
      .map((sweepstakeLocation) => {
        const enrolledLE = sweepstakeLocation.licensedEstablishment
        if (enrolledLE == null || sweepstakeLocation.createdOn == null) {
          return null
        }
        return {
          ...enrolledLE,
          name: enrolledLE.standardName ?? '',
          licenseNumber: enrolledLE.licenseNumber,
          id: enrolledLE.id,
          addresses: enrolledLE.addresses,
          dateAdded: sweepstakeLocation.createdOn,
          signage1: sweepstakeLocation.signage1,
          signage2: sweepstakeLocation.signage2,
        }
      })
      .filter(isPresent)
  }, [preferredQuery])

  // Filter organizations when searching
  const filteredOrganizations = useMemo(() => {
    if (searchKey.length >= minimumSearchLength) {
      const organizationIds = locations.map(
        (location) => location.organizationId
      )
      return allOrganizations.filter((org) => organizationIds.includes(org.id))
    }
    return allOrganizations
  }, [locations, allOrganizations, searchKey])

  // Filter corporate accounts when searching
  const filteredCorporateAccounts = useMemo(() => {
    if (searchKey.length >= minimumSearchLength) {
      const corporateAccountIds = locations.map(
        (location) => location.corporateAccountId
      )
      return allCorporateAccounts.filter((acc) =>
        corporateAccountIds.includes(acc.id)
      )
    }
    return allCorporateAccounts
  }, [locations, allCorporateAccounts, searchKey])

  // Participating locations CSV data
  const locationsCsv: ParticipatingLocationsCsv[] = useMemo(() => {
    return (preferredQuery.data ?? [])
      .map((sweepstakeLocation) => {
        const enrolledLE = sweepstakeLocation.licensedEstablishment
        if (enrolledLE == null || sweepstakeLocation.createdOn == null) {
          return null
        }
        return {
          IGBNumber: enrolledLE.licenseNumber,
          LicensedEstablishment: enrolledLE.name.replace(/["',]/g, ''),
          OptInDate: formatDateTime(sweepstakeLocation.createdOn),
          OptInUser: sweepstakeLocation.createdBy,
          Email: sweepstakeLocation.emailAddress,
          Signage1: sweepstakeLocation.signage1,
          Signage2: sweepstakeLocation.signage2,
          Notes: sweepstakeLocation.notes,
        }
      })
      .filter(isPresent)
  }, [preferredQuery])

  if (sweepstakeQuery.isError || isNil(sweepstakeQuery.data)) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstakeQuery.data}
      currentTab={`/Contests/Sweepstakes/${sweepstakeQuery.data?.id}/ParticipatingLocations`}
    >
      <Typography variant="h2">Participating Locations</Typography>
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent="space-between"
        marginTop={'20px'}
      >
        <SearchField
          placeholder="Search Participating Locations"
          onChange={(e) => setSearchKey(e.target.value)}
          fullWidth
        />

        <Button
          fullWidth
          sx={{
            display: 'flex',
            justifyContent: 'left',
          }}
          onClick={() => {
            setParticipatingLocations(undefined)
            setEnrollmentDetails(undefined)
            navigate(
              `/Contests/Sweepstakes/${sweepstakeQuery?.data?.id}/Manage-participating-locations`
            )
          }}
        >
          Enroll Locations
        </Button>
      </Stack>
      {preferredQuery.isPending ? (
        <ActivityIndicator marginTop={8} />
      ) : (
        <ParticipatingLocationList
          les={locations}
          organizations={filteredOrganizations}
          corporateAccounts={filteredCorporateAccounts}
          contestId={sweepstakeId}
        />
      )}
      {(preferredQuery.isError || isNil(preferredQuery.data)) ?? (
        <Box>An error occurred fetching the sweepstake.</Box>
      )}
      <CSVLink
        data={locationsCsv}
        filename={'participating locations list.csv'}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: 5,
          }}
        >
          Export List
        </Button>
      </CSVLink>
    </SweepstakesDetailsHeader>
  )
}
