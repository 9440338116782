import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { formatISO } from 'date-fns'
import { useApiClient } from 'hooks/useApiClient'
import { useForm, type Control } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import { date, object, string } from 'yup'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import { AdornedDateTimeField } from 'components/AdornedDateTimeField/AdornedDateTimeField'

const today = new Date()
today.setHours(0, 0, 0, 0)

const sweepstakeSchema = object().shape({
  name: string().required('Must enter a sweepstake name'),
  startDate: date()
    .typeError('Must enter a valid start date')
    .required('Must enter a start date')
    .min(today, 'Start date should not be a date in the past'),
  endDate: date()
    .typeError('Must enter a valid end date')
    .required('Must enter an end date')
    .when(['startDate'], ([startDate], schema) => {
      if (startDate instanceof Date && isFinite(+startDate)) {
        return schema.min(startDate, 'End date should not be before start date')
      }
      return schema
    }),
  description: string()
    .required('Must enter a sweepstake description')
    .max(500),
})

type FormSchemaType<T> = T extends Control<infer R> ? R : never

export const SweepstakesNewPage = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sweepstakeSchema),
  })
  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const createSweepstake = useMutation({
    mutationKey: ['sweepstakes/create'],
    mutationFn: async (data: FormSchemaType<typeof control>) => {
      const response = await post('/admin/contests', {
        params: { header: undefined },
        body: {
          name: data.name,
          description: data.description,
          startDate: formatISO(data.startDate),
          endDate: formatISO(data.endDate),
          type: 'Sweepstake',
        },
      })
      return response
    },
    onSuccess: async (response) => {
      setMessage('Sweepstake successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      if (response.data?.id) {
        navigate(`/Contests/Sweepstakes/${response.data.id}/Overview`)
      } else {
        navigate(`/Contests/Sweepstakes`)
      }
    },
    onError: async () => {
      setMessage('Error creating sweepstake', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const createSweepstakeWrapper = handleSubmit((data) => {
    createSweepstake.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Sweepstake"
          backPath="/Contests/Sweepstakes"
          backText="Sweepstakes"
          isSecondary={true}
          subtitle={`You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.`}
        />
      }
    >
      <form onSubmit={createSweepstakeWrapper}>
        <Stack spacing={4}>
          <Box>
            <TextField
              required
              error={isPresent(errors.name)}
              label="Sweepstake Name"
              placeholder="Enter sweepstake name"
              {...register('name')}
            />
            {errors.name?.message && (
              <FormFieldErrorMessage message={errors.name.message} />
            )}
          </Box>
          <Box>
            <AdornedDateTimeField
              name="startDate"
              control={control}
              label="Start Date/Time"
            />
            {errors.startDate?.message && (
              <FormFieldErrorMessage message={errors.startDate.message} />
            )}
          </Box>
          <Box>
            <AdornedDateTimeField
              name="endDate"
              control={control}
              label="End Date/Time"
            />
            {errors.endDate?.message && (
              <FormFieldErrorMessage message={errors.endDate.message} />
            )}
          </Box>
          <Box>
            <TextField
              multiline
              rows={3}
              required
              error={isPresent(errors.description)}
              label="Sweepstake Overview"
              placeholder="Description of sweepstake"
              {...register('description')}
            />
            {errors.description?.message && (
              <FormFieldErrorMessage message={errors.description.message} />
            )}
          </Box>
          <Stack direction="row" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              disabled={createSweepstake.isPending}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/Contests/Sweepstakes')
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Page>
  )
}
