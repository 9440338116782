import { isPresent } from '@jjvgaming/player-payback-library'
import { difference, remove, uniq, compact, flatten, isNil } from 'lodash'
import {
  type CorporateAccount,
  type LicensedEstablishment,
  type Organization,
} from 'types/api'

export class HandleOnChangeSelection {
  previousRows: string[]
  rows: string[]
  licensedEstablishments: LicensedEstablishment[]
  enrolledLicensedEstablishmentIds: Set<number>
  organizations: Organization[]
  corporateAccounts: CorporateAccount[]
  selectedRows: string[]

  constructor({
    previousRows,
    rows,
    licensedEstablishments,
    enrolledLicensedEstablishmentIds,
    organizations,
    corporateAccounts,
    selectedRows,
  }: {
    previousRows: string[]
    rows: string[]
    licensedEstablishments: LicensedEstablishment[]
    enrolledLicensedEstablishmentIds: number[]
    organizations: Organization[]
    corporateAccounts: CorporateAccount[]
    selectedRows: string[]
  }) {
    this.previousRows = previousRows
    this.rows = rows
    this.licensedEstablishments = licensedEstablishments
    this.enrolledLicensedEstablishmentIds = new Set(
      enrolledLicensedEstablishmentIds
    )
    this.organizations = organizations
    this.corporateAccounts = corporateAccounts
    this.selectedRows = selectedRows
  }

  getLesForOrg(orgId: number | undefined | null): LicensedEstablishment[] {
    if (isNil(orgId)) {
      return []
    }

    const lesForOrg = this.licensedEstablishments.filter(
      (x) => x.organizationId === orgId
    )
    const childOrgs = this.organizations.filter(
      (x) => x.parentOrganizationId === orgId
    )
    return compact([
      ...lesForOrg,
      ...flatten(childOrgs.map((x) => this.getLesForOrg(x.id))),
    ])
  }

  getLesForCa(caId: number | undefined | null): LicensedEstablishment[] {
    if (isNil(caId)) {
      return []
    }

    return this.licensedEstablishments.filter(
      (x) => x.corporateAccountId === caId
    )
  }

  getDescendantOrgsForOrg(orgId: number): Organization[] {
    const childOrgs = this.organizations.filter(
      (x) => x.parentOrganizationId === orgId
    )
    return compact([
      ...childOrgs,
      ...flatten(childOrgs.map((x) => this.getDescendantOrgsForOrg(x.id))),
    ])
  }

  getAncestorOrgsForOrg(orgId: number | undefined | null): Organization[] {
    if (isNil(orgId)) {
      return []
    }

    const org = this.organizations.find((x) => x.id === orgId)

    if (isNil(org)) {
      return []
    }

    const parentOrg = this.organizations.find(
      (x) => x.id === org.parentOrganizationId
    )

    if (isNil(parentOrg)) {
      return [org]
    }

    return [parentOrg, org, ...this.getAncestorOrgsForOrg(parentOrg.id)]
  }

  getOrgsForCa(caId: number): Organization[] {
    return this.organizations.filter((x) => x.corporateAccountId === caId)
  }

  handleCorporateAccountAdd(row: string, caId: number) {
    // add row aka current ca
    this.previousRows.push(row)

    // select all the les for the ca
    this.getLesForCa(caId).forEach((le) => {
      const formattedKey = `licensedEstablishment-${le.id}`
      this.previousRows.push(formattedKey)
    })

    // select all the orgs for the ca
    this.getOrgsForCa(caId).forEach((org) => {
      this.handleOrganizationAdd(`organization-${org.id}`, org.id)
    })
  }

  handleOrganizationAdd(row: string, orgId: number) {
    // add row aka current org
    this.previousRows.push(row)

    // select all the les for the org
    this.getLesForOrg(orgId).forEach((le) => {
      const formattedKey = `licensedEstablishment-${le.id}`
      this.previousRows.push(formattedKey)
    })

    // select all the descendant orgs for the org
    this.getDescendantOrgsForOrg(orgId).forEach((org) => {
      const formattedKey = `organization-${org.id}`
      this.previousRows.push(formattedKey)
    })

    const org = this.organizations.find((x) => x.id === orgId)
    if (org) {
      const caId = org.corporateAccountId
      // if we've selected every LE in this ca, then we should select the ca
      const lesInCa = this.getLesForCa(caId).map(
        (x) => `licensedEstablishment-${x.id}`
      )

      const currentlySelectedLes = uniq(
        this.previousRows.filter((x) => {
          const typeOfLocation = x.split('-')[0]
          return typeOfLocation === 'licensedEstablishment'
        })
      )

      if (difference(lesInCa, currentlySelectedLes).length === 0) {
        this.previousRows.push(`corporateAccount-${caId}`)
      }
    }
  }

  handleLicensedEstablishmentAdd(row: string, leId: number) {
    this.previousRows.push(row)

    // checking neccesary organizations
    const le = this.licensedEstablishments.find((x) => x.id === leId)

    if (isNil(le)) {
      return
    }

    const orgId = le.organizationId
    const parentOrgId = le.organization?.parentOrganizationId
    const caId = le.corporateAccountId

    const currentlySelectedLes = uniq(
      this.rows.filter((x) => {
        const typeOfLocation = x.split('-')[0]
        return typeOfLocation === 'licensedEstablishment'
      })
    )

    // if we've selected every LE in this org, then we should select the org
    if (isPresent(parentOrgId)) {
      // if we have a parent org
      const lesInOrg = this.getLesForOrg(orgId).map(
        (x) => `licensedEstablishment-${x.id}`
      )

      const lesInParentOrg = this.getLesForOrg(parentOrgId).map(
        (x) => `licensedEstablishment-${x.id}`
      )

      // child org check
      if (difference(lesInOrg, currentlySelectedLes).length === 0) {
        this.previousRows.push(`organization-${orgId}`)
      }

      // parent org check
      if (difference(lesInParentOrg, currentlySelectedLes).length === 0) {
        this.previousRows.push(`organization-${parentOrgId}`)
      }
    } else {
      // if we dont have a parent org
      const lesInOrg = this.getLesForOrg(orgId).map(
        (x) => `licensedEstablishment-${x.id}`
      )

      if (difference(lesInOrg, currentlySelectedLes).length === 0) {
        this.previousRows.push(`organization-${orgId}`)
      }
    }

    // if we've selected every LE in this ca, then we should select the ca
    const lesInCa = this.getLesForCa(caId).map(
      (x) => `licensedEstablishment-${x.id}`
    )
    if (difference(lesInCa, currentlySelectedLes).length === 0) {
      this.previousRows.push(`corporateAccount-${caId}`)
    }
  }

  handleCorporateAccountRemove(row: string, caId: number) {
    // add row aka current ca
    this.previousRows = this.previousRows.filter((x) => x !== row)

    // deselect all the les for the ca
    this.getLesForCa(caId).forEach((le) => {
      if (this.enrolledLicensedEstablishmentIds.has(le.id)) {
        // don't remove already enrolled LEs
        return
      }

      const formattedKey = `licensedEstablishment-${le.id}`
      this.previousRows = this.previousRows.filter((x) => x !== formattedKey)
    })

    // deselect all the orgs for the ca
    this.getOrgsForCa(caId).forEach((org) => {
      const formattedKey = `organization-${org.id}`
      this.previousRows = this.previousRows.filter((x) => x !== formattedKey)
    })
  }

  handleOrganizationRemove(row: string, orgId: number) {
    // add row aka current org
    this.previousRows = this.previousRows.filter((x) => x !== row)

    // deselect all child les
    this.getLesForOrg(orgId).forEach((le) => {
      const formattedKey = `licensedEstablishment-${le.id}`
      this.previousRows = this.previousRows.filter((x) => x !== formattedKey)
    })

    // deselect all child orgs
    this.getDescendantOrgsForOrg(orgId).forEach((org) => {
      const formattedKey = `organization-${org.id}`
      this.previousRows = this.previousRows.filter((x) => x !== formattedKey)
    })

    // deselect all ancestor orgs
    this.getAncestorOrgsForOrg(orgId).forEach((org) => {
      const formattedKey = `organization-${org.id}`
      this.previousRows = this.previousRows.filter((x) => x !== formattedKey)
    })

    // deselect ca since it longer can have everything
    const caWithThisOrg = this.organizations.find((org) => org.id === orgId)
      ?.corporateAccountId
    remove(this.previousRows, (x) => x === `corporateAccount-${caWithThisOrg}`)
  }

  handleLicensedEstablishmentRemove(row: string, leId: number) {
    remove(this.previousRows, (x) => x === row)

    // remove org check if applicable
    const le = this.licensedEstablishments.find((x) => x.id === leId)
    const orgId = le?.organizationId
    const caId = le?.corporateAccountId

    // deselect all ancestor orgs
    this.getAncestorOrgsForOrg(orgId).forEach((org) => {
      const lesInOrg = this.getLesForOrg(org.id).map(
        (x) => `licensedEstablishment-${x.id}`
      )
      if (difference(lesInOrg, this.rows).length > 0) {
        remove(this.previousRows, (x) => x === `organization-${org.id}`)
      }
    })

    const currentlySelectedLes = this.rows.filter((x) => {
      const typeOfLocation = x.split('-')[0]
      return typeOfLocation === 'licensedEstablishment'
    })

    // if the org has no selected les, deselect it
    const lesInOrg = this.getLesForOrg(orgId).map(
      (x) => `licensedEstablishment-${x.id}`
    )

    if (difference(lesInOrg, currentlySelectedLes).length === 0) {
      remove(this.previousRows, (x) => x === `organization-${orgId}`)
    }

    const lesInCa = this.licensedEstablishments
      .filter((x) => x.corporateAccountId === caId)
      .map((x) => `licensedEstablishment-${x.id}`)

    // if the ca has no selected les, deselect it
    if (difference(lesInCa, currentlySelectedLes).length > 0) {
      remove(this.previousRows, (x) => x === `corporateAccount-${caId}`)
    }
  }

  handleCorporateAccountAndOrgSelection(): string[] {
    const arrayOfAddedRows = difference(this.rows, this.previousRows)

    const arrayOfRemovedRows = difference(this.previousRows, this.rows)

    arrayOfAddedRows.forEach((row) => {
      const type = row.split('-')[0]
      const id = Number(row.split('-')[1])

      if (type === 'corporateAccount') {
        this.handleCorporateAccountAdd(row, id)
      }

      if (type === 'organization') {
        this.handleOrganizationAdd(row, id)
      }

      if (type === 'licensedEstablishment') {
        this.handleLicensedEstablishmentAdd(row, id)
      }
    })

    arrayOfRemovedRows.forEach((row) => {
      const type = row.split('-')[0]
      const id = Number(row.split('-')[1])

      if (type === 'corporateAccount') {
        this.handleCorporateAccountRemove(row, id)
      }

      if (type === 'organization') {
        this.handleOrganizationRemove(row, id)
      }

      if (type === 'licensedEstablishment') {
        this.handleLicensedEstablishmentRemove(row, id)
      }
    })

    return uniq(this.previousRows)
  }

  getInitialSelection(): string[] {
    this.rows.forEach((row) => {
      const type = row.split('-')[0]
      const id = Number(row.split('-')[1])

      if (type === 'corporateAccount') {
        this.handleCorporateAccountAdd(row, id)
      }

      if (type === 'organization') {
        this.handleOrganizationAdd(row, id)
      }

      if (type === 'licensedEstablishment') {
        this.handleLicensedEstablishmentAdd(row, id)
      }
    })
    return uniq(this.previousRows)
  }
}
