/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { isNil } from 'lodash'
import List from '@mui/material/List'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import Search from '@mui/icons-material/Search'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { type components } from 'api/playerPayback/api'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import { Typography, TextField, Box, Divider } from '@mui/material'
import { type MouseEventHandler, useState, useMemo } from 'react'

const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-inputAdornedStart': {
    paddingTop: '12px',
  },
}))

type NestedProperty<T, K extends keyof T> = {
  [P in keyof T[K] & (string | number)]: `${P}`
}

interface CheckBoxListTypes<T extends keyof components['schemas']> {
  items: Array<components['schemas'][T]>
  identifier: keyof components['schemas'][T]
  propertyShow: keyof components['schemas'][T]
  secondary?: boolean
  extendedPropertyShow?: keyof components['schemas'][T]
  secondaryPropertyShow?: NestedProperty<
    components['schemas'][T],
    keyof components['schemas'][T]
  >
  handleToggle: (value: number) => void
  checked: number[]
  titleForSelected: string
  rowDivider?: boolean
  searchable?: boolean
  searchablePlaceHolder?: string
}

export const CheckBoxList = <T extends keyof components['schemas']>({
  items,
  identifier,
  propertyShow,
  secondary,
  extendedPropertyShow,
  secondaryPropertyShow,
  handleToggle,
  checked,
  titleForSelected,
  rowDivider,
  searchable = false,
  searchablePlaceHolder,
}: CheckBoxListTypes<T>) => {
  const [elementSearch, setElementSearch] = useState<string>('')
  type ItemType = components['schemas'][T]
  type SecondType = NestedProperty<
    components['schemas'][T],
    keyof components['schemas'][T]
  >

  const isItemType = (item: unknown): item is ItemType | SecondType => {
    return (
      (typeof item === 'object' &&
        !isNil(item) &&
        identifier in item &&
        propertyShow in item) ||
      (typeof item === 'object' &&
        !isNil(item) &&
        identifier in item &&
        !isNil(extendedPropertyShow) &&
        extendedPropertyShow in item) ||
      (typeof item === 'object' &&
        !isNil(item) &&
        identifier in item &&
        !isNil(secondaryPropertyShow) &&
        extractValueChild(item as ItemType, secondaryPropertyShow) !== '')
    )
  }

  const extractValue = (
    item: ItemType,
    valueToExtract: keyof ItemType
  ): string => {
    return String(item[valueToExtract])
  }

  const extractValueChild = (
    item: ItemType,
    valueToExtract: NestedProperty<
      components['schemas'][T],
      keyof components['schemas'][T]
    >
  ): string => {
    const keys = String(valueToExtract).split('.')
    if (keys.length === 1) {
      return String(item[keys[0] as keyof ItemType])
    }
    const value = keys[0].toString().split('[')[0]
    const valuesIndex = keys[0].toString().split('[')[1]
    const index = valuesIndex.toString().split(']')[0]

    const element = item[value as keyof ItemType] as any
    const returnValue = element[index][keys[1]]
    return !isNil(returnValue) ? returnValue : ''
  }

  const filteredItems = useMemo(
    () =>
      items.filter((item) =>
        extractValue(item, propertyShow).includes(elementSearch)
      ),
    [elementSearch, items]
  )

  const keyDownSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setElementSearch(event.target.value)
  }

  return (
    <Box className="!pt-0 flex !h-full" sx={{ flexFlow: 'column' }}>
      <Box className="contents h-full">
        {searchable ? (
          <Box className="flex-initial max-h-[56px]">
            <StyledTextField
              id="search-element-text-field"
              placeholder={searchablePlaceHolder}
              className="border-none !max-w-[100%]"
              sx={{ paddingTop: '0px' }}
              fullWidth
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start" className="!mt-0">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="filled"
              onChange={keyDownSearch}
            />
          </Box>
        ) : (
          ''
        )}
        {checked.length > 0 && titleForSelected !== '' ? (
          <Box className="!m-0 !p-0 flex-initial max-h-[24px]">
            <Typography variant="body-1">{`${checked.length} ${titleForSelected}`}</Typography>
          </Box>
        ) : (
          <div className="!m-0 !p-0 !h-[24px]" />
        )}
        <Box className="flex-auto" sx={{ overflowY: 'auto' }}>
          <List role="list">
            {filteredItems.map((item) => {
              if (isItemType(item)) {
                const labelId = `checkbox-list-label-${extractValue(
                  item,
                  identifier
                )}`
                return (
                  <>
                    <ListItem
                      key={`checkbox-list-item-${extractValue(
                        item,
                        identifier
                      )}`}
                      disablePadding
                    >
                      <ListItemButton
                        key={`checkbox-list-item-${extractValue(
                          item,
                          identifier
                        )}`}
                        role={undefined}
                        className="!pl-0 !pr-0 !text-[#2C2C27]"
                        dense
                        onClick={
                          handleToggle(
                            Number(extractValue(item, identifier))
                          ) as unknown as MouseEventHandler
                        }
                        selected={checked.includes(
                          Number(extractValue(item, identifier))
                        )}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.includes(
                              Number(extractValue(item, identifier))
                            )}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={`${extractValue(item, propertyShow)} ${
                            extendedPropertyShow
                              ? ` - ${extractValue(item, extendedPropertyShow)}`
                              : ''
                          }`}
                          secondary={
                            secondary && !isNil(secondaryPropertyShow)
                              ? `${extractValueChild(
                                  item,
                                  secondaryPropertyShow
                                )}`
                              : ''
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    {rowDivider && <Divider />}
                  </>
                )
              } else {
                throw new Error(
                  "Identifier or propertyShow doesn't exist inside the item"
                )
              }
            })}
          </List>
        </Box>
      </Box>
    </Box>
  )
}
