import { type InferType } from 'yup'
import { type CampaignSchema } from './CampaignSchema'
import { SelectSweepstake } from 'components/SelectSweepstake'

export const SweepstakeSelect = () => {
  return (
    <SelectSweepstake<InferType<typeof CampaignSchema>>
      label="Sweepstake"
      required
      fullWidth
      contestIdKey="contestId"
    />
  )
}
