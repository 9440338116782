import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type OpReturnType } from 'openapi-typescript-fetch'
import { type paths } from 'api/playerPayback/api'

const route = '/admin/corporate-accounts/{id}'

export type CorporateAccountGetResponse = OpReturnType<
  paths[typeof route]['get']
>

export const useGetCorporateAccountById = ({
  corporateAccountId,
  enabled = true,
}: {
  corporateAccountId: number
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: route,
    method: 'get',
  })
  return useQuery({
    queryKey: [route, corporateAccountId],
    queryFn: async () => {
      const res = await get({
        id: corporateAccountId,
      })
      if (isNil(res.data)) {
        throw new Error('Corporate Account not found')
      }
      return res.data
    },
    enabled,
  })
}
