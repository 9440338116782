import { useMsal } from '@azure/msal-react'
import { createContext, useContext, useMemo } from 'react'
import { loginRequest } from '../authConfig'
import { useQuery } from '@tanstack/react-query'

export interface AccessTokenContextType {
  accessToken: string | undefined
  isPending: boolean
  loginRequired: boolean
}

export const defaultAccessTokenContext: AccessTokenContextType = {
  accessToken: undefined,
  isPending: true,
  loginRequired: false,
}

export const AccessTokenContext = createContext(defaultAccessTokenContext)
export const AccessTokenProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { instance: msalInstance, accounts } = useMsal()
  const msalAccountPresent = accounts.length > 0
  const accessTokenQuery = useQuery({
    enabled: msalAccountPresent,
    queryKey: ['msalAccessToken'],
    queryFn: async () => {
      const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest)

      if (!tokenResponse.accessToken) {
        msalInstance
          .logoutRedirect({
            postLogoutRedirectUri: '/',
            account: msalInstance.getActiveAccount(),
          })
          .catch(() => {
            throw new Error('Failed to logout')
          })
      }

      return tokenResponse.accessToken
    },
    refetchInterval: 60 * 1000,
  })

  const context = useMemo(() => {
    return {
      accessToken: accessTokenQuery.data,
      isPending: accessTokenQuery.isFetching,
      loginRequired: !msalAccountPresent || !!accessTokenQuery.error,
    }
  }, [accounts, accessTokenQuery])

  return (
    <AccessTokenContext.Provider value={context}>
      {children}
    </AccessTokenContext.Provider>
  )
}
export const useAccessTokenContext = () => useContext(AccessTokenContext)
