import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

interface SweepstakeWinnersMessageData {
  sweepstakeWinnerIds: number[]
}

interface usePostSweepstakeWinnerMessageProps {
  onSuccess?: () => void
  onError?: () => void
}

export const usePostSweepstakeWinnerMessage = ({
  onSuccess,
  onError,
}: usePostSweepstakeWinnerMessageProps) => {
  const queryClient = useQueryClient()

  const postSweepstakeWinnerMessage = useTypedApiClient({
    path: '/admin/sweepstake-winners/message',
    method: 'post',
  })

  return useMutation({
    mutationFn: async (request: SweepstakeWinnersMessageData) => {
      const { data } = await postSweepstakeWinnerMessage(request)
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes'],
      })
      onSuccess?.()
    },
    onError,
  })
}
