import { number, object, string } from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert, Button, Grid, InputLabel, TextField } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityButton } from 'components/ActivityButton'
import { usePutOrganization } from 'hooks/api/usePutOrganization'
import { type EditOrganizationDetailsProps } from '../types'
import { useSnackbar } from 'stores/useSnackbar'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'

const OrganizationDetailsSchema = object({
  Organization: object({
    id: number(),
    name: string().required('Organization Name is a required field'),
  }),
})

export const EditOrganizationDetailsModal = ({
  organization,
  isModalOpen,
  toggleModalIsOpen,
}: EditOrganizationDetailsProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const heightModal = '50%'

  const formMethods = useForm({
    resolver: yupResolver(OrganizationDetailsSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((data) => {
    putOrganizationMutation.mutate({
      ...organization,
      name: data.Organization.name,
    })
  })

  const putOrganizationMutation = usePutOrganization({
    onSuccess: () => {
      setMessage('Organization has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Organization', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={`Organization Details`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.Organization && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6} m="auto" p="0">
                <InputLabel
                  htmlFor="Organization.name"
                  sx={{ fontWeight: 'normal', margin: 0 }}
                  required
                >
                  Organization Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Organization.name', {
                    required: true,
                  })}
                  defaultValue={organization.name}
                  error={isPresent(errors.Organization?.name)}
                />
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={putOrganizationMutation.isPending}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
