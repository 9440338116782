import { Box, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetCorporateAccountById } from 'hooks/api/useGetCorporateAccountById'
import { useGetOrganizationsByCorporateAccountId } from 'hooks/api/useOrganization'
import { useGetLicensedEstablishmentsByCorporateAccountId } from 'hooks/api/useGetLicensedEstablishment'
import { CorporateAccountsTreeDataGrid } from 'components/CorporateAccountsTreeDataGrid/CorporateAccountsTreeDataGrid'
import { CorporateAccountDetailHeader } from '../CorporateAccountDetailsHeader'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'

export const CorporateAccountAssociatedAccountsPage = () => {
  const params = useParams()
  const corporateAccountId = Number(params.id)

  const {
    isPending: isPendingCorporateAccount,
    isError: isErrorCorporateAccount,
    data: corporateAccount,
  } = useGetCorporateAccountById({ corporateAccountId })

  const { data: organizations, isPending: isPendingOrgs } =
    useGetOrganizationsByCorporateAccountId({
      corporateAccountId,
      enabled: corporateAccount !== undefined,
    })

  const { data: licensedEstablishments, isPending: isPendingLes } =
    useGetLicensedEstablishmentsByCorporateAccountId({
      corporateAccountId,
      enabled: corporateAccount !== undefined,
    })

  if (isPendingCorporateAccount || isPendingOrgs || isPendingLes) {
    return <ActivityIndicator />
  }

  if (isErrorCorporateAccount || isNil(corporateAccount)) {
    return <ErrorIndicator />
  }

  if (!corporateAccount.id) {
    return <Box>Corporate Account Id must not be null</Box>
  }
  const corporateAccounts = [corporateAccount]
  return (
    <Page
      header={
        <CorporateAccountDetailHeader
          corporateAccount={corporateAccount}
          currentTab={`/CorporateAccounts/${corporateAccountId}/CorporateAccountAssociatedAccounts`}
        />
      }
    >
      <Box className="mb-2">
        <Typography variant="h2" color="text.primary">
          Associated Accounts
        </Typography>
      </Box>
      <CorporateAccountsTreeDataGrid
        recalculateRows={false}
        corporateAccounts={corporateAccounts}
        organizations={organizations ?? []}
        licensedEstablishments={licensedEstablishments ?? []}
        initialExpanded={corporateAccounts.length === 1}
        getRowClassName={() => 'text-base'}
      />
    </Page>
  )
}
