import { create } from 'zustand'

export interface RequestRedrawModalDataType {
  locationName?: string
  licenseNumber?: string
  winnerName?: string
  sweepstakeDrawingId?: number
  tierName?: string
  sweepstakeWinnerId?: number
}

interface RequestRedrawModalType {
  data?: RequestRedrawModalDataType
  setData: (data: RequestRedrawModalDataType | undefined) => void
}

export const useRequestRedrawModal = create<RequestRedrawModalType>((set) => ({
  data: undefined,
  setData: (data) => set({ data }),
}))
