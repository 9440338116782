import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import * as _ from 'lodash'
import { type Promotion } from 'types/api'

const path = '/admin/promotions/{id}'
const method = 'get' as const

export const useGetPromotionById = (
  promotionId: number | undefined,
  key?: string | boolean
): UseQueryResult<Promotion> => {
  const get = useTypedApiClient({ path, method })
  return useQuery({
    queryKey: ['/admin/promotions', promotionId, key],
    queryFn: async () => {
      if (_.isNil(promotionId)) {
        throw new Error('promotionId is required')
      }
      const { data } = await get({ id: promotionId })
      return data
    },
    enabled: !!promotionId,
  })
}
