import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { dataTableAddressFormatter, dataTableIdFormatter } from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { DataTable } from 'components/Shared/DataTable'
import ListBullet from 'assets/format_list_bulleted.svg'
import { useSnackbar } from 'stores/useSnackbar'
import { useRemoveLicensedEstablishmentFromOrganization } from 'hooks/api/LicensedEstablishment/useRemoveLicensedEstablishmentFromOrganization'
import { type components } from 'api/playerPayback/api'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'

interface AssociatedLicensedEstablishmentsTableProps {
  organizationId: number
  licensedEstablishments?: Array<
    components['schemas']['FullLicensedEstablishmentDTO']
  >
}

export const AssociatedLicensedEstablishmentsTable = ({
  organizationId,
  licensedEstablishments = [],
}: AssociatedLicensedEstablishmentsTableProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { isMobile } = useLayout()
  const setMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()

  const handleSubmit = (id: any) => {
    patchLicensedEstablishmentMutation(id)
  }

  const { mutate: patchLicensedEstablishmentMutation } =
    useRemoveLicensedEstablishmentFromOrganization({
      licensedEstablishmentId: Number(useParams().id),
      onSuccess: () => {
        setMessage('Licensed Establishment successfully removed', 'success', {
          vertical: 'top',
          horizontal: 'right',
        })
        queryClient
          .invalidateQueries({
            queryKey: [
              'licensed-establishments-by-organization-id',
              Number(organizationId),
            ],
          })
          .catch(() => {})
      },
      onError: () => {
        setMessage('Error removing Licensed Establishment', 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      },
    })

  const defaultColumns = (isMobile: boolean): GridColDef[] => {
    return [
      {
        field: 'standardName',
        minWidth: 420,
        headerName: 'Establishment Name',
      },
      {
        field: 'id',
        minWidth: 50,
        headerName: 'LE ID Number',
        flex: 1,
        valueFormatter: dataTableIdFormatter(isMobile),
      },
      {
        field: 'addresses',
        minWidth: 100,
        headerName: 'Address',
        valueFormatter: dataTableAddressFormatter(true),
        flex: 1,
      },
      {
        field: '__menu__',
        headerName: '',
        align: 'right',
        renderCell: (
          params: GridRenderCellParams<
            components['schemas']['FullLicensedEstablishmentUserDTO']
          >
        ) => {
          const handleMenuOpen = (
            event: React.SyntheticEvent<HTMLButtonElement>
          ) => {
            setAnchorEl(event.currentTarget)
          }

          const handleMenuClose = () => {
            setAnchorEl(null)
          }
          return (
            <>
              <IconButton onClick={handleMenuOpen}>
                <img
                  src={ListBullet}
                  width={4}
                  height={16}
                  alt="options icon"
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose()
                    handleSubmit(params.id)
                  }}
                >
                  Remove Associated LE
                </MenuItem>
              </Menu>
            </>
          )
        },
      },
    ]
  }

  return (
    <DataTable
      columns={defaultColumns(isMobile)}
      rows={licensedEstablishments}
    />
  )
}
