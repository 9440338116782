import { ActivityIndicator } from './Shared/ActivityIndicator'
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
  Typography,
} from '@mui/material'
import {
  type ForwardedRef,
  forwardRef,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { LicensedEstablishmentShowcaseCard } from 'components/LicensedEstablishmentShowcaseCard'
import { useFormContext } from 'react-hook-form'
import { type InferType } from 'yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useGetLicensedEstablishmentById } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentById'
import { type PromotionDetailsSchema } from 'pages/Promotions/PromotionDetailsPage/EditPromotionDetails'
import { useGetAllLicensedEstablishments } from 'hooks/api/LicensedEstablishment/useGetAllLicensedEstablishments'
import { type RequiredId } from 'utils/typeUtils'
import type { LicensedEstablishment, Address } from 'types/api'

interface AddressDetailsProps {
  address: RequiredId<Address>
}

const AddressDetails = ({ address }: AddressDetailsProps) => {
  return (
    <Typography variant="body-3">
      {address.address1}
      {address.address1 && address.city && `, ${address.city}`}
      {!address.address1 && address.city && `${address.city}`}
    </Typography>
  )
}

export const ModalSelectLicensedEstablishment = forwardRef(
  (
    { label, name, required, value, onChange, ...otherProps }: SelectProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { register, watch } =
      useFormContext<InferType<typeof PromotionDetailsSchema>>()
    const licensedEstablishments = useGetAllLicensedEstablishments()

    // watch() returns '' when there's no LE selected, in an affront to Typescript
    const rawSelectedLicensedEstablishmentId = watch(
      'Promotion.licensedEstablishmentId'
    )
    const selectedLicensedEstablishmentId = useMemo(() => {
      const id = Number(rawSelectedLicensedEstablishmentId)
      return id > 0 ? id : undefined
    }, [rawSelectedLicensedEstablishmentId])

    const { data: licensedEstablishment } = useGetLicensedEstablishmentById(
      selectedLicensedEstablishmentId
    )
    const [selectOpen, setSelectOpen] = useState(false)

    useEffect(() => {
      if (
        !licensedEstablishments.isPending &&
        !licensedEstablishments.isError
      ) {
        licensedEstablishments.data?.sort(
          (a: LicensedEstablishment, b: LicensedEstablishment) =>
            a.name.localeCompare(b.name)
        )
      }
    }, [
      licensedEstablishments.isPending,
      licensedEstablishments.isError,
      licensedEstablishments,
    ])

    if (licensedEstablishments.isPending) {
      return <ActivityIndicator />
    }

    if (licensedEstablishments.isError) {
      return <Typography>Error</Typography>
    }

    return (
      <Box>
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Select
          {...register('Promotion.licensedEstablishmentId')}
          ref={ref}
          inputProps={{ id: name }}
          labelId={name}
          required={required}
          variant="outlined"
          onOpen={() => setSelectOpen(true)}
          onClose={() => setSelectOpen(false)}
          defaultValue={rawSelectedLicensedEstablishmentId}
          {...otherProps}
          sx={{ maxWidth: '520px' }}
        >
          <MenuItem value="">
            <em>Select a Licensed Establishment</em>
          </MenuItem>
          {licensedEstablishments.data?.map((le: LicensedEstablishment) => (
            <MenuItem
              key={le.id}
              value={le.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {le.name}
              {selectOpen && le.addresses[0] && (
                <AddressDetails address={le.addresses[0]} />
              )}
            </MenuItem>
          ))}
        </Select>
        {isPresent(licensedEstablishment) && (
          <LicensedEstablishmentShowcaseCard
            licensedEstablishment={licensedEstablishment}
          />
        )}
      </Box>
    )
  }
)

ModalSelectLicensedEstablishment.displayName = 'SelectLicensedEstablishment'
