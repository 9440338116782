import { Box, Button } from '@mui/material'
import { formatFullDate } from 'utils/util'
import { Page } from 'components/Shared/Page'
import { DataTable } from 'components/Shared/DataTable'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetSweepstakes } from 'hooks/api/useGetSweepstakes'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { type GridColDef, type GridEventListener } from '@mui/x-data-grid'
import { type Sweepstake } from 'types/api'
import { SweepstakesStatusLabel } from 'components/Sweepstakes/SweepstakesStatusLabel'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { useState } from 'react'

const columns: Array<GridColDef<Sweepstake>> = [
  {
    field: 'name',
    headerName: 'Sweepstakes Name',
    minWidth: 360,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 230,
    renderCell: (params) => {
      const status = params.row.sweepstakeData?.state ?? 'Draft'
      return <SweepstakesStatusLabel status={status} />
    },
  },
  {
    field: 'startDate',
    headerName: 'Start',
    minWidth: 150,
    renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
  },
  {
    field: 'endDate',
    headerName: 'End',
    minWidth: 150,
    renderCell: (params) => <Box>{formatFullDate(params.value)}</Box>,
  },
]

export const SweepstakesPage = () => {
  const navigate = useNavigate()
  const query = useGetSweepstakes()
  const [filteredData, setFilteredData] = useState<Sweepstake[]>()

  const handleFilteredData = (filteredData: Sweepstake[]) => {
    setFilteredData(filteredData)
  }

  const selectRow: GridEventListener<'rowClick'> = (params) => {
    if (params.id !== null) {
      navigate(`/Contests/Sweepstakes/${params.id}/Overview`)
    }
  }

  return (
    <Page
      header={
        <PageHeader
          title="Sweepstakes"
          eyebrow="CONTESTS"
          actionButton={
            <RouterLink to="/Contests/Sweepstakes/New" className="gap-1">
              <Button
                variant="contained"
                sx={{
                  px: '16px',
                  width: { xs: '100%', sm: '100%', md: 200, lg: 200 },
                }}
              >
                Create New Sweepstake
              </Button>
            </RouterLink>
          }
        />
      }
    >
      {query.isPending && <ActivityIndicator />}
      {query.isError && <p>An error occured.</p>}
      {!query.isError && query.data && (
        <>
          <GridSearchField<'FullContestDTO'>
            placeholder="Search Sweepstakes"
            data={query.data ?? []}
            handleFilteredData={handleFilteredData}
            fullWidth
          />

          <DataTable
            columns={columns}
            rows={filteredData ?? query.data}
            onRowClick={selectRow}
          />
        </>
      )}
    </Page>
  )
}
