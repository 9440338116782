import { useApiQuery } from 'hooks/useApiQuery'
import { removeUndefined } from 'utils/util'

export const useGetNumberWinnerEntriesMultiplePatrons = ({
  patronIds,
  contestId,
  licensedEstablishmentId,
}: {
  patronIds?: number[]
  contestId?: number
  licensedEstablishmentId?: number
}) => {
  const queryArgs = removeUndefined({
    patronIds,
    contestId,
    licensedEstablishmentId,
  })

  return useApiQuery({
    method: 'get',
    path: '/admin/contests/entries/contest/{contestId}/patrons/total-entries',
    queryKey: [
      '/admin/contests/entries/contest/{contestId}/patrons/total-entries',
    ],
    queryArgs,
  })
}
