import { type components } from 'src/api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { Box } from '@mui/material'
import { isNil } from 'lodash'
import { getLastUpdated } from 'utils/util'
import { CorporateAccountDetailsPageTabs } from './CorporateAccountDetailsPageTabs'

interface CorporateAccountDetailHeaderProps {
  corporateAccount: components['schemas']['FullCorporateAccountDTO']
  currentTab: string
}

export const CorporateAccountDetailHeader = ({
  corporateAccount,
  currentTab,
}: CorporateAccountDetailHeaderProps) => {
  const corporateAccountName: string = isNil(corporateAccount.name)
    ? 'Unknown'
    : corporateAccount.name

  return (
    <Box>
      <PageHeader
        title={corporateAccountName}
        subtitle={getLastUpdated(corporateAccount.modifiedOn)}
        backText="Corporate Accounts"
        backPath="/ClientManagement/CorporateAccounts"
        isSecondary={true}
        tabs={
          <CorporateAccountDetailsPageTabs
            corporateAccountId={Number(corporateAccount.id)}
            currentTab={currentTab}
          />
        }
      />
    </Box>
  )
}
