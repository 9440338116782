import { isNil } from 'lodash'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { SweepstakesDetailsHeader } from './SweepstakeDetailsHeader'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { SweepstakesDetailsSection } from './SweepstakeDetailsSection'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { SweepstakeImageSection } from './SweepstakeImageSection'
import { SweepstakeParticipatingGuidelineSection } from './SweepstakeParticipatingGuidelineSection'

export const SweepstakeOverviewPage = () => {
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })

  if (sweepstakeQuery.isPending) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError || isNil(sweepstakeQuery.data)) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  const sweepstake = sweepstakeQuery.data

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Contests/Sweepstakes/${sweepstake.id}/Overview`}
    >
      <Box>
        <SweepstakesDetailsSection sweepstake={sweepstake} />
        <SweepstakeImageSection sweepstake={sweepstake} />
        <SweepstakeParticipatingGuidelineSection sweepstake={sweepstake} />
      </Box>
    </SweepstakesDetailsHeader>
  )
}
