import { Typography, Link, type TypographyProps, useTheme } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useLayout } from 'hooks/useLayout'
interface CellLinkProps {
  to: string
  fontWeight?: TypographyProps['fontWeight']
  text: string
}
export const CellLink = ({
  to,
  fontWeight = 'regular',
  text,
}: CellLinkProps) => {
  const { isMobile } = useLayout()
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <Link
      to={to}
      onClick={() => navigate(to)}
      component={RouterLink}
      underline={isMobile ? 'always' : 'hover'}
      color={isMobile ? 'secondary' : 'text.primary'}
      sx={{
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      }}
    >
      <Typography fontWeight={fontWeight}>{text}</Typography>
    </Link>
  )
}
