import { isNil } from 'lodash'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'components/Shared/DataTable'
import {
  type GridRowParams,
  type GridColDef,
  type GridEventListener,
} from '@mui/x-data-grid'
import { type Patron } from 'types/api'
import { type UseQueryResult } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { type ApiError } from 'openapi-typescript-fetch'
import { isPresent } from '@jjvgaming/player-payback-library'
import { formatPhoneNumber } from 'utils/phoneUtils'

const MobileColumns: Array<GridColDef<Patron>> = [
  {
    field: 'id',
    headerName: 'Patron ID',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => {
      return `#${params.row.id}`
    },
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    minWidth: 130,
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.firstName} ${params.row.lastName}`
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 130,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => {
      return formatPhoneNumber(params.row.phoneNumber)
    },
  },
]

const DesktopColumns: Array<GridColDef<Patron>> = [
  {
    field: 'id',
    headerName: 'Patron ID',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => {
      return `#${params.row.id}`
    },
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 130,
    flex: 1,
  },
  { field: 'lastName', headerName: 'Last Name', minWidth: 130, flex: 1 },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 130,
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </div>
    ),
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    minWidth: 170,
    flex: 1,
    valueGetter: (params) => {
      return formatPhoneNumber(params.row.phoneNumber)
    },
  },
]

export const PatronsTable = ({
  isPending,
  isError,
  error,
  data,
}: Pick<
  UseQueryResult<Patron[], ApiError>,
  'isPending' | 'isError' | 'error' | 'data'
>) => {
  const navigate = useNavigate()
  const { isMobile } = useLayout()

  const selectRow: GridEventListener<'rowClick'> = (params: GridRowParams) => {
    navigate(`/PatronAccounts/${params.id}/Overview`)
  }

  if (isPending || isNil(data)) {
    return <ActivityIndicator />
  }

  if (isError && isPresent(error)) {
    return <p>{error.message}</p>
  }

  return (
    <DataTable
      columns={isMobile ? MobileColumns : DesktopColumns}
      rows={data}
      onRowClick={selectRow}
      getRowId={(row) => row.id ?? 0} // this should never happen, it's because our api defs indicate id is optional
    />
  )
}
