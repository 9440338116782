import { Typography } from '@mui/material'
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid'

export interface RedrawnPatrons {
  id: number
  drawingNumber: number
  name: string
  phoneNumber: string
  email: string
  tierLevel: string
  gender: string
  birthday: string
  totalEntriesByWinner: number
  dateRedrawn: string
  reasonForRedraw: string
}
export const getRedrawnPatronsColumns = (): GridColDef[] => [
  {
    field: 'drawingNumber',
    headerName: 'Drawing Number',
    width: 165,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 230,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 320,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'tierLevel',
    headerName: 'Tier Level',
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'gender',
    headerName: 'Gender',
    width: 190,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'birthday',
    headerName: 'Birthday',
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'totalEntriesByWinner',
    headerName: 'Total Entries by Winner',
    width: 220,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },

  {
    field: 'dateRedrawn',
    headerName: 'Date Redrawn',
    width: 190,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
  {
    field: 'reasonForRedraw',
    headerName: 'Reason For Redraw',
    minWidth: 1150,
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.value}</Typography>
    },
  },
]
