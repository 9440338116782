import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetRewardProgramsByCatalogId = ({
  rewardsCatalogId,
}: {
  rewardsCatalogId: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/rewards-programs/catalogs-id/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['reward-programs-by-catalog-id', rewardsCatalogId],
    queryFn: async () => {
      const { data } = await get({ id: rewardsCatalogId })
      return data
    },
  })
}
