import { Box, Button, MenuItem, Typography } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import ListBullet from 'assets/format_list_bulleted.svg'
import { useState } from 'react'
import './styles.css'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useLayout } from 'hooks/useLayout'
import { colorPrimitives } from 'components/Theme'
import { usePutSweepstakeWinner } from 'hooks/api/Sweepstake/usePutSweepstakeWinner'
import { useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type LocationWinnerTableProps } from './LocationWinnersColumns'
import { SweepstakeNotifyWinnersModal } from 'components/Sweepstakes/SweepstakeNotifyWinnersModal'

interface RedrawWinnerMenuItemProps {
  handleClose: () => void
  setIsIndividualRedrawModalOpen: () => void
}

interface ViewRedrawRequestMenuItemProps {
  handleClose: () => void
  setIsViewRedrawRequestModalOpen: (value: boolean) => void
}

export const RedrawWinnerMenuItem = ({
  handleClose,
  setIsIndividualRedrawModalOpen,
}: RedrawWinnerMenuItemProps) => (
  <MenuItem
    onClick={() => {
      handleClose()
      setIsIndividualRedrawModalOpen()
    }}
  >
    <Box className="py-2">
      <Typography variant="body-2">Redraw Winner</Typography>
    </Box>
  </MenuItem>
)

const ViewRedrawRequestMenuItem = ({
  handleClose,
  setIsViewRedrawRequestModalOpen,
}: ViewRedrawRequestMenuItemProps) => (
  <MenuItem
    onClick={() => {
      handleClose()
      setIsViewRedrawRequestModalOpen(true)
    }}
  >
    <Box className="py-2">
      <Typography variant="body-2">View Redraw Request</Typography>
    </Box>
  </MenuItem>
)

export const LocationPrizeMenu = ({
  params,
  setIsIndividualRedrawModalOpen,
  setIsViewRedrawRequestModalOpen,
  sweepstakeName,
}: {
  params: GridRenderCellParams<LocationWinnerTableProps>
  setIsIndividualRedrawModalOpen: () => void
  setIsViewRedrawRequestModalOpen: (value: boolean) => void
  sweepstakeName: string
}) => {
  const [notifyWinnersModalOpen, setNotifyWinnersModalOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const qc = useQueryClient()
  const { isMobile } = useLayout()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => {
    setIsModalOpen(!isModalOpen)
  }
  const heightModal = isMobile ? '100%' : 'auto'

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (anchorEl != null) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const winner = params.row
  const { mutateAsync } = usePutSweepstakeWinner({
    onError: () => {},
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/sweepstakes'] })
    },
  })
  const postMessage = useTypedApiClient({
    path: '/admin/patrons/messages',
    method: 'post',
  })

  const sendPrizeConfirmation = async () => {
    await mutateAsync({
      req: {
        id: winner.id,
        contestEntryId: winner.dto.contestEntryId,
        patronId: winner.patronId,
        status: 'Awarded',
        sweepstakeDrawingId: winner.dto.sweepstakeDrawingId,
      },
    })
    await postMessage({
      patronMessageTemplateType: 'Custom',
      patronId: winner.patronId,
      sender: winner.leName,
      subject: 'Confirmation of Prize Details',
      body: `We've received all the required details. You can expect to receive your prize within 4-6 weeks.\n\nContinue checking in on the J&J Gaming app to earn points for prizes!`,
      patronMessageTemplateId: 0,
      patronMessageData: {
        patronMessageType: 'PrizeConfirmation',
        sweepstakeWinnerId: winner.id,
      },
    })
    setIsModalOpen(false)
  }

  const open = anchorEl !== null

  const status = params.row.winnerStatus
  return (
    <>
      <Button
        onClick={(e) => toggleMenu(e)}
        style={{
          minWidth: '50px',
        }}
      >
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </Button>
      <StyledMenu
        sx={{ mt: 0 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {status === 'LocationValidation' && (
          <Box>
            <MenuItem
              onClick={() =>
                alert(
                  'Functionality of this button to be added in a future PBI'
                )
              }
            >
              <Box className="py-2">
                <Typography variant="body-2">Validate Winner</Typography>
              </Box>
            </MenuItem>
            <RedrawWinnerMenuItem
              key="redrawWinner"
              handleClose={handleClose}
              setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
            />
          </Box>
        )}
        {status === 'RedrawRequest' && (
          <ViewRedrawRequestMenuItem
            key="viewLocationRedrawRequest"
            handleClose={handleClose}
            setIsViewRedrawRequestModalOpen={setIsViewRedrawRequestModalOpen}
          ></ViewRedrawRequestMenuItem>
        )}
        {status === 'WinnerValidation' && (
          <Box>
            <MenuItem
              onClick={() => {
                handleClose()
                setNotifyWinnersModalOpen(true)
              }}
            >
              <Box className="py-2">
                <Typography variant="body-2">Notify Winner</Typography>
              </Box>
            </MenuItem>
            <RedrawWinnerMenuItem
              key="redrawWinner"
              handleClose={handleClose}
              setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
            />
          </Box>
        )}
        {status === 'WinnerNotified' && (
          <RedrawWinnerMenuItem
            key="redrawWinner"
            handleClose={handleClose}
            setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
          />
        )}
        {status === 'AffidavitReceived' && (
          <Box>
            <MenuItem onClick={() => toggleModalIsOpen()}>
              <Box className="py-2">
                <Typography variant="body-2">
                  Send Prize Confirmation Details
                </Typography>
              </Box>
            </MenuItem>
            <RedrawWinnerMenuItem
              key="redrawWinner"
              handleClose={handleClose}
              setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
            />
          </Box>
        )}
        {status === 'Awarded' && (
          <Box>
            <MenuItem
              onClick={() =>
                alert(
                  'Functionality of this button to be added in a future PBI'
                )
              }
            >
              <Box className="py-2">
                <Typography variant="body-2">Winner Claimed Prize</Typography>
              </Box>
            </MenuItem>
            <RedrawWinnerMenuItem
              key="redrawWinner"
              handleClose={handleClose}
              setIsIndividualRedrawModalOpen={setIsIndividualRedrawModalOpen}
            />
          </Box>
        )}
      </StyledMenu>
      <Modal
        isOpen={isModalOpen}
        toggleIsOpen={toggleModalIsOpen}
        sx={{
          width: isMobile ? '100%' : '60%',
          height: 'auto',
        }}
      >
        <ModalFullHeader
          toggleIsOpen={toggleModalIsOpen}
          title={'Prize Confirmation Details'}
          subTitle={`${winner.leName} (${winner.leLicenseNumber})`}
        />
        <ModalFullBody heightModal={heightModal}>
          <Typography
            component="h3"
            variant="body-1"
            fontSize={'18px'}
            fontWeight={'bold'}
            paddingBottom={1.5}
          >
            Are you sure you want to send prize confirmation details to this
            winner?
          </Typography>
          <Typography variant="body-1" color={colorPrimitives.gray70}>
            {`Selecting "Send Confirmation" will send a confirmation message
            acknowledging the receipt of their affidavit and providing details
            regarding the expected delivery of their prize.`}
          </Typography>
          <Typography variant="body-1" paddingTop={5} paddingBottom={2}>
            <strong>{`${winner.leName}`}</strong>{' '}
            {`(${winner.leLicenseNumber}): ${winner.winnerName}`}
          </Typography>
        </ModalFullBody>
        <ModalFullFooter>
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={sendPrizeConfirmation}
          >
            Send Confirmation
          </Button>
        </ModalFullFooter>
      </Modal>
      <SweepstakeNotifyWinnersModal
        sweepstakeName={sweepstakeName}
        isModalOpen={notifyWinnersModalOpen}
        toggleIsOpen={() => setNotifyWinnersModalOpen(!notifyWinnersModalOpen)}
        selectedWinners={[
          {
            locationName: params.row.leName,
            licenseNumber: params.row.leLicenseNumber,
            winnerName: params.row.winnerName,
            sweepstakesWinner: params.row.dto,
            drawingSequence: params.row.drawingSequence ?? 0,
          },
        ]}
      />
    </>
  )
}
