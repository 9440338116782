import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetOrganizationsByIds = ({
  ids,
  enabled = true,
}: {
  ids: number[]
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: '/admin/organizations/{id}',
    method: 'get',
  })
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['organization', id],
      queryFn: async () => {
        const queryResult = await get({
          id,
        })
        return queryResult.data
      },
      enabled,
    })),
  })
}
