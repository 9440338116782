import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import type { RewardsRedemption } from 'types/api'

export const useGetRewardsRedemptionsByRewardsProgramIdAndTransactionDate = ({
  rewardsProgramId,
  startDate,
  endDate,
}: {
  rewardsProgramId: number
  startDate?: string
  endDate?: string
  onSuccess?: (response: RewardsRedemption[]) => void
}): UseQueryResult<RewardsRedemption[]> => {
  return useApiQuery({
    path: '/admin/rewards/redemptions/rewards-programs/{rewardsProgramId}',
    method: 'get',
    queryKey: [
      '/admin/redemptions/rewards/rewards-programs/rewardsProgramId/redemptions',
      rewardsProgramId,
      startDate,
      endDate,
    ],
    queryArgs: {
      rewardsProgramId,
      startTransactionDate: startDate,
      endTransactionDate: endDate,
    },
    enabled: !!startDate && !!endDate,
  })
}
