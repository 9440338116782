import { useState } from 'react'
import { Button, Typography, Box, Grid, useTheme } from '@mui/material'
import { RewardCatalogDetailsEditModal } from './RewardCatalogDetailsEditModal'
import { type RewardsCatalog } from 'types/api'

interface RewardCatalogSectionProps {
  rewardsCatalog: RewardsCatalog
}

export const RewardCatalogDetailSection = ({
  rewardsCatalog,
}: RewardCatalogSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = async () => {
    setIsModalOpen(!isModalOpen)
  }

  const theme = useTheme()

  return (
    <Box marginTop={1}>
      <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center sm:pb-4">
        <Typography variant="h2">Catalog Details</Typography>
        <Box mt={{ xs: '24px', sm: '0' }}>
          <Button
            variant={'text'}
            onClick={() => {
              setIsModalOpen(!isModalOpen)
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={12}>
          <Grid item sm={8} md={3} lg={3}>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Reward Catalog Name
              </Typography>
              <Typography variant="body1" pb={2}>
                {rewardsCatalog?.name ?? ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={8} md={3} lg={3}>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Reward Catalog ID
              </Typography>
              <Typography variant="body1">{rewardsCatalog?.id ?? 0}</Typography>
            </Box>
          </Grid>
          <Grid item sm={8} md={3} lg={3}>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Reward Catalog Type
              </Typography>
              <Typography variant="body1">{rewardsCatalog?.type}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <RewardCatalogDetailsEditModal
        rewardsCatalog={rewardsCatalog}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        toggleIsOpen={toggleModalIsOpen}
      />
    </Box>
  )
}
