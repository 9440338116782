import { AuthenticatedTemplate } from '@azure/msal-react'
import { ProfileContent } from './ProfileContent'

export const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>
    </div>
  )
}
