import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { type InferType } from 'yup'
import { type CampaignSchema } from './CampaignSchema'

export const TypeOfMessageSelect = ({ ...otherProps }: SelectProps) => {
  const { register } = useFormContext<InferType<typeof CampaignSchema>>()

  return (
    <FormControl>
      <InputLabel id="typeOfMessage-label" htmlFor="typeOfMessage" required>
        Type of Message
      </InputLabel>
      <Select
        labelId="typeOfMessage-label"
        id="typeOfMessage"
        required
        variant="outlined"
        defaultValue=""
        fullWidth
        {...register('typeOfMessage', {
          required: true,
        })}
        {...otherProps}
      >
        <MenuItem value="">
          <em>Select Type of Message</em>
        </MenuItem>
        <MenuItem key="location" value="Location">
          Location
        </MenuItem>
        <MenuItem key="sweepstakes" value="Sweepstakes">
          Sweepstakes
        </MenuItem>
        <MenuItem key="allpatrons" value="AllPatrons">
          All Patrons
        </MenuItem>
        <MenuItem key="corporate" value="Corporate">
          Corporate Account
        </MenuItem>
        <MenuItem key="allpatronsbytier" value="AllPatronsByTier">
          All Patrons by Tier
        </MenuItem>
      </Select>
    </FormControl>
  )
}
