import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import type { RewardsCatalog } from 'types/api'
import { grey } from '@mui/material/colors'
import { useSnackbar } from 'stores/useSnackbar'
import { type ApiError } from 'openapi-typescript-fetch'
import { useQueryClient } from '@tanstack/react-query'
import { usePutRewardsCatalog } from 'hooks/api/usePutRewardsCatalog'

const RewardCatalogDetailsEditSchema = object({
  catalogName: string().required('Required').max(100),
})

export const RewardCatalogDetailsEditModal = ({
  isModalOpen,
  toggleIsOpen,
  toggleModalIsOpen,
  rewardsCatalog,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  toggleIsOpen: () => void
  rewardsCatalog: RewardsCatalog
}) => {
  const formMethods = useForm({
    resolver: yupResolver(RewardCatalogDetailsEditSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()
  const putRewardrewardsCatalogMutation = usePutRewardsCatalog({
    onSuccess: () => {
      setSnackbarMessage(`Reward Catalog has been updated.`)
      toggleModalIsOpen()
      void queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-catalog'],
      })
    },
    onError: (error: ApiError) => {
      if (typeof error.data === 'string') {
        if (error.data.includes('CatalogName')) {
          control.setError('catalogName', { message: error.data })
        }
      } else {
        setSnackbarMessage(
          `Error: Cannot save Reward Catalog information`,
          'error'
        )
      }
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    putRewardrewardsCatalogMutation.mutate({
      id: rewardsCatalog.id,
      rewardsCatalogData: {
        name: formData.catalogName,
        description: rewardsCatalog.description,
        type: rewardsCatalog.type,
      },
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <form onSubmit={handleSubmitWrapper} noValidate>
        <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
          <Box px={4} py={4}>
            <Typography variant="h3" fontWeight={'bold'}>
              Catalog Details
            </Typography>
            <Typography variant="body-1" color={'text.secondary'}>
              {rewardsCatalog?.name ?? ''}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 4, px: 4 }}>
          <Grid container pt={4}>
            <Grid item xs={12} md={6} pt={{ md: 2 }}>
              <InputLabel
                required
                htmlFor="RewardsCatalog.Name"
                sx={{ fontSize: '16px', fontWeight: '100' }}
              >
                Reward Catalog Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register('catalogName', { required: true })}
                defaultValue={rewardsCatalog?.name ?? ''}
                error={isPresent(errors.catalogName)}
              />
              {errors.catalogName?.message && (
                <FormFieldErrorMessage message={errors.catalogName?.message} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
        >
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            variant="contained"
            active={false}
            onClick={handleSubmitWrapper}
          >
            Save
          </ActivityButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
