import { Typography, Chip, type ChipProps } from '@mui/material'
import { green, grey } from '@mui/material/colors'

type Status = 'active' | 'inactive'

const labels: Record<Status, string> = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
}

const colors = {
  active: green[900],
  inactive: grey[700],
}

const backgroundColors = {
  active: green[100],
  inactive: grey[300],
}

interface StatusChipProps extends ChipProps {
  active?: boolean
}

export const StatusChip = ({ active = false, sx }: StatusChipProps) => {
  const status = active ? 'active' : 'inactive'
  const finalSx = {
    borderRadius: '2px',
    color: colors[status],
    backgroundColor: backgroundColors[status],
    ...(sx ?? {}),
  }
  return (
    <Chip
      label={<Typography fontWeight="bold">{labels[status]}</Typography>}
      sx={finalSx}
    />
  )
}
