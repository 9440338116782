import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteCampaignParams {
  id: number | null | undefined
}

interface useDeleteCampaignProps {
  onSuccess?: (data: undefined, variables: useDeleteCampaignParams) => void
  onError?: (error: Error, variables: useDeleteCampaignParams) => void
}

export const useDeleteCampaignById = ({
  onSuccess,
  onError,
}: useDeleteCampaignProps) => {
  const deleteCampaign = useTypedApiClient({
    path: '/admin/campaigns/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeleteCampaignParams>({
    mutationFn: async ({ id }: useDeleteCampaignParams) => {
      await deleteCampaign({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/campaigns'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
