import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { isNil } from 'lodash'
import { useState } from 'react'
import { useRequestRedrawModal } from 'stores/useRequestRedrawModal'

interface SweepstakesRequestRedrawModalProps {
  redrawWinners: (
    theReason: string,
    sweepstakeDrawingId: number,
    sweepstakeWinnerId?: number
  ) => void
}

export const SweepstakesRequestRedrawModal = ({
  redrawWinners,
}: SweepstakesRequestRedrawModalProps) => {
  const [reason, setReason] = useState('')

  const { data, setData } = useRequestRedrawModal()

  const handleConfirm = () => {
    if (reason.trim()) {
      const sweepstakeDrawingId = data?.sweepstakeDrawingId
      if (isNil(sweepstakeDrawingId)) {
        throw Error('Sweepstake Drawing Id')
      }

      redrawWinners(
        reason,
        sweepstakeDrawingId,
        Number(data?.sweepstakeWinnerId)
      )
      handleCancel()
    }
  }

  const handleCancel = () => {
    setReason('')
    setData(undefined)
  }

  return (
    <Dialog
      open={data !== undefined}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Redraw Winner
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {data?.locationName
              ? `${data?.locationName} (${data?.licenseNumber})`
              : `Grand Prize Winner (${String(data?.tierName)})`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Box paddingBottom={2} paddingTop={3}>
          <Typography variant="h4" fontSize={18} paddingBottom={2}>
            Are you sure you want to redraw this winner?
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Selecting an alternative winner involves drawing the next eligible
            name from the standby list. Subsequently, the current winner will be
            deemed &quot;disqualified&quot;, rendering them ineligible to
            receive the prize.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <InputLabel
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Winner Name
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={data?.winnerName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              required
              sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
            >
              Reason for Redraw
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              multiline
              rows={4}
              fullWidth
              placeholder="Enter redraw reasoning"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button variant="text" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          disabled={reason.trim() === ''}
        >
          Redraw Winner
        </Button>
      </DialogActions>
    </Dialog>
  )
}
