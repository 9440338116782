import {
  Box,
  IconButton,
  AppBar as MuiAppBar,
  Stack,
  Toolbar,
} from '@mui/material'
import LogoImage from 'assets/logo.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavBar } from 'components/NavigationBar/useNavBar'
import { MainContent } from './MainContent'

export const APP_BAR_HEIGHT = 65

export const AppBar = () => {
  const toggle = useNavBar((state) => state.toggle)

  return (
    <MuiAppBar position="fixed" sx={{ bgcolor: 'white' }}>
      <Toolbar>
        <Box
          color="text.primary"
          display={{ xs: 'block', sm: 'block', md: 'block', lg: 'none' }}
        >
          <IconButton
            data-testid="mobile-sidebar-button"
            onClick={() => {
              toggle()
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              marginLeft: '5px',
            },
          }}
        >
          <img src={LogoImage} width={210} />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
        >
          <MainContent />
        </Stack>
      </Toolbar>
    </MuiAppBar>
  )
}
