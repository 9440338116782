import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type FullRewardsOptions, type RewardsOptions } from 'types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UsePostRewardOptions {
  rewardId: number
  onSuccess?: (data: FullRewardsOptions, variables: RewardsOptions) => void
  onError?: (error: Error, variables: RewardsOptions) => void
}

export const usePostRewardsOptions = ({
  rewardId,
  onSuccess,
  onError,
}: UsePostRewardOptions) => {
  const post = useTypedApiClient({
    path: '/admin/rewards-options',
    method: 'post',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, RewardsOptions>({
    mutationFn: async (data: RewardsOptions) => {
      const response = await post({ ...data, rewardId })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-options'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
