import { Box, Divider, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'
import { isNil } from 'lodash'

export const PageHeader = ({
  children,
  tabs,
  eyebrow,
  title,
  identifier,
  customIdentifier,
  subtitle,
  customSubTitle,
  actionButton,
  backText,
  backPath,
  isSecondary,
  status,
}: {
  children?: React.ReactNode
  tabs?: React.ReactNode
  eyebrow?: string
  title: string
  identifier?: string
  customIdentifier?: string
  subtitle?: string
  customSubTitle?: React.ReactNode
  actionButton?: React.ReactNode
  backText?: string
  backPath?: string
  isSecondary?: boolean
  status?: React.ReactNode
}) => {
  return (
    <Box>
      <Box
        py={4}
        pb={isSecondary === true && !tabs ? 4 : 0}
        px={6}
        bgcolor={isSecondary === true ? '#f7f7f6' : 'white'}
        borderBottom={
          isSecondary
            ? '1px solid var(--outlines-Outline-LIght, rgba(41, 43, 38, 0.10))'
            : ''
        }
      >
        {!isNil(backPath) && (
          <Link to={backPath} id="back-link">
            <Box
              display={'flex'}
              flexDirection="row"
              alignItems="center"
              gap={1}
              mb={4}
              color="text.secondary"
            >
              <ArrowLeft />
              <Typography>Back to {backText}</Typography>
            </Box>
          </Link>
        )}
        {!isNil(eyebrow) && (
          <Box pb={1}>
            <Typography variant="body-1" color="text.secondary">
              {eyebrow}
            </Typography>
          </Box>
        )}
        <Box className="flex w-full space-y-0 flex-row justify-between items-start">
          <Box>
            <Typography variant="h1">{title}</Typography>
            {!isNil(identifier) ? (
              <Typography
                variant="body-1"
                color={customIdentifier ? 'text.secondary' : 'black'}
              >
                {customIdentifier ?? `ID: #${identifier}`}{' '}
              </Typography>
            ) : (
              ''
            )}
            <Box className="flex items-center w-full flex-row gap-4">
              {status}
              <Box
                className="px-3 h-4"
                sx={{ ...(!status && { display: 'none' }) }}
              >
                <Divider
                  sx={{ ...(!subtitle && { display: 'none' }) }}
                  orientation="vertical"
                />
              </Box>
              {!isNil(subtitle) && isNil(customSubTitle) && (
                <Typography variant="body-1" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
              {customSubTitle}
            </Box>
            <Box>{children}</Box>
          </Box>
          {actionButton && <Box mt={0}>{actionButton}</Box>}
        </Box>
        {tabs && <Box pt={2}>{tabs}</Box>}
      </Box>
    </Box>
  )
}
