import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { FullRewardsOptions, RewardsOptions } from 'types/api'

interface useDeleteRewardsOptionsProps {
  onSuccess?: (data: FullRewardsOptions, variables: RewardsOptions) => void
  onError?: (error: Error, variables: RewardsOptions) => void
}

export const useDeleteRewardsOptions = ({
  onSuccess,
  onError,
}: useDeleteRewardsOptionsProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards-options/{rewardId}/{type}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, RewardsOptions>({
    mutationFn: async (data: RewardsOptions) => {
      const response = await put(data)
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-options'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
