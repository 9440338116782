import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import type { components } from 'api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import type { PatronCheckInRangeType } from 'types/api'

export const useGetPatronCheckInsByRange = ({
  patronId,
  range,
}: {
  patronId: number
  range: PatronCheckInRangeType
}): UseQueryResult<Array<components['schemas']['FullPatronCheckInDTO']>> => {
  const get = useTypedApiClient({
    path: '/admin/patron/{patronId}/checkins/{range}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/admin/patron/checkins/range', patronId, range],
    queryFn: async () => {
      const { data } = await get({ patronId, range })
      return data
    },
  })
}
