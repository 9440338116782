import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { LEConnectUserList } from './LEConnectUserList'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { SearchField } from 'components/SearchField'
import { useState } from 'react'
import { useDebounce } from '@uidotdev/usehooks'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetFilteredLicensedEstablishmentUsers } from 'hooks/api/LicensedEstablishmentUser/useGetLicensedEstablishmentUsersSearch'

export const LEConnectUsersPage = () => {
  const [searchText, setSearchText] = useState('')
  const debouncedQuery = useDebounce(searchText, 500)
  const leUsers = useGetFilteredLicensedEstablishmentUsers(debouncedQuery)

  return (
    <Page
      header={
        <PageHeader title="J&J Connect Users" eyebrow="CLIENT MANAGEMENT" />
      }
    >
      <Stack
        direction={{ md: 'row', sm: 'column', xs: 'column' }}
        gap={{ md: 0, sm: 2, xs: 2 }}
        justifyContent={'space-between'}
      >
        <SearchField
          placeholder="Search J&J Connect Users"
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
        />
        <Link to="/ClientManagement/LEConnectUsers/New">
          <Button variant="contained">Create New J&J Connect User</Button>
        </Link>
      </Stack>
      {leUsers.data ? (
        <LEConnectUserList users={leUsers.data} />
      ) : (
        <ActivityIndicator />
      )}
    </Page>
  )
}
