import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Divider } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { AssociatedCorporateAccountDetailsSection } from 'pages/ClientManagement/Organization/OrganizationDetailsPage/OrganizationAssociatedCorporateAccount/AssociatedCorporateAccountDetailsSection'
import { AssociatedLicensedEstablishmentsTableContainer } from 'pages/ClientManagement/Organization/OrganizationDetailsPage/OrganizationAssociatedLicensedEstablishment/AssociatedLicensedEstablishmentsSection'
import { OrganizationDetailsSection } from './OrganizationDetails/OrganizationDetailsSection'
import { EditOrganizationDetailsModal } from './OrganizationDetails/EditOrganizationDetailsModal'
import { EditAssociatedLicensedEstablishmentsModal } from './OrganizationAssociatedLicensedEstablishment/EditAssociatedLicensedEstablishmentsModal'
import { useGetOrganizationById } from 'hooks/api/useGetOrganizationById'
import { getLastUpdated } from 'utils/util'
import { EditAssociatedCorporateAccountModal } from './OrganizationAssociatedCorporateAccount/EditAssociatedCorporateAccountModal'
import { RewardProgramSection } from './OrganizationDetails/RewardsProgram'

export const OrganizationsDetailPage = () => {
  const param = useParams()

  const [organizationDetailModalOpen, setOrganizationDetailModalOpen] =
    useState(false)
  const [
    associatedCorporateAccountModalOpen,
    setAssociatedCorporateAccountModalOpen,
  ] = useState(false)
  const [
    associatedLicensedEstablishmentsModalOpen,
    setAssociatedLicensedEstablishmentsModalOpen,
  ] = useState(false)

  const toggleOrganizationDetailModalOpen = async () =>
    setOrganizationDetailModalOpen(!organizationDetailModalOpen)

  const toggleAssociatedCorporateAccountModalOpen = async () =>
    setAssociatedCorporateAccountModalOpen(!associatedCorporateAccountModalOpen)

  const toggleAssociatedLicensedEstablishmentsModalOpen = async () =>
    setAssociatedLicensedEstablishmentsModalOpen(
      !associatedLicensedEstablishmentsModalOpen
    )

  const organizationByIdQuery = useGetOrganizationById({
    organizationId: Number(param.id),
  })

  if (organizationByIdQuery.isPending) {
    return <CircularProgress />
  }

  if (organizationByIdQuery.isError) {
    return <p>An error occured.</p>
  }

  const org = organizationByIdQuery.data

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title={org?.name ?? 'Organization Details'}
            backText="Organizations"
            backPath="/ClientManagement/Organizations"
            isSecondary={true}
            subtitle={getLastUpdated(org?.modifiedOn)}
          />
          <Divider />
        </Box>
      }
    >
      <OrganizationDetailsSection
        organization={org}
        onClickEdit={toggleOrganizationDetailModalOpen}
      />

      <Box mt={{ xs: 5, md: 8 }}>
        <AssociatedCorporateAccountDetailsSection
          corporateAccount={org?.corporateAccount}
          onClickEdit={toggleAssociatedCorporateAccountModalOpen}
        />
      </Box>

      <Box mt={{ xs: 5, md: 8 }}>
        <AssociatedLicensedEstablishmentsTableContainer
          organizationId={org?.id ?? 0}
          onClickEdit={toggleAssociatedLicensedEstablishmentsModalOpen}
        />
      </Box>

      <EditOrganizationDetailsModal
        organization={org}
        isModalOpen={organizationDetailModalOpen}
        toggleModalIsOpen={toggleOrganizationDetailModalOpen}
      />

      <EditAssociatedCorporateAccountModal
        organization={org}
        isModalOpen={associatedCorporateAccountModalOpen}
        toggleModalIsOpen={toggleAssociatedCorporateAccountModalOpen}
      />

      <EditAssociatedLicensedEstablishmentsModal
        organization={org}
        isModalOpen={associatedLicensedEstablishmentsModalOpen}
        toggleModalIsOpen={toggleAssociatedLicensedEstablishmentsModalOpen}
      />
      <RewardProgramSection organization={org} />
    </Page>
  )
}
