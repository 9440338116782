import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'

interface SweepstakesViewRedrawRequestModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  onConfirm: (reason: string) => void
  onDeny: () => void
  onCancel: () => void
  locationName: string
  licenseNumber: string
  winnerName: string
  requestReason: string
}

export const SweepstakesViewRedrawRequestModal = ({
  isModalOpen,
  onConfirm,
  onDeny,
  onCancel,
  locationName,
  licenseNumber,
  winnerName,
  requestReason,
}: SweepstakesViewRedrawRequestModalProps) => {
  const handleConfirm = () => {
    onConfirm(requestReason)
  }
  const handleDeny = () => {
    onDeny()
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '800px' } }}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Redraw Requested
          </Typography>
          <Typography variant="body-1" color={'text.secondary'}>
            {`${locationName} (${licenseNumber})`}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography variant="body-1" fontSize={18} pt={2}>
              Winner Name
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              value={winnerName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{ fontSize: 18, paddingTop: 2, fontWeight: 'normal' }}
            >
              Reason for Request
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              multiline
              rows={4}
              value={requestReason}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Box>
              <Button variant="text" onClick={handleDeny}>
                Deny
              </Button>
              <Button variant="contained" onClick={handleConfirm}>
                Approve
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
