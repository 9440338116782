import { CardContent, Box, Typography, CardActionArea } from '@mui/material'
import { Card } from 'components/Card/Card'
import { CardImage } from 'components/Card/CardImage'

interface ImageCardProps {
  isSelected?: boolean
  onClick?: () => void
  imageSource?: string
  title: string
  subtitle?: string
  description?: string
  testId?: string
  hideBoxShadow?: boolean
}

export const ImageCard = ({
  isSelected = false,
  onClick,
  imageSource,
  title,
  subtitle,
  description,
  testId,
  hideBoxShadow,
}: ImageCardProps) => {
  const content = (
    <Box
      sx={{
        border: '1px solid rgba(41, 43, 38, 0.15)',
        borderRadius: '4px',
      }}
    >
      <CardImage imageSource={imageSource} />
      <CardContent>
        <Typography variant="subtitle-1" component="div">
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="body1" color="text.secondary">
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        )}
      </CardContent>
    </Box>
  )
  return (
    <Card isSelected={isSelected} hideBoxShadow={hideBoxShadow}>
      {onClick ? (
        <CardActionArea onClick={onClick} data-testid={testId}>
          {content}
        </CardActionArea>
      ) : (
        content
      )}
    </Card>
  )
}
