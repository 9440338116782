import { isPresent } from '@jjvgaming/player-payback-library'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type Promotion } from 'types/api'

const path = '/admin/promotions/{id}'
const method = 'put'

interface UsePutPromotionParams {
  promotionId: number
  onSuccess?: () => void
  onError?: () => void
}

type WritablePromotion = Promotion & {
  id: number
}

const isPromotionWritable = (
  promotion: Promotion
): promotion is WritablePromotion => {
  return isPresent(promotion.id)
}

export const usePutPromotion = ({
  promotionId,
  onSuccess,
  onError,
}: UsePutPromotionParams) => {
  const queryClient = useQueryClient()
  const put = useTypedApiClient({ path, method })
  const mutate = useMutation({
    mutationKey: ['/admin/promotions/{id}', promotionId],
    mutationFn: async (promotion: Promotion) => {
      if (isPromotionWritable(promotion)) {
        const { data } = await put(promotion)
        return data
      } else {
        throw new Error('Cannot write Promotion without required key: id')
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/promotions', promotionId],
      })
      onSuccess?.()
    },
    onError,
  })

  return mutate
}
