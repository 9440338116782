import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeletePromotionParams {
  id: number | null | undefined
}

interface useDeletePromotionProps {
  onSuccess?: (data: undefined, variables: useDeletePromotionParams) => void
  onError?: (error: Error, variables: useDeletePromotionParams) => void
}

export const useDeletePromotionById = ({
  onSuccess,
  onError,
}: useDeletePromotionProps) => {
  const deletePromotion = useTypedApiClient({
    path: '/admin/promotions/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeletePromotionParams>({
    mutationFn: async ({ id }: useDeletePromotionParams) => {
      await deletePromotion({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/promotions'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
