import { ThemeProvider } from 'components/ThemeProvider'
import './sentry'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Navigation } from './navigation/Navigation'
import { Snackbar } from 'components/Shared/Snackbar'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AccessTokenProvider } from 'contexts/AccessTokenContext'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'
import { Container } from '@mui/material'
import { appInsights, reactPlugin } from './appInsights'
import { useSentryIdentification } from 'hooks/useSentryIdentification'

const queryClient = new QueryClient()

appInsights.loadAppInsights()

export function App() {
  useSentryIdentification()

  return (
    <ThemeProvider>
      <AppInsightsErrorBoundary
        onError={() => {
          return (
            <Container>
              <ErrorIndicator />
            </Container>
          )
        }}
        appInsights={reactPlugin}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <AccessTokenProvider>
              <Snackbar />
              <Navigation />
            </AccessTokenProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </AppInsightsErrorBoundary>
    </ThemeProvider>
  )
}
