import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'
import { type RewardsOptionsType } from 'types/api'

export const useGetRewardsOptionsById = ({
  rewardId,
  type,
  enableJJStore,
}: {
  rewardId: number
  type: RewardsOptionsType
  enableJJStore: boolean
}): UseQueryResult<components['schemas']['RewardsOptionsDTO']> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-options/{rewardId}/{type}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/rewards-options', rewardId],
    queryFn: async () => {
      try {
        const { data } = await get({ rewardId, type })
        return data
      } catch (e) {
        if (e instanceof get.Error) {
          const error = e.getActualType()

          if (error.status === 404) {
            return null
          }
          throw e
        }
      }
    },
    enabled: enableJJStore,
  })
}
