import { useQueries } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
const route = '/admin/corporate-accounts/{id}'

export const useGetCorporateAccountsByIds = ({
  ids,
  enabled = true,
}: {
  ids: number[]
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: route,
    method: 'get',
  })
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['corporateAccount', id],
      queryFn: async () => {
        const queryResult = await get({
          id,
        })
        return queryResult.data
      },
      enabled,
    })),
  })
}
