import { Box, InputLabel, Stack, TextField } from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { useFormContext } from 'react-hook-form'
import { type BasicInformationForm } from './BasicInformation'
import { isPresent } from '@jjvgaming/player-payback-library'

export const Geolocation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ basicInfo: BasicInformationForm }>()

  return (
    <Box>
      <InputLabel htmlFor={'address.street1'}>Geolocation Details</InputLabel>

      <Stack justifyContent="space-between" direction="row" gap={2}>
        <Stack sx={{ width: 1 }}>
          <TextField
            {...register('basicInfo.leLat')}
            error={isPresent(errors.basicInfo?.leLat)}
            placeholder="Latitude"
            fullWidth
          />
          {errors.basicInfo?.leLat?.message && (
            <FormFieldErrorMessage message={errors.basicInfo?.leLat.message} />
          )}
        </Stack>
        <Stack sx={{ width: 1 }}>
          <TextField
            {...register('basicInfo.leLong')}
            error={isPresent(errors.basicInfo?.leLong)}
            placeholder="Longitude"
            fullWidth
          />
          {errors.basicInfo?.leLong?.message && (
            <FormFieldErrorMessage message={errors.basicInfo?.leLong.message} />
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
