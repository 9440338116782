import { useQuery } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type OpReturnType } from 'openapi-typescript-fetch'
import { type paths } from 'api/playerPayback/api'

const route =
  '/admin/contests/{contestId}/licensed-establishments/{licensedEstablishmentId}'

export type CorporateAccountGetResponse = OpReturnType<
  paths[typeof route]['get']
>

export const useGetContestLeByContestAndLe = ({
  contestId,
  licensedEstablishmentId,
  enabled = true,
}: {
  contestId: number
  licensedEstablishmentId: number
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: route,
    method: 'get',
  })
  return useQuery({
    queryKey: [route, contestId, licensedEstablishmentId],
    queryFn: async () => {
      try {
        const res = await get({
          contestId,
          licensedEstablishmentId,
        })

        if (isNil(res.data)) {
          throw new Error('Contest LE not found')
        }
        return res.data
      } catch (e) {
        if (e instanceof get.Error) {
          const error = e.getActualType()

          if (error.status === 404) {
            return undefined
          }
          throw e
        }
      }
    },
    enabled,
  })
}
