import { Box, Button, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { type components } from 'api/playerPayback/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { useGetLicensedEstablishmentUsersByCorporateAccountId } from 'hooks/api/useLicensedEstablishmentUsersByCorporateAccountId'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import JJnoUserFound from 'assets/jj-no-user-found.svg'

const commonColumns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 160,
  },
]

const desktopColumns: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1,
    minWidth: 160,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1,
    minWidth: 160,
  },
  ...commonColumns,
]

const mobileColumns: GridColDef[] = [
  {
    field: 'fullName',
    headerName: 'Full Name',
    flex: 1,
    minWidth: 160,
    valueGetter: (params) =>
      `${String(params.row.firstName)} ${String(params.row.lastName)}`,
  },
  ...commonColumns,
]

interface LicensedEstablishmentUsersSectionPropType {
  corporateAccount: components['schemas']['FullCorporateAccountDTO']
}

export const LicensedEstablishmentUsersSection = ({
  corporateAccount,
}: LicensedEstablishmentUsersSectionPropType) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()
  const LicensedEstablishmentUsersQuery =
    useGetLicensedEstablishmentUsersByCorporateAccountId({
      corporateAccountId: Number(corporateAccount.id),
    })

  if (LicensedEstablishmentUsersQuery.isPending) {
    return <ActivityIndicator sx={{ height: '123px' }} />
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h2">J&J Connect Users</Typography>
        <Button
          variant={'contained'}
          onClick={() => {
            navigate(
              `/ClientManagement/LEConnectUsers/New?corporateAccountId=${corporateAccount.id}`
            )
          }}
        >
          Create New J&J Connect User
        </Button>
      </Box>
      <Box className="flex flex-col justify-center pt-4">
        <DataTable
          columns={isMobile ? mobileColumns : desktopColumns}
          rows={LicensedEstablishmentUsersQuery.data ?? []}
          sort="asc"
          sortFieldName={isMobile ? 'fullName' : 'firstName'}
          onRowClick={(row) => {
            navigate(`/ClientManagement/LEConnectUsers/${row.id}`)
          }}
          noDataMessage="No J&J Connect Users"
          emptyDataImageSource={JJnoUserFound}
          getRowClassName={() => 'text-[#2c2c27]'}
        />
      </Box>
    </Box>
  )
}
