import { Box, useTheme } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export const CardSelectedIcon = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        backgroundColorColor: theme.palette.background.paper,
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        m: '8px',
      }}
    >
      <CheckCircleIcon
        color="primary"
        sx={{
          width: '32px',
          height: '32px',
        }}
      />
    </Box>
  )
}
