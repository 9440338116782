import ListBullet from 'assets/format_list_bulleted.svg'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { type ColumnType } from './RewardProgramDetailPage'
import { type RewardsCatalog, type RewardsProgramCatalogTier } from 'types/api'
import { useState } from 'react'

export const EditTierColumnRenderCell = ({
  isGlobal,
  params,
  setEditTierPerkModalData,
}: {
  params: GridRenderCellParams<ColumnType>
  isGlobal: boolean
  setEditTierPerkModalData: React.Dispatch<
    React.SetStateAction<
      | {
          rewardsProgramCatalogTiers?: RewardsProgramCatalogTier[]
          rewardsCatalog?: RewardsCatalog
          rewardProgramCatalogId: number
        }
      | null
      | undefined
    >
  >
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleMenuOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  if (!isGlobal) {
    return null
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setEditTierPerkModalData({
              rewardsCatalog: params.row.rewardsCatalog,
              rewardsProgramCatalogTiers: params.row.rewardsProgramCatalogTiers,
              rewardProgramCatalogId: params.row.rewardProgramCatalogId,
            })
            handleMenuClose()
          }}
        >
          Edit Tier Based Perks
        </MenuItem>
      </Menu>
    </>
  )
}
