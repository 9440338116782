import { removePhoneMask } from '@jjvgaming/player-payback-library'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { Patron, PatronAddress, GenderType } from 'types/api'
import { type ApiError } from 'openapi-typescript-fetch'

interface UsePutPatronProps {
  onSuccess?: (data: Patron) => void
  onError?: (error: ApiError) => void
}

export interface FormFields {
  uuid: string
  firstName: string
  lastName: string
  email: string
  dob: string
  gender: GenderType
  phoneNumber: string
  addresses?: PatronAddress[]
  id: number
  exclude?: boolean
  excludeReason?: string
  excludeDate?: string
  deactivated?: boolean
  deactivatedReason?: string
}

export const usePutPatron = ({ onSuccess, onError }: UsePutPatronProps) => {
  const queryClient = useQueryClient()
  const putPatron = useTypedApiClient({
    path: '/admin/patrons/{id}',
    method: 'put',
  })

  return useMutation<Patron, ApiError, FormFields>({
    mutationFn: async (data) => {
      const dob = data.dob.split('/')
      const { data: putPatronResult } = await putPatron({
        uuid: data.uuid,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dob: `${dob[2]}-${dob[0]}-${dob[1]}`,
        gender: data.gender,
        phoneNumber: removePhoneMask(data.phoneNumber),
        addresses: data.addresses,
        id: data.id,
        exclude: data.exclude,
        excludeReason: data.excludeReason,
        excludeDate: data.excludeDate,
        deactivated: data.deactivated,
        deactivatedReason: data.deactivatedReason,
      })
      return putPatronResult
    },
    onSuccess: async (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      await queryClient.invalidateQueries({
        queryKey: ['/admin/patronaccount/detail', data.id],
      })
    },
    onError,
  })
}
