import { type components } from 'api/playerPayback/api'
import { bool, object, string } from 'yup'

export type LeUserRoleType =
  components['schemas']['LicensedEstablishmentUserRoleType']

export const LeUserRoles: Array<LeUserRoleType | undefined> = [
  'Owner',
  'Staff',
  'SalesAccountManager',
  'LimitedLiveAtLocation',
]

export const LEUserRoleAndPermissionSchema = object({
  type: string().oneOf(LeUserRoles).required(),
  reportsFinancialPermission: bool().required(),
  reportsNonFinancialPermission: bool().required(),
  patronsAccountsPermission: bool().required(),
  rewardsPermission: bool().required(),
  promotionsPermission: bool().required(),
  locationsPermission: bool().required(),
  leUsersPermission: bool().required(),
  liveAtLocationViewPermission: bool().required(),
  sweepstakesPermission: bool().required(),
  allPermissions: bool(),
  allReports: bool(),
})
