import { Box, Grid, Typography, useTheme } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { formatLatitude, formatLongitude } from 'utils/util'
import { type LicensedEstablishmentDetailsProps } from '../types'

export const AddressDetailsDesktop = ({
  licensedEstablishment,
  onClickEdit,
}: LicensedEstablishmentDetailsProps) => {
  const theme = useTheme()
  const address = licensedEstablishment.addresses?.[0]

  return (
    <Box>
      <SectionHeader title="Address" onClickButton={onClickEdit} />
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={13}>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Street
            </Typography>
            <Typography variant="body1" pb={2}>
              {address?.address1 ? address.address1 : '-'}{' '}
              {address?.address2 ? address.address2 : ''}
            </Typography>
            {licensedEstablishment.latitude && (
              <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Latitude
                </Typography>
                <Typography variant="body1" pb={2}>
                  {licensedEstablishment.latitude
                    ? formatLatitude(licensedEstablishment.latitude)
                    : '-'}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              City
            </Typography>
            <Typography variant="body1" pb={2}>
              {address?.city ? address.city : '-'}
            </Typography>
            {licensedEstablishment.longitude && (
              <Box>
                <Typography variant="body2" pb={1} color="text.secondary">
                  Longitude
                </Typography>
                <Typography variant="body1" pb={2}>
                  {licensedEstablishment.longitude
                    ? formatLongitude(licensedEstablishment.longitude)
                    : '-'}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              State
            </Typography>
            <Typography variant="body1" pb={2}>
              {address?.state ? address.state : '-'}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              ZIP
            </Typography>
            <Typography variant="body1" pb={2}>
              {address?.postalCode ? address.postalCode : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
