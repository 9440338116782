import { useApiQuery } from 'hooks/useApiQuery'

export const useGetNumberGrandPrizeEntries = ({
  contestId,
}: {
  contestId?: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/contests/entries/contest/{contestId}/total-entries',
    queryKey: ['/admin/contests/entries/contest/{contestId}/total-entries'],
    queryArgs: { contestId },
  })
