import { useApiQuery } from 'hooks/useApiQuery'

export const useGetSweepstakeById = ({
  sweepstakeId,
}: {
  sweepstakeId?: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/contests/{id}',
    queryKey: ['/sweepstakes', sweepstakeId],
    queryArgs: { id: sweepstakeId },
  })
