import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { type InferType } from 'yup'
import { type SystemBannerSchema } from './SystemBannerSchema'

export const DismissableRadioGroup = () => {
  const { register } = useFormContext<InferType<typeof SystemBannerSchema>>()

  return (
    <Box>
      <InputLabel htmlFor={'dismissable-yes'} required>
        Allow Banner to be Dismissed
      </InputLabel>

      <RadioGroup>
        <FormControlLabel
          key={'yes'}
          label={'Yes'}
          control={
            <Radio
              id="dismissable-yes"
              {...register('systemBanner.dismissable')}
              value={'true'}
            />
          }
        />
        <FormControlLabel
          key={'no'}
          label={'No'}
          control={
            <Radio {...register('systemBanner.dismissable')} value={'false'} />
          }
        />
      </RadioGroup>
    </Box>
  )
}
