import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

const path = '/admin/contests/{id}/draw-winners'
const method = 'post'
interface Args {
  id: number
}

export const useDrawSweepstakeWinners = () => {
  const queryClient = useQueryClient()
  const action = useTypedApiClient({ path, method })

  return useMutation({
    mutationKey: [path],
    mutationFn: async (args: Args) => {
      await action(args)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [`/sweepstakes`] })
    },
  })
}
