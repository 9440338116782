import { create } from 'zustand'

export interface SnackbarState {
  setOpen: (key: string) => void
  setActiveTab: (path: string) => void
  activeTab: string | null
  'Client Management': boolean
  Contests: boolean
  Rewards: boolean
}

export const useSideNavCollapseStore = create<SnackbarState>((set, get) => ({
  setOpen: (key) => {
    const state = get()
    const isActiveTab = state.activeTab?.startsWith(key)

    // Close all drawers first
    const newState = {
      'Client Management':
        state.activeTab?.startsWith('/ClientManagement') ?? false,
      Contests: state.activeTab?.startsWith('/Contests') ?? false,
      Rewards: state.activeTab?.startsWith('/Rewards') ?? false,
      [key]:
        !state[
          key as keyof Omit<
            SnackbarState,
            'setOpen' | 'setActiveTab' | 'activeTab'
          >
        ] || isActiveTab,
    }

    set(newState)
  },
  setActiveTab: (path) => {
    // Determine which drawer should remain open based on the active tab
    const newState = {
      activeTab: path,
      'Client Management': path.startsWith('/ClientManagement'),
      Contests: path.startsWith('/Contests'),
      Rewards: path.startsWith('/Rewards'),
    }

    set(newState)
  },
  activeTab: null,
  'Client Management': false,
  Contests: false,
  Rewards: false,
}))
