import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

interface DenyLocationRequestData {
  sweepstakeDrawingId: number
}

interface UseDenyLocationRequestProps {
  onSuccess?: (data: undefined, variables: DenyLocationRequestData) => void
  onError?: (error: Error, variables: DenyLocationRequestData) => void
}

export const useDenyLocationRedrawRequest = ({
  onSuccess,
  onError,
}: UseDenyLocationRequestProps): UseMutationResult<
  any,
  Error,
  DenyLocationRequestData
> => {
  const redrawWinner = useTypedApiClient({
    path: '/admin/sweepstake-winners/denyLocationRedrawRequest/{sweepstakeDrawingId}',
    method: 'put',
  })

  const queryClient = useQueryClient()

  return useMutation<any, Error, DenyLocationRequestData>({
    mutationFn: async ({ sweepstakeDrawingId }: DenyLocationRequestData) => {
      const response = await redrawWinner({
        sweepstakeDrawingId,
      })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
