import { number } from 'yup'
import { validateLatitude, validateLongitude } from 'utils/util'

const castEmptyStringToUndefined = (
  currentValue: number,
  originalValue: string
) => {
  return originalValue === '' ? undefined : currentValue
}

const latitudeErrorMessage = 'Latitude must be a number between -90 and 90'
export const latitudeSchema = number()
  .transform(castEmptyStringToUndefined)
  .typeError(latitudeErrorMessage)
  .test(
    'is-valid-latitude',
    'Latitude must be a number between -90 and 90',
    validateLatitude
  )

const longitudeErrorMessage = 'Longitude must be a number between -180 and 180'
export const longitudeSchema = number()
  .transform(castEmptyStringToUndefined)
  .typeError(longitudeErrorMessage)
  .test(
    'is-valid-longitude',
    'Longitude must be a number between -180 and 180',
    validateLongitude
  )
