import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { useApiClient } from 'hooks/useApiClient'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'stores/useSnackbar'
import { object, string } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

interface FormData {
  name: string
}

const RewardItemSchema = object({
  name: string().required('Must enter a reward item name to create a reward'),
  description: string().optional(),
})

export const RewardItemsNewPage = () => {
  const { post } = useApiClient()
  const navigate = useNavigate()
  const setMessage = useSnackbar((state) => state.setMessage)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RewardItemSchema),
  })

  const mutate = useMutation({
    mutationKey: ['rewards/create'],
    mutationFn: async ({ name }: FormData) => {
      const { data } = await post('/admin/rewards', {
        params: { header: undefined },
        body: {
          name,
          type: 'LocationOffer',
          description: '',
        },
      })
      return data
    },
    onSuccess: (data) => {
      setMessage('Reward Item was successfully created.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate(`/Rewards/RewardItems/${data?.id}`)
    },
    onError: () => {
      setMessage('Error creating reward', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (mutate.isPending) {
    return <ActivityIndicator />
  }

  if (mutate.isError) {
    return <h1>An error occured.</h1>
  }

  const createRewardWrapper = handleSubmit((data) => {
    mutate.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title={'Create a New Reward Item'}
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backText="Reward Items"
          backPath="/Rewards/RewardItems"
          isSecondary={true}
        ></PageHeader>
      }
    >
      <form onSubmit={createRewardWrapper}>
        <TextField
          label="Reward Item Name"
          placeholder="Enter reward item name"
          id="reward-item-name-text-box"
          required
          {...register('name', { required: true })}
          error={isPresent(errors.name)}
        />
        {errors.name?.message && (
          <FormFieldErrorMessage message={errors.name.message} />
        )}
        <div className="pt-6 flex flex-row gap-6">
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/Rewards/RewardItems')
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Page>
  )
}
