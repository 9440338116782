import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Page } from 'components/Shared/Page'
import MenuItem from '@mui/material/MenuItem'
import { useSnackbar } from 'stores/useSnackbar'
import InputLabel from '@mui/material/InputLabel'
import { useApiClient } from 'hooks/useApiClient'
import FormControl from '@mui/material/FormControl'
import { type components } from 'api/playerPayback/api'
import { Grid, Button, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from 'components/Shared/PageHeader'
import { useMutation, useQuery } from '@tanstack/react-query'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, object } from 'yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

type OrganizationDTO = components['schemas']['FullOrganizationDTO']

const LicensedEstablishmentSchema = object({
  organizationId: string().required('Must select an organization'),
})

export const LicensedEstablishmentAssignOrganizationPage = () => {
  const params = useParams()
  const client = useApiClient()
  const navigate = useNavigate()
  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentSchema),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods
  const [organizationId, setOrganizationId] = useState('')
  const setMessage = useSnackbar((state) => state.setMessage)

  if (params.id === undefined) {
    throw new Error('Id must be provided in the url')
  }

  const licensedEstablishmentDetailsQuery = useQuery({
    queryKey: ['licensed-establishments-details', params.id],
    queryFn: async () => {
      const { data } = await client.get(
        '/admin/corporate-accounts/licensed-establishments/{id}',
        {
          params: {
            path: {
              id: Number(params.id),
            },
          },
        }
      )
      return data
    },
    enabled: params.id !== undefined,
  })

  const queryOrganizations = useQuery({
    queryKey: [
      'organizations',
      licensedEstablishmentDetailsQuery.data?.corporateAccountId,
    ],
    queryFn: async () => {
      const { data } = await client.get(
        '/admin/organizations/corporate-accounts/{id}',
        {
          params: {
            path: {
              id: Number(
                licensedEstablishmentDetailsQuery.data?.corporateAccountId
              ),
            },
          },
        }
      )
      return data
    },
    enabled:
      licensedEstablishmentDetailsQuery.data?.corporateAccountId !== undefined,
  })

  const assignLicensedEstablishmentMutation = useMutation<unknown, Error>({
    mutationFn: async () => {
      if (organizationId === null) {
        throw new Error('Organization must be provided')
      }

      return await client.patch(
        '/admin/organizations/{id}/licensed-establishments/{licensedEstablishmentId}',
        {
          params: {
            path: {
              id: Number(organizationId),
              licensedEstablishmentId: Number(params.id),
            },
          },
        }
      )
    },
    onSuccess: (response: any) => {
      if (response.error !== undefined) {
        setMessage(response.error.message, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
        return
      }

      setMessage('LE successfully associated', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })

      navigate(`/LicensedEstablishments/${params.id}`)
    },
    onError: (error: Error) => {
      setMessage(error.message, 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const handleChange = (event: SelectChangeEvent) => {
    setOrganizationId(event.target.value)
  }

  if (
    licensedEstablishmentDetailsQuery.isPending ||
    queryOrganizations.isPending ||
    assignLicensedEstablishmentMutation.isPending
  ) {
    return <ActivityIndicator />
  }

  if (
    licensedEstablishmentDetailsQuery.data?.corporateAccountId === undefined
  ) {
    throw new Error('Corporate account does not exist')
  }

  if (queryOrganizations.data === null) {
    throw new Error('Organizations do not exist')
  }

  return (
    <Page
      header={
        <PageHeader
          title={'Assign Organization'}
          backPath={`/LicensedEstablishments/${params.id}`}
          backText="Organization"
          isSecondary={true}
        ></PageHeader>
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={6}>
          <Typography variant="h6">
            Adding Organization to:{' '}
            <strong>{licensedEstablishmentDetailsQuery.data?.name}</strong>
          </Typography>
        </Grid>
      </Grid>
      <br />
      <form
        onSubmit={handleSubmit(() => {
          assignLicensedEstablishmentMutation.mutate()
        })}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                id="organization-select-label"
                sx={{
                  paddingRight: '4px',
                  backgroundColor: 'white',
                }}
              >
                Organization
              </InputLabel>
              <Select
                labelId="organization-select-label"
                id="organization-select"
                value={organizationId}
                label="Organization"
                {...register('organizationId', {
                  required: true,
                })}
                error={isPresent(errors.organizationId)}
                onChange={handleChange}
              >
                {queryOrganizations.data?.map((row: OrganizationDTO) => {
                  const name = `${row.id}-${row.name}`
                  return (
                    <MenuItem key={row.id} value={row.id}>
                      {`${name}`}
                    </MenuItem>
                  )
                })}
              </Select>
              {errors.organizationId?.message && (
                <FormFieldErrorMessage
                  message={errors.organizationId.message}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Button type="submit" variant="contained">
              Assign
            </Button>
          </Grid>
        </Grid>
      </form>
    </Page>
  )
}
