import { Box, Checkbox, Divider, Typography } from '@mui/material'
import {
  type GridValidRowModel,
  type MuiEvent,
  type DataGridProps,
} from '@mui/x-data-grid'
import { type GridApiCommunity } from '@mui/x-data-grid/internals'
import { colorPrimitives } from 'components/Theme'
import { compact, isNil, reject } from 'lodash'
import { isPresent } from '@jjvgaming/player-payback-library'
import { convertToArray } from 'utils/util'

const handleCheckBoxOnClick = <RowModelT extends GridValidRowModel>(
  rowSelectionModel: DataGridProps<RowModelT>['rowSelectionModel'],
  row: RowModelT,
  onRowSelectionModelChange?: DataGridProps<RowModelT>['onRowSelectionModelChange']
) => {
  if (isNil(onRowSelectionModelChange)) {
    return
  }

  if (convertToArray(rowSelectionModel).includes(row.id)) {
    const model = convertToArray(rowSelectionModel)
    onRowSelectionModelChange(compact(reject(model, (id) => id === row.id)), {})
    return
  }

  onRowSelectionModelChange([...convertToArray(rowSelectionModel), row.id], {})
}

const MobileRow = <RowModelT extends GridValidRowModel>({
  row,
  columns,
  checkboxSelection,
  rowSelectionModel,
  onRowSelectionModelChange,
}: Pick<
  DataGridProps<RowModelT>,
  | 'columns'
  | 'checkboxSelection'
  | 'rowSelectionModel'
  | 'onRowSelectionModelChange'
> & { row: RowModelT }) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const api = {} as GridApiCommunity

  return (
    <Box className="flex flex-row items-center justify-between">
      <div>
        {columns.map((col, index) => {
          if (col.type === 'checkboxSelection') {
            return null
          }

          const { field, valueGetter, valueFormatter } = col
          const variant = index === 0 ? 'h3' : 'body1'
          const color = index !== 0 ? '#292B2699' : '#2C2C27'

          const valueRow = ((): string => {
            if (isPresent(valueFormatter)) {
              return valueFormatter({
                value: row[field],
                field,
                api,
              })
            }
            if (isPresent(valueGetter)) {
              return valueGetter({
                row,
                api,

                // most of this is a guess...
                value: row.value,
                id: row.id,
                field,
                rowNode: row.node,
                colDef: { ...col, computedWidth: 0 },
                cellMode: 'view',
                hasFocus: false,
                tabIndex: 0,
              })
            }
            return row[field]
          })()

          return (
            <Typography
              variant={variant}
              color={color}
              key={`${String(row.id)}-${String(col.field)}`}
            >
              {valueRow}
            </Typography>
          )
        })}
      </div>
      {checkboxSelection && (
        <Checkbox
          checked={convertToArray(rowSelectionModel).includes(row.id)}
          onClick={() =>
            handleCheckBoxOnClick(
              rowSelectionModel ?? [],
              row,
              onRowSelectionModelChange
            )
          }
        />
      )}
    </Box>
  )
}

export const MobileDataTable = <RowModelT extends GridValidRowModel>({
  columns,
  rows,
  onRowClick,
  checkboxSelection,
  rowSelectionModel,
  onRowSelectionModelChange,
}: DataGridProps<RowModelT>) => {
  const headerName = checkboxSelection
    ? columns.find((col) => col.type === 'checkboxSelection')?.headerName
    : ''
  return (
    <Box
      border={checkboxSelection ? '1px solid rgba(41, 43, 38, 0.10)' : ''}
      borderBottom={0}
      borderRadius="4px"
    >
      {checkboxSelection && (
        <Box
          paddingX={2}
          paddingY={3}
          bgcolor={colorPrimitives.lightGray}
          borderBottom="1px solid rgba(41, 43, 38, 0.10)"
        >
          <Typography variant="h3">{headerName}</Typography>
        </Box>
      )}
      {rows.map((row) => (
        <Box
          key={row.id}
          bgcolor={
            convertToArray(rowSelectionModel).includes(row.id)
              ? 'rgba(204, 32, 39, 0.08)'
              : 'white'
          }
        >
          <Box
            padding={2}
            onClick={(
              event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
            ) => {
              if (checkboxSelection) {
                handleCheckBoxOnClick(
                  rowSelectionModel,
                  row,
                  onRowSelectionModelChange
                )
              } else if (!isNil(onRowClick)) {
                onRowClick({ row, id: row.id, columns }, event, {})
              }
            }}
          >
            <MobileRow
              key={`${String(row.id)}-row`}
              row={row}
              columns={columns}
              checkboxSelection={checkboxSelection}
              rowSelectionModel={checkboxSelection ? rowSelectionModel : []}
              onRowSelectionModelChange={onRowSelectionModelChange}
            />
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  )
}
