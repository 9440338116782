// usePutAccessLevels.ts

import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

interface AccessLevelsData {
  userId: number
  data: {
    licensedEstablishmentIds: number[]
    selectedCorporateAccountIds: number[]
    organizationIds: number[]
  }
}

interface AccessLevelsProps {
  onSuccess?: (
    data: any, // Adjust the type based on your actual data structure
    variables: AccessLevelsData
  ) => void
  onError?: (error: Error, variables: AccessLevelsData) => void
}

export const usePutAccessLevels = ({
  onSuccess,
  onError,
}: AccessLevelsProps): UseMutationResult<any, Error, AccessLevelsData> => {
  const put = useTypedApiClient({
    path: '/admin/licensed-establishments/users/{id}/locations',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, AccessLevelsData>({
    mutationFn: async ({ userId, data }: AccessLevelsData) => {
      const response = await put({ ...data, id: userId })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/licensed-establishments/users/{id}/locations',
          variables.userId,
        ],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
