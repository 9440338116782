import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const PatronDetailsTabs = ({
  currentTab,
  patronId,
}: {
  currentTab: any
  patronId: number
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Overview"
        value={`/PatronAccounts/${patronId}/Overview`}
        to={`/PatronAccounts/${patronId}/Overview`}
        component={Link}
      />
      <Tab
        label="Check-in History"
        value={`/PatronAccounts/${patronId}/CheckInHistory`}
        to={`/PatronAccounts/${patronId}/CheckInHistory`}
        component={Link}
      />
      <Tab
        label="Activity Log"
        value={`/PatronAccounts/${patronId}/ActivityLog`}
        to={`/PatronAccounts/${patronId}/ActivityLog`}
        component={Link}
      />
      <Tab
        label="Point Balance"
        value={`/PatronAccounts/${patronId}/PointBalance`}
        to={`/PatronAccounts/${patronId}/PointBalance`}
        component={Link}
      />
      <Tab
        label="Notes"
        value={`/PatronAccounts/${patronId}/Notes`}
        to={`/PatronAccounts/${patronId}/Notes`}
        component={Link}
      />
    </Tabs>
  )
}
