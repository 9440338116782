import { compact, flatten, some, chain } from 'lodash'
import { useMemo } from 'react'
import { useGetCorporateAccountsByIds } from './useGetCorporateAccountsByIds'
import { useGetOrganizationsByIds } from './useGetOrganizationsByIds'
import { useGetLicensedEstablishmentByIds } from './LicensedEstablishment/useGetLicensedEstablishmentByIds'

export const useGetCorporateAccountsOrganizationsAndLesFromLeList = ({
  leIds,
  enabled,
}: {
  leIds: number[]
  enabled: boolean
}) => {
  const lesQuery = useGetLicensedEstablishmentByIds({ ids: leIds, enabled })
  const lesQueryPending = useMemo(
    () => some(lesQuery, (x) => x.isPending),
    [lesQuery]
  )

  const licensedEstablishments = useMemo(
    () => compact(flatten(lesQuery.map((x) => x.data))),
    [lesQuery]
  )
  const caIds = useMemo(
    () =>
      chain(licensedEstablishments)
        .map((x) => x.corporateAccountId)
        .compact()
        .uniq()
        .value(),
    [licensedEstablishments]
  )
  const orgIds = useMemo(
    () =>
      chain(licensedEstablishments)
        .map((x) => x.organizationId)
        .compact()
        .uniq()
        .value(),
    [licensedEstablishments]
  )
  const caQuery = useGetCorporateAccountsByIds({
    ids: caIds,
    enabled: !lesQueryPending,
  })
  const corporateAccounts = useMemo(() => {
    const result = chain(caQuery)
      .map((x) => x.data)
      .flatten()
      .compact()
      .value()
    return result ?? []
  }, [caQuery])
  const orgQuery = useGetOrganizationsByIds({
    ids: orgIds,
    enabled: licensedEstablishments !== undefined,
  })
  const organizations = useMemo(() => {
    const result = chain(orgQuery)
      .map((x) => x.data)
      .flatten()
      .compact()
      .value()
    return result ?? []
  }, [orgQuery])

  return {
    isPending:
      some(lesQuery, (x) => x.isPending) ||
      some(caQuery, (x) => x.isPending) ||
      some(orgQuery, (x) => x.isPending),
    licensedEstablishments,
    organizations,
    corporateAccounts,
  }
}
