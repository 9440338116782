import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const path = '/admin/organizations/{licensedEstablishmentId}'
const method = 'patch'

interface UsePatchLicensedEstablishmentParams {
  licensedEstablishmentId: number
  onSuccess?: () => void
  onError?: () => void
}

export const useRemoveLicensedEstablishmentFromOrganization = ({
  licensedEstablishmentId,
  onSuccess,
  onError,
}: UsePatchLicensedEstablishmentParams) => {
  const queryClient = useQueryClient()
  const del = useTypedApiClient({ path, method })
  const mutate = useMutation({
    mutationKey: [
      '/admin/organizations/{licensedEstablishmentId}',
      licensedEstablishmentId,
    ],
    mutationFn: async (le: number) => {
      const { data } = await del({ licensedEstablishmentId: le })
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [path, licensedEstablishmentId],
      })
      onSuccess?.()
    },
    onError,
  })

  return mutate
}
