import { Page } from 'components/Shared/Page'
import { PatronsTable } from 'pages/Patrons/PatronsTable'
import { PageHeader } from 'components/Shared/PageHeader'
import { Stack } from '@mui/material'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type Patron } from 'types/api'
import { type ApiError } from 'openapi-typescript-fetch'
import { useDebounce } from '@uidotdev/usehooks'
import { SearchField } from 'components/SearchField'
import { minimumSearchLength } from 'utils/util'

export const PatronAccountsPage = () => {
  const [query, setQuery] = React.useState('')
  const debouncedQuery = useDebounce(query, 500)

  const getPatrons = useTypedApiClient({
    path: '/admin/patrons',
    method: 'get',
  })
  const searchPatrons = useTypedApiClient({
    path: '/admin/patrons/search',
    method: 'get',
  })
  const patronsQuery = useQuery<Patron[], ApiError>({
    queryKey: ['patrons', debouncedQuery],
    queryFn: async () => {
      if (debouncedQuery.length >= minimumSearchLength) {
        return (await searchPatrons({ query: debouncedQuery.trim() })).data
      } else {
        return (await getPatrons({})).data
      }
    },
  })

  return (
    <Page header={<PageHeader title="Patron Accounts"></PageHeader>}>
      <Stack
        direction={{ md: 'row', sm: 'column', xs: 'column' }}
        gap={{ md: 0, sm: 2, xs: 2 }}
        mb={{ md: 4, sm: 2, xs: 2 }}
        justifyContent={'space-between'}
      >
        <SearchField
          placeholder="Search Patrons"
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
      </Stack>

      <PatronsTable {...patronsQuery} />
    </Page>
  )
}
