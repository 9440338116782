import { Box, Button, MenuItem, Typography } from '@mui/material'
import { PatronCheckInsList } from './PatronCheckInsList'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { useNumberOfCheckIns } from './useNumberOfCheckIns'
import type { PatronCheckInRangeType } from 'types/api'

export const PatronCheckInsContainer = ({ patronId }: { patronId: number }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [range, setRange] = React.useState<PatronCheckInRangeType>('Days30')
  const numberOfCheckIns = useNumberOfCheckIns((state) => state.checkIns)
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuItemClose = (val: PatronCheckInRangeType) => {
    setRange(val)
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const setDropdownText = (key: PatronCheckInRangeType): string => {
    switch (key) {
      case 'Days30':
        return 'Last 30 Days'
      case 'Days90':
        return 'Last 90 Days'
      case 'Days365':
        return 'Last 365 Days'
      case 'CurrentQuarter':
        return 'Current Quarter'
      case 'PreviousQuarter':
        return 'Previous Quarter'
    }
  }

  return (
    <Box paddingTop={{ xs: 2, sm: 3 }}>
      <Box paddingBottom={3} paddingX={{ xs: 2, sm: 0 }}>
        <Box className="flex flex-col w-full sm:flex-row sm:justify-between sm:items-start sm:pb-4">
          <Box>
            <Typography variant={'h2'}>Check-in History</Typography>
          </Box>
          <Box
            flexDirection={'row'}
            display={'flex'}
            alignItems={'center'}
            columnGap={'10px'}
          >
            <Typography variant="h6">{numberOfCheckIns} check-ins</Typography>
            <Button
              id="activity-day-button"
              sx={{ display: 'flex', flexDirection: 'row' }}
              onClick={handleMenuClick}
            >
              <Typography variant="body-1">{setDropdownText(range)}</Typography>
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              )}
            </Button>
          </Box>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                marginTop: 0,
                minWidth: 340,
              },
            }}
          >
            <MenuItem onClick={() => handleMenuItemClose('Days30')}>
              30 Days
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClose('Days90')}>
              90 Days
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClose('Days365')}>
              365 Days
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClose('CurrentQuarter')}>
              Current Quarter
            </MenuItem>
            <MenuItem onClick={() => handleMenuItemClose('PreviousQuarter')}>
              Previous Quarter
            </MenuItem>
          </StyledMenu>
        </Box>
      </Box>

      <PatronCheckInsList patronId={patronId} range={range} />
    </Box>
  )
}
