import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

interface usePostRewardProgramProps {
  onSuccess?: (
    data: components['schemas']['FullRewardsProgramDTO'],
    variables: components['schemas']['RewardsProgramDTO']
  ) => void
  onError?: (
    error: Error,
    variables: components['schemas']['RewardsProgramDTO']
  ) => void
}

export const usePostRewardsProgram = ({
  onSuccess,
  onError,
}: usePostRewardProgramProps) => {
  const post = useTypedApiClient({
    path: '/admin/rewards-programs',
    method: 'post',
  })
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      rewardsProgramData: components['schemas']['RewardsProgramDTO']
    ) => {
      const { data } = await post(rewardsProgramData)
      return data
    },
    onSuccess: async (
      data: components['schemas']['FullRewardsProgramDTO'],
      variables: components['schemas']['RewardsProgramDTO']
    ) => {
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/rewards-programs/corporate-accounts/{id}',
          data.corporateAccountId,
        ],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/rewards-programs/licensed-establishments/{id}',
          data.licensedEstablishmentId,
        ],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/rewards-programs/organization/{id}',
          data.organizationId,
        ],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
