import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { Box, Button } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { type PatronNote } from 'types/api'
import { formatDateTime } from 'utils/util'

export const PatronNoteModal = ({
  isModalOpen,
  toggleModalIsOpen,
  row,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  row: PatronNote | undefined
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '45%',
        height: heightModal,
      }}
    >
      <Box>
        <ModalFullHeader
          toggleIsOpen={toggleModalIsOpen}
          title={`${row?.createdBy}`}
          subTitle={`${formatDateTime(row?.createdOn, 'MM-dd-yyyy p')}`}
        />
        <ModalFullBody heightModal={heightModal}>{row?.note}</ModalFullBody>
        <ModalFullFooter>
          <Button onClick={() => toggleModalIsOpen()}>Close</Button>
        </ModalFullFooter>
      </Box>
    </Modal>
  )
}
