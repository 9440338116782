import {
  BasicInformation,
  BasicInformationSchema,
} from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/BasicInformation'
import {
  Address,
  AddressSchema,
} from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/Address'
import {
  Contact,
  ContactSchema,
} from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/Contact'
import { useMutation } from '@tanstack/react-query'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import {
  HoursOfOperation,
  scheduleSchema,
} from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/HoursOfOperation'
import { TextField } from 'components/Shared/TextField'
import { Geolocation } from './Geolocation'
import { type InferType, array, number, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { SelectOrganization } from 'components/SelectOrganization'
import { useSnackbar } from 'stores/useSnackbar'
import { type ApiError } from 'openapi-typescript-fetch'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent, removePhoneMask } from '@jjvgaming/player-payback-library'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'

const LicensedEstablishmentSchema = object({
  corporateAccountId: number()
    .required('Corporate account is a required field')
    .transform((val) => (isNaN(val) ? undefined : val)),
  organizationId: number()
    .transform((val) => (isNaN(val) ? undefined : val))
    .nullable(),
  basicInfo: BasicInformationSchema,
  address: AddressSchema,
  contact: ContactSchema,
  schedules: array().of(scheduleSchema),
})

export const Form = () => {
  const post = useTypedApiClient({
    path: '/admin/corporate-accounts/licensed-establishments',
    method: 'post',
  })
  const navigate = useNavigate()
  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentSchema),
  })
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setFocus,
    formState: { errors },
  } = formMethods
  const corporateAccountId = watch('corporateAccountId')
  const { setMessage } = useSnackbar()

  const createLicensedEstablishmentMutation = useMutation<
    unknown,
    ApiError,
    InferType<typeof LicensedEstablishmentSchema>
  >({
    mutationFn: async ({
      corporateAccountId,
      contact,
      basicInfo,
      address,
      schedules,
      organizationId,
    }) => {
      const contactCast = {
        firstName: contact.firstName,
        lastName: contact.lastName,
        phoneNumber: removePhoneMask(contact.phoneNumber),
        email: contact.email,
      }
      const response = await post({
        active: true,
        corporateAccountId,
        type: basicInfo.leType,
        name: basicInfo.companyName,
        standardName: basicInfo.businessDisplayName,
        licenseNumber: basicInfo.licenseNumber,
        latitude: basicInfo.leLat,
        longitude: basicInfo.leLong,
        addresses: [address],
        contacts: [contactCast],
        schedules: schedules && Array.isArray(schedules) ? schedules : [],
        organizationId,
      })

      if (response) {
        navigate(`/LicensedEstablishments/${Number(response.data.id)}`)
      }
    },
    onSuccess: () => {
      setMessage('Successfully created licensed establishment', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
    onError: (error) => {
      if (
        error.data.message.match(
          /LicensedEstablishment with LicenseNumber .+? already exists\./
        ) != null
      ) {
        setError('basicInfo.licenseNumber', {
          message: 'This license number is already being used',
        })
        setFocus('basicInfo.licenseNumber')
      } else {
        alert(error.data.message)
      }
    },
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    const { schedules } = data
    const filteredSchedule = schedules?.filter(
      (item) => item.opening !== undefined && item.closing !== undefined
    )
    const newData = {
      ...data,
      schedules: filteredSchedule ?? [],
    }
    createLicensedEstablishmentMutation.mutate(newData)
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmitWrapper} noValidate>
        <Stack gap={6} maxWidth="520px">
          <BasicInformation />
          <Stack direction="column" gap={1}>
            <SelectCorporateAccount
              {...register('corporateAccountId', { required: true })}
              error={isPresent(errors.corporateAccountId)}
              label="Assign to Corporate Account"
              required
              fullWidth
              corporateAccountIdKey="corporateAccountId"
              placeholder="Select a corporate account"
            />
            {errors.corporateAccountId?.message && (
              <FormFieldErrorMessage
                message={errors.corporateAccountId.message}
              />
            )}
          </Stack>
          <SelectOrganization
            {...register('organizationId')}
            error={isPresent(errors.organizationId)}
            corporateAccountId={corporateAccountId}
            label="Assign to Organization"
            fullWidth
            required={false}
            showAll={false}
            placeholder="Select an organization"
          />
          <Address />
          <Geolocation />
          <Stack direction="column" gap={1}>
            <TextField
              {...register('basicInfo.licenseNumber')}
              error={isPresent(errors.basicInfo?.licenseNumber)}
              type="number"
              fullWidth
              required
              label="License Number"
            />
            {errors.basicInfo?.licenseNumber?.message && (
              <FormFieldErrorMessage
                message={errors.basicInfo.licenseNumber.message}
              />
            )}
          </Stack>
          <Contact />
          <HoursOfOperation />
          <Stack direction="row" spacing={2}>
            <Link to="/ClientManagement/LicensedEstablishments">
              <Button variant="outlined">Cancel</Button>
            </Link>
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmitWrapper}
              name="submitButton"
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
