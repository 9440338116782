import { MenuItem, Select } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const SweepstakeStatuses = [
  'Draft',
  'Enrollable',
  'Live',
  'InternalValidation',
  'WinnerValidation',
  'Closed',
]

export const StateSelect = () => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <Select
          {...field}
          labelId="Sweepstake.state"
          variant="outlined"
          fullWidth
          id="Sweepstake.state"
        >
          {SweepstakeStatuses.map((status) => (
            <MenuItem value={status} key={status}>
              {status.replace(/([A-Z])/g, ' $1').replace(/^ +/, '')}
            </MenuItem>
          ))}
        </Select>
      )}
      name="Sweepstake.state"
      control={control}
      defaultValue={defaultValues?.Sweepstake.state}
    />
  )
}
