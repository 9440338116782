import { Box, Button, Typography } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { colorPrimitives } from 'components/Theme'
import { useLayout } from 'hooks/useLayout'

interface SweepstakesValidateWinnerModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  onConfirm: () => void
  onCancel: () => void
  winnerName: string
  tierName: string
}

export const SweepstakesValidateWinnerModal = ({
  isModalOpen,
  toggleIsOpen,
  winnerName,
  tierName,
  onConfirm,
  onCancel,
}: SweepstakesValidateWinnerModalProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : ''
  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleIsOpen}
      sx={{
        width: { xs: '90vw', sm: '60%' },
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleIsOpen}
        title="Validate Winner"
        subTitle={`Grand Prize Winner (${tierName} Tier)`}
      />
      <ModalFullBody heightModal={heightModal}>
        <Typography fontWeight="bold" fontSize={'18px'} className="pb-6">
          Are you sure you want to validate this winner?
        </Typography>
        <Typography sx={{ color: colorPrimitives.gray70 }} className="pb-6">
          {`Selecting "Validate Winner" will mark this winner as validated.`}
        </Typography>
        <Box className="flex">
          <Box className="flex flex-row">
            <Typography fontWeight="bold">{`Grand Prize Winner `}</Typography>
            <Typography className="pl-1">{`(${tierName} Tier): ${winnerName}`}</Typography>
          </Box>
        </Box>
      </ModalFullBody>
      <ModalFullFooter>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Validate Winner
        </Button>
      </ModalFullFooter>
    </Modal>
  )
}
