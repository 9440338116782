import { type components } from 'src/api/playerPayback/api'
import { PageHeader } from 'components/Shared/PageHeader'
import { Box, Typography } from '@mui/material'
import { formatDateTime } from 'utils/util'
import { LicensedEstablishmentDetailsTabs } from './LicensedEstablishmentDetailsTabs'
import { Colors } from 'components/Theme'

interface LicensedEstablishmentDetailsHeaderProps {
  licensedEstablishment: components['schemas']['FullLicensedEstablishmentDTO']
  currentTab: string
}

export const LicensedEstablishmentDetailsHeader = ({
  licensedEstablishment,
  currentTab,
}: LicensedEstablishmentDetailsHeaderProps) => {
  const modifiedOn = licensedEstablishment.modifiedOn
  const formattedLastUpdatedOn = modifiedOn && `${formatDateTime(modifiedOn)}`

  return (
    <Box>
      <PageHeader
        title={licensedEstablishment.standardName ?? 'Licensed Establishment'}
        backText="LE Management"
        backPath="/ClientManagement/LicensedEstablishments"
        isSecondary={true}
        subtitle={`Last Updated: ${formattedLastUpdatedOn ?? 'N/A'}`}
        status={
          <Box className="flex items-center">
            <Box
              className="p-2 py-1 rounded-sm"
              bgcolor={
                licensedEstablishment.active
                  ? Colors.licensedEstablishmentStatus.background.active
                  : Colors.licensedEstablishmentStatus.background.inactive
              }
              color={
                licensedEstablishment.active
                  ? Colors.licensedEstablishmentStatus.text.active
                  : Colors.licensedEstablishmentStatus.text.inactive
              }
            >
              <Typography
                sx={{ textTransform: 'uppercase' }}
                variant="body-1"
                fontWeight={'bold'}
              >
                {licensedEstablishment.active ? 'ACTIVE' : 'INACTIVE'}
              </Typography>
            </Box>
          </Box>
        }
        tabs={
          <LicensedEstablishmentDetailsTabs
            licensedEstablishmentId={Number(licensedEstablishment.id)}
            currentTab={currentTab}
          />
        }
      />
    </Box>
  )
}
