import { Box, Switch } from '@mui/material'
import { usePutRewardsProgram } from 'hooks/api/usePutRewardsProgram'
import { useSnackbar } from 'stores/useSnackbar'
import { type RewardsProgram } from 'types/api'

interface SwitchRewardsProgramActiveProps {
  rewardsProgram: RewardsProgram
}

export const SwitchRewardsProgramActive = ({
  rewardsProgram,
}: SwitchRewardsProgramActiveProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const { mutate: putRewardsProgramMutation } = usePutRewardsProgram({
    onError: (_, variables) => {
      setSnackbarMessage(
        `Error changing reward program status for ${variables.rewardsProgramData.name}`,
        'error'
      )
    },
  })

  return (
    <Box>
      <Switch
        checked={rewardsProgram.active}
        onChange={(event: { target: { checked: boolean } }) => {
          putRewardsProgramMutation({
            id: Number(rewardsProgram.id),
            rewardsProgramData: {
              name: String(rewardsProgram.name),
              description: String(rewardsProgram.description),
              active: event.target.checked,
              type: rewardsProgram.type,
              corporateAccountId: rewardsProgram.corporateAccountId,
              licensedEstablishmentId: rewardsProgram.licensedEstablishmentId,
              organizationId: rewardsProgram.organizationId,
            },
          })
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Box>
  )
}
