import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { useApiClient } from 'hooks/useApiClient'

interface usePostRewardsCatalogRewardsBulkProps {
  rewardsCatalogId: number
  isDeleteRewardsCatalogRewards?: boolean
  onSuccess: () => void
  onError: () => void
}
export const usePostRewardsCatalogRewardsBulk = ({
  rewardsCatalogId,
  isDeleteRewardsCatalogRewards = false,
  onSuccess,
  onError,
}: usePostRewardsCatalogRewardsBulkProps) => {
  const queryClient = useQueryClient()
  const { post } = useApiClient()

  const mutation = useMutation({
    mutationKey: ['/admin/rewards-catalog-rewards/bulk'],
    mutationFn: async (
      rewardsCatalogRewardsData: Array<
        components['schemas']['RewardsCatalogRewardDTO']
      >
    ) => {
      const { data } = await post('/admin/rewards-catalog-rewards/bulk', {
        params: {
          query: {
            isDeleteRewardsCatalogRewards,
          },
        },
        body: rewardsCatalogRewardsData,
      })
      return data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-catalogs', rewardsCatalogId],
      })
      onSuccess()
    },
    onError,
  })
  return mutation
}
