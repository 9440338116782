import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetSweepstakes = () => {
  const get = useTypedApiClient({
    path: '/admin/contests/type/{contestType}',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/sweepstakes'],
    queryFn: async () => {
      const { data } = await get({ contestType: 'Sweepstake' })
      return data
    },
  })
}
