import { Box } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetCorporateAccountById } from 'hooks/api/useGetCorporateAccountById'
import { CorporateAccountDetailHeader } from '../CorporateAccountDetailsHeader'
import { LicensedEstablishmentUsersSection } from './LicensedEstablishmentUsersSection'
import { ErrorIndicator } from 'components/ErrorIndicator/ErrorIndicator'

export const CorporateAccountJJConnectUsersPage = () => {
  const params = useParams()
  const corporateAccountId = Number(params.id)

  const {
    isPending: isPendingCorporateAccount,
    isError: isErrorCorporateAccount,
    data: corporateAccount,
  } = useGetCorporateAccountById({ corporateAccountId })

  if (isPendingCorporateAccount) {
    return <ActivityIndicator />
  }

  if (isErrorCorporateAccount || isNil(corporateAccount)) {
    return <ErrorIndicator />
  }

  if (!corporateAccount.id) {
    return <Box>Corporate Account Id must not be null</Box>
  }

  return (
    <Page
      header={
        <CorporateAccountDetailHeader
          corporateAccount={corporateAccount}
          currentTab={`/CorporateAccounts/${corporateAccountId}/CorporateAccountLEUsers`}
        />
      }
    >
      <LicensedEstablishmentUsersSection corporateAccount={corporateAccount} />
    </Page>
  )
}
