import { Box, Button, Grid, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useUserData } from 'hooks/api/useUserData'
import { useNumberParam } from 'hooks/useNumberParam'
import { isNil, partition, remove } from 'lodash'
import { grey } from '@mui/material/colors'
import { useMemo, useState } from 'react'
import CheckIcon from 'assets/check.svg'
import CloseIcon from 'assets/close.svg'
import { EditLeUserRoleAndPermissionsModal } from './EditLeUserRoleAndPermissionsModal'
import { LeConnectUserDetailsHeader } from './LeConnectUserDetailsHeader'

const formatPermissionKey = (key: string): string => {
  switch (key) {
    case 'patronsAccountsPermission':
      return 'Patron Accounts'
    case 'locationsPermission':
      return 'Locations'
    case 'leUsersPermission':
      return 'J&J Connect Users'
    case 'liveAtLocationViewPermission':
      return 'Attendant'
    case 'reportsFinancialPermission':
      return 'Financial Reports'
    case 'reportsNonFinancialPermission':
      return 'Non Financial Reports'
    case 'rewardsPermission':
      return 'Rewards'
    case 'promotionsPermission':
      return 'Specials & Events'
    case 'sweepstakesPermission':
      return 'Sweepstakes'
  }
  return key
}

const getTypeLabel = (type: string | undefined) => {
  switch (type) {
    case 'LimitedLiveAtLocation':
      return 'Limited'
    case 'SalesAccountManager':
      return 'Sales Account Manager'
    default:
      return type ?? ''
  }
}

export const LEConnectUserDetailRoleAndPermissionPage = () => {
  const userId = useNumberParam('id')
  const [isModelOpen, setIsModelOpen] = useState(false)
  const toggleOpen = () => setIsModelOpen((prev) => !prev)
  const { isPending, isError, userName, formattedUpdatedAt, user } =
    useUserData({
      userId,
    })

  const permissions = useMemo(() => {
    if (isNil(user)) {
      return { grantedPermissions: [], notGrantedPermissions: [] }
    }
    const permissions = remove(Object.entries(user), ([key]) => {
      return key.includes('Permission')
    })
    const result = partition(permissions, ([, value]) => value)
    return {
      grantedPermissions: result[0].map(([k]) => formatPermissionKey(k)),
      notGrantedPermissions: result[1].map(([k]) => formatPermissionKey(k)),
    }
  }, [user])

  if (isPending || isNil(user)) {
    return <ActivityIndicator />
  }

  if (isError) {
    return (
      <Page
        header={
          <PageHeader
            title={``}
            isSecondary={true}
            backPath="/ClientManagement/LEConnectUsers"
            backText="J&J Connect Users"
            subtitle={`Last updated:`}
          ></PageHeader>
        }
      >
        <Typography>Something went wrong</Typography>
      </Page>
    )
  }

  return (
    <Page
      header={
        <LeConnectUserDetailsHeader
          user={user}
          formattedUpdatedAt={formattedUpdatedAt}
          userName={userName}
          currentTab="RolesAndPermissions"
        />
      }
    >
      <Box>
        <EditLeUserRoleAndPermissionsModal
          isModalOpen={isModelOpen}
          onClose={toggleOpen}
          leUser={user}
        />
        <Box className="flex flex-row items-center justify-between mb-4">
          <Typography variant="h2">Roles & Permissions</Typography>
          <Button
            variant={'text'}
            onClick={() => {
              toggleOpen()
            }}
          >
            Edit
          </Button>
        </Box>
        <Grid container border={`solid 1px ${grey[300]}`} borderRadius={'8px'}>
          <Grid item p={'24px'} md={4} xs={12}>
            <Typography variant="body-2" className="text-secondary" pb={'8px'}>
              Role
            </Typography>
            <Typography>{getTypeLabel(user.type)}</Typography>
          </Grid>
          <Grid item p={'24px'} md={4} xs={12}>
            <Typography variant="body-2" className="text-secondary" pb={'8px'}>
              Permissions Granted
            </Typography>
            {permissions.grantedPermissions.map((x) => {
              return (
                <Box
                  key={`granted-permission-${x}`}
                  className="flex flex-row pt-2"
                >
                  <img src={CheckIcon} alt="checkmark"></img>
                  <Typography>{x}</Typography>
                </Box>
              )
            })}
          </Grid>
          <Grid item p={'24px'} md={4} xs={12}>
            <Typography variant="body-2" className="text-secondary" pb={'8px'}>
              Permissions Not Granted
            </Typography>
            {permissions.notGrantedPermissions.map((x) => {
              return (
                <Box
                  key={`not-granted-permission-${x}`}
                  className="flex flex-row pt-2"
                >
                  <img src={CloseIcon} alt="close"></img>
                  <Typography>{x}</Typography>
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}
