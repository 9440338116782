import { colorPrimitives } from 'components/Theme'

export const ErrorIndicator = ({
  message = 'Something went wrong',
  style,
  color = colorPrimitives.black,
}: {
  message?: string
  style?: string
  color?: string
}) => {
  return (
    <div>
      <p className={style} style={{ color }}>
        {message}
      </p>
    </div>
  )
}
