import { SelectLicensedEstablishment } from 'components/SelectLicensedEstablishment'
import { type InferType } from 'yup'
import { type CampaignSchema } from './CampaignSchema'

export const LocationSelect = () => {
  return (
    <SelectLicensedEstablishment<InferType<typeof CampaignSchema>>
      label="Location"
      required
      fullWidth
      hideCard
      licensedEstablishmentIdKey="licensedEstablishmentId"
    />
  )
}
