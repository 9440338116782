import { Typography } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { isNil } from 'lodash'
import type { Patron } from 'types/api'
import { PatronAccountDetailPageSubtitle } from './PatronAccountDetailPageSubtitle'
import { PatronDetailsTabs } from './PatronDetailsTabs'

export const PatronDetailsHeader = ({
  patron,
  currentTab,
  children,
}: {
  patron: Patron
  currentTab: string
  children?: React.ReactNode
}) => {
  if (!patron.id) {
    return <Typography>Patron Id must not be null.</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title={
            isNil(patron.firstName) || isNil(patron.lastName)
              ? 'Unknown'
              : `${patron.firstName} ${patron.lastName}`
          }
          backText="Patron Accounts"
          backPath="/PatronAccounts"
          isSecondary={true}
          customSubTitle={
            <PatronAccountDetailPageSubtitle patronAccount={patron} />
          }
          tabs={
            <PatronDetailsTabs currentTab={currentTab} patronId={patron.id} />
          }
        />
      }
    >
      {children}
    </Page>
  )
}
