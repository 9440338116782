import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import type { RewardsProgram } from 'types/api'

export const useGetRewardProgramById = ({
  rewardProgramId,
}: {
  rewardProgramId?: number
}): UseQueryResult<RewardsProgram> => {
  return useApiQuery({
    path: '/admin/rewards-programs/{id}',
    method: 'get',
    queryKey: ['/admin/rewards-programs', rewardProgramId],
    queryArgs: {
      id: rewardProgramId,
    },
  })
}
