import { isPresent } from '@jjvgaming/player-payback-library'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useGetAllCampaigns } from 'hooks/api/useGetAllCampaigns'
import { DataTable } from 'components/Shared/DataTable'
import { type GridRenderCellParams, type GridColDef } from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  type CampaignMessageStatus,
  dataTablePromotionDateFormatter,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { Colors } from 'components/Theme'
import { DeleteMessageModal } from './DeleteMessage/DeleteMessageModal'
import { DeleteMessageRenderCell } from './DeleteMessage/DeleteMessageRenderCell'
import { isNil } from 'lodash'
import { type Campaign } from 'types/api'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'

export const CommunicationsPage = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [messageRowData, setMessageRowData] = useState<Campaign | null>()
  const { isMobile } = useLayout()
  const useGetAllCampaignsQuery = useGetAllCampaigns()
  const [filteredData, setFilteredData] = useState<Campaign[]>()

  const handleFilteredData = (filteredData: Campaign[]) => {
    setFilteredData(filteredData)
  }

  // Function to check if current time is within 5 minutes or less of scheduled time
  function within5MinutesOfScheduledTime(
    deliveryDate: string | number | Date | null | undefined
  ) {
    // Calculate difference in milliseconds between current time and scheduled time
    if (deliveryDate) {
      const timeDifferenceMillis =
        Number(new Date(deliveryDate)) - Number(new Date())

      // Check if the difference is 5 minutes or less (300,000 milliseconds)
      return timeDifferenceMillis <= 300000
    }
  }

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
    React.useCallback(
      (event) => {
        setAnchorEl(event.currentTarget)
        return false
      },
      [setAnchorEl]
    )

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])
  const navigate = useNavigate()

  const getColumns = (isMobile: boolean): GridColDef[] => [
    {
      field: '__menu__',
      headerName: '',
      align: 'left',
      renderCell: (params: GridRenderCellParams<Campaign>) => {
        const within5Minutes = within5MinutesOfScheduledTime(
          params.row.deliveryDate
        )

        return params.row.status === 'Sent'
          ? null
          : !within5Minutes && (
              <DeleteMessageRenderCell
                params={params}
                setMessageRowData={setMessageRowData}
              />
            )
      },
    },
    { field: 'subject', headerName: 'Subject Line', minWidth: 225, flex: 1 },
    { field: 'type', headerName: 'Message Type', minWidth: 145, flex: 1 },
    {
      field: 'deliveryDate',
      headerName: 'Date Scheduled',
      minWidth: 145,
      flex: 1,
      valueFormatter: dataTablePromotionDateFormatter(isMobile, 'Start'),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => {
        const promoStatusLabel = (
          status: CampaignMessageStatus,
          isMobile: boolean
        ) => {
          switch (status) {
            case 'Scheduled':
              return isMobile ? 'Status: SCHEDULED' : 'SCHEDULED'
            case 'Sent':
              return isMobile ? 'Status: SENT' : 'SENT'
            default:
              return ''
          }
        }

        const status = params.row.status
        return promoStatusLabel(status, isMobile)
      },
      renderCell: (params) => {
        const status = params.value
        const campaignMessageBackgroundColor: Record<string, string> = {
          SCHEDULED: Colors.campaignMessageStatus.background.Scheduled,
          SENT: Colors.campaignMessageStatus.background.Sent,
        }
        const campaignMessageTextColor: Record<string, string> = {
          SCHEDULED: Colors.campaignMessageStatus.text.Scheduled,
          SENT: Colors.campaignMessageStatus.text.Sent,
        }

        return (
          <Box
            style={{
              backgroundColor: campaignMessageBackgroundColor[status],
              color: campaignMessageTextColor[status],
              fontWeight: 'bold',
              borderRadius: '4px',
              padding: '6px',
              display: 'inline-block',
            }}
          >
            {status}
          </Box>
        )
      },
    },
    {
      field: 'body',
      headerName: 'Message Content',
      minWidth: 235,
      flex: 1,
      renderCell: (params) => {
        const maxCharacters = 50
        const content = params.row.body || ''
        const truncatedContent =
          content.length > maxCharacters
            ? String(content.substring(0, maxCharacters)) + ' ...'
            : content
        return (
          <p title={content} className="">
            {truncatedContent}
          </p>
        )
      },
    },
  ]

  return (
    <Page
      header={
        <PageHeader
          title="Communications"
          actionButton={
            <>
              <Button
                id="message-button"
                variant="contained"
                component={Link}
                onClick={handleClick}
                aria-controls={isPresent(anchorEl) ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isPresent(anchorEl) ? 'true' : undefined}
                to=""
                endIcon={<KeyboardArrowDownIcon />}
              >
                Create New Message
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isPresent(anchorEl)}
                onClose={handleClose}
                className="mt-2"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => navigate('/Communications/Message/New')}
                >
                  Message Center
                </MenuItem>
                <MenuItem
                  onClick={() => navigate('/Communications/SystemBanner/New')}
                >
                  System Banner
                </MenuItem>
              </Menu>
            </>
          }
        />
      }
    >
      {useGetAllCampaignsQuery.isPending && <ActivityIndicator />}
      {useGetAllCampaignsQuery.isError && <p>An error occured.</p>}
      {!useGetAllCampaignsQuery.isError && useGetAllCampaignsQuery.data && (
        <>
          <GridSearchField<'CampaignDto'>
            placeholder="Search Communications"
            data={useGetAllCampaignsQuery.data ?? []}
            handleFilteredData={handleFilteredData}
            fullWidth
          />
          <DataTable
            data-testid="data-table"
            sort="desc"
            sortFieldName={'deliveryDate'}
            rowHeight={65}
            columns={getColumns(isMobile)}
            rows={filteredData ?? useGetAllCampaignsQuery.data}
          />
        </>
      )}

      <DeleteMessageModal
        data={messageRowData}
        isModalOpen={!isNil(messageRowData)}
        toggleIsOpen={() => setMessageRowData(undefined)}
      />
    </Page>
  )
}
