import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetRewardProgramByCorporateAccountId = ({
  corporateAccountId,
  enabled = true,
}: {
  corporateAccountId: number
  enabled?: boolean
}): UseQueryResult<Array<components['schemas']['FullRewardsProgramDTO']>> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-programs/corporate-accounts/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: [
      '/admin/rewards-programs/corporate-accounts/{id}',
      corporateAccountId,
    ],
    queryFn: async () => {
      const { data } = await get({ id: corporateAccountId })
      return data
    },
    enabled,
  })
}
