import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import './index.css'

import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig.ts'

const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((event: any) => {
  if (
    [
      EventType.LOGIN_SUCCESS,
      EventType.ACQUIRE_TOKEN_SUCCESS,
      EventType.SSO_SILENT_SUCCESS,
    ].includes(event.eventType) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
)
