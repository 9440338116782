import { useState } from 'react'
import { Button, Typography, Box } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { type RewardsProgram } from 'types/api'
import { NumberOfRedemptionsPerPatronPerDayDetails } from './NumberOfRedemptionsPerPatronPerDay'
import { NumberOfRedemptionsPerPatronPerDaySettingsModal } from './NumberOfRedemptionsPerPatronPerDaySettingsModal'

interface RewardProgramSettingsSectionProps {
  rewardsProgram: RewardsProgram
}

export const RewardProgramSettingsSection = ({
  rewardsProgram,
}: RewardProgramSettingsSectionProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = async () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          pr: { xs: '0px', sm: '16px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '16px',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Number of Redemptions Per Patron Per Day
          </Typography>
        </Box>
        <Button
          variant="text"
          onClick={() => {
            setIsModalOpen(!isModalOpen)
          }}
          sx={{
            maxWidth: { xs: '32px', sm: 'auto' },
            minWidth: 'auto',
            maxHeight: { xs: '20px', sm: 'auto' },
          }}
        >
          <Typography
            color={colorPrimitives.redGaming}
            variant="body-1"
            fontWeight="bold"
          >
            Edit
          </Typography>
        </Button>
      </Box>
      <NumberOfRedemptionsPerPatronPerDayDetails
        rewardsProgram={rewardsProgram}
      />
      <NumberOfRedemptionsPerPatronPerDaySettingsModal
        rewardsProgram={rewardsProgram}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        toggleIsOpen={toggleModalIsOpen}
      />
    </Box>
  )
}
