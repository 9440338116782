/* eslint-disable @typescript-eslint/no-misused-promises */
// There's a slight mismatch between the callbacks RHF provides
// and the ones MUI expects, so we need to wrap the callback
import { Box, InputLabel } from '@mui/material'
import { DateField, type DateFieldProps } from '@mui/x-date-pickers'
import { isNil } from 'lodash'
import { forwardRef, type ForwardedRef } from 'react'
import { type ChangeHandler } from 'react-hook-form'

export const HookableDateField = forwardRef(
  <TDate extends Date>(
    {
      onChange,
      name,
      required,
      label,
      ...otherProps
    }: Omit<DateFieldProps<TDate>, 'onChange'> & { onChange: ChangeHandler },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const onChangeWrapper: DateFieldProps<TDate>['onChange'] = async (
      value
    ) => {
      const valueAsString = isNil(value) ? '' : value.toString()
      await onChange({ target: { value: valueAsString } })
    }

    return (
      <Box>
        <InputLabel required={required} htmlFor={name}>
          {label}
        </InputLabel>
        <DateField
          onChange={onChangeWrapper}
          fullWidth
          name={name}
          id={name}
          ref={ref}
          {...otherProps}
        />
      </Box>
    )
  }
)

HookableDateField.displayName = 'HookableDateField'
