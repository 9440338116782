import { useApiClient } from 'hooks/useApiClient'
import { useQuery } from '@tanstack/react-query'
import { type OpReturnType } from 'openapi-typescript-fetch'
import { type paths } from 'api/playerPayback/api'
import { isNil } from 'lodash'
import { isPresent } from '@jjvgaming/player-payback-library'

const route = '/admin/licensed-establishments/corporate-accounts/{id}'

export type LicensedEstablishmentGetResponse = OpReturnType<
  paths[typeof route]['get']
>

export const useGetLicensedEstablishmentsByCorporateAccountId = ({
  corporateAccountId,
  enabled = true,
}: {
  corporateAccountId?: number
  enabled?: boolean
}) => {
  const client = useApiClient()
  return useQuery({
    queryKey: [
      'licensed-establishments-by-corporate-account-id',
      corporateAccountId,
    ],
    queryFn: async () => {
      if (isNil(corporateAccountId)) {
        throw new Error("corporateAccountId can't be nil")
      }

      const { data } = await client.get(route, {
        params: {
          path: {
            id: corporateAccountId,
          },
        },
      })
      return data
    },
    enabled: enabled && isPresent(corporateAccountId),
  })
}
