import { useApiQuery } from 'hooks/useApiQuery'

export const useGetPassportGames = () =>
  useApiQuery({
    path: '/admin/contests/type/{contestType}',
    method: 'get',
    queryKey: ['/contests/type/{contestType}'],
    queryArgs: {
      contestType: 'PassportGame',
    },
  })
