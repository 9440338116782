import { Button, Link } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { Link as RouterLink } from 'react-router-dom'
import { PageHeader } from 'components/Shared/PageHeader'
import { RewardItemsTable } from 'components/RewardItem'

export const RewardItemsPage = () => {
  return (
    <Page
      header={
        <PageHeader
          title="Reward Items"
          eyebrow="REWARDS"
          actionButton={
            <Link to="/Rewards/RewardItems/New" component={RouterLink}>
              <Button variant="contained" fullWidth>
                Create New Reward Item
              </Button>
            </Link>
          }
        />
      }
    >
      <RewardItemsTable />
    </Page>
  )
}
