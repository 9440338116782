import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import {
  type ApiError,
  type ApiResponse,
  arrayRequestBody,
} from 'openapi-typescript-fetch'

const path = '/admin/organizations/{id}/licensed-establishments'
const method = 'patch'

type UsePatchLicensedEstablishmentsToOrganizationProps = UseMutationOptions<
  ApiResponse<boolean>,
  ApiError,
  { organizationId: number; leIds: number[] }
>

export const usePatchLicensedEstablishmentsToOrganization = ({
  onSuccess,
  onError,
}: UsePatchLicensedEstablishmentsToOrganizationProps) => {
  const patch = useTypedApiClient({ path, method })

  return useMutation({
    mutationFn: async ({
      organizationId,
      leIds,
    }: {
      organizationId: number
      leIds: number[]
    }) => {
      return await patch(arrayRequestBody(leIds, { id: organizationId }))
    },
    onSuccess: async (data, vars, context) => {
      await onSuccess?.(data, vars, context)
    },
    onError,
  })
}
