import {
  type Configuration,
  LogLevel,
  type PopupRequest,
} from '@azure/msal-browser'

const loggerCallback = (
  level: LogLevel,
  message: string,
  containsPii: boolean
) => {
  if (containsPii) {
    return
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message)
      return
    case LogLevel.Info:
      console.info(message)
      return
    case LogLevel.Verbose:
      console.debug(message)
      return
    case LogLevel.Warning:
      console.warn(message)
  }
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_ADMIN_MSAL_CONFIG_CLIENTID,
    authority: import.meta.env.VITE_ADMIN_MSAL_CONFIG_AUTHORITY,
    redirectUri: window.location.origin + '/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: { loggerCallback },
  },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: PopupRequest = {
  scopes: [import.meta.env.VITE_ADMIN_MSAL_CONFIG_SCOPE],
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
