import { type SystemBannerSchema } from 'pages/Communications/CommunicationsNew/SystemBanner/SystemBannerSchema'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useApiMutation } from 'hooks/useApiMutation'
import { formatISO } from 'date-fns'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate } from 'react-router-dom'

const buildStartDate = (scheduled: boolean, startDate: Date | undefined) => {
  if (scheduled && isPresent(startDate)) {
    return formatISO(startDate)
  }

  return formatISO(new Date())
}

const buildEndDate = (scheduled: boolean, endDate: Date | undefined) => {
  if (scheduled && isPresent(endDate)) {
    return formatISO(endDate)
  }

  return null
}

export const usePostSystemBanner = () => {
  const post = useTypedApiClient({
    path: '/admin/system-banners',
    method: 'post',
  })
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  return useApiMutation<typeof post, typeof SystemBannerSchema>({
    mutationFn: async (formData) => {
      const { systemBanner } = formData
      return await post({
        type: systemBanner.type,
        platformType: formData.platformType,
        dismissable: systemBanner.dismissable,
        content: systemBanner.content,
        title: systemBanner.title,
        startDate: buildStartDate(
          systemBanner.schedulingOption === 'Schedule a Time',
          systemBanner.startDate
        ),
        endDate: buildEndDate(
          systemBanner.schedulingOption === 'Schedule a Time',
          systemBanner.endDate
        ),
      })
    },
    onError: (error) => {
      alert(error.data.message)
    },
    onSuccess: () => {
      setMessage('System banner created successfully', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate('/Communications')
    },
  })
}
