import { UploadingFile } from './UploadingFile'
import { type StateFileUpload } from 'types/fileUpload'
import { UploadButton } from 'components/FileUploader/UploadButton'
import { Box, type SxProps, type Theme, Typography } from '@mui/material'

interface UploaderDisplayerProps {
  files: File[]
  addFiles: (files: File[]) => void
  removeFile: (file: File) => void
  toggleStateFilesUpload: (value: StateFileUpload) => void
  showImages: boolean
  textToShowButton?: string
  informationFileUpload?: string
  maxSizeAllowedUpload: number
  fileFormatsAllowedUpload: string
}

export const UploaderDisplayer = ({
  files,
  addFiles,
  removeFile,
  toggleStateFilesUpload,
  showImages,
  textToShowButton,
  informationFileUpload,
  maxSizeAllowedUpload,
  fileFormatsAllowedUpload,
}: UploaderDisplayerProps) => {
  const commonSxBox: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '16px',
    borderWidth: showImages ? 1 : 3,
    borderColor: 'grey.300',
    borderRadius: 2,
    borderStyle: showImages ? 'solid' : 'dotted',
    px: '16px',
    py: '16px',
    mt: '21px',
    width: '100%',
  }

  return (
    <Box sx={commonSxBox}>
      {!showImages ? (
        <UploadButton
          addFiles={addFiles}
          textToShow={textToShowButton}
          informationFile={informationFileUpload}
          fileFormatsAllowed={fileFormatsAllowedUpload}
        />
      ) : (
        <>
          <Typography variant="body-3" pt={2}>
            Do not close this window. Progress will not be saved.
          </Typography>
          <Box>
            {files.map((file) => {
              return (
                <UploadingFile
                  key={file.name}
                  file={file}
                  toggleStateFilesUpload={toggleStateFilesUpload}
                  fileFormatsAllowed={fileFormatsAllowedUpload}
                  maxSizeAllowed={maxSizeAllowedUpload}
                  removeFile={removeFile}
                />
              )
            })}
          </Box>
        </>
      )}
    </Box>
  )
}
