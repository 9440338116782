import { InputLabel, Stack, TextField } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { type InferType, object, string } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

export const AddressSchema = object({
  address1: string().required('Address is required'),
  address2: string(),
  city: string().required('City is required'),
  state: string().required('State is required'),
  postalCode: string().required('Zip Code is required'),
})

export type AddressForm = InferType<typeof AddressSchema>

export const Address = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ address: AddressForm }>()

  return (
    <Stack gap={2}>
      <InputLabel required htmlFor={'address.address1'}>
        Establishment Address
      </InputLabel>

      <Stack direction="column" gap={1}>
        <TextField
          {...register('address.address1')}
          error={isPresent(errors.address?.address1)}
          required
          fullWidth
          placeholder="Address Line 1"
        />
        {errors.address?.address1?.message && (
          <FormFieldErrorMessage message={errors.address?.address1.message} />
        )}
      </Stack>
      <TextField
        {...register('address.address2')}
        error={isPresent(errors.address?.address2)}
        fullWidth
        placeholder="Address Line 2"
      />
      <Stack direction="row" gap={2}>
        <Stack direction="column" gap={1}>
          <TextField
            {...register('address.city')}
            error={isPresent(errors.address?.city)}
            required
            sx={{ flex: 3 }}
            placeholder="City"
          />
          {errors.address?.city?.message && (
            <FormFieldErrorMessage message={errors.address?.city.message} />
          )}
        </Stack>
        <Stack direction="column" gap={1}>
          <TextField
            {...register('address.state')}
            error={isPresent(errors.address?.state)}
            required
            sx={{ flex: 1 }}
            placeholder="State"
          />
          {errors.address?.state?.message && (
            <FormFieldErrorMessage message={errors.address.state.message} />
          )}
        </Stack>
        <Stack direction="column" gap={1}>
          <TextField
            {...register('address.postalCode')}
            error={isPresent(errors.address?.postalCode)}
            required
            sx={{ flex: 1 }}
            placeholder="Zip Code"
          />
          {errors.address?.postalCode?.message && (
            <FormFieldErrorMessage
              message={errors.address.postalCode.message}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
