import {
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { usePutReward } from 'hooks/api/usePutReward'
import { useLayout } from 'hooks/useLayout'
import { useState } from 'react'
import { useSnackbar } from 'stores/useSnackbar'
import { type Reward } from 'types/api'
import { formatTypeName } from 'utils/util'

export const EditRewardItem = ({
  isModalOpen,
  toggleModalIsOpen,
  reward,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  reward: Reward
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'
  const alphanumericRegex = /^\w*$/
  const [itemType, setItemType] = useState(reward.type || 'LocationOffer')
  const [vendorItemName, setVendorItemName] = useState(
    reward.vendorItemName ?? ''
  )
  const [rewardItemName, setRewardItemName] = useState(reward.name)

  const [sku, setSKU] = useState(reward.sku ?? '')
  const [skuError, setSKUError] = useState('')
  const [description, setDescription] = useState(reward.description ?? '')
  const [messageError, setMessageError] = useState('')
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const handleRewardNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRewardItemName(e.target.value)
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    setItemType(e.target.value as Reward['type'])
  }
  const handleVendorItemNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setVendorItemName(e.target.value)
  }
  const handleSkuChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!alphanumericRegex.test(e.target.value)) {
      setSKUError('Alphanumeric characters only.')
    } else {
      setSKUError('')
    }
    setSKU(e.target.value)
  }
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(e.target.value)
  }

  const putRewardMutation = usePutReward({
    id: Number(reward.id),
    onSuccess: () => {
      setSnackbarMessage('Reward item successfully updated')
      toggleModalIsOpen()
    },
    onError: (error, _) => {
      setMessageError(error.message)
    },
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (skuError) {
      setMessageError('Please correct the errors below.')
    } else {
      setMessageError('')
      putRewardMutation.mutate({
        name: rewardItemName,
        type: itemType,
        vendorItemName,
        sku,
        description,
      })
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModalIsOpen}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <form onSubmit={handleSubmit}>
        <ModalFullHeader toggleIsOpen={toggleModalIsOpen} title={reward.name} />
        <ModalFullBody heightModal={heightModal}>
          <div className="container flex flex-col">
            <Typography color={'red'}>{messageError}</Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
              <Typography>
                Reward Item Name <span style={{ color: '#CC2027' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                placeholder="Reward Item Name"
                value={rewardItemName}
                onChange={handleRewardNameChange}
                sx={{ maxWidth: 'none' }}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
              <Typography>
                Reward Item Type <span style={{ color: '#CC2027' }}>*</span>
              </Typography>
              <Select
                fullWidth
                required={true}
                variant="outlined"
                displayEmpty={true}
                renderValue={(selectedType: string) =>
                  formatTypeName(selectedType) ?? 'Select Type'
                }
                value={itemType}
                onChange={handleSelectChange}
              >
                <MenuItem value="LocationOffer">Location Offer</MenuItem>
                <MenuItem value="JJStoreItem">J&J Store</MenuItem>
                <MenuItem value="MatchPlay">Match Play</MenuItem>
              </Select>
            </div>
            {itemType === 'JJStoreItem' && (
              <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
                <Typography>Vendor Item Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Vendor Item Name"
                  value={vendorItemName}
                  onChange={handleVendorItemNameChange}
                  sx={{
                    maxWidth: 'none',
                  }}
                />
              </div>
            )}
            {itemType === 'JJStoreItem' && (
              <div className="grid grid-cols-1 md:grid-cols-2 items-center mb-6">
                <Typography>SKU</Typography>
                <TextField
                  fullWidth
                  placeholder="SKU"
                  value={sku}
                  onChange={handleSkuChange}
                  helperText={skuError}
                  error={!!skuError}
                  sx={{
                    maxWidth: 'none',
                  }}
                />
              </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2">
              <Typography>Description</Typography>
              <TextField
                fullWidth
                placeholder="Describe the item"
                minRows={4}
                multiline
                value={description}
                inputProps={{
                  maxLength: 500,
                  overflow: 'scroll !important',
                }}
                onChange={handleDescriptionChange}
                sx={{
                  maxWidth: 'none',
                  '& .MuiInputBase-input': {
                    overflow: 'auto !important',
                    maxHeight: '125px !important',
                    height: '125px !important',
                  },
                }}
              />
            </div>
          </div>
        </ModalFullBody>
        <ModalFullFooter>
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            active={putRewardMutation.isPending}
            type="submit"
            variant="contained"
          >
            Save
          </ActivityButton>
        </ModalFullFooter>
      </form>
    </Modal>
  )
}
