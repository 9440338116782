import {
  Box,
  type BoxProps,
  CircularProgress,
  type CircularProgressProps,
} from '@mui/material'

const ActivityIndicatorMeasures: Record<string, string> = {
  small: '25px',
  medium: '50px',
  big: '80px',
  fullPage: '80px',
}

export const ActivityIndicator = (
  props: BoxProps & {
    circularProgressProps?: CircularProgressProps
    size?: 'small' | 'medium' | 'big' | 'fullPage'
  }
) => {
  const { size = 'medium', circularProgressProps, ...boxProps } = props

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flex="1"
      height={size === 'fullPage' ? '100vh' : 'auto'}
      {...boxProps}
    >
      <CircularProgress
        {...circularProgressProps}
        size={ActivityIndicatorMeasures[size]}
      />
    </Box>
  )
}
