import { FormProvider, useForm } from 'react-hook-form'
import { type InferType, object, string, array } from 'yup'
import { Page } from 'components/Shared/Page'
import { yupResolver } from '@hookform/resolvers/yup'
import { PageHeader } from 'components/Shared/PageHeader'
import { type components } from 'api/playerPayback/api'
import { useQuery, useMutation } from '@tanstack/react-query'
import { Button, InputLabel, Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TextField } from 'components/Shared/TextField'
import { ApiError } from 'openapi-typescript-fetch'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

export const LEUserSchema = object({
  LEUser: object({
    firstName: string().required('First Name required'),
    lastName: string().required('Last Name required'),
    email: string().email('Invalid email').required('Email required'),
    assignedIds: array(string().required()),
  }),
})

export type LEUserForm = InferType<typeof LEUserSchema>

export const NewLEConnectUserPage = () => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const corporateAccountId = params.get('corporateAccountId')
  const postLEUser = useTypedApiClient({
    path: '/admin/licensed-establishments/users',
    method: 'post',
  })
  const getCorporateAccounts = useTypedApiClient({
    path: '/admin/corporate-accounts',
    method: 'get',
  })
  const formMethods = useForm({
    resolver: yupResolver(LEUserSchema),
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods
  const allCorporateAccountsQuery = useQuery({
    queryKey: ['/admin/corporate-accounts/list'],
    queryFn: async (): Promise<
      Array<components['schemas']['FullCorporateAccountDTO']> | null | undefined
    > => {
      const { data } = await getCorporateAccounts({})
      return data
    },
  })

  const createLEUser = useMutation({
    mutationFn: async ({ LEUser }: LEUserForm) => {
      return await postLEUser({
        firstName: LEUser.firstName,
        lastName: LEUser.lastName,
        email: LEUser.email,
        type: 'Owner',
        corporateAccountId: corporateAccountId
          ? parseInt(corporateAccountId)
          : null,
      })
    },
    onSuccess: (data) => {
      setMessage('J&J Connect User successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate(`/ClientManagement/LEConnectUsers/${data.data.id}`)
    },
    onError: (e) => {
      if (e instanceof ApiError) {
        setMessage(e.data, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      } else {
        setMessage("Couldn't create J&J Connect User", 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      }
    },
  })
  const handleSubmitWrapper = handleSubmit((data) => {
    createLEUser.mutate(data)
    return false
  })

  if (allCorporateAccountsQuery.isPending) {
    return <ActivityIndicator />
  }

  if (allCorporateAccountsQuery.isError) {
    return (
      <Page
        header={
          <PageHeader
            title="J&J Connect Users"
            eyebrow="Client Management"
            isSecondary={true}
            backPath="/ClientManagement/LEConnectUsers"
            backText="J&J Connect Users"
          />
        }
      >
        <Typography>An error occurred</Typography>
      </Page>
    )
  }

  return (
    <Page
      header={
        <PageHeader
          title="Create a New J&J Connect User"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          isSecondary={true}
          backPath="/ClientManagement/LEConnectUsers"
          backText="J&J Connect Users"
        ></PageHeader>
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <Stack gap={2} maxWidth={500}>
            <InputLabel required htmlFor="LEUser.firstName" sx={{ mb: 0 }}>
              Contact Information
            </InputLabel>
            <Stack
              gap={2}
              direction={{ xs: 'column', sm: 'row' }}
              width={'100%'}
            >
              <Stack gap={1} width={'100%'}>
                <TextField
                  title="First Name"
                  placeholder="First Name"
                  {...register('LEUser.firstName', { required: true })}
                  error={isPresent(errors.LEUser?.firstName)}
                  containerStyle={{ flex: 1 }}
                />
                {errors.LEUser?.firstName?.message && (
                  <FormFieldErrorMessage
                    message={errors.LEUser.firstName.message}
                  />
                )}
              </Stack>
              <Stack gap={1} width={'100%'}>
                <TextField
                  title="Last Name"
                  placeholder="Last Name"
                  {...register('LEUser.lastName', { required: true })}
                  error={isPresent(errors.LEUser?.lastName)}
                  containerStyle={{ flex: 1 }}
                />
                {errors.LEUser?.lastName?.message && (
                  <FormFieldErrorMessage
                    message={errors.LEUser.lastName.message}
                  />
                )}
              </Stack>
            </Stack>
            <TextField
              title="Email"
              placeholder="Email Address"
              fullWidth={true}
              {...register('LEUser.email', { required: true })}
              error={isPresent(errors.LEUser?.email)}
            />
            {errors.LEUser?.email?.message && (
              <FormFieldErrorMessage message={errors.LEUser.email.message} />
            )}
            <Stack direction="row" gap={2} mt={2}>
              <Button
                variant="outlined"
                onClick={() => navigate(`/ClientManagement/LEConnectUsers`)}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
