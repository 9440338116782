import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from './Shared/ActivityIndicator'
import {
  Box,
  InputLabel,
  MenuItem,
  Typography,
  FormControl,
  createFilterOptions,
  Popper,
  Autocomplete,
  TextField,
} from '@mui/material'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { Controller } from 'react-hook-form'
import { omit } from 'lodash'

const MAX_LOCATIONS = 200

interface SelectCorporateAccountProps {
  fullWidth?: boolean
  required?: boolean
  error?: boolean
  label?: string
  corporateAccountIdKey: string
  name?: string
  defaultValue?: number | null
  placeholder?: string
  inputProps?: Record<string, any>
  idsToFilter?: number[]
}

export const SelectCorporateAccount = ({
  label,
  placeholder,
  required,
  corporateAccountIdKey,
  name = corporateAccountIdKey,
  error,
  defaultValue,
  inputProps,
  idsToFilter,
  ...otherProps
}: SelectCorporateAccountProps) => {
  const get = useTypedApiClient({
    path: '/admin/corporate-accounts',
    method: 'get',
  })
  const {
    isPending,
    isError,
    data: corporateAccounts,
  } = useQuery({
    queryKey: ['/corporate-accounts'],
    queryFn: async () => {
      const { data } = await get({})
      return data
    },
    select: (data) =>
      data.sort((a, b) => {
        const nameA = a.name ?? ''
        const nameB = b.name ?? ''
        return nameA.localeCompare(nameB)
      }),
  })

  if (isPending) {
    return <ActivityIndicator />
  }

  if (isError) {
    return <Typography>Error</Typography>
  }

  return (
    <Box>
      <FormControl>
        <InputLabel id={`${name}-label`} htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Controller
          name={name}
          defaultValue={defaultValue ?? null}
          rules={{ required: false }}
          render={({ field: { onChange, value, ref } }) => (
            <Autocomplete
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                limit: MAX_LOCATIONS,
              })}
              renderInput={(params) => (
                <TextField
                  placeholder={placeholder}
                  error={error}
                  {...params}
                  {...inputProps}
                  inputRef={ref}
                />
              )}
              id={name}
              disablePortal
              options={
                corporateAccounts.filter(
                  (ca) => !idsToFilter?.includes(ca.id)
                ) ?? []
              }
              getOptionLabel={(option) => option.name ?? ''}
              ListboxProps={{ sx: { maxHeight: '250px' } }}
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  popperOptions={{
                    modifiers: [
                      { name: 'flip', options: { fallbackPlacements: [] } },
                    ],
                  }}
                  placement="bottom"
                />
              )}
              renderOption={(props, ca) => (
                <MenuItem
                  key={`corporate-account-option-${ca.id}`}
                  value={String(ca.id)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                  {...omit(props, 'className', 'key')}
                >
                  {ca.name}
                </MenuItem>
              )}
              {...otherProps}
              onChange={(_event, newValue) => onChange(newValue?.id ?? null)}
              value={
                corporateAccounts?.find((account) => account.id === value) ??
                null
              }
            />
          )}
        />
      </FormControl>
    </Box>
  )
}
