import { Button } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import { TextField } from 'components/Shared/TextField'
import { useApiClient } from 'hooks/useApiClient'
import { object, string } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'

export interface CorporateAccountForm {
  name: string
}

const CorporateAccountmSchema = object({
  name: string().required('Corporate account name is a required field'),
})

export const CorporateAccountsNewPage = () => {
  const { post } = useApiClient()
  const formMethods = useForm<CorporateAccountForm>()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CorporateAccountmSchema),
  })
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const mutate = useMutation({
    mutationKey: ['CorporateAccounts/New'],
    mutationFn: async ({ name }: CorporateAccountForm) => {
      const { data } = await post('/admin/corporate-accounts', {
        params: { header: undefined },
        body: {
          name,
        },
      })
      return data
    },
    onSuccess: () => {
      setMessage('Corporate Account created successfully', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate('/ClientManagement/CorporateAccounts')
    },
    onError: () => {
      setMessage('Error creating corporate account', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    mutate.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Corporate Account"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          isSecondary={true}
          backPath="/ClientManagement/CorporateAccounts"
          backText="Corporate Accounts"
        ></PageHeader>
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <TextField
            {...register('name', { required: true })}
            error={isPresent(errors.name)}
            label="Corporate Account Name"
            required
            fullWidth
            placeholder="Enter Corporate Account Name"
          />
          {errors.name?.message && (
            <FormFieldErrorMessage message={errors.name.message} />
          )}
          <div className="pt-6 flex flex-row gap-6">
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmitWrapper}
            >
              Create
            </Button>
          </div>
        </form>
      </FormProvider>
    </Page>
  )
}
