import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { Button, Stack, Typography } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { type SelectedWinnerType } from 'pages/Contests/Sweeptakes/SweepstakesWinnersPage/SweepstakesWinnersPage'
import { ActivityButton } from 'components/ActivityButton'
import { usePostSweepstakeWinnerMessage } from 'hooks/api/Sweepstake/usePostSweepstakeWinnerMessage'
import { useSnackbar } from 'stores/useSnackbar'

interface SweepstakeNotifyWinnersProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  sweepstakeName: string
  selectedWinners: SelectedWinnerType[]
  clearSelectedWinners?: () => void
  isBulkAction?: boolean
}

export const SweepstakeNotifyWinnersModal = ({
  isModalOpen,
  toggleIsOpen,
  sweepstakeName,
  selectedWinners,
  clearSelectedWinners = () => {},
  isBulkAction = false,
}: SweepstakeNotifyWinnersProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)

  const notifyWinners = usePostSweepstakeWinnerMessage({
    onSuccess: () => {
      setMessage(
        `${isBulkAction ? 'Winners' : 'Winner'} has been notified.`,
        'success'
      )
      clearSelectedWinners()
      toggleIsOpen()
    },
    onError: () => {
      setMessage(
        `Error notifying ${isBulkAction ? 'winners' : 'winner'}`,
        'error'
      )
    },
  })

  const handleConfirm = () => {
    notifyWinners.mutate({
      sweepstakeWinnerIds: selectedWinners.map(
        (winner) => winner.sweepstakesWinner.id
      ),
    })
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleIsOpen}
      sx={{
        width: { xs: '90vw', sm: '60%' },
        height: '70%',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleIsOpen}
        title={isBulkAction ? 'Notify Winners' : 'Notify Winner'}
        subTitle={sweepstakeName}
      />
      <ModalFullBody heightModal={'70%'}>
        <Typography fontSize={18} fontWeight="bold" className="pb-3">
          Are you sure you want to notify{' '}
          {isBulkAction ? 'these winners' : 'this winner'} they won?
        </Typography>
        <Typography sx={{ color: colorPrimitives.gray70 }} className="pb-6">
          Selecting &quot;{isBulkAction ? 'Notify Winners' : 'Notify Winner'}
          &quot; will send a message to the winner through the Message Center of
          the J&J Gaming app, as well as an email, informing them of their win
          and providing instructions on how to claim their prize.
        </Typography>
        <Stack direction="column">
          {selectedWinners.map((winner) => {
            return (
              <Stack
                columnGap={0.5}
                direction="row"
                key={winner.sweepstakesWinner.id}
                className="pb-4"
              >
                <Typography fontWeight="bold">{winner.locationName}</Typography>
                {`(${winner.licenseNumber}): ${winner.winnerName}`}
              </Stack>
            )
          })}
        </Stack>
      </ModalFullBody>
      <ModalFullFooter
        leftFooter={
          isBulkAction && (
            <Typography
              sx={{ color: colorPrimitives.gray70 }}
              className="self-center"
            >
              Notify {selectedWinners.length} Winners
            </Typography>
          )
        }
      >
        <Button variant="text" onClick={toggleIsOpen}>
          Cancel
        </Button>
        <ActivityButton
          active={notifyWinners.isPending}
          variant="contained"
          onClick={handleConfirm}
        >
          {isBulkAction ? 'Notify Winners' : 'Notify Winner'}
        </ActivityButton>
      </ModalFullFooter>
    </Modal>
  )
}
