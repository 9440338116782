import { isNil, startCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { format, parseISO } from 'date-fns'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import {
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { EditPatronInformation } from 'pages/Patrons/EditPatronInformation/EditPatronInformation'
import { useMemo, useState } from 'react'
import { FlagPatronAccountAsExcludedModal } from '../../ExcludePatron/FlagPatronAccountAsExcludedModal'
import { FlagPatronAccountAsDeactivatedModal } from 'pages/Patrons/DeactivatePatron/FlagPatronAccountAsDeactivatedModal'
import { getDeactivatedDate, getExcludedDate } from 'utils/util'
import { formatPhoneNumber } from 'utils/phoneUtils'
import { ActivityButton } from 'components/ActivityButton'
import { usePutPatronTier } from 'hooks/api/Patron/usePutPatronTier'
import { useSnackbar } from 'stores/useSnackbar'
import { useQueryClient } from '@tanstack/react-query'
import { useGetTiers } from 'hooks/api/useGetTiers'

export const PatronOverviewDesktop = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTier, setSelectedTier] = useState<number | null>(null)
  const toggleEditModalIsOpen = async () => {
    setIsEditModalOpen(!isEditModalOpen)
  }
  const [
    isFlagPatronAccountAsExcludedModalOpen,
    setIsFlagPatronAccountAsExcludedModalOpen,
  ] = useState(false)
  const qc = useQueryClient()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const { id: patronAccountId } = useParams()
  const tiersQuery = useGetTiers()
  const tiers = tiersQuery.data
  const selectedTierString = useMemo(() => {
    if (isNil(selectedTier)) {
      return ''
    }

    return String(selectedTier)
  }, [selectedTier])

  const toggleFlagPatronAccountAsExcludedModalIsOpen = async () => {
    setIsFlagPatronAccountAsExcludedModalOpen(
      !isFlagPatronAccountAsExcludedModalOpen
    )
  }

  const [
    isFlagPatronAccountAsDeactivatedModalOpen,
    setIsFlagPatronAccountAsDeactivatedModalOpen,
  ] = useState(false)
  const toggleFlagPatronAccountAsDeactivatedModalIsOpen = async () => {
    setIsFlagPatronAccountAsDeactivatedModalOpen(
      !isFlagPatronAccountAsDeactivatedModalOpen
    )
  }

  const patronQuery = useGetPatronById({ id: Number(patronAccountId) })

  const putPatronMutation = usePutPatronTier({
    onError: () => {
      setSnackbarMessage(
        'An error occurred while processing changed Patron Tier',
        'error'
      )
    },
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: ['/admin/patronaccount/detail'],
      })
      setSelectedTier(null)
      setSnackbarMessage('Successfully changed Patron Tier', 'success')
    },
  })

  if (!patronAccountId) {
    return 'Patron Account Id cannot be null'
  }

  if (patronQuery.isPending) {
    return <ActivityIndicator />
  }

  if (patronQuery.isError || isNil(patronQuery.data)) {
    return <div>An error occurred.</div>
  }

  const createdOn = !isNil(patronQuery.data.createdOn)
    ? parseISO(patronQuery.data.createdOn)
    : null

  const dob = !isNil(patronQuery.data.dob)
    ? parseISO(patronQuery.data.dob)
    : null

  const gender = startCase(patronQuery.data?.gender)

  const mostRecentMailingAddress =
    !isNil(patronQuery.data?.addresses) && patronQuery.data.addresses.length > 0
      ? patronQuery.data?.addresses.sort((a, b) =>
          (a.id > b.id && -1) || a.id < b.id ? 1 : 0
        )[0]
      : null
  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/Overview`}
    >
      <div hidden={!patronQuery.data?.exclude}>
        <SectionHeader showButton={false} title="User Excluded" />
        <div className="flex flex-col border rounded p-6 mb-5">
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-1">
                <p className="break-words">{patronQuery.data?.excludeReason}</p>
              </Typography>
              <Typography variant="body-3">
                {getExcludedDate(patronQuery.data?.excludeDate)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div hidden={!patronQuery.data?.deactivated}>
        <SectionHeader showButton={false} title="Patron Deactivated" />
        <div className="flex flex-col border rounded p-6 mb-5">
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-1" className="break-words">
                {patronQuery.data?.deactivatedReason}
              </Typography>
              <Typography variant="body-3">
                {getDeactivatedDate(patronQuery.data?.deactivatedOn)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <SectionHeader
        title="Patron Account"
        onClickButton={toggleEditModalIsOpen}
      />
      <div className="flex flex-col border rounded p-6 mb-5">
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Patron ID
          </Typography>
          <Typography variant="body-1">#{patronQuery.data?.id}</Typography>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Name
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">First Name</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.firstName}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Last Name</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.lastName}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Contact
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">Phone Number</Typography>
              {!isNil(patronQuery.data.phoneNumber) && (
                <Typography variant="body-1">
                  {formatPhoneNumber(patronQuery.data.phoneNumber)}
                </Typography>
              )}
            </div>
            <div>
              <Typography variant="body-3">Email</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.email}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Birthdate
          </Typography>
          {!isNil(dob) && (
            <Typography variant="body-1">{`${format(dob, 'P')}`}</Typography>
          )}
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Gender
          </Typography>
          <Typography variant="body-1">{gender}</Typography>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Mailing Address
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">Address Line 1</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.address1 ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Address Line 2</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.address2 ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">City</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.city ?? '-'}
              </Typography>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">State</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.state ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Zip</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.postalCode ?? '-'}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Member Since
          </Typography>
          {!isNil(createdOn) && (
            <Typography variant="body-1">
              {`${format(createdOn, 'P')} at ${format(createdOn, 'p')}`}
            </Typography>
          )}
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center">
          <Typography variant="h4" fontWeight={'bold'}>
            Tier
          </Typography>
          <Typography variant="body-1" mt={2}>
            Current Tier:{' '}
            {patronQuery.data.currentTier?.tier?.type ?? 'Tier Not Set'}
          </Typography>
          <Stack direction={'row'} gap={2} alignItems={'center'}>
            <Select
              sx={{ minWidth: 150 }}
              value={selectedTierString}
              onChange={(event) => setSelectedTier(Number(event.target.value))}
            >
              {tiers?.map((tier) => (
                <MenuItem
                  key={tier.id}
                  value={tier.id}
                  disabled={tier.id === patronQuery.data.currentTier?.tier?.id}
                >
                  {tier.type}{' '}
                  {tier.id === patronQuery.data.currentTier?.tier?.id
                    ? '(Current)'
                    : ''}
                </MenuItem>
              ))}
            </Select>
            <ActivityButton
              variant="contained"
              disabled={isNil(selectedTier)}
              active={putPatronMutation.isPending}
              onClick={async () => {
                if (isNil(selectedTier)) {
                  throw new Error("Selected Tier can't be null")
                }
                await putPatronMutation.mutateAsync({
                  id: patronQuery.data.id,
                  tierId: selectedTier,
                })
              }}
            >
              Update Tier
            </ActivityButton>
          </Stack>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            setIsFlagPatronAccountAsExcludedModalOpen(
              !isFlagPatronAccountAsExcludedModalOpen
            )
          }}
        >
          {!patronQuery.data.exclude
            ? 'Flag Patron Account as Excluded'
            : 'Unflag Patron Account to Include'}
        </Button>
      </div>
      <Button
        onClick={() => {
          setIsFlagPatronAccountAsDeactivatedModalOpen(
            !isFlagPatronAccountAsDeactivatedModalOpen
          )
        }}
      >
        {!patronQuery.data.deactivated
          ? 'Deactivate Patron'
          : 'Reactivate Patron'}
      </Button>
      <EditPatronInformation
        isModalOpen={isEditModalOpen}
        toggleModalIsOpen={toggleEditModalIsOpen}
        patron={patronQuery.data}
      />
      <FlagPatronAccountAsExcludedModal
        isModalOpen={isFlagPatronAccountAsExcludedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        patron={patronQuery.data}
      />

      <FlagPatronAccountAsDeactivatedModal
        isModalOpen={isFlagPatronAccountAsDeactivatedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        patron={patronQuery.data}
      />
    </PatronDetailsHeader>
  )
}
