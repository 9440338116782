import { useLayout } from 'hooks/useLayout'
import { number, object, boolean } from 'yup'
import { useEffect, useCallback } from 'react'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { colorPrimitives } from 'components/Theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { TextField } from 'components/Shared/TextField'
import type { components } from 'api/playerPayback/api'
import { Box, Button, Stack, Switch, Typography, useTheme } from '@mui/material'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { GrantPointsOnCheckInSwitch } from './GrantPointsOnCheckInSwitch'
import { daysOfWeek } from 'utils/util'
import { ScheduleRow } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/ScheduleRow'
import { type CheckInPointBonusSchedule } from 'types/api'
import { ActivityButton } from 'components/ActivityButton'

interface GrantCheckInsForm {
  enablePatronCheckInPointBonus: boolean
  checkInsDailyMax: number
  enablePointMultiplier: boolean
  schedules?: CheckInPointBonusSchedule[]
}

const GrantCheckInsSchema = object({
  enablePatronCheckInPointBonus: boolean().required(
    'Enable Patron Point Bonus is required.'
  ),
  checkInsDailyMax: number()
    .typeError('Max Check-ins value must be a number.')
    .test(
      'is-positive-or-zero',
      'Value must be a positive number or zero',
      (value) => {
        return value === 0 || (typeof value === 'number' && value > 0)
      }
    )
    .required('Max Check-ins value is required.'),
  enablePointMultiplier: boolean().required(
    'Enable Point Multiplier is required.'
  ),
})

export const SettingsModal = ({
  rewardsProgram,
  checkInGrantPointsPolicy,
  checkInMaxPer24HoursPolicy,
  isModalVisible,
  toggleModalVisibility,
  daysToDisplay = [],
}: {
  rewardsProgram: components['schemas']['FullRewardsProgramDTO']
  checkInGrantPointsPolicy: components['schemas']['FullCheckInPolicyDTO']
  checkInMaxPer24HoursPolicy: components['schemas']['FullCheckInPolicyDTO']
  isModalVisible: boolean
  toggleModalVisibility: () => void
  daysToDisplay: CheckInPointBonusSchedule[]
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const setMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()
  const theme = useTheme()

  const formMethods = useForm<GrantCheckInsForm>({
    resolver: yupResolver(GrantCheckInsSchema),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = formMethods

  const resetForm = useCallback(() => {
    reset({
      enablePatronCheckInPointBonus: Boolean(
        checkInGrantPointsPolicy?.enablePatronCheckInPointBonus
      ),
      checkInsDailyMax: Number(checkInMaxPer24HoursPolicy?.checkInsDailyMax),
      enablePointMultiplier: Boolean(
        checkInGrantPointsPolicy?.checkInPointBonusSchedules?.some(
          (policy) => policy.patronCheckInPointBonusMultiplier !== undefined
        )
      ),
    })
  }, [checkInMaxPer24HoursPolicy])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const enablePatronPointBonus = watch('enablePatronCheckInPointBonus')
  const enablePointMultiplier = watch('enablePointMultiplier')

  const closeModal = () => {
    toggleModalVisibility()
    resetForm()
  }

  const putCheckInPolicy = useTypedApiClient({
    path: '/admin/policies/checkins/{id}',
    method: 'put',
  })

  const postCheckInPolicy = useTypedApiClient({
    path: '/admin/policies/checkins',
    method: 'post',
  })

  const updateCheckInGrantPointsPolicyMutation = useMutation({
    mutationFn: async ({
      enablePatronCheckInPointBonus,
      enablePointMultiplier,
      schedules,
    }: GrantCheckInsForm) => {
      const checkInPointBonusSchedules: CheckInPointBonusSchedule[] = []
      schedules?.forEach((schedule) => {
        if (schedule.opening && schedule.closing && enablePointMultiplier) {
          checkInPointBonusSchedules.push({
            ...schedule,
            patronCheckInPointBonusMultiplier: 2,
          })
        }
      })

      if (
        checkInGrantPointsPolicy?.id &&
        checkInGrantPointsPolicy?.type &&
        checkInGrantPointsPolicy?.rewardsProgramId
      ) {
        return await putCheckInPolicy({
          id: checkInGrantPointsPolicy.id,
          type: 'PatronCheckInPointBonus',
          rewardsProgramId: checkInGrantPointsPolicy.rewardsProgramId,
          enablePatronCheckInPointBonus,
          checkInPointBonusSchedules,
          patronCheckInPointBonusMin:
            checkInGrantPointsPolicy.patronCheckInPointBonusMin ?? 1,
          patronCheckInPointBonusMax:
            checkInGrantPointsPolicy.patronCheckInPointBonusMax ?? 1,
        })
      } else {
        return await postCheckInPolicy({
          type: 'PatronCheckInPointBonus',
          rewardsProgramId: checkInMaxPer24HoursPolicy.rewardsProgramId ?? 0,
          enablePatronCheckInPointBonus,
          checkInPointBonusSchedules,
          patronCheckInPointBonusMin:
            checkInGrantPointsPolicy.patronCheckInPointBonusMin ?? 1,
          patronCheckInPointBonusMax:
            checkInGrantPointsPolicy.patronCheckInPointBonusMax ?? 1,
        })
      }
    },
  })

  const updateCheckInMaxPer24HoursPolicyMutation = useMutation({
    mutationFn: async ({ checkInsDailyMax }: GrantCheckInsForm) => {
      if (
        checkInMaxPer24HoursPolicy?.id &&
        checkInMaxPer24HoursPolicy?.type &&
        checkInMaxPer24HoursPolicy?.rewardsProgramId
      ) {
        return await putCheckInPolicy({
          id: checkInMaxPer24HoursPolicy.id,
          type: 'CheckInsDailyMax',
          rewardsProgramId: checkInMaxPer24HoursPolicy.rewardsProgramId,
          checkInsDailyMax,
        })
      }
    },
  })

  useEffect(() => {
    if (
      updateCheckInGrantPointsPolicyMutation.isSuccess &&
      updateCheckInMaxPer24HoursPolicyMutation.isSuccess
    ) {
      queryClient
        .invalidateQueries({
          queryKey: [
            '/admin/policies/checkins/rewards-programs',
            rewardsProgram.id,
          ],
        })
        .finally(() => {})
      setMessage('Check In Policy has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      closeModal()
    } else {
      const error: any =
        (updateCheckInGrantPointsPolicyMutation.error as Error) ??
        (updateCheckInMaxPer24HoursPolicyMutation.error as Error)
      if (error?.message) {
        setMessage(error?.message, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      }
    }
  }, [
    updateCheckInGrantPointsPolicyMutation.isSuccess,
    updateCheckInGrantPointsPolicyMutation.isError,
    updateCheckInMaxPer24HoursPolicyMutation.isSuccess,
    updateCheckInMaxPer24HoursPolicyMutation.isError,
  ])

  const updateCheckInPolicy = handleSubmit((data) => {
    updateCheckInGrantPointsPolicyMutation.mutate(data)
    updateCheckInMaxPer24HoursPolicyMutation.mutate(data)
  })

  return (
    <Modal
      isOpen={isModalVisible}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '70%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={updateCheckInPolicy}>
          <ModalFullHeader
            title="Grant Check-Ins"
            subTitle={rewardsProgram.name}
            toggleIsOpen={closeModal}
          />
          <ModalFullBody heightModal={heightModal}>
            <Stack spacing={5}>
              <GrantPointsOnCheckInSwitch />
              {enablePatronPointBonus && (
                <Box>
                  <Box className="flex flex-col sm:flex-row justify-stretch w-full px-1">
                    <Box className="w-full sm:w-1/2 sm:pt-4 flex">
                      <Typography>
                        Max Number of Check-ins per 24 Hour Period
                      </Typography>
                      <Typography color={colorPrimitives.redGaming}>
                        *
                      </Typography>
                    </Box>
                    <Box className="flex flex-row gap-6 w-full sm:w-1/2">
                      <TextField
                        placeholder="checkInsDailyMax"
                        {...register('checkInsDailyMax', { required: true })}
                        error={isPresent(errors.checkInsDailyMax)}
                        sx={{ width: '100px' }}
                        bottomContent={
                          errors.checkInsDailyMax?.message && (
                            <FormFieldErrorMessage
                              message={errors.checkInsDailyMax.message}
                            />
                          )
                        }
                      />
                      <Typography className="sm:pt-4">Check-ins</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {/* Points Multiplier Section */}
              {enablePatronPointBonus && (
                <Box
                  sx={{
                    border: 1,
                    borderColor: theme.palette.grey[300],
                    m: '16px',
                    gap: '16px',
                    p: '16px',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h4">Points Multiplier</Typography>
                    <Typography variant="body-3">
                      When enabled the user will receive 2x points per check in.
                    </Typography>
                  </Box>
                  <Controller
                    name="enablePointMultiplier"
                    render={({ field: { onChange, value } }) => {
                      return <Switch checked={value} onChange={onChange} />
                    }}
                  />
                </Box>
              )}
              {enablePatronPointBonus && enablePointMultiplier && (
                <Box>
                  <Typography variant="subtitle-1" component={'p'}>
                    Point Multiplier Schedule
                  </Typography>
                  <Typography variant="body-3">
                    {`Select the days you'd like to award 2x points`}
                  </Typography>
                  <Stack spacing={2} mt="20px">
                    {daysOfWeek.map((day, index) => {
                      return (
                        <Box
                          key={day}
                          style={{ height: '85px' }}
                          height={'85'}
                          className="flex flex-col justify-center"
                        >
                          <ScheduleRow
                            day={day}
                            index={index}
                            key={day}
                            defaultValues={{
                              opening: daysToDisplay?.[index]?.opening,
                              closing: daysToDisplay?.[index]?.closing,
                              patronCheckInPointBonusMultiplier:
                                daysToDisplay?.[index]
                                  ?.patronCheckInPointBonusMultiplier ?? 0,
                            }}
                            openLabel="Enabled"
                            closedLabel="Disabled"
                          />
                        </Box>
                      )
                    })}
                  </Stack>
                </Box>
              )}
            </Stack>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={closeModal}>
              Cancel
            </Button>
            <ActivityButton
              active={
                updateCheckInGrantPointsPolicyMutation.isPending ||
                updateCheckInMaxPer24HoursPolicyMutation.isPending
              }
              variant="contained"
              type="submit"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
