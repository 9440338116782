import type { SnackbarOrigin, AlertColor } from '@mui/material'
import { create } from 'zustand'

interface SnackbarState {
  visible: boolean
  message: string | null
  setMessage: (
    message: string | null,
    severity?: AlertColor,
    anchorOrigin?: SnackbarOrigin
  ) => void
  severity?: AlertColor
  anchorOrigin?: SnackbarOrigin
}

export const useSnackbar = create<SnackbarState>((set) => ({
  visible: false,
  message: null,
  setMessage: (
    message,
    severity = undefined,
    anchorOrigin?: SnackbarOrigin
  ) => {
    if (message !== null) {
      set({ visible: true, message, severity, anchorOrigin })
    } else {
      set({ visible: false, message: null })
    }
  },
}))
