import { useApiQuery } from 'hooks/useApiQuery'

export const useGetRewardProgramCatalogByRewardProgramId = ({
  rewardProgramId,
}: {
  rewardProgramId?: number
}) =>
  useApiQuery({
    path: '/admin/rewards-program-catalogs/rewards-program/{rewardProgramId}',
    method: 'get',
    queryKey: ['admin-rewards-program-catalogs-by-rp-id'],
    queryArgs: { rewardProgramId },
  })
