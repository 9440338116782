import { Box, Grid, Typography, useTheme } from '@mui/material'
import { type LicensedEstablishmentSchedule } from 'src/types/api'
import { convertScheduleHourTo12HourFormat } from 'utils/util'
import { type LicensedEstablishmentDetailsProps } from '../types'
import { SectionHeader } from 'components/SectionHeader'

export const ScheduleDetails = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  const theme = useTheme()

  const addMissingDays = (
    days: LicensedEstablishmentSchedule[] | undefined | null
  ): LicensedEstablishmentSchedule[] => {
    const daysOfTheWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const allDays: LicensedEstablishmentSchedule[] = []

    daysOfTheWeek.forEach((day) => {
      if (!days?.some((d) => d.dayName === day)) {
        allDays.push({
          dayName: day,
          licensedEstablishmentId: 0,
          opening: '',
          closing: '',
          id: 0,
          createdBy: null,
          createdOn: null,
          modifiedBy: null,
          modifiedOn: null,
        })
      } else {
        allDays.push(...days.filter((d) => d.dayName === day))
      }
    })
    return allDays
  }

  return (
    <Box>
      <SectionHeader title="Standard Hours" showButton={false} />
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap">
          <Grid item xs={4} md={2}>
            {addMissingDays(licensedEstablishment.schedules).map((schedule) => {
              return (
                <Typography
                  key={`${schedule.id}-${schedule.dayName}`}
                  variant="body1"
                  pb={1}
                >
                  {`${schedule.dayName}`}
                </Typography>
              )
            })}
          </Grid>
          <Grid item xs={6} md={3}>
            {addMissingDays(licensedEstablishment.schedules).map((schedule) => {
              return (
                <Typography
                  key={`${schedule.id}-${schedule.dayName}`}
                  variant="body1"
                  pb={1}
                >
                  {schedule.opening
                    ? `${convertScheduleHourTo12HourFormat(schedule.opening)}`
                    : 'Closed'}
                  {schedule.closing
                    ? ` - ${convertScheduleHourTo12HourFormat(
                        schedule.closing
                      )}`
                    : ''}
                </Typography>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
