import { Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { components } from 'api/playerPayback/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { useNavigate } from 'react-router-dom'
import type { Address } from 'src/types/address'
import { formatFullDateTime } from 'utils/util'
import { useNumberOfCheckIns } from './useNumberOfCheckIns'
import { useGetPatronCheckInsByRange } from 'hooks/api/Patron/useGetPatronCheckInsByRange'
import type { PatronCheckInRangeType } from 'types/api'

const checkInHistoryColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'licensedEstablishmentName',
      headerName: 'LE Name',
      valueGetter: (params: {
        row: {
          licensedEstablishment: components['schemas']['FullLicensedEstablishmentDTO']
        }
      }) =>
        params.row.licensedEstablishment.standardName
          ? params.row.licensedEstablishment.standardName
          : params.row.licensedEstablishment.name,
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      valueGetter: (params: {
        row: {
          licensedEstablishment: components['schemas']['FullLicensedEstablishmentDTO']
        }
      }) => `#${params.row.licensedEstablishment.licenseNumber}`,
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'licensedEstablishmentAddressCity',
      headerName: 'City',
      valueGetter: (params: {
        row: {
          licensedEstablishment: {
            addresses: Address[]
          }
        }
      }) => {
        const city = params.row.licensedEstablishment.addresses[0]?.city ?? '-'
        return isMobile ? `City: ${city}` : city
      },
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'licensedEstablishmentAddressZip',
      headerName: 'ZIP',
      valueGetter: (params: {
        row: {
          licensedEstablishment: {
            addresses: Address[]
          }
        }
      }) => {
        const zip =
          params.row.licensedEstablishment.addresses[0]?.postalCode ?? '-'
        return isMobile ? `Zip: ${zip}` : zip
      },
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'createdOn',
      headerName: 'Date Visited',
      valueFormatter: (params: { value: string }) => {
        const date = formatFullDateTime(params.value)
        return isMobile ? `Date Visited: ${date}` : date
      },
      flex: 1,
      minWidth: 160,
    },
  ]
}

export const PatronCheckInsList = ({
  patronId,
  range,
}: {
  patronId: number
  range: PatronCheckInRangeType
}) => {
  const navigate = useNavigate()
  const patronCheckIns = useGetPatronCheckInsByRange({ patronId, range })
  const filteredData = patronCheckIns.data
  const { isMobile } = useLayout()
  const setCheckins = useNumberOfCheckIns((state) => state.setCheckIns)

  if (patronCheckIns.isPending) {
    return <ActivityIndicator />
  }

  if (patronCheckIns.isError) {
    return <Typography>Something went wrong</Typography>
  }

  setCheckins(patronCheckIns.data.length)

  return (
    <DataTable
      columns={checkInHistoryColumns(isMobile)}
      rows={filteredData ?? []}
      sortFieldName="createdOn"
      sort="desc"
      noDataMessage="No check-in history for this time period"
      onRowClick={(params) => {
        const leId = params.row.licensedEstablishment.id
        if (leId) {
          navigate(`/LicensedEstablishments/${String(leId)}`)
        }
      }}
    />
  )
}
