import {
  type TextFieldProps,
  TextField as MuiTextField,
  InputLabel,
  Box,
  type SxProps,
  type Theme,
} from '@mui/material'
import { type ForwardedRef, forwardRef } from 'react'
import { isPresent } from '@jjvgaming/player-payback-library'

interface ExtraTextFieldProps {
  sublabel?: React.ReactNode
  bottomContent?: React.ReactNode
  containerStyle?: SxProps<Theme>
}

export const TextField = forwardRef(
  (
    {
      label,
      sublabel,
      required,
      name,
      bottomContent,
      containerStyle,
      ...otherProps
    }: TextFieldProps & ExtraTextFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Box sx={containerStyle}>
        {label && (
          <InputLabel required={required} htmlFor={name}>
            {label}
          </InputLabel>
        )}
        {isPresent(sublabel) && sublabel}
        <MuiTextField
          fullWidth
          variant="outlined"
          id={name}
          name={name}
          ref={ref}
          {...otherProps}
        />
        {bottomContent}
      </Box>
    )
  }
)

TextField.displayName = 'TextField'
