import { Box, Typography } from '@mui/material'
import {
  type GridRenderCellParams,
  type GridColDef,
  DataGrid,
} from '@mui/x-data-grid'
import { type components } from 'api/playerPayback/api'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { SwitchRewardsProgramActive } from 'components/RewardsProgram/SwitchRewardsProgramActive'
import { useCallback, useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal'
import { usePostRewardsProgram } from 'hooks/api/usePostRewardsProgram'
import { useSnackbar } from 'stores/useSnackbar'
import { CellLink } from 'components/CellLink/CellLink'
import { green, grey } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import type { RewardsProgram } from 'types/api'
import { useGetRewardProgramByOrganizationId } from 'hooks/api/useGetRewardProgramByOrganizationId'
import { OrganizationRewardsProgramMobileRow } from './OrganizationRewardsProgramMobileRow'
import { SectionHeader } from 'components/SectionHeader'

const desktopSxDataGrid = {
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'white',
  },
}

const mobileSxDataGrid = {
  border: 0,
  backgroundColor: grey[50],
  '& .MuiDataGrid-row:hover': {
    backgroundColor: grey[50],
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 0,
  },
  '& .MuiDataGrid-cell': {
    border: 0,
  },
}

const getDesktopColumns = (): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Program Name',
      flex: 1,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<RewardsProgram>) => (
        <CellLink
          text={params.row.name}
          to={`/Rewards/RewardPrograms/${String(params.row.id)}`}
        />
      ),
    },
    {
      field: 'active',
      headerName: 'Status',
      maxWidth: 230,
      renderCell: (params: GridRenderCellParams<RewardsProgram>) => {
        return (
          <Box
            className="px-2 pt-1 pb-0.5 rounded-sm font-bold uppercase min-w-full min-h-full flex justify-center  items-center"
            sx={{
              backgroundColor: params.row.active ? green[100] : grey[300],
              color: params.row.active ? green[900] : grey[700],
            }}
          >
            {params.row.active ? 'ACTIVE' : 'INACTIVE'}
          </Box>
        )
      },
    },
    {
      field: '__switch__',
      headerName: '',
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams<RewardsProgram>) => (
        <SwitchRewardsProgramActive rewardsProgram={params.row} />
      ),
    },
  ]
}

const getMobileColumns = (): GridColDef[] => [
  {
    field: '__mobile__',
    headerName: '',
    flex: 1,
    align: 'right',
    renderCell: (params: GridRenderCellParams<RewardsProgram>) => (
      <OrganizationRewardsProgramMobileRow row={params.row} />
    ),
  },
]

const NoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'text.secondary',
        p: '16px',
        justifyContent: 'center',
        backgroundColor: grey[100],
        height: '110px',
      }}
    >
      <Typography variant="body1">No organization reward program</Typography>
    </Box>
  )
}

interface RewardProgramSectionPropType {
  organization: components['schemas']['FullOrganizationDTO']
}

export const RewardProgramSection = ({
  organization,
}: RewardProgramSectionPropType) => {
  const { isMobile } = useLayout()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const RewardProgramQuery = useGetRewardProgramByOrganizationId({
    organizationId: organization.id,
  })

  const toggleModalIsOpen = useCallback(() => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen, setIsModalOpen])

  const { mutate: postRewardsProgramMutation } = usePostRewardsProgram({
    onSuccess: (_, variables: components['schemas']['RewardsProgramDTO']) => {
      toggleModalIsOpen()
      setSnackbarMessage(`${variables.name} created successfully.`)
    },
    onError: (_, variables: components['schemas']['RewardsProgramDTO']) => {
      setSnackbarMessage(
        `Error creating reward program ${variables.name}.`,
        'error'
      )
    },
  })

  const createRewardsProgram = useCallback(() => {
    postRewardsProgramMutation({
      name: `${organization.name}`,
      active: true,
      type: 'Organization',
      organizationId: organization.id,
    })
  }, [organization, postRewardsProgramMutation])

  if (RewardProgramQuery.isPending) {
    return <ActivityIndicator sx={{ height: '123px' }} />
  }

  return (
    <Box className="pt-10">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          row: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            rowGap: '16px',
          }}
        >
          <SectionHeader
            title="Reward Programs"
            buttonText="Create Reward Program"
            showButton={isEmpty(RewardProgramQuery.data)}
            onClickButton={toggleModalIsOpen}
          />
        </Box>
        <DataGrid
          autoHeight
          columns={isMobile ? getMobileColumns() : getDesktopColumns()}
          rows={RewardProgramQuery.data ?? []}
          getRowHeight={() => (isMobile ? 125 : 'auto')}
          columnHeaderHeight={
            isMobile || isEmpty(RewardProgramQuery.data) ? 0 : 56
          }
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          hideFooter
          sx={
            isMobile || isEmpty(RewardProgramQuery.data)
              ? mobileSxDataGrid
              : desktopSxDataGrid
          }
        />
      </Box>
      <ConfirmModal
        confirmMessage={`Please confirm you want to create the following rewards program for ${organization.name}`}
        secondaryMessage={`${organization.name}`}
        isModalOpen={isModalOpen}
        toggleIsOpen={toggleModalIsOpen}
        onConfirm={createRewardsProgram}
        onCancel={toggleModalIsOpen}
      />
    </Box>
  )
}
