import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import { type ContestLE } from 'types/api'

export const useGetParticipatingLocations = ({
  contestId,
}: {
  contestId: number
}): UseQueryResult<ContestLE[]> =>
  useApiQuery({
    method: 'get',
    path: '/admin/contests/{contestId}/licensed-establishments/participating',
    queryKey: [
      '/admin/contests/{contestId}/licensed-establishments/participating',
      contestId,
    ],
    queryArgs: {
      contestId,
    },
  })
