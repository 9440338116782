import { Box, Typography } from '@mui/material'
import FileIcon from 'assets/file.svg'
import { isNil } from 'lodash'

export const RecordsInformation = ({
  startDate,
  endDate,
  recordsLength,
}: {
  startDate?: string
  endDate?: string
  recordsLength: number | undefined
}) => {
  if (!startDate || !endDate || isNil(recordsLength)) {
    return (
      <Box className="flex flex-row gap-6 w-3/4 items-center">
        <Typography variant="body-1">
          Enter the start and end dates to search for records
        </Typography>
      </Box>
    )
  }

  return (
    <Box className="flex flex-col lg:flex-row gap-6 lg:w-3/4 lg:items-center">
      <Box className="min-w-[200px]">
        <Typography variant="body-2">Records</Typography>
        <Box className="flex flex-row items-stretch gap-1">
          <img src={FileIcon} width={20} alt="Export icon" className="mb-1" />
          <Typography variant="body-1">
            {`${recordsLength} redemption records found`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="body-2">Selected Dates</Typography>
        <Typography variant="body-1">{`${startDate} - ${endDate}`}</Typography>
      </Box>
    </Box>
  )
}
