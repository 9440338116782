import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { colorPrimitives } from 'components/Theme'

interface PromoteSweepstakeModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  onConfirm: () => void
  sweepstakeName: string
}

export const PromoteSweepstakeModal = ({
  isModalOpen,
  onConfirm,
  toggleIsOpen,
  sweepstakeName,
}: PromoteSweepstakeModalProps) => {
  return (
    <Dialog open={isModalOpen} onClose={toggleIsOpen} maxWidth="md">
      <DialogTitle
        bgcolor={'rgba(247, 247, 246, 1)'}
        sx={{ borderBottom: '1px solid #e5e7eb' }}
      >
        <Box px={3} py={1}>
          <Typography
            variant="h3"
            fontWeight={'bold'}
            sx={{ lineHeight: { xs: '24px', sm: '33px' } }}
          >
            Promote Sweepstakes
          </Typography>
          <Typography variant="body-1" color={colorPrimitives.black}>
            {sweepstakeName}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ my: '24px', mx: 3 }}>
        <Typography variant="h4" fontWeight="bold">
          Are you sure you want to promote {sweepstakeName}?
        </Typography>
        <Typography sx={{ mt: '8px', color: colorPrimitives.gray70 }}>
          Once &quot;Promote&quot; is selected, all patrons will see this
          sweepstakes as upcoming in the J&J Gaming App. Once the sweepstakes
          goes live, this promotion will be replaced by the live sweepstakes.
        </Typography>
      </DialogContent>
      <DialogActions className="border-t">
        <Button variant="text" onClick={toggleIsOpen}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Promote
        </Button>
      </DialogActions>
    </Dialog>
  )
}
