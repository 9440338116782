import { LoginPage } from 'pages/Login/LoginPage'
import { getProtectedRoutes } from './ProtectedNavigation'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
} from 'react-router-dom'
import { getDeveloperRoutes } from './DeveloperNavigation'
import { ProtectedLayout } from './ProtectedLayout'
import { SupportTools } from 'pages/SupportTools/SupportTools'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LoginPage />} />
      <Route element={<ProtectedLayout />}>
        {getProtectedRoutes()}
        {getDeveloperRoutes()}
        {import.meta.env.VITE_ENABLE_SUPPORT_TOOLS === 'true' && (
          <Route
            path={'SupportTools'}
            key={'SupportTools'}
            element={<SupportTools />}
          />
        )}
      </Route>
    </>
  )
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
