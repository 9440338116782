import { Box, Divider, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { type components } from 'api/playerPayback/api'
import { SectionHeader } from 'components/SectionHeader'

type CorporateAccountType = components['schemas']['FullCorporateAccountDTO']
interface CorporateAccountDetailsProps {
  corporateAccount: CorporateAccountType | undefined
  onClickEdit: () => void
}

export const AssociatedCorporateAccountDetailsSection = ({
  corporateAccount,
  onClickEdit,
}: CorporateAccountDetailsProps) => {
  const { isMobile } = useLayout()
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Corporate Account Name',
      minWidth: 420,
    },
    {
      field: 'id',
      headerName: 'Corporate Account ID',
      flex: 1,
    },
  ]

  if (corporateAccount != null) {
    if (isMobile) {
      return (
        <Box>
          <SectionHeader
            title="Associated Corporate Account"
            onClickButton={onClickEdit}
          />
          <Box pl={2}>
            <Typography variant="h3" pb={1}>
              {corporateAccount.name}
            </Typography>
            <Typography variant="body1" pb={3} color={'text.secondary'}>
              {corporateAccount?.id !== null &&
              corporateAccount?.id !== undefined
                ? `ID# ${corporateAccount.id}`
                : ''}
            </Typography>
          </Box>
          <Divider />
        </Box>
      )
    } else {
      return (
        <Box>
          <SectionHeader
            title="Associated Corporate Account"
            onClickButton={onClickEdit}
          />
          <DataTable
            columns={columns}
            rows={[{ id: corporateAccount.id, name: corporateAccount.name }]}
          />
        </Box>
      )
    }
  }
}
