import { useApiQuery } from 'hooks/useApiQuery'

export const useGetNumberLocationPrizeEntries = ({
  contestId,
  licensedEstablishmentId,
}: {
  contestId?: number
  licensedEstablishmentId?: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/contests/entries/contest/{contestId}/licensed-establishment/{licensedEstablishmentId}/total-entries',
    queryKey: [
      '/admin/contests/entries/contest/{contestId}/licensed-establishment/{licensedEstablishmentId}/total-entries',
    ],
    queryArgs: { contestId, licensedEstablishmentId },
  })
