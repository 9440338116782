import { useQuery } from '@tanstack/react-query'
import { ApiError, type OpReturnType } from 'openapi-typescript-fetch'
import { type paths } from 'api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { isNil } from 'lodash'
import { isPresent } from '@jjvgaming/player-payback-library'

export const route = '/admin/organizations/corporate-accounts/{id}'

export type OrganizationsGetResponse = OpReturnType<paths[typeof route]['get']>

export const useGetOrganizationsByCorporateAccountId = ({
  corporateAccountId,
  enabled = true,
}: {
  corporateAccountId?: number
  enabled?: boolean
}) => {
  const get = useTypedApiClient({
    path: route,
    method: 'get',
  })
  return useQuery({
    queryKey: [route, corporateAccountId],
    queryFn: async () => {
      if (isNil(corporateAccountId)) {
        throw new Error("corporateAccountId can't be nil")
      }

      try {
        const result = await get({
          id: corporateAccountId,
        })
        return result.data
      } catch (err) {
        if (err instanceof ApiError && err.status === 404) {
          return []
        } else {
          throw err
        }
      }
    },
    enabled: enabled && isPresent(corporateAccountId),
  })
}
