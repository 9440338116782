import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { type GridColDef } from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useRewardsCatalog } from 'hooks/api/useRewardsCatalog'
import { DataTable } from 'components/Shared/DataTable'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Button, Link } from '@mui/material'
import { useState } from 'react'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { type RewardsCatalog } from 'types/api'

const dataTableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Reward Catalog Name',
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'Catalog ID',
    flex: 1,
    valueFormatter: (params) => `#${params.value as string}`,
  },
]

export const RewardCatalogsPage = () => {
  const rewardsCatalogQuery = useRewardsCatalog({})
  const navigate = useNavigate()
  const [filteredData, setFilteredData] = useState<RewardsCatalog[]>()

  const handleFilteredData = (filteredData: RewardsCatalog[]) => {
    setFilteredData(filteredData)
  }

  return (
    <Page
      header={
        <PageHeader
          title="Reward Catalogs"
          eyebrow="REWARDS"
          actionButton={
            <Link to="/Rewards/RewardCatalogs/New" component={RouterLink}>
              <Button variant="contained" fullWidth>
                Create New Reward Catalog
              </Button>
            </Link>
          }
        />
      }
    >
      {rewardsCatalogQuery.isPending ? (
        <ActivityIndicator />
      ) : (
        <>
          <GridSearchField<'FullRewardsCatalogDTO'>
            placeholder="Search Reward Catalogs"
            data={rewardsCatalogQuery.data ?? []}
            handleFilteredData={handleFilteredData ?? []}
            fullWidth
          />

          <DataTable
            columns={dataTableColumns}
            rows={filteredData ?? rewardsCatalogQuery.data ?? []}
            onRowClick={(params) => {
              if (params.id !== null) {
                navigate(`/Rewards/RewardCatalogs/${params.id}`)
              }
            }}
          />
        </>
      )}
    </Page>
  )
}
