import { InputLabel, Grid, TextField } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import { object, string, type InferType } from 'yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import {
  isPresent,
  applyPhoneMask,
  getPhoneMaskRegex,
} from '@jjvgaming/player-payback-library'

export const ContactSchema = object({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  phoneNumber: string()
    .matches(getPhoneMaskRegex, {
      message: 'Please enter a valid phone number',
    })
    .required('Phone number is required'),
  email: string().email('Email is required').required('Email is required'),
})

export type ContactForm = InferType<typeof ContactSchema>

export const Contact = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<{ contact: ContactForm }>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel htmlFor="contact.firstName" required>
          Contact Information
        </InputLabel>
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...register('contact.firstName')}
          error={isPresent(errors.contact?.firstName)}
          placeholder="First Name"
          fullWidth
        />
        {errors.contact?.firstName?.message && (
          <FormFieldErrorMessage message={errors.contact.firstName.message} />
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...register('contact.lastName')}
          error={isPresent(errors.contact?.lastName)}
          placeholder="Last Name"
          fullWidth
        />
        {errors.contact?.lastName?.message && (
          <FormFieldErrorMessage message={errors.contact?.lastName.message} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="contact.phoneNumber"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextField
              error={!!errors.contact?.phoneNumber}
              fullWidth
              placeholder="Phone number"
              onChange={(phoneNumber) => {
                onChange(applyPhoneMask(String(phoneNumber.target.value)))
              }}
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        {errors.contact?.phoneNumber?.message && (
          <FormFieldErrorMessage message={errors.contact.phoneNumber.message} />
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register('contact.email')}
          error={isPresent(errors.contact?.email)}
          type="email"
          fullWidth
          placeholder="Email address"
        />
        {errors.contact?.email?.message && (
          <FormFieldErrorMessage message={errors.contact.email.message} />
        )}
      </Grid>
    </Grid>
  )
}
