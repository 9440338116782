import { number, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import type { RewardsProgram } from 'types/api'
import { grey } from '@mui/material/colors'
import { colorPrimitives } from 'components/Theme'
import { useSnackbar } from 'stores/useSnackbar'
import { type ApiError } from 'openapi-typescript-fetch'
import { usePutRewardsProgram } from 'hooks/api/usePutRewardsProgram'
import { useQueryClient } from '@tanstack/react-query'

const NumberOfRedemptionsPerPatronPerDaySettingsSchema = object({
  redemptionsPerDay: number()
    .required('Required')
    .transform((val) => (isNaN(val) ? undefined : val))
    .min(
      1,
      'Number of Redemptions Per Patron Per Day must be greater than or equal to 1'
    )
    .max(99, 'Number of Redemptions Per Patron Per Day must be less than 100'),
})

export const NumberOfRedemptionsPerPatronPerDaySettingsModal = ({
  isModalOpen,
  toggleIsOpen,
  toggleModalIsOpen,
  rewardsProgram,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  toggleIsOpen: () => void
  rewardsProgram: RewardsProgram
}) => {
  const formMethods = useForm({
    resolver: yupResolver(NumberOfRedemptionsPerPatronPerDaySettingsSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()
  const putRewardProgramMutation = usePutRewardsProgram({
    onSuccess: () => {
      setSnackbarMessage(`Reward Program has been updated.`)
      toggleModalIsOpen()
      void queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-programs'],
      })
    },
    onError: (error: ApiError) => {
      if (typeof error.data === 'string') {
        if (error.data.includes('RedemptionsPerDay')) {
          control.setError('redemptionsPerDay', { message: error.data })
        }
      } else {
        setSnackbarMessage(
          `Error: Cannot save Reward Program information`,
          'error'
        )
      }
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    putRewardProgramMutation.mutate({
      id: rewardsProgram.id,
      rewardsProgramData: {
        name: String(rewardsProgram.name),
        description: String(rewardsProgram.description),
        active: rewardsProgram.active,
        type: rewardsProgram.type,
        corporateAccountId: rewardsProgram.corporateAccountId,
        licensedEstablishmentId: rewardsProgram.licensedEstablishmentId,
        maxRedemptionsPerPatronPerDay: Number(formData.redemptionsPerDay),
        organizationId: rewardsProgram.organizationId,
      },
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <form onSubmit={handleSubmitWrapper} noValidate>
        <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
          <Box px={4} py={4}>
            <Typography variant="h3" fontWeight={'bold'}>
              Number of Redemptions Per Patron Per Day
            </Typography>
            <Typography variant="body-1" color={'text.secondary'}>
              {rewardsProgram.name}
            </Typography>
          </Box>
        </DialogTitle>
        <div></div>
        <DialogContent sx={{ pt: 4, px: 4 }}>
          <Box>
            <Box className="flex flex-col sm:flex-row justify-stretch w-full px-1">
              <Box className="w-full sm:w-1/2 sm:pt-4 flex">
                <Typography># of Redemptions per day</Typography>
                <Typography color={colorPrimitives.redGaming}>*</Typography>
              </Box>
              <Box className="flex flex-row gap-6 w-full sm:w-1/2">
                <TextField
                  {...register('redemptionsPerDay', {
                    required: true,
                  })}
                  type="number"
                  error={isPresent(errors.redemptionsPerDay)}
                  sx={{ width: '100px' }}
                  defaultValue={rewardsProgram.maxRedemptionsPerPatronPerDay}
                />
                {errors.redemptionsPerDay?.message && (
                  <FormFieldErrorMessage
                    message={errors.redemptionsPerDay.message}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
        >
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            variant="contained"
            active={false}
            onClick={handleSubmitWrapper}
          >
            Save
          </ActivityButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
