import { useState, useMemo, useEffect, useCallback } from 'react'
import { useGetRewardsCatalogById } from 'hooks/api/useGetRewardsCatalogById'
import { useParams, useNavigate } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import {
  type RewardsCatalogReward,
  getRewardsCatalogRewardsMap,
  addRewardsCatalogReward,
  removeRewardsCatalogReward,
  changeRewardsCatalogRewardField,
  clearAllAmountErrors,
  validateAllAmounts,
} from 'utils/rewardsCatalogRewards'
import { type Reward } from 'types/api'
import { useSnackbar } from 'stores/useSnackbar'
import { usePostRewardsCatalogRewardsBulk } from 'hooks/api/usePostRewardsCatalogRewardsBulk'

export const useManageCatalogRewards = () => {
  const [step, setStep] = useState<number>(0)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const param = useParams()
  const navigate = useNavigate()

  const rewardsCatalogByIdQuery = useGetRewardsCatalogById({
    rewardsCatalogId: Number(param.id),
  })

  const isRewardsCatalogLoading = rewardsCatalogByIdQuery.isPending
  const isRewardsCatalogError = rewardsCatalogByIdQuery.isError
  const rewardsCatalog = rewardsCatalogByIdQuery.data

  const oldRewardsCatalogRewards = useMemo(
    () =>
      getRewardsCatalogRewardsMap(rewardsCatalog?.rewardsCatalogRewards ?? []),
    [rewardsCatalog]
  )

  const [rewardsCatalogRewards, setRewardsCatalogRewards] = useState<
    Map<number, RewardsCatalogReward>
  >(new Map<number, RewardsCatalogReward>())

  useEffect(() => {
    setRewardsCatalogRewards(cloneDeep(oldRewardsCatalogRewards))
  }, [oldRewardsCatalogRewards])

  const mutation = usePostRewardsCatalogRewardsBulk({
    rewardsCatalogId: Number(rewardsCatalog?.id),
    isDeleteRewardsCatalogRewards: true,
    onSuccess: () => {
      setSnackbarMessage(
        `${rewardsCatalogRewards.size} Item${
          rewardsCatalogRewards?.size > 1 ? 's' : ''
        } added to Catalog`
      )
      navigate(`/Rewards/RewardCatalogs/${rewardsCatalog?.id}`)
    },
    onError: () => {
      setSnackbarMessage('Error adding reward items', 'error')
    },
  })

  const onClickCancel = useCallback(() => {
    navigate(`/Rewards/RewardCatalogs/${rewardsCatalog?.id}`)
  }, [rewardsCatalog, navigate])

  const onAddRewardCatalogReward = useCallback(
    (reward: Reward) => {
      setRewardsCatalogRewards(
        addRewardsCatalogReward(
          reward,
          oldRewardsCatalogRewards,
          rewardsCatalogRewards
        )
      )
    },
    [oldRewardsCatalogRewards, rewardsCatalogRewards, setRewardsCatalogRewards]
  )

  const onRemoveRewardCatalogReward = useCallback(
    (rewardId: number, validateAtLeast1: boolean | undefined = false) => {
      if (validateAtLeast1 && rewardsCatalogRewards.size < 2) {
        setSnackbarMessage('At least 1 reward must be selected.', 'error')
      } else {
        setRewardsCatalogRewards(
          removeRewardsCatalogReward(rewardId, rewardsCatalogRewards)
        )
        if (validateAtLeast1) {
          setSnackbarMessage('1 item successfully removed.')
        }
      }
    },
    [rewardsCatalogRewards, setRewardsCatalogRewards]
  )

  const onClickNextSelectRewards = useCallback(() => {
    if (rewardsCatalogRewards.size === 0) {
      setSnackbarMessage('At least 1 reward must be selected.', 'error')
    } else {
      setStep(1)
    }
  }, [rewardsCatalogRewards, setStep, setSnackbarMessage])

  const onClickBackToSelectRewards = useCallback(() => {
    setStep(0)
  }, [setStep])

  const onChangeRewardsCatalogRewardField = useCallback(
    (rewardId: number, field: 'amount' | 'amountError', value?: string) => {
      setRewardsCatalogRewards(
        changeRewardsCatalogRewardField(
          rewardsCatalogRewards,
          rewardId,
          field,
          value
        )
      )
    },
    [rewardsCatalogRewards, setRewardsCatalogRewards]
  )

  const onSubmit = useCallback(async () => {
    let newRewardsCatalogRewards = clearAllAmountErrors(rewardsCatalogRewards)
    const validation = await validateAllAmounts(newRewardsCatalogRewards)
    if (validation.valid) {
      const data = Array.from(newRewardsCatalogRewards).map((entry) => ({
        rewardsCatalogId: Number(rewardsCatalog?.id),
        rewardId: Number(entry[1].rewardId),
        amount: Number(entry[1].amount),
      }))
      mutation.mutate(data)
    } else {
      newRewardsCatalogRewards = validation.rewardsCatalogRewards
      setSnackbarMessage(
        'Please correct the errors before adding items to the catalog.',
        'error'
      )
    }
    setRewardsCatalogRewards(newRewardsCatalogRewards)
  }, [
    rewardsCatalog,
    rewardsCatalogRewards,
    setRewardsCatalogRewards,
    mutation,
  ])

  return {
    step,
    isRewardsCatalogLoading,
    isRewardsCatalogError,
    rewardsCatalog,
    rewardsCatalogRewards,
    onClickCancel,
    onAddRewardCatalogReward,
    onRemoveRewardCatalogReward,
    onClickNextSelectRewards,
    onClickBackToSelectRewards,
    onChangeRewardsCatalogRewardField,
    onSubmit,
    isMutationLoading: mutation.isPending,
  }
}
