import { KeyboardArrowDown } from '@mui/icons-material'
import { Menu, MenuItem, Button } from '@mui/material'
import { type SweepstakeWinnerStatusType } from 'types/api'
import { SweepstakeNotifyWinnersModal } from './SweepstakeNotifyWinnersModal'
import { type SelectedWinnerType } from 'pages/Contests/Sweeptakes/SweepstakesWinnersPage/SweepstakesWinnersPage'

import { useState } from 'react'

interface MenuButtonProps {
  selectedWinners: SelectedWinnerType[]
  clearSelectedWinners: () => void
  winnerStatusFilter: SweepstakeWinnerStatusType
  sweepstakeName: string
}

export const SweepstakesWinnerActionsMenu = ({
  selectedWinners,
  clearSelectedWinners,
  winnerStatusFilter,
  sweepstakeName,
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [notifyWinnersModalOpen, setNotifyWinnersModalOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClick}
        disabled={
          selectedWinners.length === 0 ||
          winnerStatusFilter !== 'WinnerValidation'
        }
      >
        Action To Take
        <KeyboardArrowDown fontSize="small" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {winnerStatusFilter === 'WinnerValidation' && (
          <MenuItem
            onClick={() => {
              setNotifyWinnersModalOpen(true)
              handleClose()
            }}
          >
            Notify Winners
          </MenuItem>
        )}
        {winnerStatusFilter === 'AffidavitReceived' && (
          <MenuItem onClick={handleClose}>Redraw Request</MenuItem>
        )}
        {winnerStatusFilter === 'Awarded' && (
          <MenuItem onClick={handleClose}>Awarded</MenuItem>
        )}
        {winnerStatusFilter === 'WinnerNotified' && (
          <MenuItem onClick={handleClose}>Notified</MenuItem>
        )}
      </Menu>
      <SweepstakeNotifyWinnersModal
        sweepstakeName={sweepstakeName}
        isModalOpen={notifyWinnersModalOpen}
        toggleIsOpen={() => setNotifyWinnersModalOpen(!notifyWinnersModalOpen)}
        selectedWinners={selectedWinners}
        clearSelectedWinners={clearSelectedWinners}
        isBulkAction={true}
      />
    </div>
  )
}
