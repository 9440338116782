import { ReactComponent as ClientManagementIcon } from 'assets/nav_icons/client_management.svg'
import { Box, SvgIcon, Typography } from '@mui/material'
import { type components } from 'api/playerPayback/api'
import { colorPrimitives } from './Theme'

type licensedEstablishmentType =
  components['schemas']['FullLicensedEstablishmentDTO']
interface LicensedEstablishmentDetailsProps {
  licensedEstablishment: licensedEstablishmentType
}

export const LicensedEstablishmentShowcaseCard = ({
  licensedEstablishment,
}: LicensedEstablishmentDetailsProps) => {
  const address = licensedEstablishment.addresses?.[0]

  return (
    <Box
      sx={{
        margin: '10px 0',
        display: 'flex',
        alignItems: 'flex-start',
        background: colorPrimitives.lightGray,
        padding: 2,
        borderRadius: 1,
        maxWidth: '520px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ marginRight: '20px' }}>
          <SvgIcon
            sx={{ fontSize: 40, marginLeft: '0px', marginRight: '-5px' }}
          >
            <ClientManagementIcon />
          </SvgIcon>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="body-1"
            sx={{
              marginTop: '-5px',
              marginBottom: '-8px',
            }}
          >
            {address?.address1 && (
              <span>
                {address.address1}
                <br />
              </span>
            )}
            {address?.address2 && (
              <span>
                {address.address2}
                <br />
              </span>
            )}
            {address?.city && (
              <span>
                {address.city}
                <br />
              </span>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
