import { format, parseISO } from 'date-fns'
import { useGetLEUserById } from 'hooks/api/useGetLEUserById'
import { useMemo } from 'react'
import { isPresent } from '@jjvgaming/player-payback-library'
import { compact, some, join, uniq, isNil } from 'lodash'
import { useGetCorporateAccountsByIds } from './useGetCorporateAccountsByIds'
import { useGetOrganizationsByCorporateAccountIds } from './useGetOrganizationsByCorporateAccountIds'
import { useGetLicensedEstablishmentsByCorporateAccountIds } from './useGetLicensedEstablishmentsByCorporateAccountIds'

export const useUserData = ({ userId }: { userId: number }) => {
  const userQuery = useGetLEUserById({ userId })
  const user = userQuery.data
  const caQueries = useGetCorporateAccountsByIds({
    ids: uniq(
      user?.licensedEstablishmentUserCorporateAccounts?.map(
        (x) => x.corporateAccountId
      )
    ),
    enabled: !userQuery.isPending && isPresent(user),
  })
  const orgsQuery = useGetOrganizationsByCorporateAccountIds({
    corporateAccountIds: compact(caQueries.map((query) => query?.data?.id)),
    enabled: !userQuery.isPending && isPresent(user),
  })

  const lesQuery = useGetLicensedEstablishmentsByCorporateAccountIds({
    corporateAccountIds: compact(caQueries.map((query) => query?.data?.id)),
    enabled: !userQuery.isPending && isPresent(user),
  })

  // Format Updated AT Memo
  const formattedUpdatedAt = useMemo(() => {
    if (user?.modifiedOn) {
      return (
        'Last updated: ' +
        format(parseISO(user.modifiedOn), 'MM-dd-yyyy hh:mm a')
      )
    }

    return null
  }, [user])
  // Handle UserName memo
  const userName = useMemo(() => {
    if (isNil(user)) {
      return null
    }

    if (isPresent(user.firstName) || isPresent(user.lastName)) {
      return join(compact([user.firstName, user.lastName]), ' ')
    }

    return 'Unknown'
  }, [user])
  /// Return it all
  return {
    isPending:
      userQuery.isPending ||
      some(caQueries, (x) => x.isPending) ||
      some(orgsQuery, (x) => x.isPending) ||
      some(lesQuery, (x) => x.isPending),
    isError:
      userQuery.isError ||
      some(caQueries, (x) => x.isError) ||
      some(orgsQuery, (x) => x.isError) ||
      some(lesQuery, (x) => x.isError),
    user: userQuery.data,
    licensedEstablishments: lesQuery.map((x) => x.data),
    organizations: orgsQuery.map((x) => x.data),
    corporateAccounts: compact(caQueries.map((x) => x.data)),
    userName,
    formattedUpdatedAt,
  }
}
