import { useState, useRef } from 'react'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface RemoveRewardMenuProps {
  removeRewardCatalogReward: () => void
  rewardId?: number
}

export const RemoveRewardMenu = ({
  removeRewardCatalogReward,
  rewardId,
}: RemoveRewardMenuProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box>
      <IconButton
        onClick={toggleIsOpen}
        ref={anchorRef}
        data-testid={`moreIcon-${String(rewardId)}`}
      >
        <MoreVertIcon
          sx={{ color: isOpen ? 'primary.main' : 'text.secondary' }}
        />
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={toggleIsOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          onClick={removeRewardCatalogReward}
          sx={{
            width: '169px',
            height: '59px',
          }}
          data-testid={`removeButton-${String(rewardId)}`}
        >
          <Box
            sx={{
              m: '16px',
              px: '8px',
              backgroundColor: 'rgba(204, 32, 39, 0.08)',
              height: '43px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color="primary">Remove</Typography>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
