import { boolean, date, object, string } from 'yup'
import { type SystemBannerPlatformType, type SystemBannerType } from '../types'

export const SchedulingOptions = [
  'Display Immediately',
  'Schedule a Time',
] as const

export const SystemBannerSchema = object({
  platformType: string<SystemBannerPlatformType>().required(
    'Platform type is required'
  ),
  systemBanner: object().shape({
    type: string<SystemBannerType>().required('Communication type is required'),
    title: string()
      // eslint-disable-next-line no-template-curly-in-string
      .max(50, 'Title can be at most ${max} characters')
      .required('Title is required'),
    content: string()
      // eslint-disable-next-line no-template-curly-in-string
      .max(150, 'Content can be at most ${max} characters')
      .required('Content is required'),
    dismissable: boolean().required('Dismissable is required'),
    schedulingOption: string().oneOf(SchedulingOptions).required(),
    startDate: date().when('schedulingOption', {
      is: (val: string) => val === 'Schedule a Time',
      then: (schema) => schema.required('Start date is required'),
    }),
    endDate: date().when('schedulingOption', {
      is: (val: string) => val === 'Schedule a Time',
      then: (schema) => schema.required('End date is required'),
    }),
  }),
})
