import { isPresent } from '@jjvgaming/player-payback-library'
import { useApiQuery } from 'hooks/useApiQuery'
import { minimumSearchLength } from 'utils/util'

interface UseGetContestLicensedEstablishmentSearchParams {
  contestId: number
  searchKey?: string
}

export const useGetContestLicensedEstablishmentSearch = ({
  contestId,
  searchKey,
}: UseGetContestLicensedEstablishmentSearchParams) => {
  return useApiQuery({
    enabled: isPresent(searchKey) && searchKey.length >= minimumSearchLength,
    method: 'get',
    path: '/admin/contests/{contestId}/licensed-establishments/search',
    queryKey: [
      '/admin/contests/{contestId}/licensed-establishments/search',
      searchKey,
    ],
    queryArgs: {
      contestId,
      SearchKey: searchKey,
    },
  })
}
