import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type OrganizationRequest, type Organization } from 'types/api'
import {
  useMutation,
  useQueryClient,
  type UseMutationOptions,
} from '@tanstack/react-query'

type UsePutOrganizationProps = UseMutationOptions<
  Organization,
  Error,
  OrganizationRequest
>

export const usePutOrganization = ({
  onSuccess,
  onError,
}: UsePutOrganizationProps) => {
  const put = useTypedApiClient({
    path: '/admin/organizations/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<Organization, Error, OrganizationRequest>({
    mutationFn: async (data: OrganizationRequest) => {
      const response = await put({ ...data, id: Number(data.id) })
      return response.data
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/organizations', Number(data.id)],
      })
      onSuccess?.(data, variables, context)
    },
    onError,
  })
}
