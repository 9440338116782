import { useApiQuery } from 'hooks/useApiQuery'

export const useGetWinnerById = ({
  sweepstakeWinnerId,
}: {
  sweepstakeWinnerId: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/sweepstake-winners/{id}',
    queryKey: ['/sweepstake-winners', sweepstakeWinnerId],
    queryArgs: { id: sweepstakeWinnerId },
  })
