import { Box, Typography, Button, CircularProgress } from '@mui/material'
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg'

interface AssignRedemptionValueFooterProps {
  onClickBack: () => void
  onClickCancel: () => void
  onClickNext: () => void
  submitText: string
  isMutationLoading: boolean
  isMutationButtonDisabled: boolean
}
export const AssignRedemptionValueFooter = ({
  onClickBack,
  onClickCancel,
  onClickNext,
  submitText,
  isMutationLoading,
  isMutationButtonDisabled,
}: AssignRedemptionValueFooterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        display={'flex'}
        flexDirection="row"
        alignItems="center"
        gap={1}
        color="text.secondary"
        onClick={onClickBack}
        sx={{ cursor: 'pointer' }}
      >
        <ArrowLeft />
        <Typography pt={0.5} variant="body2">
          Back
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: { xs: '0px', sm: '16px' },
        }}
      >
        <Button variant={'text'} onClick={onClickCancel}>
          Cancel
        </Button>
        <Button
          disabled={isMutationButtonDisabled}
          variant="contained"
          onClick={onClickNext}
          startIcon={
            isMutationLoading ? (
              <CircularProgress size={16} color="inherit" />
            ) : null
          }
        >
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}
