import { Typography, Box, useTheme, Stack } from '@mui/material'
import type { RewardsProgram } from 'types/api'

interface NumberOfRedemptionsPerPatronPerDayDetailsProps {
  rewardsProgram: RewardsProgram
}

export const NumberOfRedemptionsPerPatronPerDayDetails = ({
  rewardsProgram,
}: NumberOfRedemptionsPerPatronPerDayDetailsProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.grey[200],
        mt: '16px',
        gap: '16px',
        p: '24px',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: { xs: 'column' },
          gap: '16px',
        }}
      >
        <Stack spacing={3}>
          <Box display={'flex'} flexDirection={'row'}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" pb={1} color="text.secondary">
                # of Redemptions per day
              </Typography>
              <Typography variant="body1">
                {rewardsProgram.maxRedemptionsPerPatronPerDay}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
