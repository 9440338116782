import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type Contest, type ContestRequest } from 'types/api'
import { useMutation } from '@tanstack/react-query'
import { isNil } from 'lodash'

interface UsePutContestProps {
  onSuccess?: (data: Contest, variables: ContestRequest) => void | Promise<void>
  onError?: (error: Error, variables: ContestRequest) => void
}

export const usePutContest = ({ onSuccess, onError }: UsePutContestProps) => {
  const put = useTypedApiClient({
    path: '/admin/contests/{id}',
    method: 'put',
  })

  return useMutation<Contest, Error, ContestRequest>({
    mutationFn: async (data: ContestRequest) => {
      const id = data.id
      if (isNil(id)) {
        throw Error('Id is null')
      }
      const response = await put({ ...data, id })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await onSuccess?.(data, variables)
    },
    onError,
  })
}
