import { Box, Stack } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PlatformSelect } from './PlatformSelect'
import { SystemBannerForm } from './SystemBannerForm'
import { SystemBannerSchema } from './SystemBannerSchema'
import { usePostSystemBanner } from 'hooks/api/usePostSystemBanner'

export const CommunicationsSystemBannerNewPage = () => {
  const formMethods = useForm({
    resolver: yupResolver(SystemBannerSchema),
    mode: 'onChange',
  })
  const { handleSubmit } = formMethods
  const { mutate: postSystemBanner } = usePostSystemBanner()

  return (
    <Page
      header={
        <PageHeader
          title="Create a New System Banner"
          subtitle="Fill out every field in the form before creating the new system banner."
          backPath="/Communications"
          backText="Communications"
          isSecondary={true}
        />
      }
    >
      <Box maxWidth={500}>
        <form
          onSubmit={handleSubmit((data) => {
            postSystemBanner(data)
          })}
        >
          <FormProvider {...formMethods}>
            <Stack gap={4}>
              <PlatformSelect />
              <SystemBannerForm />
            </Stack>
          </FormProvider>
        </form>
      </Box>
    </Page>
  )
}
