import { Box, Button, Typography } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { usePutSweepstake } from 'hooks/api/Sweepstake/usePutSweepstake'
import { type Sweepstake, type SweepstakeStatusType } from 'types/api'
import { useSnackbar } from 'stores/useSnackbar'

interface SweepstakeSettingsEnrollmentModalProps {
  isModalOpen: boolean
  toggleIsOpen: () => void
  onCancel: () => void
  sweepstake: Sweepstake
  isEnrollmentOpen: boolean
}

export const SweepstakeSettingsEnrollmentModal = ({
  isModalOpen,
  toggleIsOpen,
  onCancel,
  sweepstake,
  isEnrollmentOpen,
}: SweepstakeSettingsEnrollmentModalProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const heightModal = '51%'

  const enrollmentSettings: {
    title: string
    subTitle: string
    confirmButtonText: string
    confirmMessage: string
    errorMessage: string
    enrollmentState: SweepstakeStatusType
    enrollableOpenedDate: string | null | undefined
    enrollableClosedDate: string | null | undefined
    boxMessage: string
  } = isEnrollmentOpen
    ? {
        title: 'Open Enrollment to J&J Connect',
        subTitle: `Are you sure you want to open enrollment for ${sweepstake.name}?`,
        confirmButtonText: 'Open Enrollment',
        confirmMessage: 'Enrollment has been opened.',
        errorMessage: 'Error opening enrollment',
        enrollmentState: 'Enrollable',
        enrollableOpenedDate: new Date().toUTCString(),
        enrollableClosedDate: sweepstake.sweepstakeData?.enrollableClosedDate,
        boxMessage:
          'Once enrollment is open, all J&J Connect users will have access to view the upcoming sweepstakes and register their locations for participation.',
      }
    : {
        title: 'Close Enrollment to J&J Connect',
        subTitle: `Are you sure you want to close enrollment for ${sweepstake.name}?`,
        confirmButtonText: 'Close Enrollment',
        confirmMessage: 'Enrollment has been closed.',
        errorMessage: 'Error closing enrollment',
        enrollmentState: 'EnrollmentClosed',
        enrollableOpenedDate: sweepstake.sweepstakeData?.enrollableOpenedDate,
        enrollableClosedDate: new Date().toUTCString(),
        boxMessage:
          'Once enrollment is closed, J&J Connect users will no longer be able to register their locations for participation.',
      }

  const putSweepstakeMutation = usePutSweepstake({
    sweepstakeId: sweepstake.id,
    onSuccess: () => {
      setMessage(enrollmentSettings.confirmMessage, 'success')
      toggleIsOpen()
    },
    onError: () => {
      setMessage(enrollmentSettings.errorMessage, 'error')
    },
  })

  const handleConfirm = () => {
    putSweepstakeMutation.mutate({
      ...sweepstake,
      sweepstakeData: {
        contestId: sweepstake.id,
        id: sweepstake.sweepstakeData?.id ?? 0,
        numGrandPrizeWinnersPerTier:
          sweepstake.sweepstakeData?.numGrandPrizeWinnersPerTier ?? 1,
        participationGuidelines:
          sweepstake.sweepstakeData?.participationGuidelines,
        state: enrollmentSettings.enrollmentState,
        enrollableOpenedDate: enrollmentSettings.enrollableOpenedDate,
        enrollableClosedDate: enrollmentSettings.enrollableClosedDate,
        promoted: sweepstake.sweepstakeData?.promoted,
      },
    })
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleIsOpen}
      sx={{
        width: { xs: '90vw', sm: '50%' },
        height: 'heightModal',
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleIsOpen}
        title={enrollmentSettings.title}
        subTitle={sweepstake.name}
      />
      <ModalFullBody
        heightModal={heightModal}
        sx={{
          height: '175px',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Box>
          <Typography variant="h4" fontSize={18} paddingBottom={2}>
            {enrollmentSettings.subTitle}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {enrollmentSettings.boxMessage}
          </Typography>
        </Box>
      </ModalFullBody>
      <ModalFullFooter>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
          sx={{
            paddingLeft: '22px',
            paddingBottom: '8px',
            paddingTop: '8px',
            paddingRight: '22px',
          }}
        >
          {enrollmentSettings.confirmButtonText}
        </Button>
      </ModalFullFooter>
    </Modal>
  )
}
