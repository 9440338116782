import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type {
  FullRewardsOptions,
  RewardsOptions,
  RewardsOptionsType,
} from 'types/api'

interface usePutRewardsOptionsProps {
  rewardId: number
  type: RewardsOptionsType | null
  value: string
  id: number | null | undefined
  onSuccess?: (data: FullRewardsOptions, variables: RewardsOptions) => void
  onError?: (error: Error, variables: RewardsOptions) => void
}

export const usePutRewardsOptions = ({
  rewardId,
  id,
  onSuccess,
  onError,
}: usePutRewardsOptionsProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards-options/{rewardId}/{type}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, RewardsOptions>({
    mutationFn: async (data: RewardsOptions) => {
      const response = await put({ ...data, rewardId, id })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-options'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
