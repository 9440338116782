import { Box, Button, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { type LicensedEstablishmentUser } from 'types/api'

export const ContactInformation = ({
  toggleModal,
  user,
}: {
  toggleModal: () => void
  user: LicensedEstablishmentUser
}) => {
  return (
    <>
      <Box className="flex flex-row items-center justify-between flex-1">
        <Grid
          container
          width={'full'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          mb={2}
        >
          <Grid md={4} item>
            <Typography variant="h2">Contact Information</Typography>
          </Grid>
          <Grid md={1} item display={'flex'} justifyContent={'flex-end'}>
            <Button
              sx={{ p: 0 }}
              data-testid="edit-le-user-button"
              variant={'text'}
              onClick={() => {
                toggleModal()
              }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        p={4}
        border={`solid 1px ${grey[300]}`}
        borderRadius={'4px'}
        rowGap={1}
        gap={2}
      >
        <Grid item xs={12} md={3}>
          <Typography variant="body-3">First Name</Typography>
          <Typography variant="body-1">{user.firstName}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="body-3">Last Name</Typography>
          <Typography variant="body-1" className="break-words">
            {user.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={1.5}>
          <Typography variant="body-3">Email</Typography>
          <Typography variant="body-1">{user.email}</Typography>
        </Grid>
      </Grid>
    </>
  )
}
