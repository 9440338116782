import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'

interface usePutRewardCatalogProps {
  onSuccess?: (
    data: components['schemas']['FullRewardsCatalogDTO'],
    variables: {
      id: number
      rewardsCatalogData: components['schemas']['RewardsCatalogDTO']
    }
  ) => void
  onError?: (
    error: ApiError,
    variables: {
      id: number
      rewardsCatalogData: components['schemas']['RewardsCatalogDTO']
    }
  ) => void
}

export const usePutRewardsCatalog = ({
  onSuccess,
  onError,
}: usePutRewardCatalogProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards-catalogs/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      id,
      rewardsCatalogData,
    }: {
      id: number
      rewardsCatalogData: components['schemas']['RewardsCatalogDTO']
    }) => {
      const { data } = await put({ ...rewardsCatalogData, id })
      return data
    },
    onSuccess: async (
      data: components['schemas']['FullRewardsCatalogDTO'],
      variables: {
        id: number
        rewardsCatalogData: components['schemas']['RewardsCatalogDTO']
      }
    ) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-catalogs', data.id],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
