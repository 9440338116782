import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type components } from 'api/playerPayback/api'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetPatronNotesById = ({
  id,
}: {
  id: number
}): UseQueryResult<Array<components['schemas']['PatronNoteDTO']>> => {
  const get = useTypedApiClient({
    path: '/admin/patrons/{id}/notes',
    method: 'get',
  })

  return useQuery({
    queryKey: ['/notes'],
    queryFn: async () => {
      const { data } = await get({ id: Number(id) })
      return data
    },
  })
}
