import { SelectRewards } from './SelectRewards/SelectRewards'
import { AssignRedemptionValue } from './AssignRedemptionValue/AssignRedemptionValue'
import { isNil } from 'lodash'
import { CircularProgress } from '@mui/material'
import { useManageCatalogRewards } from './useManageCatalogRewards'

export const ManageCatalogRewardsPage = () => {
  const {
    step,
    isRewardsCatalogLoading,
    isRewardsCatalogError,
    rewardsCatalog,
    rewardsCatalogRewards,
    onClickCancel,
    onAddRewardCatalogReward,
    onRemoveRewardCatalogReward,
    onClickNextSelectRewards,
    onClickBackToSelectRewards,
    onChangeRewardsCatalogRewardField,
    onSubmit,
    isMutationLoading,
  } = useManageCatalogRewards()

  if (isRewardsCatalogLoading) {
    return <CircularProgress />
  }

  if (isRewardsCatalogError) {
    return <p>An error occured.</p>
  }

  if (isNil(rewardsCatalog)) {
    return <p>An error occured fetching reward catalog.</p>
  }

  const stepViews = [
    <SelectRewards
      key="step1"
      onClickNext={onClickNextSelectRewards}
      rewardsCatalog={rewardsCatalog}
      onClickCancel={onClickCancel}
      rewardsCatalogRewards={rewardsCatalogRewards}
      addRewardCatalogReward={onAddRewardCatalogReward}
      removeRewardCatalogReward={onRemoveRewardCatalogReward}
    />,
    <AssignRedemptionValue
      key="step2"
      onClickBack={onClickBackToSelectRewards}
      onClickCancel={onClickCancel}
      rewardsCatalogRewards={rewardsCatalogRewards}
      onChangeRewardsCatalogRewardField={onChangeRewardsCatalogRewardField}
      removeRewardCatalogReward={onRemoveRewardCatalogReward}
      onSubmit={onSubmit}
      isMutationLoading={isMutationLoading}
    />,
  ]

  return stepViews[step]
}
