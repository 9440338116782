import { useApiQuery } from 'hooks/useApiQuery'

export const useGetCheckInPolicyByRewardsProgramsId = ({
  rewardProgramId,
}: {
  rewardProgramId?: number
}) =>
  useApiQuery({
    method: 'get',
    path: '/admin/policies/checkins/rewards-programs/{id}',
    queryKey: [
      '/admin/policies/checkins/rewards-programs',
      rewardProgramId ?? '',
    ],
    queryArgs: { id: rewardProgramId },
  })
