import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation } from '@tanstack/react-query'
import type { Patron, RewardsOptions } from 'types/api'
import { type components } from 'api/playerPayback/api'

interface usePostRedeemFreeRewardProps {
  selectedPatron?: Patron
  selectedRewardItem?: {
    id: number
    image: string
    rewardId: number
    rewardItemName: string
    type: 'JJStoreItem' | 'MatchPlay' | 'LocationOffer'
  }
  rewardsProgramId?: string
  rewardsOptionsQuery?: RewardsOptions
  selectedOption: string
  patronAddress?: {
    address1?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    postalCode?: string | null
  }
  programRewardItems?: Array<components['schemas']['RewardsCatalogRewardDTO']>
  onSuccess: () => void
  onError: (error: { message: string }) => void
}

export const usePostRedeemFreeReward = ({
  selectedPatron,
  selectedRewardItem,
  rewardsProgramId,
  rewardsOptionsQuery,
  selectedOption,
  patronAddress,
  programRewardItems,
  onSuccess,
  onError,
}: usePostRedeemFreeRewardProps) => {
  const post = useTypedApiClient({
    path: '/admin/rewards/redemptions/free',
    method: 'post',
  })

  return useMutation({
    mutationFn: async () => {
      const { data } = await post({
        isKioskRewardRedemption: false,
        patronId: Number(selectedPatron?.id),
        rewardsCatalogRewardId: selectedRewardItem?.id ?? 0,
        rewardsProgramId: Number(rewardsProgramId),
        rewardsRedemptionsOptionType: rewardsOptionsQuery?.type,
        rewardsRedemptionsOptionValue: selectedOption,
        address: {
          address1: patronAddress?.address1 ?? '',
          address2: patronAddress?.address2 ?? '',
          city: patronAddress?.city ?? '',
          state: patronAddress?.state ?? '',
          country: patronAddress?.country ?? '',
          postalCode: patronAddress?.postalCode ?? '',
        },
        updatePatronMailingAddress: false,
        rewardsCatalogId:
          programRewardItems?.find((item) => item.id === selectedRewardItem?.id)
            ?.rewardsCatalogId ?? 0,
        rewardsId:
          programRewardItems?.find(
            (item) => item.rewardId === selectedRewardItem?.rewardId
          )?.rewardId ?? 0,
      })
      return data
    },
    onSuccess: async () => {
      onSuccess()
    },
    onError,
  })
}
