import { Box, Typography, type TypographyOwnProps } from '@mui/material'

export const Title = ({
  title,
  titleVariant = 'h3',
  actionComponent,
}: {
  title: string
  titleVariant?: TypographyOwnProps['variant']
  actionComponent?: React.ReactNode
}) => {
  return (
    <Box className="flex flex-col w-full space-y-6 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center">
      <Typography variant={titleVariant}>{title}</Typography>
      <Box sx={{ justifyContent: 'flex-end' }}>{actionComponent}</Box>
    </Box>
  )
}
