import { Box } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { grey } from '@mui/material/colors'

interface ExpandableCellProps {
  expanded: boolean
  toggleExpandHandler: () => void
  children: React.ReactNode
  rowId: string
}

export const iconWidth = 22
export const iconMargin = 8

export const ExpandableCell = ({
  expanded,
  toggleExpandHandler,
  children,
  rowId,
}: ExpandableCellProps) => {
  const Icon = expanded ? KeyboardArrowDownIcon : KeyboardArrowRightIcon
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Icon
        sx={{
          width: `${iconWidth}px`,
          mr: `${iconMargin}px`,
          color: expanded ? 'primary.main' : grey[500],
        }}
        onClick={toggleExpandHandler}
        data-testid={
          expanded ? `collapse-icon-${rowId}` : `expand-icon-${rowId}`
        }
        className="expandable-table-chevron"
      />
      {children}
    </Box>
  )
}
