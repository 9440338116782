import { Box, Typography } from '@mui/material'
import { type components } from 'src/api/playerPayback/api'
import { CellLink } from 'components/CellLink/CellLink'
import { StatusChip } from 'components/StatusChip/StatusChip'
import { SwitchRewardsProgramActive } from 'components/RewardsProgram/SwitchRewardsProgramActive'

interface LocationRewardsProgramMobileRowProps {
  row: components['schemas']['FullRewardsProgramDTO']
}

export const LocationRewardsProgramMobileRow = ({
  row,
}: LocationRewardsProgramMobileRowProps) => (
  <Box sx={{ flex: 1 }}>
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <CellLink
        text={row.name}
        fontWeight="bold"
        to={`/Rewards/RewardPrograms/${String(row.id)}`}
      />

      <SwitchRewardsProgramActive rewardsProgram={row} />
    </Box>
    <Typography variant="body1">ID: {row.id}</Typography>
    <Box sx={{ flex: 1, mt: '8px' }}>
      <StatusChip active={row.active} />
    </Box>
  </Box>
)
