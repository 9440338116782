import { Box, Checkbox, FormControlLabel, MenuItem, Stack } from '@mui/material'
import { TextField } from 'components/Shared/TextField'
import { useFormContext, useWatch } from 'react-hook-form'
import { type InferType, boolean, object, string } from 'yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { type LEType } from 'types/api'
import {
  latitudeSchema,
  longitudeSchema,
} from 'utils/schemas/latitudeAndLongitudeSchemas'
import { useCallback } from 'react'

export const BasicInformationSchema = object({
  leType: string<LEType>().required('Establishment type is a required field'),
  companyName: string().required('Legal Company Name is a required field'),
  businessDisplayName: string(),
  sameAsCompanyName: boolean(),
  licenseNumber: string()
    .required('License number is a required field')
    .transform((val) => (isNaN(val) ? undefined : val)),
  leLat: latitudeSchema,
  leLong: longitudeSchema,
})

export type BasicInformationForm = InferType<typeof BasicInformationSchema>

const SameAsDisplayName = () => {
  const { register, setValue, getValues } = useFormContext()

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValue(
          'basicInfo.businessDisplayName',
          getValues('basicInfo.companyName')
        )
      } else {
        setValue('basicInfo.businessDisplayName', '')
      }
      setValue('basicInfo.sameAsCompanyName', e.target.checked)
    },
    [setValue]
  )

  return (
    <Box mt={1}>
      <FormControlLabel
        control={
          <Checkbox
            {...register('basicInfo.sameAsCompanyName', {
              onChange,
            })}
          />
        }
        label="Same as company name"
      />
    </Box>
  )
}

export const BasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ basicInfo: BasicInformationForm }>()
  const sameAsCompanyName = useWatch({ name: 'basicInfo.sameAsCompanyName' })

  return (
    <Stack gap={6}>
      <Stack direction="column" gap={1}>
        <TextField
          {...register('basicInfo.companyName')}
          error={isPresent(errors.basicInfo?.companyName)}
          fullWidth
          required
          label="Legal Company Name"
          placeholder="Valid Company Name"
        />
        {errors.basicInfo?.companyName?.message && (
          <FormFieldErrorMessage
            message={errors.basicInfo.companyName.message}
          />
        )}
      </Stack>
      <TextField
        {...register('basicInfo.businessDisplayName')}
        error={isPresent(errors.basicInfo?.businessDisplayName)}
        fullWidth
        label="DBA Name"
        placeholder="This can be the standard name or the short hand name"
        InputProps={{ readOnly: sameAsCompanyName }}
        bottomContent={<SameAsDisplayName />}
      />
      <Stack direction="column" gap={1}>
        <TextField
          {...register('basicInfo.leType', { required: true })}
          error={isPresent(errors.basicInfo?.leType)}
          inputProps={{ 'data-testid': 'le-type' }}
          required
          fullWidth
          label="Establishment Type"
          defaultValue=""
          select
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value="Bar">Bar</MenuItem>
          <MenuItem value="BowlingAlley">Bowling Alley</MenuItem>
          <MenuItem value="Fraternal">Fraternal</MenuItem>
          <MenuItem value="GolfCourse">Golf Course</MenuItem>
          <MenuItem value="Restaurant">Restaurant</MenuItem>
          <MenuItem value="Parlor">Parlor</MenuItem>
          <MenuItem value="ConvenienceStore">Convenience Store</MenuItem>
          <MenuItem value="TruckStop">Truck Stop</MenuItem>
          <MenuItem value="LargeTruckStop">Large Truck Stop</MenuItem>
          <MenuItem value="LiquorStore">Liquor Store</MenuItem>
          <MenuItem value="Veterans">Veterans</MenuItem>
          <MenuItem value="Winery">Winery</MenuItem>
          <MenuItem value="GasStation">Gas Station</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
        {errors.basicInfo?.leType?.message && (
          <FormFieldErrorMessage message={errors.basicInfo.leType.message} />
        )}
      </Stack>
    </Stack>
  )
}
