import { type Control, Controller } from 'react-hook-form'
import { type permissionFormDataType } from './EditLeUserRoleAndPermissionsModal'
import { Checkbox, FormControlLabel } from '@mui/material'

export const PermissionControllerCheckbox = ({
  onChange,
  label,
  name,
  control,
}: {
  onChange: () => void
  name: permissionFormDataType
  label: string
  control: Control<any, any>
}) => {
  return (
    <Controller
      render={({ field: { value } }) => {
        return (
          <FormControlLabel
            sx={{ pt: '6px' }}
            control={<Checkbox checked={value} onChange={onChange} />}
            label={label}
          />
        )
      }}
      name={name}
      control={control}
    />
  )
}
