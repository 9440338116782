import { Box, Typography } from '@mui/material'
import NoDataFound from 'assets/noData.svg'
import { colorPrimitives } from 'components/Theme'

interface NoDataIndicatorProps {
  imageSource?: string
  noDataMessage?: string
}

export const NoDataIndicator = ({
  imageSource = NoDataFound,
  noDataMessage = 'No Data Found',
}: NoDataIndicatorProps) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    bgcolor={colorPrimitives.lightGray}
    padding={{ xs: 7 }}
    marginTop={{ xs: 2 }}
  >
    <img src={imageSource} alt="No data found icon" width={160} height={160} />
    <Typography
      variant="label-form"
      marginTop={{ xs: 3 }}
      color="rgba(41, 43, 38, 0.60)"
    >
      {noDataMessage}
    </Typography>
  </Box>
)
