import { CircularProgress } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useGetLicensedEstablishmentById } from 'hooks/api/LicensedEstablishment/useGetLicensedEstablishmentById'
import { LicensedEstablishmentDetailsHeader } from './LicensedEstablishmentDetailsHeader'
import { useGetRewardProgramByLicensedEstablishmentId } from 'hooks/api/useGetRewardProgramByLicensedEstablishmentId'
import { LocationRewardsProgramsSection } from './LocationRewardsPrograms/LocationRewardsProgramsSection'

export const LicensedEstablishmentDetailsRewardsProgramPage = () => {
  const { id } = useParams()

  const licensedEstablishmentById = useGetLicensedEstablishmentById(Number(id))

  const RewardProgramQuery = useGetRewardProgramByLicensedEstablishmentId({
    licensedEstablishmentId: Number(licensedEstablishmentById.data?.id),
    includeGlobal: true,
    enabled: !isNil(licensedEstablishmentById.data),
  })

  if (licensedEstablishmentById.isPending) {
    return <CircularProgress />
  }

  if (licensedEstablishmentById.isError) {
    return <p>An error occured.</p>
  }

  const licensedEstablishment = licensedEstablishmentById.data

  if (isNil(licensedEstablishment)) {
    return <p>An error occured.</p>
  }

  const locationRewardsPrograms = (RewardProgramQuery.data ?? [])?.filter(
    (rp) => rp.licensedEstablishmentId === licensedEstablishment.id
  )

  return (
    <Page
      header={
        <LicensedEstablishmentDetailsHeader
          licensedEstablishment={licensedEstablishment}
          currentTab={`/LicensedEstablishments/${licensedEstablishment.id}/RewardsProgram`}
        />
      }
    >
      <LocationRewardsProgramsSection
        locationRewardsPrograms={locationRewardsPrograms}
        licensedEstablishment={licensedEstablishment}
      />
    </Page>
  )
}
