/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, number, object, string } from 'yup'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { SizeSwitch } from './SizeSwitch'
import {
  type RewardsOptions,
  type Reward,
  type RewardsOptionsType,
} from 'types/api'
import { usePostRewardsOptions } from 'hooks/api/usePostRewardsOptions'
import { useSnackbar } from 'stores/useSnackbar'
import { useLayout } from 'hooks/useLayout'
import { usePutRewardsOptions } from 'hooks/api/usePutRewardsOptions'
import { useDeleteRewardsOptions } from 'hooks/api/useDeleteRewardsOptions'
import { RewardsOptionsSize } from 'utils/constants/rewardsOptionsSize'

const OptionsSchema = object({
  type: string<RewardsOptionsType>(),
  value: array(string()),
  id: number(),
})

export const EditRewardOptionsModal = ({
  isModalOpen,
  reward,
  rewardsOptions,
  rewardsOptionsValues,
  toggleModalIsOpen,
}: {
  isModalOpen: boolean
  reward: Reward
  rewardsOptions?: RewardsOptions
  rewardsOptionsValues: RewardsOptionsSize[]
  toggleModalIsOpen: () => void
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : 'fit-content'
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const defaultRewardsOptions: string[] = Object.keys(RewardsOptionsSize)
  const [showSelect, setShowSelect] = useState(false)

  // Split the incoming rewards options string into comma separated array of strings
  const incomingRewardsOptionsValues = rewardsOptionsValues

  const formMethods = useForm({
    resolver: yupResolver(OptionsSchema),
  })

  const { register, control, handleSubmit, watch, reset } = formMethods

  const type = watch('type')
  const value = watch('value')

  const onCancel = () => {
    toggleModalIsOpen()
  }

  useMemo(() => {
    if (rewardsOptions && rewardsOptions.type !== 'Unknown') {
      setShowSelect(true)
    }

    if (type === 'Unknown') {
      setShowSelect(false)
    }

    if (type === 'Size') {
      setShowSelect(true)
    }
  }, [rewardsOptions, type])

  const usePostRewardOptionsMutation = usePostRewardsOptions({
    rewardId: reward.id,
    onSuccess: () => {
      setSnackbarMessage('Reward item successfully updated')
      toggleModalIsOpen()
    },
  })

  const usePutRewardOptionsMutation = usePutRewardsOptions({
    rewardId: reward.id,
    type: rewardsOptions ? rewardsOptions?.type : null,
    value: '',
    id: rewardsOptions?.id,
    onSuccess: () => {
      setSnackbarMessage('Reward item successfully updated')
      toggleModalIsOpen()
    },
  })

  const useDeleteRewardOptionsMutation = useDeleteRewardsOptions({
    onSuccess: () => {
      setSnackbarMessage('Reward item successfully updated')
      toggleModalIsOpen()
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    // sort values in specific order using the order object
    const order = {
      Small: 1,
      Medium: 2,
      Large: 3,
      XL: 4,
      default: Number.MAX_VALUE,
    }

    const orderedValueArray = formData?.value?.sort((a, b) => {
      const keyA = a as keyof typeof RewardsOptionsSize | undefined
      const keyB = b as keyof typeof RewardsOptionsSize | undefined

      // Ensure keyA and keyB are not undefined before comparison
      if (keyA !== undefined && keyB !== undefined) {
        return (
          (order[keyA] || order.default) - (order[keyB] || order.default) ||
          Number(keyA > keyB) ||
          Number(-(keyA < keyB))
        )
      }

      // Return 0 if either key is undefined
      return 0
    })

    if (!type || type === 'Unknown') {
      useDeleteRewardOptionsMutation.mutate({
        rewardId: reward.id,
        type: rewardsOptions ? rewardsOptions?.type : 'Unknown',
        value: '',
      })
      reset({ type: 'Unknown', value: [] })
    } else if (
      rewardsOptions &&
      rewardsOptions.type !== 'Unknown' &&
      formData.type !== undefined
    ) {
      usePutRewardOptionsMutation.mutate({
        rewardId: reward.id,
        type: formData.type,
        value: String(orderedValueArray?.join(',')),
      })
    } else {
      usePostRewardOptionsMutation.mutate({
        rewardId: reward.id,
        type: (formData?.type ?? 'Size') || (formData?.type ?? 'Color'),
        value: String(orderedValueArray?.join(',')),
      })
    }
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '70%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <ModalFullHeader
            title="Options"
            subTitle={reward.name}
            toggleIsOpen={toggleModalIsOpen}
          />
          <ModalFullBody heightModal={heightModal}>
            {/* Size Options */}
            <Stack spacing={5}>
              <SizeSwitch
                rewardsOptions={
                  rewardsOptions && rewardsOptions.type !== 'Unknown'
                    ? rewardsOptions.type
                    : ''
                }
              />

              {showSelect && (
                <Stack marginTop="20px">
                  <Typography variant="subtitle-1">
                    Which sizes would you like to offer?
                  </Typography>
                  <Controller
                    name="value"
                    control={control}
                    defaultValue={incomingRewardsOptionsValues || []}
                    render={({ field }) => (
                      <Select
                        {...register('value', { required: false })}
                        {...field}
                        displayEmpty
                        input={<OutlinedInput label="Tag" />}
                        label="label"
                        multiple
                        notched={false}
                        sx={{ maxWidth: '272px' }}
                        renderValue={(selected) => {
                          return selected.length === 0
                            ? 'Select Sizes'
                            : selected.join(', ')
                        }}
                      >
                        {defaultRewardsOptions.map((x) => {
                          return (
                            <MenuItem key={`size-option-${x}`} value={x}>
                              <Checkbox checked={watch('value')?.includes(x)} />
                              <ListItemText primary={x} />
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                </Stack>
              )}
            </Stack>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            <ActivityButton
              disabled={
                (type &&
                  type === 'Unknown' &&
                  (!value || value.length === 0) &&
                  !rewardsOptions?.value) ||
                (type &&
                  type.length > 0 &&
                  (!value || value.length === 0) &&
                  !rewardsOptions?.value) ||
                (type === 'Unknown' &&
                  value &&
                  value.length > 0 &&
                  !rewardsOptions?.value) ||
                !!(
                  type &&
                  type.length > 0 &&
                  (!value || value.length === 0) &&
                  rewardsOptions &&
                  rewardsOptions.value.length > 0
                )
              }
              active={
                (usePutRewardOptionsMutation.isPending,
                usePostRewardOptionsMutation.isPending,
                useDeleteRewardOptionsMutation.isPending)
              }
              variant="contained"
              type="submit"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
