import { formatDateTime } from 'utils/util'
import { useParams } from 'react-router-dom'
import { Page } from 'components/Shared/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { Box, Button, MenuItem, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useGetPointsLedgersByRewardProgramId } from 'hooks/api/useGetPointsLedgersByRewardProgramId'
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid'
import { type components } from 'src/api/playerPayback/api'
import { DataTable } from 'components/Shared/DataTable'
import { useLayout } from 'hooks/useLayout'
import { CellLink } from 'components/CellLink/CellLink'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useState } from 'react'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import { compareAsc, parseISO, sub } from 'date-fns'

const getColumns = (
  isMobile: boolean
): Array<GridColDef<components['schemas']['FullPointsLedgerEntryDTO']>> => [
  {
    field: 'licensedEstablishmentName',
    headerName: 'LE Name',
    valueGetter: (params) =>
      params.row.patronCheckIn?.licensedEstablishment?.standardName,
    width: 320,
    renderCell: (params: GridRenderCellParams) => (
      <CellLink
        to={`/LicensedEstablishments/${
          params.row.patronCheckIn?.licensedEstablishment?.id as string
        }`}
        fontWeight={isMobile ? 'bold' : 'regular'}
        text={
          params.row.patronCheckIn?.licensedEstablishment?.standardName ?? '-'
        }
      />
    ),
  },
  {
    field: 'licensedEstablishmentId',
    headerName: 'LE ID',
    valueGetter: (params) =>
      `${isMobile ? 'LE ID: ' : ''}${
        params.row.patronCheckIn?.licensedEstablishment?.id ?? '-'
      }`,
    width: 222,
  },
  {
    field: 'patronName',
    headerName: 'Patron Name',
    valueGetter: (params) =>
      `${isMobile ? 'Patron Name: ' : ''}${params.row.patron
        ?.firstName} ${params.row.patron?.lastName}`,
    width: 320,
  },
  {
    field: 'patronId',
    headerName: 'Patron ID',
    valueGetter: (params) =>
      `${isMobile ? 'Patron ID: ' : ''}${params.row.patronId}`,
    width: 222,
  },
  {
    field: 'amount',
    headerName: 'Points Awarded',
    valueGetter: (params: {
      row: {
        amount?: number
      }
    }) => `${isMobile ? 'Points Awarded: ' : ''}${params.row.amount}`,
    width: 222,
  },
  {
    field: 'createdBy',
    headerName: 'Manually Awarded By',
    valueGetter: (params) => {
      const createdBy = params.row.createdBy
      return createdBy === 'JJVentures.PlayerPAYBACK.WebApi' ? '' : createdBy
    },
    width: 260,
  },
  {
    field: 'createdOn',
    headerName: 'Transaction Timestamp',
    valueFormatter: (params: { value: string }) => {
      const date = formatDateTime(params.value, 'MM-dd-yyyy p')
      return isMobile ? `Date: ${String(date)}` : date
    },
    width: 260,
  },
]

export const RewardProgramTransactionHistoryPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById({
    rewardProgramId: Number(rewardProgramId),
  })

  const { isMobile } = useLayout()
  const pointsLedgersQuery = useGetPointsLedgersByRewardProgramId({
    rewardProgramId: Number(rewardProgramId),
    enabled: !!rewardProgramQuery.data,
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [numDays, setNumDays] = useState<number>(30)
  const open = Boolean(anchorEl)
  const handleMenuItemClose = (val: number) => {
    setNumDays(val)
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  if (rewardProgramQuery.isPending || pointsLedgersQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || pointsLedgersQuery.isError) {
    return <p>An error occurred.</p>
  }

  const filteredData = pointsLedgersQuery.data.filter((transaction) => {
    const filterDate = sub(new Date(), {
      days: numDays,
    })
    const transactionDate = parseISO(transaction.createdOn ?? '')
    return compareAsc(transactionDate, filterDate) === 1
  })

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${Number(
            rewardProgramId
          )}/TransactionHistory`}
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          rowGap: { xs: '8px', sm: '24px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%',
            justifyContent: { xs: 'start', sm: 'space-between' },
            alignItems: { xs: 'start', sm: 'center' },
            rowGap: '8px',
          }}
        >
          <Box>
            <Typography variant={'h2'}>Transaction History</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              id="activity-day-button"
              sx={{ display: 'flex', flexDirection: 'row', pr: 0 }}
              onClick={handleMenuClick}
            >
              <Typography variant="body-1">Last {numDays} days</Typography>
              {open ? (
                <KeyboardArrowUpIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  sx={{
                    width: '22px',
                    ml: '4px',
                  }}
                />
              )}
            </Button>
            <StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{
                '& .MuiPaper-root': {
                  marginTop: 0,
                  minWidth: 340,
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClose(30)}>
                30 Days
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClose(90)}>
                90 Days
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClose(365)}>
                365 Days
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
        <DataTable
          columns={getColumns(isMobile)}
          rows={filteredData}
          sortFieldName="createdOn"
          sort="desc"
          noDataMessage="No transaction history to date."
        />
      </Box>
    </Page>
  )
}
