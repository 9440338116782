import { type components } from 'api/playerPayback/api'
import { number, type ValidationError } from 'yup'

export interface RewardsCatalogReward {
  rewardId?: number
  name?: string
  amount?: string
  amountError?: string
}

export const getRewardsCatalogRewardsMap = (
  rewardsCatalogRewards: Array<
    components['schemas']['FullRewardsCatalogRewardDTO']
  >
): Map<number, RewardsCatalogReward> =>
  new Map<number, RewardsCatalogReward>(
    rewardsCatalogRewards.map(
      (
        rewardsCatalogReward: components['schemas']['FullRewardsCatalogRewardDTO']
      ) => [
        rewardsCatalogReward.rewardId,
        {
          rewardId: rewardsCatalogReward.rewardId,
          name: rewardsCatalogReward.reward?.name,
          amount: String(rewardsCatalogReward.amount),
        },
      ]
    )
  )

export const addRewardsCatalogReward = (
  reward: components['schemas']['FullRewardDTO'],
  oldRewardsCatalogRewards: Map<number, RewardsCatalogReward>,
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
): Map<number, RewardsCatalogReward> => {
  const rewardsCatalogReward: RewardsCatalogReward = {
    rewardId: reward.id,
    name: reward.name,
    // persist the amount in case it was unselected before
    amount: String(
      oldRewardsCatalogRewards.get(Number(reward.id))?.amount ?? ''
    ),
  }
  const newRewardsCatalogRewards = new Map<number, RewardsCatalogReward>(
    rewardsCatalogRewards
  )
  newRewardsCatalogRewards.set(
    Number(rewardsCatalogReward.rewardId),
    rewardsCatalogReward
  )
  return newRewardsCatalogRewards
}

export const removeRewardsCatalogReward = (
  rewardId: number,
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
) => {
  const newRewardsCatalogRewards = new Map<number, RewardsCatalogReward>(
    rewardsCatalogRewards
  )
  newRewardsCatalogRewards.delete(rewardId)
  return newRewardsCatalogRewards
}

export const changeRewardsCatalogRewardField = (
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>,
  rewardId: number,
  field: 'amount' | 'amountError',
  value?: string
) => {
  const newRewardsCatalogRewards = new Map<number, RewardsCatalogReward>(
    rewardsCatalogRewards
  )
  const rewardsCatalogReward = newRewardsCatalogRewards.get(rewardId)
  if (rewardsCatalogReward) {
    newRewardsCatalogRewards.set(rewardId, {
      ...rewardsCatalogReward,
      [field]: value,
    })
  }
  return newRewardsCatalogRewards
}

const amountValidationSchema = number()
  .typeError('*This field can only contain numbers')
  .min(1, '*The point cost for redemption must be greater than zero.')

export interface AmountValidation {
  valid: boolean
  error?: string
}

export const validateAmount = async (
  value: string
): Promise<AmountValidation> => {
  try {
    await amountValidationSchema.validate(Number(value))
  } catch (e) {
    const validationError = e as ValidationError
    return { valid: false, error: validationError.message }
  }
  return { valid: true }
}

export const clearAllAmountErrors = (
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
): Map<number, RewardsCatalogReward> =>
  new Map<number, RewardsCatalogReward>(
    Array.from(rewardsCatalogRewards).map((entry) => [
      entry[0],
      { ...entry[1], amountError: undefined },
    ])
  )

export const validateAllAmounts = async (
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
): Promise<{
  valid: boolean
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
}> => {
  let valid = true
  const newRewardsCatalogRewards = new Map<number, RewardsCatalogReward>(
    rewardsCatalogRewards
  )

  for (const entry of Array.from(rewardsCatalogRewards)) {
    const validation = await validateAmount(String(entry[1].amount))
    valid = valid && validation.valid
    if (validation.error) {
      newRewardsCatalogRewards.set(entry[0], {
        ...entry[1],
        amountError: validation.error,
      })
    }
  }

  return { valid, rewardsCatalogRewards: newRewardsCatalogRewards }
}

export const sortRows = (a: RewardsCatalogReward, b: RewardsCatalogReward) => {
  const rewardIdA = Number(a.rewardId)
  const rewardIdB = Number(b.rewardId)
  if (rewardIdA < rewardIdB) {
    return -1
  }
  if (rewardIdA > rewardIdB) {
    return 1
  }
  return 0
}
