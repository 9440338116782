import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetLicensedEstablishmentUsersByCorporateAccountId = ({
  corporateAccountId,
}: {
  corporateAccountId: number
}): UseQueryResult<
  Array<components['schemas']['FullLicensedEstablishmentUserDTO']>
> => {
  const get = useTypedApiClient({
    path: '/admin/corporate-accounts/{id}/licensed-establishments/users',
    method: 'get',
  })
  return useQuery({
    queryKey: [
      '/admin/corporate-accounts/{id}/licensed-establishments/users',
      corporateAccountId,
    ],
    queryFn: async () => {
      const { data } = await get({ id: corporateAccountId })
      return data
    },
  })
}
