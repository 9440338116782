import { InputAdornment, InputLabel } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { DateTimeField } from '@mui/x-date-pickers'
import {
  type Control,
  useController,
  type FieldValues,
  type Path,
} from 'react-hook-form'

export interface ControlledDateTimeFieldProps<FV extends FieldValues> {
  name: Path<FV>
  label?: string
  id?: string
  control?: Control<FV>
}

export const AdornedDateTimeField = <FV extends FieldValues>({
  name,
  label,
  id = name,
  control,
}: ControlledDateTimeFieldProps<FV>) => {
  const { field } = useController({
    name,
    control,
  })

  return (
    <>
      {label ? (
        <InputLabel required htmlFor={name}>
          {label}
        </InputLabel>
      ) : null}
      <DateTimeField
        fullWidth
        {...field}
        id={id}
        slotProps={{
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <AccessTimeIcon />
                </InputAdornment>
              ),
            },
          },
        }}
      />
    </>
  )
}
