import ListBullet from 'assets/format_list_bulleted.svg'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { type Campaign } from 'types/api'

export const DeleteMessageRenderCell = ({
  params,
  setMessageRowData,
}: {
  params: GridRenderCellParams<Campaign>
  setMessageRowData: React.Dispatch<
    React.SetStateAction<Campaign | null | undefined>
  >
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleMenuOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            setMessageRowData(params.row)
            handleMenuClose()
          }}
        >
          Delete Message
        </MenuItem>
      </Menu>
    </>
  )
}
