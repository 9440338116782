import { useApiQuery } from 'hooks/useApiQuery'
import { removeUndefined } from 'utils/util'

export const useGetNumberWinnerEntries = ({
  patronId,
  contestId,
  licensedEstablishmentId,
}: {
  patronId?: number
  contestId?: number
  licensedEstablishmentId?: number
}) => {
  const queryArgs = removeUndefined({
    patronId,
    contestId,
    licensedEstablishmentId,
  })

  return useApiQuery({
    method: 'get',
    path: '/admin/contests/entries/contest/{contestId}/patron/{patronId}/total-entries',
    queryKey: [
      '/admin/contests/entries/contest/{contestId}/patron/{patronId}/total-entries',
      queryArgs,
    ],
    queryArgs,
  })
}
