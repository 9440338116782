import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type {
  PointsLedgerEntry,
  UpdateManualPointsLedgerRequest,
} from 'types/api'

interface usePostManualPointsLedgerProps {
  patronId: number
  onSuccess?: (
    data: PointsLedgerEntry,
    variables: UpdateManualPointsLedgerRequest
  ) => void
  onError?: (error: Error, variables: UpdateManualPointsLedgerRequest) => void
}

export const usePostManualPointsLedger = ({
  patronId,
  onSuccess,
  onError,
}: usePostManualPointsLedgerProps) => {
  const queryClient = useQueryClient()
  const post = useTypedApiClient({
    path: '/admin/patron/{patronId}/points-ledgers/manual',
    method: 'post',
  })

  return useMutation({
    mutationFn: async (
      updateManualPointsLedgerRequest: UpdateManualPointsLedgerRequest
    ) => {
      const { data } = await post({
        patronId,
        ...updateManualPointsLedgerRequest,
      })
      return data
    },
    onSuccess: async (
      data: any,
      variables: UpdateManualPointsLedgerRequest
    ) => {
      onSuccess?.(data, variables)
      await queryClient.invalidateQueries({
        queryKey: ['/admin/patronaccount/detail', patronId],
      })
    },
    onError,
  })
}
