import { Box, Grid, Typography, useTheme } from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { type LicensedEstablishmentDetailsProps } from '../types'
import { useMemo } from 'react'
import { formatPhoneNumber } from 'utils/phoneUtils'

export const ContactDetailsDesktop = ({
  licensedEstablishment,
  onClickEdit,
}: LicensedEstablishmentDetailsProps) => {
  const theme = useTheme()
  const contact = licensedEstablishment.contacts?.[0]

  const formattedName = useMemo(() => {
    if (!contact?.firstName && !contact?.lastName) {
      return '-'
    }
    return `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`.trim()
  }, [contact])

  return (
    <Box>
      <SectionHeader title="Contact" onClickButton={onClickEdit} />

      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container className="flex flex-row flex-wrap" columnGap={13}>
          <Grid item md={2}>
            <Typography variant="body2" pb={1} color="text.secondary">
              Primary Contact
            </Typography>
            <Typography variant="body1" pb={2}>
              {formattedName}
            </Typography>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Phone Number
              </Typography>
              <Typography variant="body1" pb={2}>
                {formatPhoneNumber(contact?.phoneNumber)}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box>
              <Typography variant="body2" pb={1} color="text.secondary">
                Email
              </Typography>
              <Typography variant="body1" pb={2}>
                {contact?.email ? contact.email : '-'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
