import { useMemo, useCallback } from 'react'
import { Box, Divider } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { AssignRedemptionValueFooter } from './AssignRedemptionValueFooter'
import { type GridColDef, DataGrid } from '@mui/x-data-grid'
import { RedemptionValueInput } from './RedemptionValueInput'
import {
  validateAmount,
  sortRows,
  type RewardsCatalogReward,
} from 'utils/rewardsCatalogRewards'
import { AssignRedemptionMobileRow } from './AssignRedemptionMobileRow'
import { RemoveRewardMenu } from './RemoveRewardMenu'
import { useLayout } from 'hooks/useLayout'
import { NoDataIndicator } from 'components/NoDataIndicator/NoDataIndicator'
import { isEmpty } from 'lodash'

const getDesktopColumns = (
  onChangeAmountField: (rewardId: number, value: string) => void,
  onBlurAmountField: (rewardId: number, value: string) => Promise<void>,
  removeRewardCatalogReward: (
    rewardId: number,
    validateAtLeast1?: boolean
  ) => void
): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Reward Items',
    flex: 1.5,
  },
  {
    field: 'rewardId',
    headerName: 'Item ID',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Redemption Value',
    flex: 2,
    renderCell: (params) => (
      <Box sx={{ pt: `${params.row.amountError ? 32 : 0}px` }}>
        <RedemptionValueInput
          value={params.row.amount}
          error={params.row.amountError}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            onChangeAmountField(params.row.rewardId, event.target.value)
          }}
          onBlur={async (
            event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            await onBlurAmountField(params.row.rewardId, event.target.value)
          }}
          testId={`textInput-${String(params.row.rewardId)}`}
        />
      </Box>
    ),
  },
  {
    field: '__options__',
    headerName: '',
    flex: 1.5,
    renderCell: (params) => (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <RemoveRewardMenu
          removeRewardCatalogReward={() => {
            removeRewardCatalogReward(params.row.rewardId, true)
          }}
          rewardId={params.row.rewardId}
        />
      </Box>
    ),
  },
]

const getMobileColumns = (
  onChangeAmountField: (rewardId: number, value: string) => void,
  onBlurAmountField: (rewardId: number, value: string) => Promise<void>,
  removeRewardCatalogReward: (
    rewardId: number,
    validateAtLeast1?: boolean
  ) => void
): GridColDef[] => [
  {
    field: '__mobile__',
    headerName: '',
    flex: 1,
    renderCell: (params) => (
      <AssignRedemptionMobileRow
        row={params.row}
        onChangeAmountField={onChangeAmountField}
        onBlurAmountField={onBlurAmountField}
        removeRewardCatalogReward={() =>
          removeRewardCatalogReward(params.row.rewardId, true)
        }
      />
    ),
  },
]

const getColumns = (
  isMobile: boolean,
  onChangeAmountField: (rewardId: number, value: string) => void,
  onBlurAmountField: (rewardId: number, value: string) => Promise<void>,
  removeRewardCatalogReward: (
    rewardId: number,
    validateAtLeast1?: boolean
  ) => void
): GridColDef[] =>
  isMobile
    ? getMobileColumns(
        onChangeAmountField,
        onBlurAmountField,
        removeRewardCatalogReward
      )
    : getDesktopColumns(
        onChangeAmountField,
        onBlurAmountField,
        removeRewardCatalogReward
      )

interface AssignRedemptionValueProps {
  onClickBack: () => void
  onClickCancel: () => void
  rewardsCatalogRewards: Map<number, RewardsCatalogReward>
  onChangeRewardsCatalogRewardField: (
    rewardId: number,
    field: 'amount' | 'amountError',
    value?: string
  ) => void
  removeRewardCatalogReward: (
    rewardId: number,
    validateAtLeast1?: boolean
  ) => void
  submitText?: string
  onSubmit: () => Promise<void>
  isMutationLoading: boolean
}

export const AssignRedemptionValue = ({
  onClickBack,
  onClickCancel,
  rewardsCatalogRewards,
  onChangeRewardsCatalogRewardField,
  removeRewardCatalogReward,
  submitText = 'Add Items',
  onSubmit,
  isMutationLoading,
}: AssignRedemptionValueProps) => {
  const { isMobile } = useLayout()
  const rows = useMemo(
    () =>
      Array.from(rewardsCatalogRewards)
        .map((entry) => entry[1])
        .sort(sortRows),
    [rewardsCatalogRewards]
  )

  const onChangeAmountField = useCallback(
    (rewardId: number, value: string) => {
      onChangeRewardsCatalogRewardField(rewardId, 'amount', value)
    },
    [onChangeRewardsCatalogRewardField]
  )

  const onBlurAmountField = useCallback(
    async (rewardId: number, value: string) => {
      const validation = await validateAmount(value)
      onChangeRewardsCatalogRewardField(
        rewardId,
        'amountError',
        validation.error
      )
    },
    [onChangeRewardsCatalogRewardField]
  )

  const columns = useMemo(
    () =>
      getColumns(
        isMobile,
        onChangeAmountField,
        onBlurAmountField,
        removeRewardCatalogReward
      ),
    [
      isMobile,
      onChangeAmountField,
      onBlurAmountField,
      removeRewardCatalogReward,
    ]
  )
  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Assign Redemption Value to the Selected Items"
            isSecondary={true}
            subtitle="All items must be assigned a redemption value before adding to catalog."
          />
          <Divider />
        </Box>
      }
      footer={
        <AssignRedemptionValueFooter
          onClickBack={onClickBack}
          onClickCancel={onClickCancel}
          submitText={submitText}
          onClickNext={onSubmit}
          isMutationLoading={isMutationLoading}
          isMutationButtonDisabled={rows.some((row) => row.amount === '')}
        />
      }
    >
      {isEmpty(rows) ? (
        <NoDataIndicator noDataMessage="No Rewards Selected" />
      ) : (
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          columns={columns}
          rows={rows}
          getRowId={(row) => row.rewardId}
          hideFooter
          getRowHeight={() => (isMobile ? 180 : 120)}
          columnHeaderHeight={isMobile ? 0 : 56}
        />
      )}
    </Page>
  )
}
