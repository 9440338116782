import { isNil, startCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { format, parseISO } from 'date-fns'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import { Button, Grid, Typography } from '@mui/material'
import { EditPatronInformation } from 'pages/Patrons/EditPatronInformation/EditPatronInformation'
import { useState } from 'react'
import { FlagPatronAccountAsExcludedModal } from 'pages/Patrons/ExcludePatron/FlagPatronAccountAsExcludedModal'
import { FlagPatronAccountAsDeactivatedModal } from 'pages/Patrons/DeactivatePatron/FlagPatronAccountAsDeactivatedModal'
import { getDeactivatedDate, getExcludedDate } from 'utils/util'
import { formatPhoneNumber } from 'utils/phoneUtils'

export const PatronOverviewMobile = () => {
  const { id: patronAccountId } = useParams()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const toggleEditModalIsOpen = async () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  const [
    isFlagPatronAccountAsExcludedModalOpen,
    setIsFlagPatronAccountAsExcludedModalOpen,
  ] = useState(false)
  const toggleFlagPatronAccountAsExcludedModalIsOpen = async () => {
    setIsFlagPatronAccountAsExcludedModalOpen(
      !isFlagPatronAccountAsExcludedModalOpen
    )
  }

  const [
    isFlagPatronAccountAsDeactivatedModalOpen,
    setIsFlagPatronAccountAsDeactivatedModalOpen,
  ] = useState(false)
  const toggleFlagPatronAccountAsDeactivatedModalIsOpen = async () => {
    setIsFlagPatronAccountAsDeactivatedModalOpen(
      !isFlagPatronAccountAsDeactivatedModalOpen
    )
  }

  const patronQuery = useGetPatronById({ id: Number(patronAccountId) })

  if (!patronAccountId) {
    return 'Patron Account Id cannot be null'
  }

  if (patronQuery.isPending) {
    return <ActivityIndicator />
  }

  if (patronQuery.isError || isNil(patronQuery.data)) {
    return <p>An error occurred.</p>
  }

  const createdOn = !isNil(patronQuery.data.createdOn)
    ? parseISO(patronQuery.data.createdOn)
    : null

  const dob = !isNil(patronQuery.data.dob)
    ? parseISO(patronQuery.data.dob)
    : null

  const gender = startCase(patronQuery.data?.gender)

  const mostRecentMailingAddress =
    !isNil(patronQuery.data?.addresses) && patronQuery.data.addresses.length > 0
      ? patronQuery.data?.addresses.sort((a, b) =>
          (a.id > b.id && -1) || a.id < b.id ? 1 : 0
        )[0]
      : null
  return (
    <Grid>
      <Grid item>
        <PatronDetailsHeader
          patron={patronQuery.data}
          currentTab={`/PatronAccounts/${patronAccountId}/Overview`}
        >
          <div hidden={!patronQuery.data?.exclude}>
            <Grid
              container
              gap={2}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item className="pt-3" xs={5}>
                <Typography variant="h3">User Excluded</Typography>
              </Grid>
              <div className="flex flex-col min-h-[118px] justify-center space-y-6 pb-5">
                <div className="space-y-3">
                  <Typography variant="body-1">
                    {patronQuery.data?.excludeReason}
                  </Typography>
                  <Typography variant="body-3">
                    {getExcludedDate(patronQuery.data?.excludeDate)}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
          <div hidden={!patronQuery.data?.deactivated}>
            <Grid
              container
              gap={2}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Grid item className="pt-3" xs={5}>
                <Typography variant="h3">Patron Deactivated</Typography>
              </Grid>
              <div className="flex flex-col min-h-[118px] justify-center space-y-6 pb-5">
                <div className="space-y-3">
                  <Typography variant="body-1">
                    {patronQuery.data?.deactivatedReason}
                  </Typography>
                  <Typography variant="body-3">
                    {getDeactivatedDate(patronQuery.data?.deactivatedOn)}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
          <Grid
            container
            gap={2}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Grid item className="pt-3 pb-3" xs={8}>
              <Typography variant="h3" pb={2}>
                Patron Account
              </Typography>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button
                data-testid="edit-le-business-details-button"
                variant={'text'}
                sx={{ justifyContent: 'flex-end' }}
                onClick={toggleEditModalIsOpen}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
          <div className="flex flex-col min-h-[118px] justify-center space-y-6 pb-5">
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Patron ID
              </Typography>
              <Typography variant="body-1">#{patronQuery.data?.id}</Typography>
            </div>{' '}
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Name
              </Typography>
              <div>
                <Typography variant="body-3">First Name</Typography>
                <Typography variant="body-1">
                  {patronQuery.data?.firstName}
                </Typography>
              </div>
              <div>
                <Typography variant="body-3">Last Name</Typography>
                <Typography variant="body-1">
                  {patronQuery.data?.lastName}
                </Typography>
              </div>
            </div>
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Contact
              </Typography>
              <div>
                <Typography variant="body-3">Phone Number</Typography>
                {!isNil(patronQuery.data.phoneNumber) && (
                  <Typography>
                    {formatPhoneNumber(patronQuery.data.phoneNumber)}
                  </Typography>
                )}
              </div>
              <div>
                <Typography variant="body-3">Email</Typography>
                <Typography variant="body-1">
                  {patronQuery.data?.email}
                </Typography>
              </div>
            </div>
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Birthdate
              </Typography>
              {!isNil(dob) && (
                <Typography variant="body-1">{`${format(
                  dob,
                  'P'
                )}`}</Typography>
              )}
            </div>
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Gender
              </Typography>
              <Typography variant="body-1">{gender}</Typography>
            </div>
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Mailing Address
              </Typography>
              <div>
                <Typography variant="body-3">Address Line 1</Typography>
                <Typography variant="body-1">
                  {mostRecentMailingAddress?.address1 ?? '-'}
                </Typography>
              </div>
              <div>
                <Typography variant="body-3">Address Line 2</Typography>
                <Typography variant="body-1">
                  {mostRecentMailingAddress?.address2 ?? '-'}
                </Typography>
              </div>
              <div>
                <Typography variant="body-3">City</Typography>
                <Typography variant="body-1">
                  {mostRecentMailingAddress?.city ?? '-'}
                </Typography>
              </div>
              <div className="grid grid-cols-2">
                <div>
                  <Typography variant="body-3">State</Typography>
                  <Typography variant="body-1">
                    {mostRecentMailingAddress?.state ?? '-'}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body-3">Zip</Typography>
                  <Typography variant="body-1">
                    {mostRecentMailingAddress?.postalCode ?? '-'}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <Typography variant="h3" fontWeight={'bold'}>
                Member Since
              </Typography>
              {!isNil(createdOn) && (
                <Typography variant="body-1">
                  {`${format(createdOn, 'P')} at ${format(createdOn, 'p')}`}
                </Typography>
              )}
            </div>
          </div>
        </PatronDetailsHeader>
      </Grid>

      <Grid className="pt-12" item>
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          onClick={() => {
            setIsFlagPatronAccountAsExcludedModalOpen(
              !isFlagPatronAccountAsExcludedModalOpen
            )
          }}
        >
          {!patronQuery.data.exclude
            ? 'Flag Patron Account as Excluded'
            : 'Unflag Patron Account to Include'}
        </Button>
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          onClick={() => {
            setIsFlagPatronAccountAsDeactivatedModalOpen(
              !isFlagPatronAccountAsDeactivatedModalOpen
            )
          }}
        >
          {!patronQuery.data.deactivated
            ? 'Deactivate Patron'
            : 'Activate Patron'}
        </Button>
      </Grid>
      <EditPatronInformation
        key={isEditModalOpen ? 1 : 0}
        isModalOpen={isEditModalOpen}
        toggleModalIsOpen={toggleEditModalIsOpen}
        patron={patronQuery.data}
      />
      <FlagPatronAccountAsExcludedModal
        key={isFlagPatronAccountAsExcludedModalOpen ? 1 : 0}
        isModalOpen={isFlagPatronAccountAsExcludedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        patron={patronQuery.data}
      />

      <FlagPatronAccountAsDeactivatedModal
        key={isFlagPatronAccountAsDeactivatedModalOpen ? 1 : 0}
        isModalOpen={isFlagPatronAccountAsDeactivatedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        patron={patronQuery.data}
      />
    </Grid>
  )
}
