import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useQueryClient } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { useGetTiers } from 'hooks/api/useGetTiers'
import { usePutRewardProgramCatalogs } from 'hooks/api/usePutRewardProgramCatalogs'
import { find, includes, isNil, some, uniq } from 'lodash'
import { useState, useEffect } from 'react'
import type {
  TierType,
  RewardsProgramCatalogTier,
  RewardsCatalog,
  Tier,
} from 'types/api'

const tierOptions: TierType[] = ['Member', 'Insider', 'Elite', 'VIP']

export const EditTierBasedPerkModal = ({
  isOpen,
  onCancel,
  rewardsCatalog,
  rewardsProgramCatalogTiers,
  rewardProgramId,
  rewardProgramCatalogId,
}: {
  isOpen: boolean
  onCancel: () => void
  rewardsProgramCatalogTiers?: RewardsProgramCatalogTier[]
  rewardsCatalog?: RewardsCatalog
  rewardProgramId: number
  rewardProgramCatalogId?: number
}) => {
  const qc = useQueryClient()
  const [earlyAccess, setEarlyAccess] = useState(false)
  const [discounted, setDiscounted] = useState(false)
  const [selectedEarlyAccessTiers, setSelectedEarlyAccessTiers] = useState<
    string[]
  >([])
  const [selectedDiscountedTiers, setSelectedDiscountedTiers] = useState<
    string[]
  >([])

  const clearWholeModal = () => {
    setEarlyAccess(false)
    setDiscounted(false)
    setSelectedEarlyAccessTiers([])
    setSelectedDiscountedTiers([])
  }

  useEffect(() => {
    if (rewardsProgramCatalogTiers) {
      setEarlyAccess(some(rewardsProgramCatalogTiers, (x) => x.earlyAccess))
      setDiscounted(
        some(rewardsProgramCatalogTiers, (x) => x.discountedRewardItem)
      )
      setSelectedEarlyAccessTiers(
        uniq(
          rewardsProgramCatalogTiers
            .filter((x) => x.earlyAccess)
            .map((x) => x.tier?.type ?? '')
        )
      )
      setSelectedDiscountedTiers(
        uniq(
          rewardsProgramCatalogTiers
            .filter((x) => x.discountedRewardItem)
            .map((x) => x.tier?.type ?? '')
        )
      )
    }
  }, [rewardsProgramCatalogTiers])

  const tiersQuery = useGetTiers()
  const putRewardProgramCatalogMutation = usePutRewardProgramCatalogs({
    onError: () => {
      console.log('error')
    },
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: ['/admin/rewards-programs'],
      })
      await qc.invalidateQueries({
        queryKey: ['admin-rewards-program-catalogs-by-rp-id'],
      })
      clearWholeModal()
      onCancel()
    },
  })

  if (tiersQuery.isPending || !tiersQuery.data) {
    return <ActivityIndicator />
  }

  const handleSave = () => {
    const uniqueTiers = uniq([
      ...selectedDiscountedTiers,
      ...selectedEarlyAccessTiers,
    ])
    let existingTierData =
      rewardsProgramCatalogTiers?.map((x) => ({
        rewardsProgramCatalogId: x.rewardsProgramCatalogId,
        tierId: x.tierId,
        tier: x.tier,
        earlyAccess: false,
        discountedRewardItem: false,
        id: 0,
      })) ?? []

    // Remove unselected tiers
    existingTierData = existingTierData.filter(
      (tier) =>
        !isNil(tier.tier) &&
        (selectedDiscountedTiers.includes(tier.tier.type) ||
          selectedEarlyAccessTiers.includes(tier.tier.type))
    )

    if (earlyAccess || discounted) {
      uniqueTiers.forEach((tier) => {
        const searchedTier = find(tiersQuery.data, (x) => x.type === tier)
        const existingRewardProgramCatalogTierIndex = find(
          existingTierData,
          (x) => x.tierId === searchedTier?.id
        )

        if (isNil(existingRewardProgramCatalogTierIndex)) {
          existingTierData?.push({
            discountedRewardItem: includes(selectedDiscountedTiers, tier),
            earlyAccess: includes(selectedEarlyAccessTiers, tier),
            rewardsProgramCatalogId: Number(rewardProgramCatalogId),
            tierId: Number(searchedTier?.id),
            tier: searchedTier,
            id: 0,
          })
        } else if (!isNil(existingTierData)) {
          existingRewardProgramCatalogTierIndex.earlyAccess = includes(
            selectedEarlyAccessTiers,
            tier
          )
          existingRewardProgramCatalogTierIndex.discountedRewardItem = includes(
            selectedDiscountedTiers,
            tier
          )
        }
      })
    }

    putRewardProgramCatalogMutation.mutate({
      id: Number(rewardProgramCatalogId),
      rewardsCatalogId: Number(rewardsCatalog?.id),
      rewardsProgramId: rewardProgramId,
      rewardsProgramCatalogTiers: existingTierData,
    })
  }
  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="md">
      <DialogTitle sx={{ backgroundColor: grey[50] }} className="border-b ">
        <Box className="pt-2 px-2">
          <Typography variant="h2">Tier Based Perks</Typography>
          <Typography variant="label-form">{rewardsCatalog?.name}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="px-2">
          <Stack
            className="border rounded py-3 px-4 mt-6"
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Typography variant="h6">
                Early Access to New Reward Items
              </Typography>
              <Typography variant="body-2" color={'text.secondary'}>
                Only display early access to new reward items to patrons at a
                selected tier level for this reward catalog.
              </Typography>
            </Stack>
            <Switch
              checked={earlyAccess}
              onChange={() => {
                if (earlyAccess) {
                  setSelectedEarlyAccessTiers([])
                }
                setEarlyAccess((prev) => !prev)
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
          {earlyAccess && (
            <Stack pt={'24px'}>
              <Typography variant="subtitle-1">
                Which tier level would you like to offer early access?
              </Typography>
              <Select
                notched={false}
                sx={{ maxWidth: '272px' }}
                label="label"
                multiple
                value={selectedEarlyAccessTiers}
                input={<OutlinedInput label="Tag" />}
                displayEmpty
                renderValue={(selected) =>
                  selected.length === 0 ? 'Select Tier' : selected.join(', ')
                }
                onChange={(event) => {
                  setSelectedEarlyAccessTiers(
                    typeof event.target.value === 'string'
                      ? event.target.value.split(',')
                      : event.target.value
                  )
                }}
              >
                {tierOptions.map((x) => {
                  if (tiersQuery.data.map((t: Tier) => t.type).includes(x)) {
                    return (
                      <MenuItem key={`early-access-${x}`} value={x}>
                        <Checkbox
                          checked={selectedEarlyAccessTiers.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </Select>
            </Stack>
          )}
          <Stack
            className="border rounded py-3 px-4 mt-10"
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Typography variant="h6">Discounted Reward Items</Typography>
              <Typography variant="body-2" color={'text.secondary'}>
                Only display discounted reward items to patrons at a selected
                tier level for this reward catalog.
              </Typography>
            </Stack>
            <Box>
              <Switch
                checked={discounted}
                onChange={() => {
                  if (discounted) {
                    setSelectedDiscountedTiers([])
                  }
                  setDiscounted((prev) => !prev)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Stack>
          {discounted && (
            <Stack pt={'24px'}>
              <Typography variant="subtitle-1">
                Which tier level would you like to offer discounted rewards?{' '}
              </Typography>
              <Select
                notched={false}
                sx={{ maxWidth: '272px' }}
                placeholder="Select Tier"
                multiple
                value={selectedDiscountedTiers}
                input={<OutlinedInput label="Tag" />}
                displayEmpty
                renderValue={(selected) =>
                  selected.length === 0 ? 'Select Tier' : selected.join(', ')
                }
                onChange={(event) => {
                  setSelectedDiscountedTiers(
                    typeof event.target.value === 'string'
                      ? event.target.value.split(',')
                      : event.target.value
                  )
                }}
              >
                {tierOptions.map((x) => {
                  if (tiersQuery.data.map((t: Tier) => t.type).includes(x)) {
                    return (
                      <MenuItem key={`discounted-${x}`} value={x}>
                        <Checkbox
                          checked={selectedDiscountedTiers.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    )
                  }
                  return null
                })}
              </Select>
            </Stack>
          )}
        </Box>
      </DialogContent>
      <DialogActions className="border-t">
        <Box className="py-4 px-4">
          <Button variant="text" onClick={onCancel}>
            <Typography variant="subtitle-2">Cancel</Typography>
          </Button>
          <ActivityButton
            active={putRewardProgramCatalogMutation.isPending}
            variant="contained"
            onClick={handleSave}
          >
            <Typography variant="subtitle-2">Save</Typography>
          </ActivityButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
