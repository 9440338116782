import { Box, Typography, Button } from '@mui/material'

interface SelectRewardsFooterProps {
  onClickCancel: () => void
  onClickNext: () => void
}
export const SelectRewardsFooter = ({
  onClickCancel,
  onClickNext,
}: SelectRewardsFooterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        rowGap: '16px',
        justifyContent: {
          xs: 'normal',
          sm: 'space-between',
        },
        alignItems: { xs: 'normal', sm: 'center' },
      }}
    >
      <Typography variant="body1">Next: Assign redemption values</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '16px',
        }}
      >
        <Button variant={'text'} onClick={onClickCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onClickNext}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
