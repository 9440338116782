import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SystemBannerTypes } from '../types'
import { Sublabel } from 'components/Sublabel'

export const BannerTypeRadioGroup = () => {
  const { register } = useFormContext()

  return (
    <Box>
      <InputLabel htmlFor={'systemBanner.type'} required>
        Banner Type
      </InputLabel>
      <Sublabel>This label type will not be visible to users.</Sublabel>

      <RadioGroup>
        {SystemBannerTypes.map((type) => (
          <FormControlLabel
            key={type}
            label={type}
            control={<Radio {...register('systemBanner.type')} value={type} />}
          />
        ))}
      </RadioGroup>
    </Box>
  )
}
