import {
  ApplicationInsights,
  DistributedTracingModes,
  type ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

export const reactPlugin = new ReactPlugin()
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_MICROSOFT_APP_INSIGHTS,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    instrumentationKey: import.meta.env
      .VITE_MICROSOFT_APP_INSIGHT_INSTRUMENTATION_KEY,
    distributedTracingMode: DistributedTracingModes.W3C,
    enableCorsCorrelation: true,
  },
})

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags = envelope.tags ?? []
  envelope.tags.push({ 'ai.cloud.role': 'jj-admin-client' })
})
