import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteRewardsCatalogParams {
  id: number | null | undefined
}

interface useDeleteRewardsCatalogProps {
  onSuccess?: (
    data: undefined,
    variables: useDeleteRewardsCatalogParams
  ) => void
  onError?: (error: Error, variables: useDeleteRewardsCatalogParams) => void
}

export const useDeleteRewardsCatalogById = ({
  onSuccess,
  onError,
}: useDeleteRewardsCatalogProps) => {
  const deleteRewardsCatalog = useTypedApiClient({
    path: '/admin/rewards-catalogs/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeleteRewardsCatalogParams>({
    mutationFn: async ({ id }: useDeleteRewardsCatalogParams) => {
      await deleteRewardsCatalog({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-catalogs'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
