import { useApiClient } from 'hooks/useApiClient'
import { useQuery } from '@tanstack/react-query'

export const useGetAllRewardPrograms = ({
  enabled = true,
}: {
  enabled?: boolean
}) => {
  const client = useApiClient()
  return useQuery({
    queryKey: ['/rewardsprogram'],
    queryFn: async () => {
      const { data } = await client.get('/admin/rewards-programs', {
        params: { header: undefined },
      })
      return data
    },
    enabled,
  })
}
