import { type Sweepstake } from 'types/api'
import { SectionHeader } from 'components/SectionHeader'
import { Box, Button, Typography, InputLabel } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Modal } from 'components/Modal/Modal'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ActivityButton } from 'components/ActivityButton'
import { useForm } from 'react-hook-form'
import { usePutSweepstake } from 'hooks/api/Sweepstake/usePutSweepstake'
import { useSnackbar } from 'stores/useSnackbar'
import Markdown from 'react-markdown'
import { type MDXEditorMethods } from '@mdxeditor/editor'
import rehypeRaw from 'rehype-raw'
import { MdxEditorWrapper } from './MdxEditorWrapper'
import { isNil } from 'lodash'

export const SweepstakeParticipatingGuidelineSection = ({
  sweepstake,
}: {
  sweepstake: Sweepstake
}) => {
  const sweepstakeGuideline =
    sweepstake?.sweepstakeData?.participationGuidelines
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editorGuidelines, setEditorGuidelines] = useState(sweepstakeGuideline)
  const [hasChanged, setHasChanged] = useState(false)
  const setMessage = useSnackbar((state) => state.setMessage)
  const toggleModalIsOpen = () => {
    setEditorGuidelines(sweepstakeGuideline)
    setIsModalOpen(!isModalOpen)
  }
  const { handleSubmit } = useForm()

  const mdxEditorRef = React.useRef<MDXEditorMethods>(null)

  const putSweepstakeMutation = usePutSweepstake({
    sweepstakeId: sweepstake.id,
    onSuccess: () => {
      setMessage('Participation Guidelines have been updated.', 'success')
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Participation Guidelines', 'error')
    },
  })

  useEffect(() => {
    setHasChanged(editorGuidelines !== sweepstakeGuideline)
  }, [editorGuidelines, sweepstakeGuideline])

  useEffect(() => {
    if (!hasChanged) {
      return
    }
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      return ''
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [hasChanged])

  const handleSubmitWrapper = handleSubmit(() => {
    const newSweepstakeDataId = 0
    const state = sweepstake.sweepstakeData?.state
    if (isNil(state)) {
      throw Error('State is not present')
    }
    putSweepstakeMutation.mutate({
      ...sweepstake,
      sweepstakeData: {
        contestId: sweepstake.id,
        id: sweepstake.sweepstakeData?.id ?? newSweepstakeDataId,
        numGrandPrizeWinnersPerTier:
          sweepstake.sweepstakeData?.numGrandPrizeWinnersPerTier ?? 1,
        participationGuidelines: editorGuidelines,
        state,
        enrollableClosedDate: sweepstake.sweepstakeData?.enrollableClosedDate,
        enrollableOpenedDate: sweepstake.sweepstakeData?.enrollableOpenedDate,
        promoted: sweepstake.sweepstakeData?.promoted,
      },
    })
  })

  return (
    <>
      <SectionHeader title="Details" onClickButton={toggleModalIsOpen} />
      <Box
        sx={{
          p: '16px',
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          ul: { paddingLeft: '24px' },
        }}
      >
        <Typography variant="body-2" color="text.secondary">
          Description for Locations to Enroll
        </Typography>

        <Typography variant="body-1" color="text.primary">
          {sweepstakeGuideline ? (
            <Markdown rehypePlugins={[rehypeRaw]}>
              {sweepstakeGuideline.replace(/\n\n/gi, '&nbsp;\n\n')}
            </Markdown>
          ) : (
            '-'
          )}
        </Typography>
      </Box>
      <Modal isOpen={isModalOpen} toggleIsOpen={() => {}} sx={{ width: '60%' }}>
        <Box>
          <form onSubmit={handleSubmitWrapper}>
            <ModalFullHeader
              toggleIsOpen={toggleModalIsOpen}
              title={'Edit Participation Details'}
            />
            <ModalFullBody heightModal={'55%'}>
              <InputLabel
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  marginBottom: '16px',
                }}
              >
                Description for J&J Connect Users to Enroll in Sweepstake
              </InputLabel>
              <Box
                className="border-2 rounded-lg"
                onClick={() => mdxEditorRef.current?.focus()}
                sx={[
                  {
                    '.mdxeditor-toolbar': {
                      position: 'relative',
                      cursor: 'auto',
                    },
                    ':not(.mdxeditor-toolbar):hover': { cursor: 'text' },
                    ul: { paddingLeft: '24px' },
                    'min-height': '80%',
                    'button + button': { display: 'none' },
                  },
                ]}
              >
                <MdxEditorWrapper
                  sweepstake={sweepstake}
                  setGuidelines={setEditorGuidelines}
                  mdxEditorRef={mdxEditorRef}
                />
              </Box>
            </ModalFullBody>
            <ModalFullFooter>
              <Button onClick={toggleModalIsOpen}>Cancel</Button>
              <ActivityButton type="submit" variant="contained" active={false}>
                Save
              </ActivityButton>
            </ModalFullFooter>
          </form>
        </Box>
      </Modal>
    </>
  )
}
