import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { Box, Button, InputLabel, TextField } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ActivityButton } from 'components/ActivityButton'
import { useForm } from 'react-hook-form'
import { object, number, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePostPatronNote } from 'hooks/api/usePostPatronNote'
import { useSnackbar } from 'stores/useSnackbar'
import { useQueryClient } from '@tanstack/react-query'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'

const NoteSchema = object({
  patronId: number(),
  note: string().required('Note is required'),
})

export const AddPatronNoteModal = ({
  patronId,
  isModalOpen,
  toggleModalIsOpen,
}: {
  patronId: number
  isModalOpen: boolean
  toggleModalIsOpen: () => void
}) => {
  const qc = useQueryClient()
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const formMethods = useForm({
    resolver: yupResolver(NoteSchema),
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = formMethods

  const noteValue = watch('note', '')

  const usePostPatronNoteMutation = usePostPatronNote({
    patronId,
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/notes'] })
      toggleModalIsOpen()
      reset()
    },
    onError: () =>
      setSnackbarMessage(`Error: Cannot save Patron note`, 'error'),
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    if (formData.note) {
      usePostPatronNoteMutation.mutate({
        patronId,
        note: formData.note,
      })
    }
  })

  const onModalClose = () => {
    toggleModalIsOpen()
    reset()
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '45%',
        height: heightModal,
      }}
    >
      <Box>
        <ModalFullHeader
          toggleIsOpen={toggleModalIsOpen}
          title={'New Patron Note'}
        />
        <ModalFullBody heightModal={heightModal}>
          <form>
            <div className="flex flex-col md:flex-row justify-between py-3 md:items-center">
              <InputLabel sx={{ fontSize: '16px', fontWeight: '100' }}>
                Note
              </InputLabel>
              <div className="md:w-3/4 flex flex-col">
                <TextField
                  inputProps={{ maxLength: 500 }}
                  multiline={true}
                  minRows={2}
                  fullWidth
                  {...register('note')}
                  error={isPresent(errors.note)}
                />
                {errors.note?.message && (
                  <FormFieldErrorMessage message={errors.note.message} />
                )}
              </div>
            </div>
          </form>
        </ModalFullBody>
        <ModalFullFooter>
          <Button onClick={() => onModalClose()}>Cancel</Button>
          <ActivityButton
            active={false}
            type="submit"
            variant="contained"
            disabled={!noteValue.trim()}
            onClick={handleSubmitWrapper}
          >
            Save
          </ActivityButton>
        </ModalFullFooter>
      </Box>
    </Modal>
  )
}
