import { type Reward } from 'types/api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useApiClient } from 'hooks/useApiClient'
import { type FileImageRequest } from 'types/fileUpload'

interface usePutRewardProps {
  id: number
  onSuccess?: (data: Reward, variables: FileImageRequest) => void
  onError?: (error: Error, variables: FileImageRequest) => void
}

export const usePutImageReward = ({
  id,
  onSuccess,
  onError,
}: usePutRewardProps) => {
  const putAPIClient = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (rewards: FileImageRequest) => {
      const body = new FormData()
      body.set('FileUpload', rewards.ImageFile)
      body.set('FileUploadName', rewards.ImageFileName)
      body.set('FileUploadType', rewards.ImageFileType)

      const { data, error } = await putAPIClient.put(
        '/admin/rewards/{id}/upload',
        {
          params: { path: { id } },
          body: {},
          bodySerializer: () => body,
        }
      )
      return { data, error }
    },
    onSuccess: async ({ data, error }, variables: FileImageRequest) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards/{id}', id],
      })
      if (error !== undefined) {
        onError?.(error as Error, variables)
      } else {
        onSuccess?.(data as Reward, variables)
      }
    },
    onError,
  })
}
