import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { useGetNumberGrandPrizeEntries } from 'hooks/api/Sweepstake/EntriesCount/useGetNumberGrandPrizeEntries'

export const GrandPrizeDetails = ({
  sweepstakeId,
}: {
  sweepstakeId: number
}) => {
  const theme = useTheme()

  const query = useGetNumberGrandPrizeEntries({
    contestId: sweepstakeId,
  })

  if (query.isPending) {
    return (
      <Box>
        <CircularProgress />
        <Typography> Loading getGrandPrizeTotalEntries query...</Typography>
      </Box>
    )
  }

  if (query.isError) {
    return (
      <Typography variant="body1" color="error">
        Could not retrieve grand prize entries
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="h2" pb={2}>
        Grand Prize Details
      </Typography>
      <Box
        border={{ md: `1px solid ${theme.palette.grey[200]}` }}
        borderRadius={'5px'}
        p={{ xs: 0, sm: 3 }}
      >
        <Grid container columnGap={20}>
          <Grid item md={2}>
            <Typography variant="body2" pb={0.5} color="text.secondary">
              Total Entries for Grand Prize
            </Typography>
            <Typography variant="body1" pb={2}>
              {query.data ?? 'Cannot retrieve'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
