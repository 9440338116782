import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SchedulingOptions } from './SystemBannerSchema'

export const SchedulingOptionsRadioGroup = () => {
  const { register } = useFormContext()

  return (
    <Box>
      <InputLabel htmlFor={'schedulingOption-Display Immediately'} required>
        Scheduling Options
      </InputLabel>

      <RadioGroup>
        {SchedulingOptions.map((option) => (
          <FormControlLabel
            key={option}
            label={option}
            control={
              <Radio
                {...register('systemBanner.schedulingOption')}
                value={option}
                id={`schedulingOption-${option}`}
              />
            }
          />
        ))}
      </RadioGroup>
    </Box>
  )
}
