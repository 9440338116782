import { Card, type SxProps, type Theme, Typography } from '@mui/material'

export const SimpleImageCard = ({
  imageSource,
  sx,
}: {
  imageSource: string | undefined | null
  sx?: SxProps<Theme>
}) => {
  return (
    <>
      {imageSource === null || imageSource === undefined ? (
        <Typography>No image</Typography>
      ) : (
        ''
      )}
      <Card sx={sx}>
        {imageSource && (
          <img
            alt="ImageCard"
            src={imageSource}
            className="w-full h-full object-center object-cover"
          />
        )}
      </Card>
    </>
  )
}
