import {
  useMutation,
  useQueryClient,
  type UseMutationResult,
} from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useNavigate, useParams } from 'react-router-dom'

interface UseDeleteAdminLEContestParams {
  contestId: number
  licensedEstablishmentId: number
}

interface UseDeleteAdminLEContestProps {
  onSuccess?: (
    data: undefined,
    variables: UseDeleteAdminLEContestParams
  ) => void
  onError?: (error: Error, variables: UseDeleteAdminLEContestParams) => void
}

export const UseDeleteAdminLEContest = ({
  onSuccess,
  onError,
}: UseDeleteAdminLEContestProps): UseMutationResult<
  undefined,
  Error,
  UseDeleteAdminLEContestParams
> => {
  const deleteAdminLEContest = useTypedApiClient({
    path: '/admin/contests/{contestId}/licensed-establishments/{licensedEstablishmentId}',
    method: 'delete',
  })

  const queryClient = useQueryClient()
  const params = useParams()
  const navigate = useNavigate()

  return useMutation<undefined, Error, UseDeleteAdminLEContestParams>({
    mutationFn: async ({
      contestId,
      licensedEstablishmentId,
    }: UseDeleteAdminLEContestParams) => {
      await deleteAdminLEContest({
        contestId,
        licensedEstablishmentId,
      })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          '/admin/contests/{contestId}/licensed-establishments/{licensedEstablishmentId}',
        ],
      })
      onSuccess?.(data, variables)
      navigate(
        `/Contests/Sweepstakes/${params.contestId}/ParticipatingLocations`
      )
    },
    onError,
  })
}
