import { Button, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { useApiClient } from 'hooks/useApiClient'
import { useLayout } from 'hooks/useLayout'
import { isNil } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import { dataTableIdFormatter } from 'utils/util'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { useState } from 'react'
import { type Organization } from 'types/api'

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      minWidth: 300,
      headerName: 'Organization Name',
      flex: 0.5,
    },
    {
      field: 'id',
      minWidth: 50,
      headerName: 'Organization ID',
      flex: 1,
      valueFormatter: dataTableIdFormatter(isMobile),
    },
  ]
}

interface OrganizationListProps {
  orgId?: string
  keysToShow?: GridColDef[]
}

export const OrganizationList = ({
  orgId,
  keysToShow,
}: OrganizationListProps) => {
  const client = useApiClient()
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const [filteredData, setFilteredData] = useState<Organization[]>()

  const handleFilteredData = (filteredData: Organization[]) => {
    setFilteredData(filteredData)
  }

  const {
    isPending,
    isError,
    data: organizations,
  } = useQuery({
    queryKey: ['organizations/list', orgId],
    queryFn: async () => {
      const { data } = await client.get('/admin/organizations', {
        params: { header: undefined },
      })
      if (isNil(data)) {
        throw new Error("Couldn't fetch Organizations")
      }
      if (isNil(orgId)) {
        return data
      }
      return data.filter((org) => org.parentOrganizationId === Number(orgId))
    },
  })

  if (isError) {
    return <Typography>Something went wrong</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title="Organizations"
          eyebrow="CLIENT MANAGEMENT"
          actionButton={
            <Link to="/Organizations/New">
              <Button variant="contained">Create New Organization</Button>
            </Link>
          }
        />
      }
    >
      {isPending ? (
        <ActivityIndicator />
      ) : (
        <>
          <GridSearchField<'FullOrganizationDTO'>
            placeholder="Search Organizations"
            data={organizations ?? []}
            handleFilteredData={handleFilteredData}
            fullWidth
          />

          <DataTable
            data-testid="custom-data-table"
            rows={filteredData ?? organizations}
            columns={keysToShow ?? defaultColumns(isMobile)}
            onRowClick={(params) => {
              if (params.id !== null) {
                navigate(`/Organization/${params.id}`)
              }
            }}
          />
        </>
      )}
    </Page>
  )
}
