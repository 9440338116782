import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type Sweepstake } from 'types/api'
import { isPresent } from '@jjvgaming/player-payback-library'

const path = '/admin/contests/{id}'
const method = 'put'

interface UsePutSweepstakeParams {
  sweepstakeId: number
  onSuccess?: () => void
  onError?: () => void
}

type WritableSweepstake = Sweepstake & {
  id: number
}

const isSweepstakeWritable = (
  sweepstake: Sweepstake
): sweepstake is WritableSweepstake => {
  return isPresent(sweepstake.id)
}

export const usePutSweepstake = ({
  sweepstakeId,
  onSuccess,
  onError,
}: UsePutSweepstakeParams) => {
  const queryClient = useQueryClient()
  const put = useTypedApiClient({ path, method })
  const mutate = useMutation({
    mutationKey: ['/contests/{id}', sweepstakeId],
    mutationFn: async (sweepstake: Sweepstake) => {
      if (isSweepstakeWritable(sweepstake)) {
        const { data } = await put(sweepstake)
        return data
      } else {
        throw new Error(
          'Cannot write LicensedEstablishment without required keys: id, corporateAccount'
        )
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes', sweepstakeId],
      })
      onSuccess?.()
    },
    onError,
  })

  return mutate
}
