import { type components } from 'api/playerPayback/api'

export type SystemBannerType = components['schemas']['SystemBannerType']
export type SystemBannerPlatformType =
  components['schemas']['SystemBannerPlatformType']

// unfortunately no way to convert string literal unions to a value
export const SystemBannerTypes = ['Urgent', 'Informational'] as const
export const SystemBannerPlatformTypes = [
  'PatronMobileApp',
  'AdminConsole',
  'LEConnect',
  'Kiosk',
] as const
