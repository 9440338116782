import { Button, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { DataTable } from 'components/Shared/DataTable'
import { useApiClient } from 'hooks/useApiClient'
import { useLayout } from 'hooks/useLayout'
import { isNil } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'
import { dataTableIdFormatter } from 'utils/util'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useState } from 'react'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { type CorporateAccount } from 'types/api'

const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Corporate Account Name',
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'id',
      headerName: 'Corporate Account ID',
      valueFormatter: dataTableIdFormatter(isMobile),
      flex: 1,
      minWidth: 160,
    },
    {
      field: 'smartID',
      headerName: 'SMART ID',
      valueFormatter: dataTableIdFormatter(isMobile),
      flex: 1,
      minWidth: 160,
    },
  ]
}

export const CorporateAccountList = ({
  keysToShow,
}: {
  keysToShow?: GridColDef[]
}) => {
  const client = useApiClient()
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const [filteredData, setFilteredData] = useState<CorporateAccount[]>()

  const handleFilteredData = (filteredData: CorporateAccount[]) => {
    setFilteredData(filteredData)
  }

  const {
    isPending,
    isError,
    data: corporateAccounts,
  } = useQuery({
    queryKey: ['corporateAccounts'],

    queryFn: async () => {
      const { data } = await client.get('/admin/corporate-accounts', {
        params: { header: undefined },
      })
      if (isNil(data)) {
        throw new Error("Couldn't fetch Corporate Accounts")
      }
      return data
    },

    select: (data) =>
      data?.map((corporateAccount) => ({
        id: corporateAccount.id,
        name: corporateAccount.name,
        smartID: corporateAccount.smartID,
      })),
  })

  if (isError) {
    return <Typography>Something went wrong</Typography>
  }

  return (
    <Page
      header={
        <PageHeader
          title="Corporate Accounts"
          eyebrow="CLIENT MANAGEMENT"
          actionButton={
            <Link to="/CorporateAccounts/New">
              <Button variant="contained">Create New Corporate Account</Button>
            </Link>
          }
        />
      }
    >
      {isPending || isNil(corporateAccounts) ? (
        <ActivityIndicator />
      ) : (
        <>
          <GridSearchField<'FullCorporateAccountDTO'>
            placeholder="Search Corporate Accounts"
            data={corporateAccounts}
            handleFilteredData={handleFilteredData}
            fullWidth
          />

          <DataTable
            data-testid="custom-data-table"
            rows={filteredData ?? corporateAccounts}
            columns={keysToShow ?? defaultColumns(isMobile)}
            onRowClick={(params) => {
              if (params.id !== null) {
                navigate(
                  `/CorporateAccounts/${params.id}/CorporateAccountOverview`
                )
              }
            }}
            getRowClassName={() => 'text-[#2c2c27]'}
          />
        </>
      )}
    </Page>
  )
}
