import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useRewardsCatalog = ({
  enabled = true,
}: {
  enabled?: boolean
}): UseQueryResult<Array<components['schemas']['FullRewardsCatalogDTO']>> => {
  const get = useTypedApiClient({
    path: '/admin/rewards-catalogs',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/rewards-catalogs'],
    queryFn: async () => {
      const { data } = await get({})
      return data
    },
    enabled,
  })
}
