import { useSnackbar } from 'stores/useSnackbar'
import { ActivityButton } from 'components/ActivityButton'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useDeleteRewardsCatalogById } from 'hooks/api/useDeleteRewardsCatalogById'
import type { RewardsCatalog } from 'types/api'
import { grey } from '@mui/material/colors'

export const RewardsCatalogDeleteModal = ({
  isModalOpen,
  toggleModalIsOpen,
  rewardsCatalog,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  rewardsCatalog: RewardsCatalog
}) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const deleteRewardsCatalogMutation = useDeleteRewardsCatalogById({
    onSuccess: () => {
      setSnackbarMessage(`Rewards Catalog has been deleted.`)
      toggleModalIsOpen()
      navigate(`/Rewards/RewardCatalogs`)
    },
    onError: () => {
      setSnackbarMessage(`Error: Cannot delete rewards catalog`, 'error')
    },
  })

  const handleDelete = () => {
    deleteRewardsCatalogMutation.mutate({ id: rewardsCatalog.id })
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '600px' } }}
      onClose={toggleModalIsOpen}
    >
      <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
        <Box px={4} py={4}>
          <Typography variant="h3" fontWeight={'bold'}>
            Delete Rewards Catalog?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 4, px: 4 }}>
        <Typography variant="body-1" className="text-secondary" py={'16px'}>
          <b>Are you sure you want to delete this rewards catalog?</b>
        </Typography>
        <Typography variant="body-1" className="text-secondary" pb={'16px'}>
          {`${rewardsCatalog?.name}`}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
      >
        <Button onClick={toggleModalIsOpen}>Cancel</Button>
        <ActivityButton
          variant="contained"
          active={deleteRewardsCatalogMutation.isPending}
          onClick={handleDelete}
        >
          Delete
        </ActivityButton>
      </DialogActions>
    </Dialog>
  )
}
