import { Alert, Box, Button, Grid, Stack, Typography } from '@mui/material'
import { SweepstakesDetailsHeader } from './SweepstakeDetailsHeader'
import { useParams } from 'react-router-dom'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { isNil } from 'lodash'
import { usePutContest } from 'hooks/api/usePutContest'
import { formatFullDateTime } from 'utils/util'
import { useQueryClient } from '@tanstack/react-query'
import { isPresent } from '@jjvgaming/player-payback-library'
import { PromoteSweepstakeModal } from './PromoteSweepstakeModal'
import { useMemo, useState } from 'react'
import { SweepstakeSettingsEnrollmentModal } from './SweepstakeSettingsEnrollmentModal'

interface InvalidSweepstakeAlertBoxProps {
  validSweepstake: boolean
}

const InvalidSweepstakeAlertBox = ({
  validSweepstake,
}: InvalidSweepstakeAlertBoxProps) => {
  if (validSweepstake) {
    return (
      <Grid item xs={12} sm={12} marginTop={'20px'}>
        <Alert severity="error">
          Unable to open enrollment. All fields must be completed to
          successfully open enrollment to J&J Connect.
        </Alert>
      </Grid>
    )
  }
  return null
}

export const SweepstakeDetailsSettingsPage = () => {
  const { id: idParam } = useParams()

  if (isNil(idParam)) {
    throw Error('Id Param Missing')
  }

  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState<boolean>(false)

  const qc = useQueryClient()
  const sweepstakeId = Number(idParam)
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)

  const { mutateAsync: putContestAsync } = usePutContest({
    onError: () => {},
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/sweepstakes'] })
      setIsPromoteModalOpen(false)
    },
  })

  const validSweepstake = useMemo(() => {
    if (sweepstakeQuery.data) {
      const { description, name, startDate, endDate, sweepstakeData } =
        sweepstakeQuery.data
      return !(
        description &&
        name &&
        startDate &&
        endDate &&
        sweepstakeData?.participationGuidelines
      )
    }
    return false
  }, [sweepstakeQuery.data])

  if (sweepstakeQuery.isPending) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError || isNil(sweepstakeQuery.data)) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  const sweepstake = sweepstakeQuery.data
  const isContestPromoted = !isNil(sweepstake.sweepstakeData?.promoted)

  const isSetUpValid =
    isPresent(sweepstake.name) &&
    sweepstake.name !== '' &&
    !isNil(sweepstake.startDate) &&
    !isNil(sweepstake.endDate) &&
    isPresent(sweepstake.url) &&
    sweepstake.url !== '' &&
    !isNil(sweepstake.imageFileName) &&
    !isNil(sweepstake.publicImageUrl)

  const sweepstakeData = sweepstake.sweepstakeData

  if (!sweepstakeData) {
    return (
      <SweepstakesDetailsHeader
        sweepstake={sweepstake}
        currentTab={`/Contests/Sweepstakes/${sweepstake.id}/Settings`}
      >
        <Stack>
          <Typography variant="h4">
            We had an issue with the sweepstake data. Try again by filling the #
            of grand prize winners or another field.
          </Typography>
        </Stack>
      </SweepstakesDetailsHeader>
    )
  }

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Contests/Sweepstakes/${sweepstake.id}/Settings`}
    >
      <Typography mt="25px" mb="15px" variant="h3">
        Enrollment to J&J Connect
      </Typography>
      {sweepstake.sweepstakeData?.state === 'Draft' && (
        <Stack
          sx={{
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            p: '24px',
            mb: '40px',
            width: '100%',
          }}
        >
          <Grid
            container
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <Grid item xs={8} sm={10}>
              <Typography variant="body-1" color="text.primary">
                Once enrollment is open, all J&J Connect users will have access
                to view the upcoming sweepstakes and register their locations
                for participation.
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sm={2}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <Button
                variant="contained"
                sx={{ padding: '22px' }}
                onClick={toggleModalIsOpen}
                disabled={validSweepstake}
              >
                Open Enrollment
              </Button>
            </Grid>
            <InvalidSweepstakeAlertBox validSweepstake={validSweepstake} />
          </Grid>
          <SweepstakeSettingsEnrollmentModal
            isModalOpen={isModalOpen}
            toggleIsOpen={toggleModalIsOpen}
            onCancel={toggleModalIsOpen}
            sweepstake={sweepstake}
            isEnrollmentOpen={true}
          />
        </Stack>
      )}
      {sweepstake.sweepstakeData?.state === 'Enrollable' && (
        <Stack
          sx={{
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            p: '24px',
            mb: '40px',
            width: '100%',
          }}
        >
          <Grid
            container
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <Grid item xs={8} sm={10}>
              <Typography variant="body-1" color="text.primary">
                Once enrollment is closed, J&J Connect users will no longer be
                able to register their locations for participation.
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2} display="flex" justifyContent="right">
              <Button
                variant="contained"
                sx={{ padding: '22px' }}
                onClick={toggleModalIsOpen}
              >
                Close Enrollment
              </Button>
            </Grid>
          </Grid>
          <Typography variant="body-2" fontSize={'16px'} color="text.secondary">
            Enrollment Opened:{' '}
            {formatFullDateTime(
              sweepstake.sweepstakeData?.enrollableOpenedDate
            )}
          </Typography>
          <SweepstakeSettingsEnrollmentModal
            isModalOpen={isModalOpen}
            toggleIsOpen={toggleModalIsOpen}
            onCancel={toggleModalIsOpen}
            sweepstake={sweepstake}
            isEnrollmentOpen={false}
          />
        </Stack>
      )}
      {sweepstake.sweepstakeData?.state !== 'Draft' &&
        sweepstake.sweepstakeData?.state !== 'Enrollable' && (
          <Stack
            sx={{
              borderWidth: 1,
              borderColor: 'grey.300',
              borderRadius: 2,
              p: '24px',
              mb: '40px',
              width: '100%',
            }}
          >
            <Grid
              container
              alignItems={'flex-start'}
              justifyContent={'space-between'}
            >
              <Grid item xs={8} sm={10}>
                <Typography variant="body-1" color="text.primary">
                  Enrollment has been closed, J&J Connect users are no longer
                  able to register their locations for the sweepstakes. But
                  admin are still able to enroll through the Control Center.
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2} display="flex" justifyContent="right">
                <Button variant="contained" sx={{ padding: '22px' }} disabled>
                  Enrollment Closed
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="label-form"
              color="text.secondary"
              paddingY={'5px'}
            >
              Enrollment Opened:{' '}
              {formatFullDateTime(
                sweepstake.sweepstakeData?.enrollableOpenedDate
              )}
            </Typography>
            <Typography variant="label-form" color="text.secondary">
              Enrollment Closed:{' '}
              {formatFullDateTime(
                sweepstake.sweepstakeData?.enrollableClosedDate
              )}
            </Typography>
          </Stack>
        )}
      <PromoteSweepstakeModal
        sweepstakeName={sweepstake.name}
        isModalOpen={isPromoteModalOpen}
        toggleIsOpen={() => setIsPromoteModalOpen((x) => !x)}
        onConfirm={async () => {
          await putContestAsync({
            ...sweepstake,
            sweepstakeData: {
              ...sweepstakeData,
              contestId: Number(sweepstakeData?.contestId),
              promoted: new Date().toUTCString(),
            },
          })
        }}
      />
      <Box>
        <Typography variant="h3" pb={'8px'}>
          Promote to Patrons
        </Typography>
        <Stack
          spacing={'24px'}
          sx={{
            p: '24px',
            width: '100%',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
          }}
        >
          <Stack
            direction={'row'}
            spacing={'24px'}
            justifyContent={'space-between'}
          >
            <Typography variant="label-form">
              Once &quot;Promote&quot; is selected, all patrons will see this
              sweepstakes as upcoming in the J&J Gaming App. Once the
              sweepstakes goes live, this promotion will be replaced by the live
              sweepstakes.{' '}
            </Typography>
            <Button
              variant="contained"
              onClick={async () => {
                setIsPromoteModalOpen(true)
              }}
              disabled={isContestPromoted || !isSetUpValid}
            >
              {isContestPromoted ? 'Promoted' : 'Promote'}
            </Button>
          </Stack>
          {!isSetUpValid && !isContestPromoted ? (
            <Alert severity="error" className="p-6">
              Unable to promote sweepstakes. All fields must be completed to
              successfully promote sweepstakes in the J&J Gaming app. Make sure
              an image, URL, and appropriate start date is provided.
            </Alert>
          ) : null}
          {isContestPromoted && (
            <Typography
              variant="label-form"
              className="pt-4"
              color={'text.secondary'}
            >
              Promoted:{' '}
              {formatFullDateTime(sweepstake.sweepstakeData?.promoted)}
            </Typography>
          )}
        </Stack>
      </Box>
    </SweepstakesDetailsHeader>
  )
}
