import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetRewardItemById = ({
  rewardItemId,
}: {
  rewardItemId: number
  enabled?: boolean
}): UseQueryResult<components['schemas']['FullRewardDTO']> => {
  const get = useTypedApiClient({
    path: '/admin/rewards/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/rewards/{id}', rewardItemId],
    queryFn: async () => {
      const { data } = await get({ id: rewardItemId })
      return data
    },
  })
}
