import { Form } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/Form'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'

export const LicensedEstablishmentNewPage = () => {
  return (
    <Page
      header={
        <PageHeader
          title="Create a New Licensed Establishment"
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backPath="/ClientManagement/LicensedEstablishments"
          backText="LE Management"
          isSecondary={true}
        />
      }
    >
      <Form />
    </Page>
  )
}
