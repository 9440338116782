import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { Counter } from 'components/Counter/Counter'
import type { PointsLedgerSummary } from 'types/api'
import { Box, Button, Typography } from '@mui/material'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { usePostManualPointsLedger } from 'hooks/api/usePostManualPointsLedger'
import { ActivityButton } from 'components/ActivityButton'

export const ManuallyGrantPointsModal = ({
  patronId,
  isModalOpen,
  toggleModalIsOpen,
  pointLedgerSummary,
}: {
  patronId: number
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  pointLedgerSummary: PointsLedgerSummary | undefined
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '60%'
  const { handleSubmit } = useForm()
  const [messageError, setMessageError] = useState('')
  const [pointsToGrant, setPointsToGrant] = useState(0)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const grantPoints = handleSubmit(async () => {
    postManualPointsLedgerMutation.mutate({
      rewardsProgramId: Number(pointLedgerSummary?.rewardsProgramId),
      amount: pointsToGrant,
    })
  })

  const resetAll = () => {
    toggleModalIsOpen()
    setMessageError('')
    setPointsToGrant(0)
  }

  const setPointsToGrantWithValidation = (amount: number) => {
    if (amount > 0) {
      setPointsToGrant(amount)
      return
    }

    if (Number(pointLedgerSummary?.balance) + amount >= 0) {
      setPointsToGrant(amount)
    }
  }

  const postManualPointsLedgerMutation = usePostManualPointsLedger({
    patronId,
    onSuccess: (_, __) => {
      resetAll()
      setSnackbarMessage(
        `${pointLedgerSummary?.rewardsProgram?.name} Points balance has been updated.`
      )
    },
    onError: (error, _) => {
      setMessageError(error.message)
    },
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '45%',
        height: heightModal,
      }}
    >
      <Box>
        <form onSubmit={grantPoints}>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={`Award ${pointLedgerSummary?.rewardsProgram?.name} Points`}
            subTitle={`ID ${String(pointLedgerSummary?.rewardsProgram?.id)}`}
          />
          <ModalFullBody heightModal={heightModal}>
            {messageError && (
              <Box className="w-full mb-[3%]">
                <FormFieldErrorMessage
                  message={messageError}
                  variant="contained"
                />
              </Box>
            )}
            <Box className="flex flex-col border rounded px-4 h-[40px] mb-5 w-full">
              <div className="grid grid-cols-2 gap-2 h-full">
                <div className="flex items-center">
                  <Typography variant="body-1">
                    Current Point Balance
                  </Typography>
                </div>
                <div className="flex items-center justify-end">
                  <Typography variant="body-1">
                    {pointLedgerSummary?.balance}
                  </Typography>
                </div>
              </div>
            </Box>
            <Box className="flex flex-col w-full h-[52px]">
              <div className="grid grid-cols-2 gap-2 h-full">
                <div className="flex items-center">
                  <div>
                    <Typography variant="body-1" fontWeight={'bold'}>
                      Add or remove points
                    </Typography>
                  </div>
                </div>
                <Counter
                  amount={pointsToGrant}
                  setAmount={setPointsToGrantWithValidation}
                />
              </div>
            </Box>
          </ModalFullBody>
          <ModalFullFooter>
            <Button onClick={resetAll}>Cancel</Button>
            <ActivityButton
              active={postManualPointsLedgerMutation.isPending}
              type="submit"
              variant="contained"
              disabled={pointsToGrant === 0}
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </Box>
    </Modal>
  )
}
