import { ActivityIndicator } from './Shared/ActivityIndicator'
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  type SelectProps,
  Typography,
} from '@mui/material'
import { type ForwardedRef, forwardRef } from 'react'
import { useGetTiers } from 'hooks/api/useGetTiers'

export const SelectPatronTier = forwardRef(
  (
    { label, name, required, placeholder, ...otherProps }: SelectProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { isPending, isError, data: patronTiers } = useGetTiers()
    if (isPending) {
      return <ActivityIndicator />
    }

    if (isError) {
      return <Typography>Error</Typography>
    }

    return (
      <Box>
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Select
          labelId={name}
          name={name}
          ref={ref}
          variant="outlined"
          defaultValue=""
          required={required}
          inputProps={{ id: name }}
          displayEmpty
          {...otherProps}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <MenuItem value="">{placeholder}</MenuItem>
          {patronTiers?.map((pt) => (
            <MenuItem key={`patron-tier-option-${pt.id}`} value={String(pt.id)}>
              {pt.type}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }
)

SelectPatronTier.displayName = 'SelectCorporateAccount'
