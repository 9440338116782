import { Button, Typography } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useLayout } from 'hooks/useLayout'

interface SweepstakesConfirmationModalProps {
  variant: 'Redraw' | 'Notify'
  isModalOpen: boolean
  toggleIsOpen: () => void
  onConfirm: () => void
  onCancel: () => void
  sweepstakeName: string
}

export const SweepstakesConfirmationModal = ({
  variant,
  isModalOpen,
  toggleIsOpen,
  sweepstakeName,
  onConfirm,
  onCancel,
}: SweepstakesConfirmationModalProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '50%'
  const title =
    variant === 'Redraw'
      ? 'Redraw Winners'
      : 'Notify All Participating Locations of Winners'
  const bodyText =
    variant === 'Redraw'
      ? `Are you sure you want to redraw all winners for the ${sweepstakeName}? Redrawing for all locations will pull the next eligible name from the standby lists. It will also mark the current winners as disqualified.`
      : `Are you sure you want to notify all locations of their location winners? Selecting "Notify" will send an email to all participating locations directing them to J&J Connect to view winner(s) and request a redraw, as needed.`
  const confirmButtonText = variant === 'Redraw' ? 'Redraw Winner' : 'Notify'
  const firstSentenceEndIndex = bodyText.indexOf('?') + 1
  const question = bodyText.substring(0, firstSentenceEndIndex)
  const remainingText = bodyText.substring(firstSentenceEndIndex + 1).trim()
  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleIsOpen}
      sx={{
        width: { xs: '90vw', sm: '60%' },
      }}
    >
      <ModalFullHeader
        toggleIsOpen={toggleIsOpen}
        title={title}
        subTitle={sweepstakeName}
      />
      <ModalFullBody heightModal={heightModal}>
        <Typography variant="h3">{question}</Typography>
        {remainingText && (
          <Typography className="text-secondary" sx={{ mt: '16px' }}>
            {remainingText}
          </Typography>
        )}
      </ModalFullBody>
      <ModalFullFooter>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </ModalFullFooter>
    </Modal>
  )
}
