import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

const path = '/admin/sweepstake-drawings/notify/{contestId}'
const method = 'post'
interface Args {
  contestId: number
}

export const useNotifyLocations = () => {
  const action = useTypedApiClient({ path, method })
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [path],
    mutationFn: async (args: Args) => {
      await action(args)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['/sweepstakes'] })
    },
  })
}
