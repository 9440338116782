import { Box, Button, Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useNumberParam } from 'hooks/useNumberParam'
import { useState } from 'react'
import { isNil, some } from 'lodash'
import { useUserData } from 'hooks/api/useUserData'
import { EditAssociatedAccountsModal } from 'pages/ClientManagement/LEConnectUsersPage/EditAssociatedAccountsModal'
import { EditLEUserInformation } from '../LEConnectUsers/EditLEUserInformation'
import { ContactInformation } from './ContactInformation'
import { LeConnectUserDetailsHeader } from './LeConnectUserDetailsHeader'
import { useQueryClient } from '@tanstack/react-query'
import { AccessLevels } from './AccessLevels'

export const LEConnectUserDetailsPage = () => {
  const userId = useNumberParam('id')
  const {
    isPending,
    isError,
    userName,
    formattedUpdatedAt,
    user,
    licensedEstablishments,
    organizations,
    corporateAccounts,
  } = useUserData({
    userId,
  })
  const queryClient = useQueryClient()

  const [isAssignLeUserModalOpen, setIsAssignLeUserModalOpen] = useState(false)
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false)
  const toggleAssignLeUserModal = () =>
    setIsAssignLeUserModalOpen((state) => !state)

  const toggleEditUserModal = async () => {
    if (isEditUserModalOpen) {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/licensed-establishments/users/{id}', user?.id],
      })
    }
    setIsEditUserModalOpen(!isEditUserModalOpen)
  }

  if (
    isPending ||
    isNil(user) ||
    some(corporateAccounts, isNil) ||
    some(organizations, isNil) ||
    some(licensedEstablishments, isNil)
  ) {
    return (
      <Box className="items-center justify-center h-full">
        <ActivityIndicator />
      </Box>
    )
  }

  if (isError) {
    return (
      <Page
        header={
          <PageHeader
            title={``}
            isSecondary={true}
            backPath="/ClientManagement/LEConnectUsers"
            backText="J&J Connect Users"
            subtitle={`Last updated:`}
          />
        }
      >
        <Typography>Something went wrong</Typography>
      </Page>
    )
  }

  return (
    <Page
      header={
        <LeConnectUserDetailsHeader
          user={user}
          formattedUpdatedAt={formattedUpdatedAt}
          userName={userName}
          currentTab="Overview"
        />
      }
    >
      <ContactInformation user={user} toggleModal={toggleEditUserModal} />

      <Box className="flex flex-row justify-between mt-12">
        <Typography variant="h2">Associated Accounts</Typography>
        <Button variant="text" onClick={toggleAssignLeUserModal}>
          Edit Associated Accounts
        </Button>
      </Box>

      <Stack rowGap={4}>
        <AccessLevels
          corporateAccounts={corporateAccounts}
          user={user}
          organizations={organizations}
          licensedEstablishments={licensedEstablishments}
        />
      </Stack>

      <EditAssociatedAccountsModal
        isOpen={isAssignLeUserModalOpen}
        onCancel={toggleAssignLeUserModal}
        leUserId={user.id}
        user={user}
        corporateAccounts={corporateAccounts}
      />

      <EditLEUserInformation
        LEUserId={Number(userId)}
        isModalOpen={isEditUserModalOpen}
        toggleModal={toggleEditUserModal}
      />
    </Page>
  )
}
