import { Typography, Box, Checkbox } from '@mui/material'
import { type GridColDef, type GridRenderCellParams } from '@mui/x-data-grid'
import { Colors } from 'components/Theme'
import { colors } from 'components/ThemeProvider'
import { toUpper, isNil } from 'lodash'
import {
  type SweepstakeWinner,
  type SweepstakeWinnerStatusType,
} from 'types/api'
import { statusTextMap } from 'components/List/FilterStatusList'
import { LocationPrizeMenu } from './LocationPrizeMenu'
import { isPresent } from '@jjvgaming/player-payback-library'
import { type SelectedWinnerType } from './SweepstakesWinnersPage'
import { formatFullDate } from 'utils/util'
import { type RequestRedrawModalDataType } from 'stores/useRequestRedrawModal'

export interface LocationWinnerTableProps {
  id: number
  leName: string
  leLicenseNumber: string
  winnerName: string
  patronId: number
  winnerStatus: string
  dto: SweepstakeWinner
  resultType: string | undefined
  drawingSequence: number | undefined
  drawnDate: string
}

export const getStatusStyleColors = (state: SweepstakeWinnerStatusType) => {
  const background = Colors.sweepstakesWinnerStatus.background[state]
  const color = Colors.sweepstakesWinnerStatus.text[state]

  return {
    background,
    color,
    padding: '3px 10px',
    borderRadius: '2px',
    fontWeight: 'bold',
  }
}

export const getLocationWinnersColumns = (
  locationWinners: LocationWinnerTableProps[],
  handleCheckboxChange: (id: SelectedWinnerType) => void,
  handleSelectAllWinnerRows: () => void,
  selectedWinnerRows: SelectedWinnerType[],
  winnerStatusFilter: SweepstakeWinnerStatusType | null,
  setIsIndividualRedrawModalOpen: (
    data: RequestRedrawModalDataType | undefined
  ) => void,
  setIsViewRequestRedrawModalOpen: (value: boolean) => void,
  sweepstakeName: string
): Array<GridColDef<LocationWinnerTableProps>> => [
  {
    field: 'actions',
    headerName: '',
    minWidth: 50,
    renderCell: (params) => {
      const hasWinner = params.row.winnerName && params.row.winnerName !== '-'
      if (
        winnerStatusFilter &&
        !['RedrawRequest', 'PrizeClaim', 'NoWinner'].includes(
          params.row.winnerStatus
        )
      ) {
        return (
          <Checkbox
            checked={
              !isNil(
                selectedWinnerRows.find(
                  (winner) => winner.sweepstakesWinner.id === params.row.id
                )
              )
            }
            onChange={() =>
              handleCheckboxChange({
                locationName: params.row.leName,
                licenseNumber: params.row.leLicenseNumber,
                winnerName: params.row.winnerName,
                sweepstakesWinner: params.row.dto,
                drawingSequence: params.row.drawingSequence ?? 0,
              })
            }
            onClick={(event) => event.stopPropagation()}
          />
        )
      } else if (
        hasWinner &&
        !['PrizeClaim', 'NoWinner'].includes(params.row.winnerStatus)
      ) {
        return (
          <LocationPrizeMenu
            params={params}
            setIsIndividualRedrawModalOpen={() => {
              setIsIndividualRedrawModalOpen({
                licenseNumber: params.row.leLicenseNumber,
                locationName: params.row.leName,
                winnerName: params.row.winnerName,
                sweepstakeDrawingId: params.row.dto.sweepstakeDrawingId,
              })
            }}
            setIsViewRedrawRequestModalOpen={setIsViewRequestRedrawModalOpen}
            sweepstakeName={sweepstakeName}
          />
        )
      }
      return null
    },
    renderHeader: () =>
      winnerStatusFilter &&
      !['RedrawRequest', 'PrizeClaim', 'NoWinner'].includes(
        winnerStatusFilter
      ) ? (
        <Checkbox
          checked={
            selectedWinnerRows.length ===
            locationWinners.filter(
              (row) => row.winnerStatus === winnerStatusFilter
            ).length
          }
          onChange={handleSelectAllWinnerRows}
        />
      ) : null,
  },
  {
    field: 'leName',
    minWidth: 200,
    headerName: 'Licensed Establishment',
    flex: 1,
  },
  {
    field: 'leLicenseNumber',
    minWidth: 200,
    headerName: 'License Number',
    flex: 1,
    renderCell: (params) => (
      <Typography color={colors.text.primary}>{params.value}</Typography>
    ),
  },
  {
    field: 'winnerName',
    minWidth: 200,
    headerName: 'Winner Name',
    flex: 1,
    renderCell: (params) => (
      <Typography color={colors.text.primary}>{params.value}</Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Winner Status',
    minWidth: 150,
    flex: 1,
    filterable: false,
    valueGetter: (params) => {
      return params.row.winnerStatus
    },
    renderCell: (
      params: GridRenderCellParams<
        LocationWinnerTableProps,
        SweepstakeWinnerStatusType
      >
    ) => {
      if (isPresent(params.value)) {
        const statusText = params.value
        const style = getStatusStyleColors(params.value)
        return <Box sx={{ ...style }}>{toUpper(statusTextMap[statusText])}</Box>
      }

      return null
    },
  },
  {
    field: 'drawnDate',
    minWidth: 200,
    headerName: 'Date Drawn',
    flex: 1,
    renderCell: (params) => (
      <Typography color={colors.text.primary}>
        {params.value === '-' ? params.value : formatFullDate(params.value)}
      </Typography>
    ),
  },
]
