import { isNil } from 'lodash'
import { PatronTierSubtitle } from './PatronTierSubtitle'
import { Typography } from '@mui/material'
import { getMemberSince } from 'utils/util'
import type { components } from 'api/playerPayback/api'

export const PatronAccountDetailPageSubtitle = ({
  patronAccount,
}: {
  patronAccount: components['schemas']['FullPatronDTO']
}) => {
  const patronTier = patronAccount.currentTier?.tier
    ? patronAccount.currentTier.tier
    : null
  return (
    <Typography>
      {patronTier && <PatronTierSubtitle patronTier={patronTier?.type} />}
      {!patronTier && <PatronTierSubtitle patronTier={'Member'} />}
      {!isNil(patronAccount.createdOn) && (
        <Typography variant="body-3" className="whitespace-pre-line">
          |
        </Typography>
      )}
      <Typography
        sx={{ marginLeft: '15px' }}
        variant="body-3"
        className="whitespace-pre-line"
      >
        {getMemberSince(patronAccount.createdOn, 'MM-dd-yyyy')}
      </Typography>
    </Typography>
  )
}
