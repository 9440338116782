import { Box, Typography, useTheme, Switch } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'

interface SizeSwitchProps {
  rewardsOptions: string | null
}

export const SizeSwitch = ({ rewardsOptions }: SizeSwitchProps) => {
  const theme = useTheme()
  const { control } = useFormContext()

  return (
    <Box
      sx={{
        border: 1,
        borderColor: theme.palette.grey[300],
        m: '16px',
        gap: '16px',
        p: '16px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          gap: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Size
        </Typography>
        <Typography variant="body2" color="text.secondary">
          For reward items like clothing with various sizes
        </Typography>
      </Box>
      <Controller
        name="type"
        control={control}
        defaultValue={rewardsOptions}
        render={({ field, field: { value } }) => {
          return (
            <Switch
              {...field}
              checked={value === 'Size'}
              onChange={(e) =>
                field.onChange(e.target.checked ? 'Size' : 'Unknown')
              }
            />
          )
        }}
      />
    </Box>
  )
}
