import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type RewardRequest, type Reward } from 'types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UsePutRewardProps {
  id: number
  onSuccess?: (data: Reward, variables: RewardRequest) => void
  onError?: (error: Error, variables: RewardRequest) => void
}

export const usePutReward = ({ id, onSuccess, onError }: UsePutRewardProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, RewardRequest>({
    mutationFn: async (data: RewardRequest) => {
      const response = await put({ ...data, id })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards/{id}', id],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
