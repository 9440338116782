import { Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export const SweepstakeDetailsTabs = ({
  currentTab,
  sweepstakeId,
}: {
  currentTab: any
  sweepstakeId: number
}) => {
  return (
    <Tabs value={currentTab}>
      <Tab
        label="Overview"
        value={`/Contests/Sweepstakes/${sweepstakeId}/Overview`}
        to={`/Contests/Sweepstakes/${sweepstakeId}/Overview`}
        component={Link}
      />
      <Tab
        label="Participating Locations"
        value={`/Contests/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
        to={`/Contests/Sweepstakes/${sweepstakeId}/ParticipatingLocations`}
        component={Link}
      />
      <Tab
        label="Winners"
        value={`/Contests/Sweepstakes/${sweepstakeId}/Winners`}
        to={`/Contests/Sweepstakes/${sweepstakeId}/Winners`}
        component={Link}
      />
      <Tab
        label="Settings"
        value={`/Contests/Sweepstakes/${sweepstakeId}/Settings`}
        to={`/Contests/Sweepstakes/${sweepstakeId}/Settings`}
        component={Link}
      />
    </Tabs>
  )
}
