// Like Required<T>, but rejects null
export type ReallyRequired<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

interface Identifiable {
  id?: number | undefined | null
}

export type RequiredId<T extends Identifiable> = T & { id: number }

export const isIdPresent = <T extends Identifiable>(
  x: T
): x is RequiredId<T> => {
  return x.id !== undefined && x.id !== null
}
